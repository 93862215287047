import { Outlet, Route, Routes } from 'react-router-dom'

import {
  Flex,
  Link,
  Page,
  PageHeader,
  PageHeaderActions,
  PageHeaderPrimaryAction,
  Text,
} from '@bounty/brands-design-system'

import { BriefDrawer } from './BriefsDrawer/BriefsDrawer'
import { BriefsTable } from './BriefsTable'

export const Bounties = () => {
  return (
    <Page
      name="Bounties"
      fixedHeight="all"
      fluid
      addBottomSpacing={false}
      paddingBottom="12"
      width="100%"
      data-testid="briefs-page"
    >
      <Flex justifyContent="space-between">
        <PageHeader
          title="Bounties"
          //TODO: Update support article and link to Bounties page
          description={
            <Text maxW={{ lg: '70%' }}>
              Think of a Bounty like a campaign. Anyone can join public Bounties
              through your store listing or any signup form, private Bounties
              can only be joined via their direct link, and a Bounty that’s
              inactive cannot be joined.
            </Text>
          }
          actions={
            <PageHeaderActions>
              <PageHeaderPrimaryAction
                event="Add Brief Clicked"
                as={Link}
                to="./new"
                data-testid="add-brief-button"
              >
                Create Bounty
              </PageHeaderPrimaryAction>
            </PageHeaderActions>
          }
        />
      </Flex>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <BriefsTable />
              <Outlet />
            </>
          }
        >
          <Route
            path="details/:briefId"
            element={<BriefDrawer mode="update" rootPath="/bounties" />}
          />
          <Route
            path="new/*"
            element={<BriefDrawer mode="add" rootPath="/bounties" />}
          />
        </Route>
      </Routes>
    </Page>
  )
}
