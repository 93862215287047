import {
  Flex,
  Skeleton,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
} from '@bounty/brands-design-system'
import { getLast30Days } from '@bounty/utils'
import { useQueryBackend } from '../../../../../apollo/backend/hooks'
import { GiftOfferStatsDocument } from '../../../../../generated/backendGraphql'
import { useGiftsHistoryState } from '../../useGiftsHistory'

export const GiftsHistoryStatTiles = () => {
  const { value } = useGiftsHistoryState()

  const { data, loading } = useQueryBackend(GiftOfferStatsDocument, {
    variables: {
      params: {
        startDate: value.params.startDate ?? getLast30Days()[0].toISOString(),
        endDate: value.params.endDate ?? getLast30Days()[0].toISOString(),
      },
    },
  })

  return (
    <StatGroup>
      <Stat>
        <StatLabel>Gift Links Sent</StatLabel>
        <Flex justifyContent={'space-between'} alignItems="center">
          {!data || loading ? (
            <Skeleton my="10px" height="24px" width="30px" />
          ) : (
            <StatNumber>{data.giftOfferStats.totalGifts}</StatNumber>
          )}
        </Flex>
      </Stat>
      <Stat>
        <StatLabel>Gifts Redeemed</StatLabel>
        <Flex justifyContent={'space-between'} alignItems="center">
          {!data || loading ? (
            <Skeleton my="10px" height="24px" width="60px" />
          ) : (
            <StatNumber>{data.giftOfferStats.giftsRedeemed}</StatNumber>
          )}
        </Flex>
      </Stat>
      <Stat>
        <StatLabel>Bounties Posted</StatLabel>
        <Flex justifyContent={'space-between'} alignItems="center">
          {!data || loading ? (
            <Skeleton my="10px" height="24px" width="60px" />
          ) : (
            <StatNumber>{data.giftOfferStats.bountiesPosted}</StatNumber>
          )}
        </Flex>
      </Stat>
    </StatGroup>
  )
}
