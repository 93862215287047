import { Button, Link, Page, Text } from '@bounty/brands-design-system'
import {
  Flex,
  PageHeader,
  PageHeaderActions,
  PageHeaderPrimaryAction,
} from '@bounty/brands-design-system'
import { Routes, Route, Outlet } from 'react-router-dom'
import { GiftsTable } from './GiftsTable'
import { GiftDrawer } from './GiftDrawer/GiftDrawer'
import { LearnMoreLink } from '../../components/LearnMoreLink/LearnMoreLink'

export type GiftsProps = unknown

export const Gifts = () => {
  return (
    <Page
      name="Gifts"
      fixedHeight="all"
      fluid
      addBottomSpacing={false}
      paddingBottom="12"
      width="100%"
      data-testid="gifts-page"
    >
      <Flex justifyContent="space-between">
        <PageHeader
          title="Gifts"
          description={
            <Text as="span">
              Offers you can give to anyone to get them to join your community
              of creators on Bounty, or to reward your existing creators. Gifts
              can be products or Bounties.{' '}
              <LearnMoreLink href="https://brands.support.bounty.co/hc/en-us/sections/11757708438427-Gifting" />
            </Text>
          }
          actions={
            <PageHeaderActions>
              <PageHeaderPrimaryAction
                as={Link}
                to="/creators/invite/advanced"
                event="Clicked open bulk link generator"
              >
                Bulk link generator
              </PageHeaderPrimaryAction>
              <PageHeaderPrimaryAction
                event="Add Gift Clicked"
                as={Link}
                to="./new"
              >
                Add Gift
              </PageHeaderPrimaryAction>
            </PageHeaderActions>
          }
        />
      </Flex>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <GiftsTable />
              <Outlet />
            </>
          }
        >
          <Route
            path="edit/:giftId"
            element={<GiftDrawer mode="edit" rootPath="/gifts" />}
          />
          <Route
            path="details/:giftId"
            element={<GiftDrawer mode="details" rootPath="/gifts" />}
          />
          <Route
            path="new/*"
            element={<GiftDrawer mode="create" rootPath="/gifts" />}
          />
        </Route>
      </Routes>
    </Page>
  )
}
