import { ReactNode } from 'react'
import { Box, Link, Text, Flex, Logo } from '@bounty/brands-design-system'
import { SUPPORT_EMAIL } from '../const'

export const WelcomeNav = ({
  assumeSpacing = false,
  centerComponent,
}: {
  assumeSpacing?: boolean
  centerComponent?: ReactNode
}) => {
  return (
    <Flex
      {...(assumeSpacing
        ? {
            maxWidth: '1500px',
            margin: '0 auto',
            paddingX: ['1rem', '2rem', '3rem', '4rem'],
          }
        : {})}
      alignItems="center"
      justifyContent="space-between"
      paddingY="4"
      height="75px"
    >
      <Flex justifyContent="flex-start" flex="1">
        <Logo style={{ width: '80px' }} />
      </Flex>
      {centerComponent ? (
        <Flex
          justifyContent="center"
          flex="1"
          display={['none', 'none', 'flex']}
        >
          {centerComponent}
        </Flex>
      ) : null}
      <Flex
        flex={['2', '2', '1']}
        alignItems="center"
        justifyContent="flex-end"
      >
        <Box>
          <Text display="inline" fontSize={['xs', 'sm']} textAlign="right">
            Need help? Email{' '}
          </Text>
          <Link
            color="primary.500"
            showIcon={false}
            display="inline"
            fontSize={['xs', 'sm']}
            isExternal
            href={`mailto:${SUPPORT_EMAIL}?subject=Bounty Support Request`}
          >
            {SUPPORT_EMAIL}
          </Link>
        </Box>
      </Flex>
    </Flex>
  )
}
