import { Box, Flex, Page, PageHeader, Text } from '@bounty/brands-design-system'
import { Route, Routes } from 'react-router-dom'
import { DateRangeSelect } from './components/DateRangeSelect/DateRangeSelect'
import { CreatorDetailsDrawer } from '../../Creators/CreatorDetailsDrawer/CreatorDetailsDrawer'
import { GiftDrawer } from '../../Gifts/GiftDrawer/GiftDrawer'
import { GiftOfferDetailsDrawer } from './components/GiftOfferDetailsDrawer/GiftOfferDetailsDrawer'
import { GiftsHistoryStatTiles } from './components/GiftsHistoryStatTiles/GiftsHistoryStatTiles'
import { GiftsHistoryTable } from './components/GiftsHistoryTable/GiftsHistoryTable'
import { GiftsHistoryTableFilters } from './components/GiftsHistoryTable/GiftsHistoryTableFilters'
import { GiftsHistoryTablePagination } from './components/GiftsHistoryTable/GiftsHistoryTablePagination'
import { GiftsHistoryTableSearch } from './components/GiftsHistoryTable/GiftsHistoryTableSearch'

export const GiftsHistoryPerformancePage = () => {
  return (
    <Page
      data-testid="gift-history-performance-page"
      name="Gifts History"
      addBottomSpacing={false}
      fluid
      width="100%"
      px={16}
      pb={8}
    >
      <PageHeader
        title="Gifts History"
        description={
          <Text as="span">
            View past gifts and measure the performance of your gifting
            campaigns.
          </Text>
        }
        actions={<DateRangeSelect />}
        mb={12}
      />
      <Box as="main">
        <Box mb={8}>
          <GiftsHistoryStatTiles />
        </Box>
        <Flex gridGap={6}>
          <Box flexGrow={1}>
            <GiftsHistoryTableSearch />
          </Box>
          <GiftsHistoryTableFilters />
        </Flex>
        <Box mb={8} minHeight="300px">
          <GiftsHistoryTable />
        </Box>
        <GiftsHistoryTablePagination />
      </Box>
      <Routes>
        <Route
          path="creator-details/:userId/*"
          element={
            <CreatorDetailsDrawer
              rootPath="/performance/gifts-history"
              showBackButton={true}
            />
          }
        />
        <Route
          path="gift-details/:giftId/*"
          element={
            <GiftDrawer rootPath="/performance/gifts-history" mode="details" />
          }
        />
        <Route
          path="gift-offer-details/:giftOfferId/*"
          element={
            <GiftOfferDetailsDrawer rootPath="/performance/gifts-history" />
          }
        />
      </Routes>
    </Page>
  )
}
