import { Storefront } from '../../generated/backendGraphql'
import { FeaturedProductIdType } from './FeaturedProducts'

type FeaturedProductIdsType = Storefront['featuredProductIds']

export const generateUpdatedProductIds = (
  featuredProductIds: FeaturedProductIdsType | undefined,
  allSelectedProductIds: FeaturedProductIdType[],
) => {
  const currentFeaturedProductIds: FeaturedProductIdType[] = []

  featuredProductIds?.forEach((id) => {
    if (allSelectedProductIds.includes(id)) {
      currentFeaturedProductIds.push(id)
    }
  })

  const updatedFeaturedProductIds: FeaturedProductIdType[] =
    currentFeaturedProductIds
      .concat(allSelectedProductIds)
      .reduce((acc: FeaturedProductIdType[], curr: FeaturedProductIdType) => {
        if (!acc.includes(curr)) {
          acc.push(curr)
        }
        return acc
      }, [])

  return updatedFeaturedProductIds
}
