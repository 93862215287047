import { Navigate, Route, Routes } from 'react-router-dom'
import { PageSkeleton } from '@bounty/brands-design-system'
import { StorefrontDocument } from '../../generated/backendGraphql'
import { useQueryBackend } from '../../apollo/backend/hooks'
import { StorefrontOverview } from './StorefrontOverview'
import { Deals } from './Deals'
import { FeaturedProducts } from './FeaturedProducts'
import { FeaturedBounties } from './FeaturedBounties'

export const Storefront = () => {
  const { data, loading } = useQueryBackend(StorefrontDocument)

  if (loading || !data) return <PageSkeleton />
  return (
    <Routes>
      <Route path="/" element={<StorefrontOverview />} />
      <Route
        path="/deals"
        element={<Deals dealsData={data.storefront?.deals ?? []} />}
      />
      <Route path="/products" element={<FeaturedProducts />} />
      <Route path="/bounties" element={<FeaturedBounties />} />
      <Route element={<Navigate to="/" replace />} path="*" />
    </Routes>
  )
}
