import {
  Text,
  Skeleton,
  Tile,
  VStack,
  HStack,
  Link,
  Image,
  Button,
} from '@bounty/brands-design-system'
import { useQueryBackend } from '../../../apollo/backend/hooks'
import {
  CreatorDetailUserTileDocument,
  SocialProfile,
} from '../../../generated/backendGraphql'
import { useParamsOrThrow } from '@bounty/hooks'
import { getLinkToSocialAccount } from '../../../utils/externalContentLinksGetters'
import InstagramLogo from '../../../images/instagram-logo.png'
import TikTokLogo from '../../../images/tiktok-logo.png'
import { prettyCount } from '@bounty/utils'
import { getSocialPlatformType } from '../../../utils/codesToDisplayMapings'

export const CreatorDetailUserTile = () => {
  const { userId } = useParamsOrThrow(['userId'])

  const { data, loading } = useQueryBackend(CreatorDetailUserTileDocument, {
    variables: { userId },
  })
  const details = data?.creatorDetails

  if (!details || loading) {
    return (
      <Tile mt={10} mb="2" width="100%" borderRadius="md" background="white">
        <VStack spacing={4}>
          <Skeleton width="100%" height="3rem" />
          <Skeleton width="100%" height="3rem" />
        </VStack>
      </Tile>
    )
  }

  return (
    <VStack width="100%" spacing={'8'}>
      {details.tikTokProfile && (
        <CreatorSocialMediaDetailTile
          socialMediaPlatform={details.tikTokProfile}
          logo={TikTokLogo}
        />
      )}
      {details.instagramProfile && (
        <CreatorSocialMediaDetailTile
          socialMediaPlatform={details.instagramProfile}
          logo={InstagramLogo}
        />
      )}
    </VStack>
  )
}

type CreatorSocialMediaDetailTileProps = {
  socialMediaPlatform: SocialProfile
  logo: string
}

export const CreatorSocialMediaDetailTile = ({
  socialMediaPlatform,
  logo,
}: CreatorSocialMediaDetailTileProps) => {
  return (
    <Tile width={'100%'} backgroundColor={'neutral.50'}>
      <VStack spacing={'4'}>
        <HStack
          justifyContent="space-between"
          width="100%"
          spacing={'2'}
          flexWrap={'wrap'}
        >
          <HStack>
            <Image height="6" width="6" objectFit={'contain'} src={logo} />
            <Text fontWeight={'semibold'}>
              {socialMediaPlatform.profileName}
            </Text>
          </HStack>
          {socialMediaPlatform?.profileName}
          <HStack>
            <Text fontWeight={'semibold'}>
              {prettyCount(socialMediaPlatform.followerCount ?? 0)}
            </Text>
            <Text>Followers</Text>
          </HStack>
          <HStack>
            <Text fontWeight={'semibold'}>
              {prettyCount(socialMediaPlatform.followingCount ?? 0)}
            </Text>
            <Text>Following</Text>
          </HStack>
        </HStack>
        <Text alignSelf={'flex-start'}>
          {socialMediaPlatform.bioDescription}
        </Text>
        <Button
          as={Link}
          variant={'outline'}
          isExternal={true}
          href={getLinkToSocialAccount(
            socialMediaPlatform.profileName,
            socialMediaPlatform.type,
          )}
          width="100%"
          event={{
            eventName: 'View User on Social Media Platform Clicked',
            platform: getSocialPlatformType(socialMediaPlatform.type),
          }}
        >
          View on {getSocialPlatformType(socialMediaPlatform.type)}
        </Button>
      </VStack>
    </Tile>
  )
}
