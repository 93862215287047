import dlv from 'dlv'
import { forwardRef, ReactNode } from 'react'
import { FieldValues, useFormContext } from 'react-hook-form'
import { Select, SelectProps } from '../../Chakra'
import { FastFormControl } from './FastFormControl'
import { FastFormElementProps } from './types'
import { getLabelFromName } from './utils'

export type FastFormNativeSelectBaseProps = SelectProps
export const FastFormNativeSelectBase = forwardRef<
  any,
  FastFormNativeSelectBaseProps
>((props, ref) => {
  return <Select ref={ref} {...props} />
})

FastFormNativeSelectBase.displayName = 'FastFormNativeSelectBase'

export type FastFormNativeSelectProps<
  TFieldValues extends FieldValues = FieldValues,
> = FastFormElementProps<FastFormNativeSelectBaseProps, TFieldValues> & {
  children: ReactNode
  placeholder?: string
}

export const FastFormNativeSelect = <
  TFieldValues extends FieldValues = FieldValues,
>({
  label,
  name,
  helpText,
  labelProps,
  tooltipText,
  errorProps,
  children,
  isRequired,
  isDisabled,
  formControlProps,
  formElementProps,
  placeholder,
  showLabel,
}: FastFormNativeSelectProps<TFieldValues>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<TFieldValues>()
  const error = dlv(errors, name)

  return (
    <FastFormControl
      error={error}
      helpText={helpText}
      label={label ?? getLabelFromName(name)}
      labelProps={labelProps}
      tooltipText={tooltipText}
      errorProps={errorProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
      showLabel={showLabel}
      {...formControlProps}
    >
      <FastFormNativeSelectBase
        placeholder={placeholder}
        {...formElementProps}
        {...register(name)}
      >
        {children}
      </FastFormNativeSelectBase>
    </FastFormControl>
  )
}
