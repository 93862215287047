import { FC, useState } from 'react'
import {
  Page,
  PageHeader,
  Link,
  PageHeaderPrimaryAction,
  PageHeaderActions,
  PageHeaderSecondaryAction,
  useDisclosure,
} from '@bounty/brands-design-system'
import { PreviousCustomersForm } from './PreviousCustomersForm'
import { Route, Routes } from 'react-router-dom'
import { GiftDrawer } from '../../../Gifts/GiftDrawer/GiftDrawer'
import { useMutationBackend } from '../../../../apollo/backend/hooks'
import {
  PreviewPreviousCustomerInviteRequestDocument,
  PreviewPreviousCustomerInviteRequestMutation,
} from '../../../../generated/backendGraphql'
import { PrevCustomerInviteRequestParamsSchemaType } from '@bounty/schemas'

export type ActivatePreviousCustomersProps = unknown

export const ActivatePreviousCustomers: FC<
  ActivatePreviousCustomersProps
> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isFormValid, setIsFormValid] = useState(false)
  const [previewData, setPreviewData] =
    useState<PreviewPreviousCustomerInviteRequestMutation | null>(null)

  const [formValues, setFormValues] =
    useState<null | PrevCustomerInviteRequestParamsSchemaType>(null)

  const [
    previewPrevCustomerInvite,
    { loading: previewPrevCustomerInviteLoading },
  ] = useMutationBackend(PreviewPreviousCustomerInviteRequestDocument)

  const triggerPreviewCall = async () => {
    if (!isFormValid || !formValues) {
      return
    }
    const res = await previewPrevCustomerInvite({
      variables: {
        params: formValues!,
      },
    })
    if (res?.data?.previewPreviousCustomerInviteRequest) {
      setPreviewData(res.data)
    }
  }

  return (
    <Page
      name="Activate previous customers"
      fixedHeight="all"
      fluid
      addBottomSpacing={false}
      paddingBottom="3rem"
      width="100%"
    >
      <Routes>
        <Route
          path="create-gift"
          element={
            <GiftDrawer
              mode="create"
              rootPath="/creators/invite/activate-previous-customers"
            />
          }
        />
      </Routes>
      <PageHeader
        title="Activate Previous Customers"
        description="We'll send an email to select customers to complete a Bounty for a
        previous purchase."
        breadcrumbs={[
          {
            name: 'Creators',
            to: '/creators',
          },
          {
            name: 'Invite',
            to: '/creators/invite',
          },
          {
            name: 'Current',
            to: '',
            isCurrentPage: true,
          },
        ]}
        actions={
          <PageHeaderActions>
            <PageHeaderSecondaryAction
              event="Invites Active Previous Customers Invite History Clicked"
              as={Link}
              to="/creators/invite/history"
            >
              History
            </PageHeaderSecondaryAction>
            <PageHeaderPrimaryAction
              data-testid="send-invites-button"
              onClick={async () => {
                await triggerPreviewCall()
                onOpen()
              }}
              event="Advanced Invites Download Invites Clicked"
              disabled={!isFormValid}
              isLoading={previewPrevCustomerInviteLoading}
            >
              Next
            </PageHeaderPrimaryAction>
          </PageHeaderActions>
        }
      />

      <PreviousCustomersForm
        setIsFormValid={setIsFormValid}
        isOpen={isOpen}
        onClose={onClose}
        setFormValues={setFormValues}
        previewData={previewData}
      />
    </Page>
  )
}
