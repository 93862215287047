import { ComponentProps } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { ExternalLinkIcon } from '../icons'
import { Link as ChakraLink, Text } from './Chakra'
import { defineStyle, defineStyleConfig, forwardRef } from '@chakra-ui/react'

const unstyled = defineStyle({
  textDecoration: 'none',
  _hover: { textDecoration: 'none' },
})

const normal = defineStyle({})

export const linkTheme = defineStyleConfig({
  variants: { unstyled, normal },
  baseStyle: {
    color: 'inherit',
    textDecoration: 'underline',
    _hover: { textDecoration: 'underline' },
  },
  defaultProps: {
    variant: 'normal',
  },
})

type LinkCommonProps = { variant?: 'normal' | 'unstyled' } & ComponentProps<
  typeof ChakraLink
>

export type LinkPropsExternal = {
  isExternal: true
  showIcon?: boolean
  to?: string
} & LinkCommonProps
export type LinkPropsInternal = { isExternal: false } & LinkCommonProps &
  ComponentProps<typeof RouterLink>

export type LinkProps = LinkPropsExternal | LinkPropsInternal

export const Link = forwardRef<LinkProps, 'a'>(
  ({ children, ...props }, ref) => {
    return props.isExternal ? (
      <ChakraLink
        target="_blank"
        rel="noopener noreferrer"
        data-element-name="link"
        ref={ref}
        {...props}
      >
        <Text as="span" display={'inline'}>
          {children}
        </Text>
        {props.showIcon && (
          <ExternalLinkIcon
            ml="1"
            display="inline-block"
            lineHeight="1em"
            verticalAlign="text-top"
          />
        )}
      </ChakraLink>
    ) : (
      <ChakraLink data-element-name="link" ref={ref} as={RouterLink} {...props}>
        {children}
      </ChakraLink>
    )
  },
)
