/**
 * SEE HERE FOR SEARCHABLE ICONS!
 * https://phosphoricons.com/
 */

import { FaLinkedin, FaTiktok, FaTwitter } from 'react-icons/fa'

/* eslint-disable import/export */
import { Icon, IconProps as ChakraIconProps } from '@chakra-ui/react'
import {
  Armchair,
  ArrowLeft,
  ArrowRight,
  ArrowsClockwise,
  ArrowSquareOut,
  At,
  Atom,
  Barbell,
  Bathtub,
  Book,
  BookBookmark,
  BracketsAngle,
  BriefcaseMetal,
  BuildingOffice,
  CalendarCheck,
  Car,
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
  ChartLineUp,
  ChatCircle,
  ChatCircleText,
  Check,
  CheckCircle,
  CircleWavyCheck,
  ClockClockwise,
  CreditCard,
  CurrencyCircleDollar,
  CurrencyDollar,
  Desktop,
  DeviceMobile,
  Dress,
  Envelope,
  Equals,
  Eye,
  ForkKnife,
  GameController,
  Garage,
  Gauge,
  Gear,
  Gift,
  Hamburger,
  Handshake,
  HandWaving,
  Hash,
  Heart,
  IconProps as PhosphorIconPropsCore,
  ImageSquare,
  Info,
  Island,
  Lifebuoy,
  MagicWand,
  MagnifyingGlass,
  Megaphone,
  Minus,
  Mountains,
  PaintBrush,
  Palette,
  PaperPlaneTilt,
  Play,
  Plus,
  Prohibit,
  Share,
  ShoppingBag,
  SignOut,
  Sparkle,
  SpeakerHigh,
  SpeakerSlash,
  Star,
  Storefront,
  StripeLogo,
  Tag,
  Trash,
  User,
  UserPlus,
  VideoCamera,
  Warning,
  WarningCircle,
  Wrench,
  X,
} from '@phosphor-icons/react'

export type PhosphorIconProps = Omit<ChakraIconProps, 'fontWeight'> &
  Omit<PhosphorIconPropsCore, 'color'> & {
    icon?: React.ForwardRefExoticComponent<
      PhosphorIconPropsCore & React.RefAttributes<SVGSVGElement>
    >
  }

const PhosphorIcon = ({
  icon,
  color = 'currentColor',
  size,
  ...props
}: PhosphorIconProps) => {
  return <Icon as={icon} color={color} fontSize={size} {...props} />
}

export const AddIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Plus} {...props} />
)
export const AddUserIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={UserPlus} {...props} />
)
export const AnnouncementsIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Megaphone} {...props} />
)
export const ArrowLeftIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={ArrowLeft} {...props} />
)
export const ArrowRightIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={ArrowRight} {...props} />
)
export const AtSignIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={At} {...props} />
)
export const BillingIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={CreditCard} {...props} />
)
export const CaretDownIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={CaretDown} {...props} />
)
export const CaretLeftIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={CaretLeft} {...props} />
)
export const CaretRightIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={CaretRight} {...props} />
)
export const CaretUpIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={CaretUp} {...props} />
)
export const ChartLineUpIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={ChartLineUp} {...props} />
)
export const CheckIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Check} {...props} />
)
export const CheckCircleIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={CheckCircle} {...props} />
)
export const CloseIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={X} {...props} />
)
export const CodeSlashIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={BracketsAngle} {...props} />
)
export const ContentLibraryIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={VideoCamera} {...props} />
)
export const CurrencyCircleDollarIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={CurrencyCircleDollar} {...props} />
)
export const CustomizeIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={PaintBrush} {...props} />
)
export const DesktopIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Desktop} {...props} />
)
export const DollarIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={CurrencyDollar} {...props} />
)
export const ErrorIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Warning} {...props} />
)
export const ExternalLinkIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={ArrowSquareOut} {...props} />
)
export const EyeIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Eye} {...props} />
)
export const FilledCalendarIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={CalendarCheck} {...props} />
)
export const GiftIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Gift} {...props} />
)
export const HandshakeIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Handshake} {...props} />
)
export const HandWavingIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={HandWaving} {...props} />
)
export const HashtagIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Hash} {...props} />
)
export const HeartIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Heart} {...props} />
)
export const ImageSquareIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={ImageSquare} {...props} />
)
export const InfoIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Info} {...props} />
)
export const LinkedinIcon = (props: ChakraIconProps) => (
  <Icon as={FaLinkedin} {...props} />
)
export const LogoutIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={SignOut} {...props} />
)
export const MailIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Envelope} {...props} />
)
export const MessageIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={ChatCircle} {...props} />
)
export const MinusIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Minus} {...props} />
)
export const MobilePhoneIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={DeviceMobile} {...props} />
)
export const PlayFillIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Play} {...props} />
)
export const PriceTagIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Tag} {...props} />
)
export const ProhibitIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Prohibit} {...props} />
)
export const RefreshIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={ArrowsClockwise} {...props} />
)
export const ResourcesIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={BookBookmark} {...props} />
)
export const ReleasesIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Book} {...props} />
)
export const RepeatClockIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={ClockClockwise} {...props} />
)
export const ReplyIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Share} {...props} />
)
export const SendIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={PaperPlaneTilt} {...props} />
)
export const SearchIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={MagnifyingGlass} {...props} />
)
export const SettingsIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Gear} {...props} />
)
export const ShopIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={ShoppingBag} {...props} />
)
export const SliderThumbIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Equals} {...props} />
)
export const SpeedometerIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Gauge} {...props} />
)
export const StarIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Star} {...props} />
)
export const StarsIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Sparkle} {...props} />
)
export const StripeIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={StripeLogo} {...props} />
)
export const SupportIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Lifebuoy} {...props} />
)
export const TikTokIcon = (props: ChakraIconProps) => (
  <Icon as={FaTiktok} {...props} />
)
export const TwitterIcon = (props: ChakraIconProps) => (
  <Icon as={FaTwitter} {...props} />
)
export const UserIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={User} {...props} />
)
export const VerifiedIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={CircleWavyCheck} {...props} />
)
export const VolumeMuteIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={SpeakerSlash} {...props} />
)
export const VolumeUpIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={SpeakerHigh} {...props} />
)
export const WarningCircleIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={WarningCircle} {...props} />
)
export const WrenchIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Wrench} {...props} />
)
export const TrashIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Trash} {...props} />
)
export const StorefrontIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Storefront} {...props} />
)
export const BriefcaseIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={BriefcaseMetal} {...props} />
)
export const MountainsIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Mountains} {...props} />
)
export const HamburgerIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Hamburger} {...props} />
)
export const GameControllerIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={GameController} {...props} />
)
export const DressIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Dress} {...props} />
)
export const PaletteIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Palette} {...props} />
)
export const MagicWandIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={MagicWand} {...props} />
)
export const BarbellIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Barbell} {...props} />
)
export const AtomIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Atom} {...props} />
)
export const ForkKnifeIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={ForkKnife} {...props} />
)
export const BathtubIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Bathtub} {...props} />
)
export const CarIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Car} {...props} />
)
export const ArmchairIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Armchair} {...props} />
)
export const IslandIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Island} {...props} />
)
export const BuildingOfficeIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={BuildingOffice} {...props} />
)
export const GarageIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={Garage} {...props} />
)
export const ChatCircleTextIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={ChatCircleText} {...props} />
)
export const UserPlusIcon: typeof PhosphorIcon = (props) => (
  <PhosphorIcon icon={UserPlus} {...props} />
)
