import {
  Alert,
  AlertDescription,
  AlertTitle,
  Link,
  Text,
} from '@bounty/brands-design-system'
import { useQueryBackend } from '../../apollo/backend/hooks'
import { FulfillmentEventsDiagnosticsCheckDocument } from '../../generated/backendGraphql'

export const FulfillmentEventsDiagnostics = () => {
  const { data, loading } = useQueryBackend(
    FulfillmentEventsDiagnosticsCheckDocument,
  )

  if (loading || !data) {
    return null
  }

  /**
   * No data will always happen when brands are new
   */
  const shouldShowAlert = () => {
    const ignore = ['OK', 'NO_DATA']
    return (
      ignore.includes(data.fulfillmentEventsDiagnosticsCheck.threeWeeksAgo) ===
        false ||
      ignore.includes(data.fulfillmentEventsDiagnosticsCheck.twoWeeksAgo) ===
        false
    )
  }

  if (!shouldShowAlert()) {
    return null
  }

  return (
    <Alert flexShrink="0" mb="6" status="warning">
      <AlertTitle fontSize="xl" fontWeight="semibold" mb="2">
        Your fulfillment events aren't being delivered consistently
      </AlertTitle>
      <AlertDescription fontSize="md">
        <Text fontSize="md" mt="4">
          In some cases fulfillment events may not be working properly for your
          Shopify store. If this is the case you can instead cue reminder
          notifications for creators off of a standard time delay.{' '}
          <Link to={'/settings'} isExternal={false}>
            Click here to configure standard time delay
          </Link>
        </Text>
        <Link
          isExternal={true}
          href={
            'https://brands.support.bounty.co/hc/en-us/articles/12372385535771-Fixing-Missing-Fulfillment-Events'
          }
        >
          You can learn more about troubleshooting the issue here.
        </Link>{' '}
      </AlertDescription>
    </Alert>
  )
}
