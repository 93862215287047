import { Button, InfoIcon, theme } from '@bounty/brands-design-system'
import { IconWeight } from '@phosphor-icons/react'
import { forwardRef, LegacyRef, ReactNode } from 'react'

type InfoButtonProps = {
  color?: string
  icon?: ReactNode
  name?: string
  weight?: IconWeight
}

export const InfoButton = forwardRef(
  (
    { color, icon, name, weight, ...rest }: InfoButtonProps,
    ref: LegacyRef<HTMLButtonElement>,
  ) => {
    return (
      <Button
        ref={ref}
        {...rest}
        variant="link"
        event={name ? `${name} info button click` : `info button click`}
        p="0"
        minWidth="2"
        borderRadius="full"
      >
        {icon ?? (
          <InfoIcon
            color={color ?? theme.colors.purple[400]}
            weight={weight ?? 'duotone'}
          />
        )}
      </Button>
    )
  },
)
