import {
  Tile,
  Box,
  Heading,
  Flex,
  Text,
  SwitchProps,
  FlexProps,
  HeadingProps,
  TextProps,
  Button,
  ButtonProps,
  forwardRef,
} from '@bounty/brands-design-system'
import { ReactNode } from 'react'

export const UserExperiencePreview = ({
  children,
}: {
  children: ReactNode
}) => {
  return (
    <Box
      width={['100%', '100%', '100%', '100%', '60%']}
      marginBottom={['8', '8', '8', '8', '0']}
    >
      <Heading pl="4" fontSize="lg" as="h3" size="sm" color="gray.600" mb="4">
        Preview
      </Heading>
      <Box p={['0', '0', '0', '4']} border="1px solid" borderColor="gray.600">
        {children}
      </Box>
    </Box>
  )
}

export type UserExperienceTogglerProps = FlexProps & {
  onChange: SwitchProps['onChange']
  isChecked: boolean
  isLoading: boolean
}

export const UserExperienceInfoContainer = ({
  children,
}: {
  children: ReactNode
}) => {
  return (
    <Flex
      flexDir="column"
      width={['100%', '100%', '100%', '100%', '400px']}
      mr="6"
    >
      {children}
    </Flex>
  )
}

export const UserExperienceHeading = ({ children, ...rest }: HeadingProps) => {
  return (
    <Heading size="md" mb="4" {...rest}>
      {children}
    </Heading>
  )
}

export const UserExperienceText = ({ children, ...rest }: TextProps) => {
  return (
    <Text mb="6" width="80%" {...rest}>
      {children}
    </Text>
  )
}

export const UserExperienceButtonContainer = ({
  children,
  ...rest
}: FlexProps) => {
  return (
    <Flex mt="6" alignItems="flex-start" flex={1} {...rest}>
      {children}
    </Flex>
  )
}

export const UserExperienceButton = forwardRef<ButtonProps, 'button'>(
  ({ children, ...rest }, ref) => {
    return (
      <Button size="lg" ref={ref} {...rest}>
        {children}
      </Button>
    )
  },
)

export const UserExperience = ({ children }: { children: ReactNode }) => {
  return (
    <Tile
      py="8"
      px="6"
      mb="6"
      as={Flex}
      flexDirection={[
        'column-reverse',
        'column-reverse',
        'column-reverse',
        'column-reverse',
        'row',
      ]}
      justifyContent="space-between"
    >
      {children}
    </Tile>
  )
}
