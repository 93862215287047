import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { WelcomeProvider } from './useWelcome'
import { WelcomeNavigator } from './WelcomeNavigator'
import { WELCOME_ROUTES } from './const'
import { WelcomeNav } from '../../components/WelcomeNav'

export const Wrapper = () => {
  return (
    <>
      <WelcomeNav assumeSpacing centerComponent={<WelcomeNavigator />} />
      <Outlet />
    </>
  )
}

export const Welcome = () => {
  return (
    <WelcomeProvider>
      <Routes>
        <Route element={<Wrapper />}>
          {WELCOME_ROUTES.map(({ component: Component, path }) => {
            return <Route key={path} element={<Component />} path={path} />
          })}

          <Route index element={<Navigate to="get-started" />} />
          <Route element={<Navigate to="get-started" />} path="*" />
        </Route>
      </Routes>
    </WelcomeProvider>
  )
}
