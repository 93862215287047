import { useClipboard, Box, Button, HStack } from '@bounty/brands-design-system'

export const CommunityLandingPageCopyLink = ({ slug }: { slug: string }) => {
  const LINK = `www.bounty.co/s/${slug}`
  const { hasCopied, onCopy } = useClipboard(LINK)
  return (
    <HStack>
      <Box
        display="inline-block"
        fontSize={['xs', 'sm']}
        borderRadius="lg"
        paddingY="2"
        paddingX="4"
        mb={['4', '0']}
        border={'1px'}
        borderColor={'gray.200'}
      >
        <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
          {LINK}
        </pre>
      </Box>
      <Button
        event="Code Snippet Copy Clicked"
        ml={'2'}
        onClick={onCopy}
        size="sm"
        variant="outline"
      >
        {hasCopied ? 'Copied' : 'Copy'}
      </Button>
    </HStack>
  )
}
