import { isFunction } from '@bounty/utils'
import dlv from 'dlv'
import { forwardRef, ReactNode } from 'react'
import {
  Controller,
  FieldValues,
  useFormContext,
  UseFormReturn,
} from 'react-hook-form'
import {
  InputGroup,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputFieldProps,
  NumberInputProps,
  NumberInputStepper,
} from '../../Chakra'
import { FastFormControl } from './FastFormControl'
import { FastFormElementProps } from './types'
import { getLabelFromName } from './utils'

export type FastFormNumberInputBaseProps =
  | ({
      numberMode: true
      numberInputFieldProps?: NumberInputFieldProps
      onChange?: (value: number) => void
    } & Omit<NumberInputProps, 'name' | 'onChange'>)
  | ({
      numberMode: false
      numberInputFieldProps?: NumberInputFieldProps
      onChange?: (value: string) => void
    } & Omit<NumberInputProps, 'name' | 'onChange'>)

export const FastFormNumberInputBase = forwardRef<
  any,
  FastFormNumberInputBaseProps
>(({ numberMode, onChange, numberInputFieldProps, ...rest }, ref) => {
  return (
    <NumberInput
      allowMouseWheel
      ref={ref}
      {...rest}
      onChange={(valueAsString, valueAsNumber) => {
        if (numberMode) {
          onChange?.(isNaN(valueAsNumber) ? 0 : valueAsNumber)
        } else {
          onChange?.(valueAsString)
        }
      }}
    >
      <NumberInputField {...numberInputFieldProps} />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  )
})

FastFormNumberInputBase.displayName = 'FastFormNumberInputBase'

export type FastFormNumberInputProps<
  TFieldValues extends FieldValues = FieldValues,
> = FastFormElementProps<FastFormNumberInputBaseProps, TFieldValues> & {
  children?: (props: UseFormReturn<TFieldValues, object>) => ReactNode
  leftElement?: ReactNode
} & { placeholder?: string }

export const FastFormNumberInput = <
  TFieldValues extends FieldValues = FieldValues,
>({
  label,
  name,
  helpText,
  labelProps,
  tooltipText,
  errorProps,
  children,
  leftElement,
  isRequired,
  isDisabled,
  formControlProps,
  formElementProps,
  placeholder,
  showLabel,
}: FastFormNumberInputProps<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>()
  const {
    formState: { errors },
    control,
  } = methods
  const error = dlv(errors, name)

  return (
    <FastFormControl
      error={error}
      helpText={helpText}
      label={label ?? getLabelFromName(name)}
      labelProps={labelProps}
      tooltipText={tooltipText}
      errorProps={errorProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
      showLabel={showLabel}
      {...formControlProps}
    >
      {children != null && isFunction(children) ? (
        children(methods)
      ) : leftElement ? (
        <InputGroup>
          {leftElement}

          <Controller
            control={control}
            name={name}
            render={({ field }) => {
              return (
                <FastFormNumberInputBase
                  numberInputFieldProps={{
                    pl: leftElement ? '8' : '4',
                    placeholder,
                  }}
                  numberMode
                  {...formElementProps}
                  {...field}
                />
              )
            }}
          />
        </InputGroup>
      ) : (
        <Controller
          control={control}
          name={name}
          render={({ field }) => {
            return (
              <FastFormNumberInputBase
                numberInputFieldProps={{
                  pl: leftElement ? '8' : '4',
                  placeholder,
                }}
                numberMode
                {...formElementProps}
                {...field}
              />
            )
          }}
        />
      )}
    </FastFormControl>
  )
}
