import * as z from 'zod'

export const shopifyStoreTikTokSchema = z.object({
  tikTokUsername: z
    .string()
    .min(1, 'TikTok is required.')
    .regex(
      /^@[\w|_|-|.]+$/,
      'TikTok username must start with @ sign and contain no spaces.',
    ),
})
