import { Flex } from '@bounty/brands-design-system'

type FromDataDisplayWrapperProps = {
  children: React.ReactNode
}
export const FormDataDisplayWrapper = ({
  children,
}: FromDataDisplayWrapperProps) => {
  return (
    <Flex
      mb="10"
      direction={['column', null, 'row']}
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
    >
      {children}
    </Flex>
  )
}
