import {
  Box,
  Flex,
  Skeleton,
  useFastForm,
  useToast,
} from '@bounty/brands-design-system'
import { useEffect } from 'react'
import { z } from 'zod'
import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import {
  DeliveryTimeDelayFormDocument,
  UpdateDeliveryTimeDelayDocument,
} from '../../generated/backendGraphql'

const UpdateDeliveryTimeDelaySchema = z.object({
  deliveryTimeoutDays: z.number().min(3).max(45),
})

export const DeliveryTimeDelayForm = () => {
  const toast = useToast()

  const { data, loading } = useQueryBackend(DeliveryTimeDelayFormDocument)

  const [updateDeliveryTimeDelay, { loading: updateDeliveryTimeDelayLoading }] =
    useMutationBackend(UpdateDeliveryTimeDelayDocument)

  const {
    Form,
    NumberInput,
    SubmitButton,
    methods: { setValue },
  } = useFastForm({
    schema: UpdateDeliveryTimeDelaySchema,
    defaultValues: {
      deliveryTimeoutDays: data?.currentStore.deliveryTimeoutDays || 30,
    },
  })

  useEffect(() => {
    if (data?.currentStore.deliveryTimeoutDays) {
      setValue('deliveryTimeoutDays', data.currentStore.deliveryTimeoutDays)
    }
  }, [data?.currentStore.deliveryTimeoutDays, setValue])

  if (!data || loading) return <Skeleton height="8" width="310px" />

  return (
    <Form
      onSubmit={async ({ deliveryTimeoutDays }) => {
        const { data } = await updateDeliveryTimeDelay({
          variables: {
            deliveryTimeoutDays,
          },
        })
        if (data) {
          toast({
            status: 'success',
            description: 'Delivery time delay updated.',
          })
        }
      }}
    >
      <Flex alignItems="center" justifyContent="flex-start" maxWidth="310px">
        <Box mr="2">
          <NumberInput
            name="deliveryTimeoutDays"
            label="Time Delay"
            isRequired={false}
            helpText="days"
          />
        </Box>
        <Box pb={4}>
          <SubmitButton
            event={'Update Bounty Limit Per Month Submit Clicked'}
            isLoading={updateDeliveryTimeDelayLoading}
          >
            Submit
          </SubmitButton>
        </Box>
      </Flex>
    </Form>
  )
}
