import dlv from 'dlv'
import { forwardRef } from 'react'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import { ColorInput, ColorInputProps } from '../ColorInput'
import { FastFormControl } from './FastFormControl'
import { FastFormElementProps } from './types'
import { getLabelFromName } from './utils'

export type FastFormColorInputBaseProps = ColorInputProps
export const FastFormColorInputBase = forwardRef<
  any,
  FastFormColorInputBaseProps
>((props, ref) => {
  return <ColorInput ref={ref} {...props} />
})

FastFormColorInputBase.displayName = 'FastFormColorInputBase'

export type FastFormColorInputProps<
  TFieldValues extends FieldValues = FieldValues,
> = FastFormElementProps<
  Omit<FastFormColorInputBaseProps, 'name' | 'onChange' | 'value'>,
  TFieldValues
> & {
  placeholder?: string
}

export const FastFormColorInput = <
  TFieldValues extends FieldValues = FieldValues,
>({
  label,
  name,
  helpText,
  labelProps,
  tooltipText,
  errorProps,
  isRequired,
  isDisabled,
  formControlProps,
  formElementProps,
  placeholder,
  showLabel,
}: FastFormColorInputProps<TFieldValues>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<TFieldValues>()
  const error = dlv(errors, name)

  return (
    <FastFormControl
      error={error}
      helpText={helpText}
      label={label ?? getLabelFromName(name)}
      labelProps={labelProps}
      tooltipText={tooltipText}
      errorProps={errorProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
      showLabel={showLabel}
      {...formControlProps}
    >
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <FastFormColorInputBase
              placeholder={placeholder}
              {...formElementProps}
              {...field}
            />
          )
        }}
      />
    </FastFormControl>
  )
}
