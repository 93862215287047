import {
  PageHeaderContents,
  PageHeaderTitle,
  PageHeaderInformation,
  PageHeaderActions,
  PageHeaderPrimaryAction,
  Image,
  Page,
  Heading,
  Flex,
  Text,
  HeadingProps,
  TextProps,
  Button,
  ButtonProps,
  Tile,
  forwardRef,
  ImageProps,
  Switch,
  PageSkeleton,
  Skeleton,
  Tooltip,
  Link as ChakraLink,
  VStack,
  PageHeaderDescription,
  Alert,
  AlertTitle,
  AlertDescription,
  Box,
  Stack,
  Metric,
} from '@bounty/brands-design-system'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import StorefrontBranding from '../../images/storefront-branding.png'
import StorefrontProducts from '../../images/storefront-featured-products.png'
import StorefrontBounties from '../../images/storefront-featured-bounties.png'
import { useAnalytics } from '@bounty/web-analytics'
import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import {
  ToggleStorefrontDocument,
  StorefrontDocument,
} from '../../generated/backendGraphql'
import { LearnMoreLink } from '../../components/LearnMoreLink/LearnMoreLink'
import { InstallSnippetToggleButton } from '../UserExperiences/InstallSnippetToggleButton'
import { prettyCurrency, prettyPercentage } from '@bounty/utils'
import { FEATURE_STOREFRONT_METRICS } from '../../config/env'

const StorefrontTile = ({ children }: { children: ReactNode }) => {
  return (
    <Tile
      py="8"
      px="6"
      mb="6"
      as={Flex}
      flexDirection={['column-reverse', null, null, null, 'row']}
      justifyContent="space-between"
    >
      {children}
    </Tile>
  )
}

const StorefrontTextContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Flex
      flexDir="column"
      width={['100%', '100%', '100%', '100%', '600px']}
      mr="6"
    >
      {children}
    </Flex>
  )
}

const StorefrontHeading = ({ children, ...rest }: HeadingProps) => {
  return (
    <Heading size="md" mb="4" {...rest}>
      {children}
    </Heading>
  )
}

const StorefrontText = ({ children, ...rest }: TextProps) => {
  return (
    <Text mb="6" width="80%" {...rest}>
      {children}
    </Text>
  )
}

const StorefrontButton = forwardRef<ButtonProps, 'button'>(
  ({ children, ...rest }, ref) => {
    return (
      <Button size="md" width="min-content" ref={ref} {...rest}>
        {children}
      </Button>
    )
  },
)

const StorefrontImage = ({
  image,
  altText,
  ...rest
}: ImageProps & {
  image: string
  altText: string
}) => {
  return (
    <Image
      src={image}
      alt={altText}
      height="24"
      width="56"
      objectFit="contain"
      objectPosition={['center left', null, null, 'center center']}
      my="auto"
      pb="2"
      {...rest}
    />
  )
}

export const StorefrontOverview = () => {
  const { track } = useAnalytics()
  const { loading, data: { storefront, currentStore, pixelMetrics } = {} } =
    useQueryBackend(StorefrontDocument)
  const [toggleStorefront] = useMutationBackend(ToggleStorefrontDocument)

  if (!storefront || loading) return <PageSkeleton />

  const isPixelInstalled = currentStore?.embeds?.find(
    (e) => e.code === 'PIXEL',
  )?.enabled

  const hasMetrics =
    FEATURE_STOREFRONT_METRICS &&
    pixelMetrics &&
    Object.values(pixelMetrics)
      .filter((v) => typeof v === 'number')
      .every((v) => v !== 0)

  if (!currentStore?.isStorefrontEligible) {
    return (
      <Page
        name="Storefront"
        data-testid="storefront"
        fixedHeight="all"
        fluid
        addBottomSpacing={false}
        paddingBottom="3rem"
        width="100%"
      >
        <PageHeaderInformation>
          <Flex alignItems="center" gap="3">
            <PageHeaderTitle>Storefront</PageHeaderTitle>
          </Flex>
        </PageHeaderInformation>
        <Flex>
          <Alert status="warning">
            <AlertTitle>This feature is not available.</AlertTitle>
            <AlertDescription>
              Not all stores are eligible for storefront. Please contact support
              to learn more.
            </AlertDescription>
          </Alert>
        </Flex>
      </Page>
    )
  }

  return (
    <Page
      name="Storefront"
      data-testid="storefront"
      fixedHeight="all"
      fluid
      addBottomSpacing={true}
      paddingBottom="rem"
      width="100%"
    >
      <PageHeaderContents>
        <PageHeaderInformation>
          <Flex alignItems="center" gap="3">
            <PageHeaderTitle>Storefront</PageHeaderTitle>
            {storefront ? (
              <VStack>
                <Tooltip
                  hasArrow
                  shouldWrapChildren
                  placement="top"
                  label="Turning off the Bounty storefront removes your store from our
                  directory. This prevents us from driving you new shoppers and
                  creators from our network."
                >
                  <Switch
                    mb="2"
                    isChecked={storefront.enabled}
                    onChange={async (e) => {
                      track('Storefront Overview Toggled', {
                        enabled: e.target.checked,
                      })

                      await toggleStorefront({
                        variables: {
                          input: {
                            enabled: e.target.checked,
                          },
                        },
                        optimisticResponse: {
                          updateStorefront: {
                            id: storefront.id,
                            enabled: e.target.checked,
                            __typename: 'Storefront',
                          },
                          __typename: 'Mutation',
                        },
                      })
                    }}
                  />
                </Tooltip>
              </VStack>
            ) : (
              <Skeleton height="10" width="12" mb="2" />
            )}
          </Flex>
          <PageHeaderDescription mb="6">
            We show your brand to our network of creators. Here you can set the
            branding, featured products, and featured videos that creators will
            see when viewing your brand.{' '}
            <LearnMoreLink href="https://brands.support.bounty.co/hc/en-us/articles/12628097814939-What-is-the-Bounty-storefront-" />
          </PageHeaderDescription>
          {FEATURE_STOREFRONT_METRICS &&
            !isPixelInstalled &&
            storefront.enabled && (
              <Flex mb={4}>
                <Alert status="warning">
                  <AlertTitle>Bounty Pixel is not enabled.</AlertTitle>
                  <AlertDescription>
                    Get comprehensive analytics on how creators interact with
                    your store including conversion rate and attributable sales
                    from your store directory listing on Bounty.
                    <Box mt={4} maxW={'150px'}>
                      <InstallSnippetToggleButton
                        prettyName="Bounty Pixel"
                        showToggle={false}
                        code={'PIXEL'}
                      />
                    </Box>
                  </AlertDescription>
                </Alert>
              </Flex>
            )}
        </PageHeaderInformation>
        <PageHeaderActions mb="6">
          {storefront.slug ? (
            <PageHeaderPrimaryAction
              as={ChakraLink}
              isExternal={true}
              href={`https://bounty.co/shop/${storefront.slug}`}
              target="_blank"
              event="Storefront Overview View Live Clicked"
              isDisabled={!storefront.enabled}
            >
              View Live
            </PageHeaderPrimaryAction>
          ) : (
            <Skeleton height="10" width="20" mb="2" />
          )}
        </PageHeaderActions>
      </PageHeaderContents>
      {storefront.enabled && isPixelInstalled && hasMetrics && (
        <StorefrontTile>
          <VStack alignItems="flex-start" width="100%">
            <StorefrontHeading>Metrics</StorefrontHeading>
            <Stack
              spacing={3}
              width="100%"
              direction={['column', 'column', 'row']}
            >
              <Metric
                isLoading={loading || !pixelMetrics}
                title="Referrals"
                value={pixelMetrics?.pixelReferralsLastMonth}
              ></Metric>

              <Metric
                isLoading={loading || !pixelMetrics}
                title="Attributed orders"
                value={pixelMetrics?.pixelOrderCountLastMonth}
              ></Metric>
              <Metric
                isLoading={loading || !pixelMetrics}
                title="Conversion rate"
                value={prettyPercentage(
                  pixelMetrics?.pixelConversionRateLastMonth ?? 0,
                )}
              ></Metric>
              <Metric
                isLoading={loading || !pixelMetrics}
                title="Attributed order value"
                value={prettyCurrency(
                  pixelMetrics?.pixelSalesAmountLastMonth ?? 0,
                )}
              ></Metric>
            </Stack>
          </VStack>
        </StorefrontTile>
      )}
      <StorefrontTile>
        <StorefrontTextContainer>
          <StorefrontHeading>Branding</StorefrontHeading>
          <StorefrontText>
            Update your logo, imagery, select categories and tell shoppers what
            makes your brand/product special.
          </StorefrontText>
          <StorefrontButton
            as={Link}
            event="Storefront Configure Branding Clicked"
            to={'/branding'}
          >
            Configure
          </StorefrontButton>
        </StorefrontTextContainer>
        <StorefrontImage
          image={StorefrontBranding}
          altText={'Storefront branding'}
        />
      </StorefrontTile>

      <StorefrontTile>
        <StorefrontTextContainer>
          <StorefrontHeading>Featured Products</StorefrontHeading>
          <StorefrontText>
            Control and sort the products you want to display in your directory
            listing.
          </StorefrontText>
          <StorefrontButton
            event="Storefront Configure Featured Products Clicked"
            as={Link}
            to={'../products'}
          >
            Configure
          </StorefrontButton>
        </StorefrontTextContainer>
        <StorefrontImage
          image={StorefrontProducts}
          altText={'Storefront products'}
        />
      </StorefrontTile>

      <StorefrontTile>
        <StorefrontTextContainer>
          <StorefrontHeading>Featured Bounties</StorefrontHeading>
          <StorefrontText>
            Display your favorite Bounties to shoppers and creators to inspire
            action and creativity.
          </StorefrontText>
          <StorefrontButton
            event="Storefront Configure Featured Bounties Clicked"
            as={Link}
            to={'../bounties'}
          >
            Configure
          </StorefrontButton>
        </StorefrontTextContainer>
        <StorefrontImage
          image={StorefrontBounties}
          altText={'Storefront bounties'}
        />
      </StorefrontTile>
    </Page>
  )
}
