import {
  Tile,
  Heading,
  PageHeader,
  useBounty,
  Text,
  Link,
  Box,
  Alert,
  AlertTitle,
  AlertDescription,
} from '@bounty/brands-design-system'

import { Page } from '@bounty/brands-design-system'
import { TikTokForm } from './TikTokForm'
import { useAuthState } from '../../hooks/useAuth'
import { useAnalytics } from '@bounty/web-analytics'
import { SnippetExperimentsToggler } from '../../components/SnippetsExperimentsToggler'
import { BrandedHashtagForm } from './BrandedHashtagForm'
import { BountyTogglerSwitch } from './BountyToggler'
import { CategoriesAndTags } from '../../components/CategoriesAndTags/CategoriesAndTags'
import { BountyLimitPerMonthForm } from './BountyLimitPerMonthForm'
import {
  UserExperience,
  UserExperienceHeading,
  UserExperienceInfoContainer,
  UserExperienceText,
} from '../UserExperiences/UserExperience'
import { InstallSnippetToggleButton } from '../UserExperiences/InstallSnippetToggleButton'
import { DeliveryTimeDelayForm } from './DeliveryTimeDelayForm'
import { InstagramForm } from './InstagramForm'
import { ContentTypesAllowedForm } from './ContentTypesAllowedForm'
export type SettingsProps = unknown

export const Settings = () => {
  const { supportEmail, appName } = useBounty()
  const { shopifyStoreUrl } = useAuthState()
  const { track } = useAnalytics()

  return (
    <Page alignItems="center" data-testid="settings-page" name="Settings">
      <PageHeader title="Account Settings" />
      <Tile mb="6">
        <Heading as={'h2'} size="md" mb="2">
          Update TikTok
        </Heading>
        <TikTokForm
          onSubmitSuccess={({ tikTokUsername }) => {
            track('TikTok Username Updated', { tikTokUsername })
          }}
        />
        <ContentTypesAllowedForm variant="TikTok" />
      </Tile>

      <Tile mb="6">
        <Heading as={'h2'} size="md" mb="2">
          Update Instagram
        </Heading>
        <InstagramForm />
        <ContentTypesAllowedForm variant="Instagram" />
      </Tile>
      <Tile mb="6">
        <Heading as={'h2'} size="md" mb="2">
          Maximum Bounties Per Month Per Creator
        </Heading>
        <Text mb="2">
          This is the number of bounties a creator is limited to per a 30 day
          period. Reach out to support if you want to update this limit for
          specific creators.
        </Text>
        <BountyLimitPerMonthForm />
      </Tile>

      <Tile mb="6">
        <Heading as={'h2'} size="md" mb="2">
          Update Branded Hashtag
        </Heading>
        <BrandedHashtagForm
          variant="regular"
          onSubmitSuccess={({ brandedHashtag }) => {
            track('Branded Hashtag Updated', { brandedHashtag })
          }}
        />
      </Tile>

      <Tile mb="6">
        <Heading as={'h2'} size="md" mb="2">
          Brand Categories
        </Heading>
        <CategoriesAndTags />
      </Tile>

      <Tile mb="6">
        <Heading as={'h2'} size="md" mb="2">
          Standard Time Delay
        </Heading>
        <Text mb={5}>
          In some cases fulfillment events may not be working properly for your
          Shopify store. If this is the case you can cue reminder notifications
          for creators off of a standard time delay. Pick a number that is close
          to your usual delivery times. Usually 5 or 7 days.
        </Text>
        <DeliveryTimeDelayForm />
      </Tile>

      {/**
       * We hid this starting 4/24/2023 because we have
       * stopped with self onboarding and
       * this confuses people during onboardings and
       * creates long discussions about what this means.
       */}
      {/* <Tile mb="6">
        <Heading as={'h2'} size="md" mb="2">
          Update Content Preferences
        </Heading>
        <ContentPreferencesForm variant={'regular'} />
      </Tile> */}

      <UserExperience>
        <UserExperienceInfoContainer>
          <UserExperienceHeading>Bounty Pixel</UserExperienceHeading>
          <UserExperienceText>
            Get comprehensive analytics on how creators interact with your store
            including conversion rate and attributable sales from your store
            directory listing on Bounty.
          </UserExperienceText>
          <Box>
            <InstallSnippetToggleButton
              prettyName="Bounty Pixel"
              showToggle={false}
              code={'PIXEL'}
            />
          </Box>
        </UserExperienceInfoContainer>
      </UserExperience>

      <SnippetExperimentsToggler />

      <Alert status="warning">
        <AlertTitle>Danger Zone</AlertTitle>
        <AlertDescription>
          <Text>
            If you would like to delete your account please{' '}
            <Link
              fontWeight="bold"
              color="gray.800"
              href={`mailto:${supportEmail}?subject=${appName} | ${shopifyStoreUrl} Billing Update`}
              isExternal
            >
              email us
            </Link>{' '}
            so we can start the deletion process.
          </Text>
          <Box py="4">
            <BountyTogglerSwitch />
          </Box>
        </AlertDescription>
      </Alert>
    </Page>
  )
}
