import { Creators } from './Creators/Creators'
import { Home } from './Home/Home'
import { Products } from './Products/Products'
import { Settings } from './Settings/Settings'
import {
  AppRoute,
  SpeedometerIcon,
  CodeSlashIcon,
  PriceTagIcon,
  SettingsIcon,
  ContentLibraryIcon,
  AnnouncementsIcon,
  GiftIcon,
  HandWavingIcon,
  ShopIcon,
  ChartLineUpIcon,
  StorefrontIcon,
  StarIcon,
  BriefcaseIcon,
} from '@bounty/brands-design-system'
import { Billing } from './Billing/Billing'
import { Snippets } from './UserExperiences/UserExperiences'
import { ContentLibrary } from './ContentLibrary/ContentLibrary'
import { ContentPricing } from './ContentPricingSettings/ContentPricingSettings'
import { Announcements } from './Announcements/Announcements'
import { NotificationPreferences } from './Notifications/NotificationPreferences'
// import { Prospects } from './Prospects/Prospects'
import { Gifts } from './Gifts/Gifts'
import { Storefront } from './Storefront/Storefront'
import { UgcConversionFunnelPage } from './Performance/UgcConversionFunnel/UgcConversionFunnelPage'
import { GiftsHistoryPerformanceRoutes } from './Performance/GiftsHistory/GiftsHistoryPerformanceRoutes'
import { Retail } from './Retail/Retail'
import { Branding } from './Settings/Branding'
import { Community } from './Community/Community'
import { Bounties } from './Bounties/Bounties'

export const INTERIOR_NAV_ROUTES: AppRoute[] = [
  {
    isExternal: false,
    isGrouping: false,
    displayPath: '/',
    routerPath: '/',
    name: 'Dashboard',
    icon: SpeedometerIcon,
    component: Home,
  },
  {
    isExternal: false,
    isGrouping: false,
    displayPath: '/bounties',
    routerPath: '/bounties/*',
    name: 'Bounties',
    icon: AnnouncementsIcon,
    component: Bounties,
  },
  {
    isExternal: false,
    isGrouping: false,
    displayPath: '/content-library',
    routerPath: '/content-library/*',
    name: 'Content',
    icon: ContentLibraryIcon,
    component: ContentLibrary,
  },
  {
    isExternal: false,
    isGrouping: false,
    displayPath: '/user-experiences',
    routerPath: '/user-experiences/*',
    name: 'Signup widgets',
    icon: CodeSlashIcon,
    component: Snippets,
  },
  {
    isExternal: false,
    isGrouping: false,
    displayPath: '/community',
    routerPath: '/community/*',
    name: 'Signup page',
    icon: StarIcon,
    component: Community,
  },
  {
    isExternal: false,
    isGrouping: false,
    displayPath: '/retail',
    routerPath: '/retail/*',
    name: 'Retailers',
    icon: StorefrontIcon,
    component: Retail,
  },
  {
    isGrouping: false,
    isExternal: false,
    icon: HandWavingIcon,
    displayPath: '/creators',
    routerPath: '/creators/*',
    name: 'Creators',
    component: Creators,
  },
  // {
  //   isExternal: false,
  //   isGrouping: true,
  //   name: 'Creators',
  //   icon: HandWavingIcon,
  //   children: [
  //     {
  //       isExternal: false,
  //       displayPath: '/creators',
  //       routerPath: '/creators/*',
  //       name: 'Users',
  //       component: Creators,
  //     },

  //     // {
  //     //   isExternal: false,
  //     //   displayPath: '/prospects',
  //     //   routerPath: '/prospects/*',
  //     //   name: 'Prospects',
  //     //   component: Prospects,
  //     // },
  //   ],
  // },
  {
    name: 'Incentives',
    isExternal: false,
    isGrouping: true,
    icon: GiftIcon,
    children: [
      {
        isExternal: false,
        displayPath: '/your-offer',
        routerPath: '/your-offer',
        name: 'Your offer',
        component: ContentPricing,
      },
      {
        isExternal: false,
        displayPath: '/gifts',
        routerPath: '/gifts/*',
        name: 'Gifts',
        component: Gifts,
      },
    ],
  },
  {
    isExternal: false,
    isGrouping: false,
    displayPath: '/storefront',
    routerPath: '/storefront/*',
    name: 'Storefront',
    icon: ShopIcon,
    component: Storefront,
  },
  // Commented for now as reference
  // {
  //   isExternal: false,
  //   isGrouping: false,
  //   displayPath: '/announcements',
  //   routerPath: '/announcements/*',
  //   name: 'Announcements',
  //   icon: AnnouncementsIcon,
  //   component: Announcements,
  // },
  {
    name: 'Analytics',
    isExternal: false,
    isGrouping: true,
    icon: ChartLineUpIcon,
    children: [
      {
        isExternal: false,
        displayPath: '/performance/ugc-conversion-funnel',
        routerPath: '/performance/ugc-conversion-funnel/*',
        name: 'UGC Funnel',
        component: UgcConversionFunnelPage,
      },
      {
        isExternal: false,
        displayPath: '/performance/gifts-history',
        routerPath: '/performance/gifts-history/*',
        name: 'Gifts',
        component: GiftsHistoryPerformanceRoutes,
      },
    ],
  },
  {
    name: 'Settings',
    isExternal: false,
    isGrouping: true,
    icon: SettingsIcon,
    children: [
      {
        isExternal: false,
        displayPath: '/branding',
        routerPath: '/Branding',
        name: 'Branding',
        component: Branding,
      },

      {
        isExternal: false,
        displayPath: '/billing',
        routerPath: '/billing',
        name: 'Billing',
        component: Billing,
      },
      {
        isExternal: false,
        displayPath: '/notifications',
        routerPath: '/notifications',
        name: 'Notifications',
        component: NotificationPreferences,
      },

      {
        isExternal: false,
        displayPath: '/settings',
        routerPath: '/settings',
        name: 'Account Settings',
        component: Settings,
      },
    ],
  },
]
