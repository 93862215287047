import { CellRendererSelectorFunc, ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import _, { isEmpty } from 'lodash'
import { useRef, useState } from 'react'

import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Button,
  CloseIcon,
  CopyClipboard,
  FancySelect,
  Flex,
  Grid,
  Link,
  Skeleton,
  Tag,
  useToast,
} from '@bounty/brands-design-system'

import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import {
  BriefsTableDocument,
  BriefsTableQuery,
  UpdateBriefDocument,
} from '../../generated/backendGraphql'
import {
  fillBriefFieldsByVisibility,
  visibilityOptions,
  VisibilityValueType,
} from './BriefsDrawer/BriefForms'

export type BriefItem = BriefsTableQuery['briefs'][0]

type CellRendererParams = Parameters<CellRendererSelectorFunc<BriefItem>>[0]

export const BriefsTable = () => {
  const toast = useToast()
  const gridRef = useRef<AgGridReact<BriefItem>>(null)
  const { data: { briefs = [], currentStore } = {}, loading } = useQueryBackend(
    BriefsTableDocument,
    {
      fetchPolicy: 'network-only',
    },
  )

  const [selectedBriefIds, setSelectedBriefIds] = useState<string[]>([])
  const [bulkAction, setBulkAction] = useState<undefined | any>()

  const [updateBrief] = useMutationBackend(UpdateBriefDocument, {
    update(cache, { data }) {
      cache.modify({
        id: `Brief:${data?.updateBrief?.id}`,
        fields: {
          isActive() {
            return data?.updateBrief?.isActive ?? false
          },
          isPublic() {
            return data?.updateBrief?.isPublic ?? false
          },
        },
      })
    },
  })

  return loading || !briefs ? (
    <Skeleton height="10" width="100%" mb="18" />
  ) : isEmpty(briefs) ? (
    <Alert status="info">
      <Box>
        <AlertTitle fontSize="lg" fontWeight="semibold" mb="2">
          No briefs configured.
        </AlertTitle>
        <AlertDescription fontSize="md">
          <Link isExternal={false} to="/briefs/new">
            Add Brief
          </Link>
        </AlertDescription>
      </Box>
    </Alert>
  ) : (
    <Grid<BriefItem>
      showQuickSearch={false}
      gridRef={gridRef}
      suppressRowClickSelection
      rowSelection="multiple"
      onSelectionChanged={(e) =>
        setSelectedBriefIds(e.api.getSelectedRows().map((x) => x.id))
      }
      rightComponent={
        <Box ml="2" w="200px">
          <FancySelect
            isDisabled={selectedBriefIds.length === 0}
            value={bulkAction}
            placeholder="Update Visibility"
            options={visibilityOptions}
            onChange={(option) => {
              if (option?.value) {
                setBulkAction(option)
                handleBulkAction(option?.value)
              }
            }}
          />
        </Box>
      }
      rowData={[...new Set(briefs)]}
      columnDefs={columnDefs()}
    />
  )

  function handleBulkAction(visibility: VisibilityValueType) {
    Promise.all(
      selectedBriefIds.map((id) => {
        const brief = briefs.find((x) => x.id === id)!
        return handleStatusUpdate(visibility, brief)
      }),
    )
      .then(() => {
        toast({
          status: 'success',
          title: 'Statuses Updated Successfully',
          description: `${
            selectedBriefIds.length
          } bounties successfully set to ${_.capitalize(visibility)}`,
        })
      })
      .catch(() => {
        toast({
          status: 'error',
          title: 'Statuses Update Failed',
          description: 'An error occurred while updating bounty statuses',
        })
      })
      .finally()
  }

  function handleStatusUpdate(
    visibility: VisibilityValueType,
    brief: BriefItem,
  ) {
    updateBrief({
      variables: {
        input: {
          id: brief.id,
          content: brief.content,
          name: brief.name,
          videoUrl: brief.videoUrl,
          ...fillBriefFieldsByVisibility(visibility),
        },
      },
    })
  }

  function columnDefs(): ColDef<BriefItem>[] {
    return [
      {
        headerName: '',
        minWidth: 70,
        maxWidth: 70,
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {
        headerName: 'Created',
        field: 'createdAt',
        minWidth: 120,
        maxWidth: 120,
        valueGetter: (params) => {
          // default filter uses midnight as comparator
          const getMidnight = (d: Date) => new Date(d.setHours(0, 0, 0, 0))
          return getMidnight(new Date(params.data!.createdAt))
        },
        cellRenderer: (params: CellRendererParams) => {
          return new Date(params.data!.createdAt).toLocaleDateString()
        },
      },
      {
        headerName: 'Bounty',
        field: 'isDefault',
        minWidth: 240,
        cellRenderer: (params: CellRendererParams) => {
          return (
            <Flex alignItems={'center'}>
              <Box mr="2">{params.data!.name}</Box>
              {params.data!.isDefault ? (
                <Tag>
                  Default <CloseIcon ml="1" color="neutral.500" />
                </Tag>
              ) : null}
            </Flex>
          )
        },
      },
      {
        headerName: 'Visibility',
        minWidth: 120,
        maxWidth: 120,
        cellRenderer: (params: CellRendererParams) => {
          if (!params.data?.isActive) {
            return <Tag bg="warning.400">Inactive</Tag>
          }

          if (params.data.isPublic) {
            return <Tag bg="success.400">Public</Tag>
          }

          return <Tag bg="neutral.400">Private</Tag>
        },
      },
      {
        headerName: 'Direct Link',
        minWidth: 180,
        cellRenderer: (params: CellRendererParams) => {
          const label = `bounty.co/s/${currentStore?.id}?briefId=${params.data?.id}`
          const link = `https://www.${label}`
          return (
            <CopyClipboard
              label={label}
              value={link}
              containerProps={{ w: '160px', mr: '4' }}
            />
          )
        },
      },
      {
        headerName: 'Min. payout',
        field: 'minPayout',
        minWidth: 110,
        cellRenderer: (params: CellRendererParams) => {
          return params.data?.minPayout ? `$${params.data?.minPayout}` : 'N/A'
        },
      },
      {
        minWidth: 220,
        maxWidth: 220,
        cellRenderer: (params: CellRendererParams) => {
          return (
            <Flex gap="1" alignItems={'center'} width="100%">
              <Button
                size="sm"
                as={Link}
                isExternal={false}
                variant="outline"
                to={`/content-library/?briefId=${params.data?.id}`}
                event={{
                  eventName: 'Briefs Table View Content Clicked',
                  briefId: params.data!.id,
                }}
              >
                View Content
              </Button>
              <Button
                data-testid="brief-table-view-details-button"
                size="sm"
                as={Link}
                isExternal={false}
                variant="solid"
                to={`./details/${params.data?.id}`}
                event={{
                  eventName: 'Briefs Table View Details Clicked',
                  briefId: params.data!.id,
                }}
              >
                View Details
              </Button>
            </Flex>
          )
        },
      },
    ]
  }
}
