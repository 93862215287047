import { Page } from '@bounty/brands-design-system'
import { FC } from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'
import { GiftDrawer } from '../../../Gifts/GiftDrawer/GiftDrawer'
import { AddCreatorsYouDontKnow } from './steps/AddCreatorsYouDontKnow/AddCreatorsYouDontKnow'
import { AddGift } from './steps/AddGift/AddGift'
import { ReviewInvites } from './steps/ReviewInvites/ReviewInvites'
import { InviteCreatorsYouDontKnowProvider } from './useInviteCreatorsYouDontKnow'

export const InviteCreatorsYouDontKnow: FC = () => {
  return (
    <InviteCreatorsYouDontKnowProvider>
      <Page
        name="Invite | Invite creators you do not know to Bounty"
        fixedHeight="all"
        fluid
        addBottomSpacing={false}
        paddingBottom="3rem"
        width="100%"
      >
        <Routes>
          <Route path="/" element={<AddCreatorsYouDontKnow />} />
          <Route
            path="/message"
            element={
              <>
                <AddGift />
                <Outlet />
              </>
            }
          >
            <Route
              path="create-gift"
              element={
                <GiftDrawer
                  mode="create"
                  rootPath="/creators/invite/creators-you-know/message"
                />
              }
            />
          </Route>

          <Route path="/review" element={<ReviewInvites />} />
        </Routes>
      </Page>
    </InviteCreatorsYouDontKnowProvider>
  )
}
