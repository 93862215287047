/**
 * IF YOU EDIT ANY OF THESE VALUES YOU NEED TO GO TO RETOOL AND SYNC THEM UP AFTER THE CODE IS DEPLOYED TO PROD!
 *
 * https://earnbounty.retool.com/apps/18201966-6806-11ec-aa5f-cbb27e4d4669/Bounty%20content%20approval%20app
 */
export const BONUS_TYPES = [
  'DEMONSTRATION',
  'HOOK',
  'UNIQUE',
  'PRESENCE',
  'LOGO',
  'PRODUCT_IN_FIRST_5_SECONDS',
  'AUDIO',
  'LIGHTING',
] as const
export type BONUS_TYPE = typeof BONUS_TYPES[number]

export const PENALTY_TYPES = [
  'PROFANITY',
  'PRODUCT_NOT_VISIBLE',
  'COPYRIGHTED_CONTENT',
] as const
export type PENALTY_TYPE = typeof PENALTY_TYPES[number]

export const PROBLEM_TYPES = [
  'FRAUD',
  'REFERRAL_CODE',
  'BRAND_NOT_TAGGED',
  'BOUNTY_NOT_TAGGED',
  'PROFANITY',
] as const
export type PROBLEM_TYPE = typeof PROBLEM_TYPES[number]

export type MODIFIER = {
  name: string
  description: string
  multiplier: number
}

export const APPROVAL_BONUSES: Record<BONUS_TYPE, MODIFIER> = {
  DEMONSTRATION: {
    name: 'Demonstration',
    description:
      'Include a shot of you in the camera while showing or using product / or include a shot of product after showing before/after results. Speak to the camera or include a voiceover.',
    multiplier: 1.0,
  },
  HOOK: {
    name: 'Has a hook',
    description:
      'A hook is a short, catchy intro to your video. It should be 3-5 seconds long, grab the attention of your viewers and convince them to keep watching!',
    multiplier: 1.0,
  },
  UNIQUE: {
    name: 'Unique/Creative',
    description:
      'Think outside the box! Some examples: Feature the product in an unexpected setting or new way, perform a skit or come up with a challenge.',
    multiplier: 0.5,
  },
  PRESENCE: {
    name: 'Good camera presence',
    description:
      'Make sure your face is in frame and not hidden by any shadows. Show your energy and enthusiasm for the product!',
    multiplier: 0.5,
  },
  LOGO: {
    name: 'Product & logo shown',
    description:
      'Show the logo clearly and up close. This lets your audience know what the product is, and makes your content more valuable to the brand.',
    multiplier: 0.5,
  },
  PRODUCT_IN_FIRST_5_SECONDS: {
    name: 'Product first 5 seconds',
    description: `Grab your audience's attention by showing the product within the first 5 seconds of the video.`,
    multiplier: 0.5,
  },
  AUDIO: {
    name: 'Clear audio',
    description:
      'Speak loudly and clearly to the camera so both the brand and your viewers can understand what you love about the product!',
    multiplier: 0.5,
  },
  LIGHTING: {
    name: 'Good lighting',
    description:
      'Film in a bright room where you and your product are easy to see. Use natural outdoor lighting when possible.',
    multiplier: 0.5,
  },
}

export const APPROVAL_PENALTIES: Record<PENALTY_TYPE, MODIFIER> = {
  PRODUCT_NOT_VISIBLE: {
    name: 'Product not clearly visible',
    description:
      'Make sure the focus of your video is the product your Bounty is for and that your audience can clearly see the product in your video.',
    multiplier: 0.7,
  },
  COPYRIGHTED_CONTENT: {
    name: 'Uses copyrighted content',
    description:
      'Brands are looking for content they can license and use as Spark Ads. Avoid using copyrighted content from other creators or musicians.',
    multiplier: 0.15,
  },
  PROFANITY: {
    name: 'Uses profanity',
    description:
      'Brands cannot use your content if it includes profanity. Keep it PG and earn more!',
    multiplier: 0.15,
  },
}

// need to convert to mutable type
export const ALL_BONUSES = {
  bonuses: BONUS_TYPES as unknown as BONUS_TYPE[],
  penalties: [],
  problems: [],
}
