import { useToast } from '@bounty/brands-design-system'
import { APP_NAME } from '../config/env'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { useAuth } from './useAuth'
import { SubscriptionPlanOptionId } from '../generated/backendGraphql'
import { UnreachableCaseError } from '@bounty/utils'

const subscriptionSuccessToastId = 'subscription-success'

/**
 * mark subscription value, affiliate get 50%
 */
const getAmount = (plan: SubscriptionPlanOptionId | null) => {
  if (!plan) return 0
  switch (plan) {
    case 'V1_BOUNTY_PLAN':
      return 0
    case 'V2_BASIC_PLAN':
      return 100
    case 'V2_PRO_PLAN':
      return 300
    case 'V2_ENTERPRISE_PLAN':
      return 300
    default:
      throw new UnreachableCaseError(plan)
  }
}

export const useSubscriptionSuccess = () => {
  const toast = useToast()
  const location = useLocation()
  const [{ shopifyStoreUrl }] = useAuth()

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const plan = params.get('plan')
    if (
      params.get('subscriptionSuccess') === 'true' &&
      !toast.isActive(subscriptionSuccessToastId)
    ) {
      toast({
        id: subscriptionSuccessToastId,
        title: '🎉 Subscription Success',
        description: `You've successfully signed up for ${APP_NAME}!`,
        status: 'success',
      })
      if (!shopifyStoreUrl) {
        console.log('Shopify store url undefined ')
        return
      }
      console.log('Shopify store url - recording conversion', shopifyStoreUrl)

      // Tapfiliate turned off
      // // @ts-expect-error tapfiliate
      // window.tap &&
      //   // @ts-expect-error tapfiliate
      //   window.tap('conversion', shopifyStoreUrl, getAmount(plan), {
      //     meta_data: {
      //       plan,
      //       shopifyStoreUrl,
      //     },
      //   })
    }
  }, [location.search, toast, shopifyStoreUrl])
}
