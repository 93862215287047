/**
 * Docs: https://learn.microsoft.com/en-us/clarity/setup-and-installation/clarity-api
 */
declare global {
  interface Window {
    clarity?:
      | ((eventName: 'identify', userId: string) => void)
      | ((eventName: 'set', key: string, value: string) => void)
  }
}

export const initClarity = ({
  isEnabled,
  projectId,
}: {
  isEnabled: boolean
  projectId: string
}) => {
  if (isEnabled === false) return

  const script = document.createElement('script')
  script.async = true
  script.id = 'ms_clarity'
  script.type = 'text/javascript'
  script.innerHTML = `
(function(c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "${projectId}");
`

  document.head.appendChild(script)
}

export const setClarityUser = (userId: string) => {
  // @ts-expect-error - Too much effort to type it correctly based off of their API
  window?.clarity?.('identify', userId)
}
