import * as z from 'zod'

export const ManuallyAssociateVideoRequestSchema = z
  .object({
    userId: z.string(),
    videoId: z.string(),
    bountyId: z.string(),
  })
  .strict()

export type ManuallyAssociateVideoRequest = z.infer<
  typeof ManuallyAssociateVideoRequestSchema
>

export const ManuallyAssociateVideoInstagramShortCodeRequestSchema = z
  .object({
    userId: z.string(),
    shortCode: z.string(),
    bountyId: z.string(),
  })
  .strict()

export type ManuallyAssociateVideoInstagramShortCodeRequest = z.infer<
  typeof ManuallyAssociateVideoInstagramShortCodeRequestSchema
>
