import { CellRendererSelectorFunc, ColDef, RowNode } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Fragment, useCallback, useMemo, useRef } from 'react'

import {
  Avatar,
  Box,
  Button,
  FancySelect,
  Grid,
  HStack,
  Image,
  Link,
  Spinner,
  Text,
} from '@bounty/brands-design-system'
import { useAnalytics } from '@bounty/web-analytics'

import { useQueryBackend } from '../../apollo/backend/hooks'
import { FavoriteCreatorButton } from '../../components/FavoriteCreatorButton/FavoriteCreatorButton'
import {
  CreatorsTableDocument,
  CreatorsTableQuery,
} from '../../generated/backendGraphql'
import { useAuthState } from '../../hooks/useAuth'
import {
  getLinkToSocialAccount,
  getUserProfileImage,
} from '../../utils/externalContentLinksGetters'
import { getSocialMediaLogo } from '../../utils/preferredSocialProfileUtils'

export type CreatorsGridProps = unknown

export type CreatorItem = CreatorsTableQuery['storeCreators'][0]
type Order = CreatorsTableQuery['storeCreators'][0]['orders'][0]
type CellRendererParams = Parameters<CellRendererSelectorFunc<CreatorItem>>[0]

export const CreatorsTable = () => {
  const { shopifyStoreUrl } = useAuthState()
  const { track } = useAnalytics()
  const gridRef = useRef<AgGridReact<CreatorItem>>(null)
  const platformRef = useRef('ALL')

  const columnDefs = useMemo(
    (): ColDef<CreatorItem>[] => [
      {
        headerName: '',
        width: 30,
        flex: 0,
        field: 'starred',
        suppressMovable: true,
        cellRenderer: (params: CellRendererParams) => {
          return (
            <FavoriteCreatorButton
              userId={params.data!.id}
              data={params.data!}
            />
          )
        },
        resizable: false,
      },
      {
        headerName: '',
        field: 'id',
        width: 80,
        flex: 0,
        resizable: false,
        suppressMovable: true,
        cellRenderer: (params: CellRendererParams) => {
          if (!params.data) return null

          // could have empty profileName / accountType for empty signup
          if (!params.data.profileName) return null
          if (!params.data.accountType) return null

          return (
            <Avatar
              as={Link}
              name={params.data!.profileName}
              isExternal
              href={getLinkToSocialAccount(
                params.data.profileName!,
                params.data.accountType,
              )}
              boxSize="40px"
              src={
                getUserProfileImage(params.data.id, params.data.accountType)!
              }
            />
          )
        },
      },
      {
        headerName: 'Creator',
        field: 'profileName',
        cellRenderer: (params: CellRendererParams) => {
          return (
            <Link
              isExternal={false}
              fontWeight="medium"
              to={`./details/${params.data?.id}`}
              onClick={() => {
                track('Creator Table Profile Name Link Clicked', {
                  tikTokUsername: params.data!.profileName,
                })
              }}
            >
              {params.data!.profileName}
            </Link>
          )
        },
      },
      {
        headerName: 'Platforms',
        field: 'accountsLinked',
        cellRenderer: (params: CellRendererParams) => {
          if (!params.data || params.data.accountsLinked.length === 0)
            return 'N/A'
          return (
            <HStack spacing={'1'}>
              {params.data.accountsLinked.map((account) => {
                return (
                  <Image
                    boxSize={'5'}
                    objectFit={'contain'}
                    src={getSocialMediaLogo(account)}
                    key={`${params.data?.profileName}-${account}`}
                  />
                )
              })}
            </HStack>
          )
        },
      },
      {
        headerName: 'Orders',
        field: 'orders',
        valueGetter: (params) =>
          params.data?.orders.map((o) => o.shopifyOrderNumber).join(' '),
        cellRenderer: (params: CellRendererParams) => {
          if (!params.data || params.data.orders.length === 0) return 'N/A'
          return params.data.orders.map((order: Order, i: number) => {
            return (
              <Fragment key={order.id}>
                <Link
                  isExternal
                  href={`https://${shopifyStoreUrl}/admin/orders/${order.shopifyOrderId}`}
                  onClick={() => {
                    track('User Table Order Clicked', {
                      link: `https://${shopifyStoreUrl}/admin/orders/${order.shopifyOrderId}`,
                    })
                  }}
                >
                  {order.shopifyOrderNumber}
                </Link>
                {i < params.data!.orders.length - 1 ? (
                  <Text as="span">,&nbsp;</Text>
                ) : null}
              </Fragment>
            )
          })
        },
      },
      {
        headerName: 'Bounties',
        field: 'numBounties',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Gifts Sent',
        field: 'numGifts',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Rating',
        field: 'averageRating',
        valueFormatter: (x) => x.value ?? 'N/A',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Followers',
        field: 'followerCount',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Joined On',
        filter: 'agDateColumnFilter',
        field: 'firstActiveDate',
        valueGetter: (params) => {
          // default filter uses midnight as comparator
          const getMidnight = (d: Date) => new Date(d.setHours(0, 0, 0, 0))
          return getMidnight(new Date(params.data!.firstActiveDate))
        },
        cellRenderer: (params: CellRendererParams) => {
          return params.data!.firstActiveDate
            ? new Date(params.data!.firstActiveDate).toLocaleDateString()
            : new Date(params.data!.createdAt).toLocaleDateString()
        },
      },
      {
        headerName: 'Source',
        field: 'isCommunityMember',
        cellRenderer: (params: CellRendererParams) => {
          return (
            <Text>
              {params.data?.isCommunityMember ? 'Signup page' : 'Shopify'}
            </Text>
          )
        },
      },
      {
        headerName: 'Creator Details',
        cellRenderer: (params: CellRendererParams) => {
          /**
           * DO NOT allow empty profile details to be opened.
           *
           * The detail view will simply blow up
           */
          if (!params.data?.profileName) return null

          return (
            <Button
              size="sm"
              as={Link}
              isExternal={false}
              variant="outline"
              to={`./details/${params.data?.id}`}
              event={{
                eventName: 'Creator Table Creator View Details Clicked',
                profileName: params.data!.profileName,
              }}
            >
              View Details
            </Button>
          )
        },
      },
    ],
    [track, shopifyStoreUrl],
  )

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      filter: true,
    }
  }, [])

  const { data: { storeCreators = [] } = {}, loading } = useQueryBackend(
    CreatorsTableDocument,
  )

  const externalFilterChanged = useCallback((newValue: string) => {
    platformRef.current = newValue

    if (gridRef.current) {
      gridRef.current.api.onFilterChanged()
    }
  }, [])

  const isExternalFilterPresent = useCallback((): boolean => {
    // if platform is not ALL, then we are filtering
    return platformRef.current !== 'ALL'
  }, [])

  const doesExternalFilterPass = useCallback(
    (node: RowNode<CreatorItem>): boolean => {
      if (node.data) {
        switch (platformRef.current) {
          case 'ALL':
            return Boolean(node.data)
          case 'INSTAGRAM':
            return Boolean(node.data.accountsLinked.indexOf('INSTAGRAM') > -1)
          case 'TIKTOK':
            return Boolean(node.data.accountsLinked.indexOf('TIKTOK') > -1)
          default:
            return true
        }
      }
      return true
    },
    [platformRef],
  )

  if (loading) return <Spinner size="xl" />

  return (
    <Grid<CreatorItem>
      gridRef={gridRef}
      containerProps={{ height: '100%' }}
      rowStyle={{ cursor: 'pointer' }}
      rowData={[...storeCreators]}
      columnDefs={columnDefs}
      defaultColDef={defaultColDef}
      isExternalFilterPresent={isExternalFilterPresent}
      doesExternalFilterPass={doesExternalFilterPass}
      leftComponent={
        <Box width={['100%', '220px', '220px', '220px']}>
          <FancySelect
            defaultValue={{ value: 'ALL', label: 'All' }}
            placeholder="Filter by platform..."
            options={[
              {
                value: 'ALL',
                label: 'All',
              },
              {
                value: 'TIKTOK',
                label: 'TikTok',
              },
              {
                value: 'INSTAGRAM',
                label: 'Instagram',
              },
            ]}
            onChange={(option) => {
              if (option?.value) {
                externalFilterChanged(option.value)
              }
            }}
          />
        </Box>
      }
    />
  )
}
