import { z, ZodNumber, ZodOptional } from 'zod'

export const AttributionIdentifySchema = z
  .object({
    userId: z.string().optional(),
    shop: z.string().optional(),
  })
  .strict()

export type AttributionIdentifyType = z.infer<typeof AttributionIdentifySchema>

export const AttributionAliasSchema = z
  .object({
    previousId: z.string(),
    userId: z.string(),
  })
  .strict()

export type AttributionAliasType = z.infer<typeof AttributionAliasSchema>

const numberString = <schema extends ZodNumber | ZodOptional<ZodNumber>>(
  schema: schema,
) => {
  return z.preprocess(
    (value) =>
      typeof value === 'string'
        ? parseFloat(value)
        : typeof value === 'number'
        ? value
        : undefined,
    schema,
  )
}

export const AttributionEventSchema = z
  .object({
    userId: z.string().optional(),
    event: z.enum([
      'Referral',
      'Page View',
      'Item Added to Cart',
      'Checkout Started',
      'Purchase',
      'VIEWED_THANK_YOU_PAGE',
    ]),
    productId: z.string().optional(),
    location: z.string().optional(),
    productVariant: z.string().optional(),
    productVariantId: z.string().optional(),
    productPrice: numberString(z.number().optional()),
    shop: z.string().optional(),
    orderId: z.string().optional(),
    totalPrice: numberString(z.number().optional()),
    dealId: z.string().optional(),
    sessionId: z.string().optional(),
  })
  .strict()

export type AttributionEventType = z.infer<typeof AttributionEventSchema>
