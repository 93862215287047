import { SVGProps } from 'react'
import { Icon, IconProps } from '@chakra-ui/react'

export type WarningRawProps = SVGProps<SVGSVGElement>

export const WarningRaw = ({ ...rest }: WarningRawProps) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 7.5C10.3452 7.5 10.625 7.77982 10.625 8.125V11.25C10.625 11.5952 10.3452 11.875 10 11.875C9.65482 11.875 9.375 11.5952 9.375 11.25V8.125C9.375 7.77982 9.65482 7.5 10 7.5Z"
        fill="#E53E3E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.06283 2.12694C9.34741 1.96191 9.67054 1.875 9.9995 1.875C10.3285 1.875 10.6516 1.96191 10.9362 2.12694C11.2203 2.29168 11.4559 2.52844 11.6193 2.81331C11.6195 2.8138 11.6198 2.81428 11.6201 2.81476L18.4939 14.6877C18.6582 14.9722 18.7448 15.295 18.7451 15.6235C18.7454 15.9521 18.6593 16.275 18.4956 16.5598C18.3318 16.8447 18.096 17.0815 17.8119 17.2465C17.5278 17.4116 17.2053 17.499 16.8767 17.5002L16.8745 17.5002H3.1245L3.1223 17.5002C2.79373 17.499 2.47123 17.4116 2.18712 17.2465C1.903 17.0815 1.66724 16.8447 1.50346 16.5598C1.33967 16.275 1.25362 15.9521 1.25391 15.6235C1.2542 15.295 1.34083 14.9722 1.50512 14.6877L8.37893 2.81476C8.3792 2.81428 8.37948 2.8138 8.37976 2.81331C8.54312 2.52844 8.77873 2.29168 9.06283 2.12694ZM8.92138 3.1252L9.46227 3.43835L2.58764 15.3127C2.5876 15.3128 2.58769 15.3126 2.58764 15.3127C2.53297 15.4075 2.504 15.5152 2.50391 15.6247C2.50381 15.7342 2.53249 15.8418 2.58709 15.9367C2.64168 16.0317 2.72027 16.1106 2.81498 16.1656C2.90944 16.2205 3.01662 16.2497 3.12585 16.2502H16.8732C16.9824 16.2497 17.0896 16.2205 17.184 16.1656C17.2787 16.1106 17.3573 16.0317 17.4119 15.9367C17.4665 15.8418 17.4952 15.7342 17.4951 15.6247C17.495 15.5153 17.4662 15.4078 17.4116 15.3131C17.4115 15.3129 17.4116 15.3132 17.4116 15.3131L10.5352 3.43564C10.4812 3.34126 10.4032 3.26282 10.3091 3.20828C10.215 3.15373 10.1082 3.125 9.9995 3.125C9.89077 3.125 9.78396 3.15373 9.68989 3.20828C9.59583 3.26283 9.51784 3.34126 9.46383 3.43563L8.92138 3.1252Z"
        fill="#E53E3E"
      />
      <path
        d="M10 15C10.5178 15 10.9375 14.5803 10.9375 14.0625C10.9375 13.5447 10.5178 13.125 10 13.125C9.48223 13.125 9.0625 13.5447 9.0625 14.0625C9.0625 14.5803 9.48223 15 10 15Z"
        fill="#E53E3E"
      />
    </svg>
  )
}

export const WarningIcon = (props: IconProps) => (
  <Icon as={WarningRaw} {...props} />
)
