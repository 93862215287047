import { z } from 'zod'

export const userMediaFeedbackSubmitSchema = z
  .object({
    userId: z.string().uuid().nullable().optional(),
    fileLink: z.string().url(),
    experimentId: z.string().optional().nullable(),
    responseId: z.string().optional().nullable(),
    message: z.string().optional().nullable(),
    surveyMedium: z.string().optional().nullable(),
    campaign: z.string().optional().nullable(),
    notificationMedium: z.string().optional().nullable(),
    ref: z.string().optional().nullable(),
    email: z.string().optional().nullable(),
  })
  .strict()
