import { borders } from './borders'
import { breakpoints } from './breakpoints'
import { colors } from './colors'
import { radii } from './radius'
import { shadows } from './shadows'
import { sizes } from './sizes'
import { spacing } from './spacing'
import { transition } from './transition'
import { typography } from './typography'
import { zIndices } from './z-index'
import { blur } from './blur'

export * from './borders'
export * from './breakpoints'
export * from './colors'
export * from './radius'
export * from './shadows'
export * from './sizes'
export * from './spacing'
export * from './transition'
export * from './typography'
export * from './z-index'
export * from './blur'

export const foundations = {
  breakpoints,
  zIndices,
  radii,
  blur,
  colors,
  ...typography,
  sizes,
  shadows,
  space: spacing,
  borders,
  transition,
}
