import {
  Tooltip as TooltipCore,
  TooltipProps as TooltipPropsCore,
} from './Chakra'
import { defineStyleConfig } from '@chakra-ui/react'

export const tooltipTheme = defineStyleConfig({
  baseStyle: (p) => {
    return {
      bg: 'neutral.900',
      borderRadius: 'md',
      px: '2',
      py: '2',
      '--popper-arrow-bg': p.theme.colors.gray[900],
    }
  },
})

export type TooltipProps = TooltipPropsCore

export const Tooltip = ({ ...props }: TooltipProps) => {
  return <TooltipCore hasArrow {...props} />
}
