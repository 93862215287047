import { Box, Flex, useFastForm } from '@bounty/brands-design-system'
import { isNotNil } from '@bounty/utils'
import { useEffect } from 'react'
import { z } from 'zod'
import {
  GiftOfferHistoryFiltersInput,
  StoreGiftsForFilterDocument,
} from '../../../../../generated/backendGraphql'
import {
  BOUNTY_STATUS_OPTIONS,
  GIFT_SOURCE_OPTIONS,
  GIFT_STATUS_OPTIONS,
} from '../../constants'
import {
  useGiftsHistoryDispatch,
  useGiftsHistoryState,
} from '../../useGiftsHistory'
import { useQueryBackend } from '../../../../../apollo/backend/hooks'

const giftsHistoryFiltersSchema = z.object({
  giftStatus: z.string().optional(),
  bountyStatus: z.string().optional(),
  giftSource: z.string().optional(),
  giftId: z.string().optional(),
})

export const GiftsHistoryTableFilters = () => {
  const { setValues } = useGiftsHistoryDispatch()
  const { value } = useGiftsHistoryState()

  const { data: { storeGifts } = {}, loading } = useQueryBackend(
    StoreGiftsForFilterDocument,
    {
      fetchPolicy: 'cache-and-network',
    },
  )

  const {
    Form,
    Select,
    methods: { watch },
  } = useFastForm({
    defaultValues: {
      giftId:
        value.params.filters?.find((el) => el.field === 'GIFT_ID')?.value ??
        null,
      giftStatus:
        value.params.filters?.find((el) => el.field === 'GIFT_OFFER_STATUS')
          ?.value ?? null,
      bountyStatus:
        value.params.filters?.find((el) => el.field === 'BOUNTY_STATUS')
          ?.value ?? null,
      giftSource:
        value.params.filters?.find((el) => el.field === 'GIFT_SOURCE')?.value ??
        null,
    },
    schema: giftsHistoryFiltersSchema,
  })

  const [bountyStatusFilter, giftStatusFilter, giftSourceFilter, giftIdFilter] =
    watch(['bountyStatus', 'giftStatus', 'giftSource', 'giftId'])

  useEffect(() => {
    const activeFilters: GiftOfferHistoryFiltersInput[] = []
    if (isNotNil(bountyStatusFilter)) {
      activeFilters.push({
        field: 'BOUNTY_STATUS',
        value: bountyStatusFilter,
      })
    }
    if (isNotNil(giftStatusFilter)) {
      activeFilters.push({
        field: 'GIFT_OFFER_STATUS',
        value: giftStatusFilter,
      })
    }
    if (isNotNil(giftSourceFilter)) {
      activeFilters.push({
        field: 'GIFT_SOURCE',
        value: giftSourceFilter,
      })
    }
    if (isNotNil(giftIdFilter)) {
      activeFilters.push({
        field: 'GIFT_ID',
        value: giftIdFilter,
      })
    }
    setValues((prev) => ({
      value: {
        ...prev.value,
        params: {
          ...prev.value.params,
          filters: activeFilters,
          skip: 0,
        },
      },
    }))
  }, [
    bountyStatusFilter,
    giftStatusFilter,
    giftSourceFilter,
    giftIdFilter,
    setValues,
  ])

  return (
    <Flex
      as={Form}
      direction="row"
      gridGap={6}
      flexGrow={1}
      onSubmit={() => null}
    >
      <Box width="200px">
        <Select
          name="giftStatus"
          options={GIFT_STATUS_OPTIONS}
          label="Filter by Gift Status"
        />
      </Box>
      <Box width="200px">
        <Select
          name="bountyStatus"
          options={BOUNTY_STATUS_OPTIONS}
          label={'Filter by Bounty Status'}
        />
      </Box>
      <Box width="200px">
        <Select
          name="giftSource"
          options={GIFT_SOURCE_OPTIONS}
          label={'Filter by Gift Source'}
        />
      </Box>
      {!loading && storeGifts && (
        <Box width="240px">
          <Select
            name="giftId"
            options={storeGifts.map((gift) => ({
              label: gift.name,
              value: gift.id,
            }))}
            label={'Filter by Gift Name'}
          />
        </Box>
      )}
    </Flex>
  )
}
