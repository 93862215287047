import {
  Box,
  Flex,
  Text,
  Link,
  Skeleton,
  Button,
} from '@bounty/brands-design-system'
import { useQueryBackend } from '../../apollo/backend/hooks'
import { BudgetTileDocument } from '../../generated/backendGraphql'
import { prettyCurrency } from '@bounty/utils'
import { isAfter, parse } from 'date-fns'
import { SUPPORT_EMAIL } from '../../const'

const isDateAfterToday = (isoDate: string) => {
  // exepected like: 2022-08-11
  return isAfter(parse(isoDate, 'yyyy-MM-dd', new Date()), new Date()) ?? false
}

export const CurrentSubscriptionTile = () => {
  const { data, loading } = useQueryBackend(BudgetTileDocument)

  if (!data || loading)
    return (
      <Box flex="1" px="4">
        <Skeleton height="16px" mb="4" />
        <Skeleton height="16px" />
      </Box>
    )

  if (
    !data.currentSubscription?.currentPlanDetails ||
    data.currentSubscription?.currentPlanDetails?.status !== 'active'
  ) {
    /**
     * Currently you can't hit this code path. If the store does not finish setting up their
     * subscription they just get spit back into the signup flow.
     */
    return (
      <Box
        border="1px solid"
        borderColor="gray.200"
        mr={4}
        pt="16"
        pb="6"
        borderRadius="lg"
        px={10}
        width="100%"
      >
        <Box
          maxWidth="350px"
          borderRadius="lg"
          paddingTop="20"
          paddingBottom="6"
          mx="auto"
          position="relative"
          px="4"
          background="#e5fedc"
          mb="8"
        >
          <Flex flexDirection="column" justifyContent={'center'}>
            <Text
              color="black"
              textAlign="center"
              fontWeight="semibold"
              fontSize="lg"
              mb="6"
            >
              Your store has no active subscription.
            </Text>
            <Link
              textAlign={'center'}
              isExternal={false}
              to={'/welcome/budget'}
            >
              Finish setup
            </Link>
          </Flex>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      border="1px solid"
      borderColor="gray.200"
      mr={4}
      pt="16"
      pb="6"
      borderRadius="lg"
      px={10}
      width="100%"
    >
      <Box
        maxWidth="350px"
        borderRadius="lg"
        paddingTop="12"
        paddingBottom="6"
        mx="auto"
        position="relative"
        px="6"
        background="primary.100"
        mb="8"
      >
        <Text
          color="black"
          textAlign="center"
          fontWeight="semibold"
          fontSize="2xl"
        >
          {data.currentSubscription?.currentPlanDetails.name}
        </Text>

        <Text
          color="black"
          textAlign="center"
          fontWeight="bold"
          fontSize="2xl"
          mb="6"
        >
          {prettyCurrency(
            Number(data.currentSubscription.currentPlanDetails.price),
          )}
          /Month
        </Text>

        <Flex maxWidth={'400px'}>
          <Text flex="1" fontSize="lg">
            Video fees
          </Text>
          <Text fontWeight={'bold'} fontSize="lg">
            {prettyCurrency(data.currentStore.bountyApprovalBaseFee, {
              precision: 2,
            })}{' '}
            <span style={{ fontWeight: 400 }}>+</span>{' '}
            {data.currentStore.bountyServiceFee}%
          </Text>
        </Flex>
      </Box>

      {data.currentSubscription?.currentPlanDetails.trial_ends_on &&
      isDateAfterToday(
        data.currentSubscription.currentPlanDetails.trial_ends_on,
      ) ? (
        <Text mb="6">
          Your trial ends on{' '}
          {data.currentSubscription.currentPlanDetails.trial_ends_on}.
        </Text>
      ) : null}
      <Text mb="6">
        In addition to the subscription you'll be billed based on the views
        generated through Bounty. You can view and adjust payouts in the "Public
        Offer" tab.
      </Text>

      <Text mb="6">Want to change your plan?</Text>
      <Text mb="6">
        Reach out to{' '}
        <Link
          isExternal
          textDecoration="underline"
          href={`mailto:${SUPPORT_EMAIL}?subject=Change my Plan`}
        >
          support
        </Link>
        .
      </Text>
    </Box>
  )
}
