import {
  Flex,
  Link,
  Page,
  Tile,
  Link as ChakraLink,
  Button,
  Image,
  Text,
  Alert,
  AlertTitle,
  AlertDescription,
  Heading,
  PageHeaderDescription,
  PageHeaderContents,
  PageHeaderActions,
  PageSkeleton,
  PageHeaderPrimaryAction,
  Skeleton,
  PageHeaderTitle,
  PageHeaderInformation,
} from '@bounty/brands-design-system'
import { useQueryBackend } from '../../apollo/backend/hooks'
import { CommunityLandingPageCopyLink } from '../../components/Community/CommunityLandingPageCopyLink'
import { StorefrontDocument } from '../../generated/backendGraphql'
import StorefrontBranding from '../../images/storefront-branding.png'

export const Community = () => {
  const { loading, data: { storefront } = {} } =
    useQueryBackend(StorefrontDocument)

  if (loading) return <PageSkeleton />

  const hasBranding = storefront && storefront.headerImgLinkPrimary

  return (
    <Page name="Signup page">
      <PageHeaderContents>
        <PageHeaderInformation>
          <PageHeaderTitle>Your signup page</PageHeaderTitle>
          <PageHeaderDescription mb="6">
            Share this link anywhere you'd like people to be able to sign up to
            make videos and we'll take care of the rest!
          </PageHeaderDescription>
        </PageHeaderInformation>
        {hasBranding && (
          <PageHeaderActions mb="6">
            {storefront.slug ? (
              <PageHeaderPrimaryAction
                as={ChakraLink}
                isExternal={true}
                href={`https://bounty.co/s/${storefront.slug}`}
                target="_blank"
                event="Community View Live Clicked"
              >
                View Live
              </PageHeaderPrimaryAction>
            ) : (
              <Skeleton height="10" width="20" mb="2" />
            )}
          </PageHeaderActions>
        )}
      </PageHeaderContents>
      {hasBranding ? (
        <>
          <Tile
            py="8"
            px="6"
            mb="6"
            as={Flex}
            flexDirection={['column-reverse', null, null, null, 'row']}
            justifyContent="space-between"
          >
            <Flex
              flexDir="column"
              width={['100%', '100%', '100%', '100%', '600px']}
              mr="6"
            >
              <Heading size="md" mb="4">
                Your signup page link
              </Heading>
              <CommunityLandingPageCopyLink slug={storefront.slug} />
            </Flex>
          </Tile>
          <Tile
            py="8"
            px="6"
            mb="6"
            as={Flex}
            flexDirection={['column-reverse', null, null, null, 'row']}
            justifyContent="space-between"
          >
            <Flex
              flexDir="column"
              width={['100%', '100%', '100%', '100%', '600px']}
              mr="6"
            >
              <Heading size="md" mb="4">
                Branding
              </Heading>
              <Text mb="6" width="80%">
                Update your logo, imagery, select categories and tell shoppers
                what makes your brand/product special.
              </Text>
              <Button
                size="md"
                width="min-content"
                as={Link}
                event="Storefront Configure Branding Clicked"
                to={'../branding'}
              >
                Configure
              </Button>
            </Flex>

            <Image
              src={StorefrontBranding}
              alt={'Storefront branding'}
              height="24"
              width="56"
              objectFit="contain"
              objectPosition={['center left', null, null, 'center center']}
              my="auto"
              pb="2"
            />
          </Tile>
        </>
      ) : (
        <Alert>
          <AlertTitle>Branding is not configured.</AlertTitle>
          <AlertDescription>
            Add a logo image and header image in branding to enable the
            community landing page.{' '}
            <Link
              isExternal={false}
              to={'/branding'}
              textDecoration={'underline'}
            >
              Update branding
            </Link>
            .
          </AlertDescription>
        </Alert>
      )}
    </Page>
  )
}
