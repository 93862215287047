import { CLOUDINARY_URL, S3_BUCKET_URL } from '../config/env'
import {
  getLinkForTikTokThumbnail,
  getInstagramReelThumbnail,
  getLinkForTikTokVideo,
  getInstagramReelVideo,
  getTikTokProfileImage,
  getInstagramProfileImage,
} from '@bounty/utils'

// fix this to share these types across projects somehow
type ContentTypes =
  | 'TIKTOK_VIDEO'
  | 'INSTAGRAM_REEL'
  | 'INSTAGRAM_STORY'
  | 'DIRECT_UPLOAD_VIDEO'

export type AccountTypes = 'TIKTOK' | 'INSTAGRAM'

const CLOUDINARY_UPLOADS_PATH = '/image/upload'

/**
 * TODO: move everything in this file to common or something so it can be used in every
 * project and not just brands
 */
export const getPreviewImageLinkForContent = (
  contentType: ContentTypes,
  externalContentId: string,
) => {
  if (contentType === 'TIKTOK_VIDEO') {
    return getLinkForTikTokThumbnail(CLOUDINARY_URL, externalContentId)
  }
  if (contentType === 'INSTAGRAM_REEL') {
    return getInstagramReelThumbnail(CLOUDINARY_URL, externalContentId)
  }
  return null
}

export const getContentVideoLocation = (
  contentType: ContentTypes,
  externalContentId: string,
) => {
  if (contentType === 'TIKTOK_VIDEO') {
    return getLinkForTikTokVideo(S3_BUCKET_URL, externalContentId)
  }
  if (contentType === 'INSTAGRAM_REEL') {
    return getInstagramReelVideo(S3_BUCKET_URL, externalContentId)
  }
  return null
}

export const getUserProfileImage = (
  userId: string,
  accountType: 'TIKTOK' | 'INSTAGRAM',
) => {
  if (accountType === 'TIKTOK') {
    return getTikTokProfileImage(
      CLOUDINARY_URL + CLOUDINARY_UPLOADS_PATH,
      userId,
    )
  }
  if (accountType === 'INSTAGRAM') {
    return getInstagramProfileImage(
      CLOUDINARY_URL + CLOUDINARY_UPLOADS_PATH,
      userId,
    )
  }

  return null
}

export const contentTypeToAccountType = (
  contentType: ContentTypes,
): AccountTypes | null => {
  if (contentType === 'TIKTOK_VIDEO') {
    return 'TIKTOK'
  }
  if (contentType === 'INSTAGRAM_REEL' || contentType === 'INSTAGRAM_STORY') {
    return 'INSTAGRAM'
  }
  return null
}

export const getLinkToSocialAccount = (
  profileName: string,
  accountType: AccountTypes,
) => {
  if (accountType === 'TIKTOK') {
    return `https://www.tiktok.com/${profileName}`
  }
  if (accountType === 'INSTAGRAM') {
    return `https://www.instagram.com/${profileName}`
  }
  return undefined
}
