import { z } from 'zod'

export const inviteSchema = z.object({
  rowData: z.array(z.object({ email: z.string().email(), name: z.string() })),
})

export type InviteSchema = z.infer<typeof inviteSchema>

// TODO: Stubbed to show the pattern. Make sure this is right
export const messageSchema = z.object({
  giftId: z.string(),
  message: z.string().max(2000).optional(),
})

export type MessageSchema = z.infer<typeof inviteSchema>

export const reviewSchema = z.object({
  reviewData: z.array(
    z.object({
      email: z.string().email(),
      message: z.string().max(2000).optional(),
      gift: z.string(),
      name: z.string().optional(),
    }),
  ),
})

export type ReviewSchema = z.infer<typeof reviewSchema>

export const inviteCreatorsYouKnowSchema = inviteSchema.merge(messageSchema)

export type InviteCreatorsYouKnowSchema = z.infer<
  typeof inviteCreatorsYouKnowSchema
>
