import { ReactNode } from 'react'
import {
  HeadingProps,
  Heading,
  Flex,
  FlexProps,
  TextProps,
  ButtonProps,
  Text,
  BoxProps,
  Box,
  forwardRef,
} from '../../Chakra'
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbItemProps,
  BreadcrumbLink,
  BreadcrumbLinkProps,
  BreadcrumbProps,
} from '../../Chakra/Breadcrumb'
import { Button } from '../../Chakra/Button'

export type PageHeaderTitleProps = HeadingProps

export const PageHeaderTitle = forwardRef<PageHeaderTitleProps, 'h2'>(
  ({ children, ...rest }, ref) => {
    return (
      <Heading as="h1" ref={ref} size="lg" mb="2" {...rest}>
        {children}
      </Heading>
    )
  },
)

export type PageHeaderDescriptionProps = TextProps

export const PageHeaderDescription = forwardRef<
  PageHeaderDescriptionProps,
  'p'
>(({ children, ...rest }, ref) => {
  return (
    <Text color="neutral.700" ref={ref} {...rest}>
      {children}
    </Text>
  )
})

export type PageHeaderInformationProps = BoxProps

export const PageHeaderInformation = forwardRef<
  PageHeaderInformationProps,
  'div'
>((props, ref) => {
  return <Box ref={ref} width="100%" mb={['6', null, '0']} {...props} />
})

export type PageHeaderPrimaryActionProps = ButtonProps

export const PageHeaderPrimaryAction = forwardRef<
  PageHeaderPrimaryActionProps,
  'button'
>((props, ref) => {
  return <Button {...props} ref={ref} />
})

export type PageHeaderSecondaryActionProps = ButtonProps

export const PageHeaderSecondaryAction = forwardRef<
  PageHeaderSecondaryActionProps,
  'button'
>((props, ref) => {
  return <Button ref={ref} variant="outline" {...props} />
})

export type PageHeaderTertiaryActionProps = ButtonProps

export const PageHeaderTertiaryAction = forwardRef<
  PageHeaderTertiaryActionProps,
  'button'
>((props, ref) => {
  return <Button ref={ref} variant={'ghost'} {...props} />
})

export type PageHeaderActionsProps = FlexProps

export const PageHeaderActions = forwardRef<PageHeaderActionsProps, 'div'>(
  (props, ref) => {
    return <Flex ref={ref} gap="3" {...props} />
  },
)

export type PageHeaderBreadcrumbItemProps = BreadcrumbItemProps

export const PageHeaderBreadcrumbItem = forwardRef<
  PageHeaderBreadcrumbItemProps,
  'li'
>(({ ...rest }, ref) => {
  return <BreadcrumbItem ref={ref} {...rest} />
})

export type PageHeaderBreadcrumbLinkProps = BreadcrumbLinkProps

export const PageHeaderBreadcrumbLink = forwardRef<
  PageHeaderBreadcrumbLinkProps,
  'a'
>(({ ...rest }, ref) => {
  return <BreadcrumbLink ref={ref} {...rest} />
})

export type PageHeaderBreadcrumbProps = BreadcrumbProps

export const PageHeaderBreadcrumb = forwardRef<
  PageHeaderBreadcrumbProps,
  'nav'
>((props, ref) => {
  return <Breadcrumb ref={ref} mb="5" {...props} />
})

export type PageHeaderContentsProps = FlexProps

export const PageHeaderContents = forwardRef<PageHeaderContentsProps, 'div'>(
  (props, ref) => {
    return (
      <Flex
        ref={ref}
        flexDirection={['column', null, 'row']}
        justifyContent={'space-between'}
        width="100%"
        {...props}
      />
    )
  },
)

export type PageHeaderProps = BoxProps & {
  breadcrumbs?: {
    name: ReactNode
    to: string
    isCurrentPage?: boolean
    props?: Omit<PageHeaderBreadcrumbLinkProps, 'to'>
  }[]
  title?: ReactNode
  description?: ReactNode
  actions?: ReactNode
}

export const PageHeader = forwardRef<PageHeaderProps, 'div'>(
  ({ breadcrumbs, title, description, actions, children, ...props }, ref) => {
    return (
      <Box mb="6" ref={ref} width="100%" {...props}>
        {breadcrumbs && (
          <PageHeaderBreadcrumb>
            {breadcrumbs.map(({ isCurrentPage, ...breadcrumb }, index) => {
              return (
                <BreadcrumbItem key={index}>
                  <PageHeaderBreadcrumbItem
                    as="ul"
                    isCurrentPage={isCurrentPage}
                  >
                    <PageHeaderBreadcrumbLink
                      to={breadcrumb.to}
                      {...breadcrumb.props}
                    >
                      {breadcrumb.name}
                    </PageHeaderBreadcrumbLink>
                  </PageHeaderBreadcrumbItem>
                </BreadcrumbItem>
              )
            })}
          </PageHeaderBreadcrumb>
        )}
        <PageHeaderContents>
          <PageHeaderInformation>
            {title && <PageHeaderTitle>{title}</PageHeaderTitle>}
            {description && (
              <PageHeaderDescription>{description}</PageHeaderDescription>
            )}
          </PageHeaderInformation>
          {actions}
        </PageHeaderContents>
        {children}
      </Box>
    )
  },
)
