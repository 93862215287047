import * as z from 'zod'

export const payoutCalculatorViewsParamsSchema = z.object({
  views: z.string().transform((x: any) => Number(x)),
  includeMinimumPayout: z
    .string()
    .optional()
    .transform((x?: string | null) => x?.toLowerCase() === 'true'),
})

export type PayoutCalculatorViewsParams = z.infer<
  typeof payoutCalculatorViewsParamsSchema
>
