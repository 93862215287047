import {
  AlertDialog as AlertDialogCore,
  AlertDialogProps as AlertDialogPropsCore,
  AlertDialogBody as AlertDialogBodyCore,
  AlertDialogFooter as AlertDialogFooterCore,
  AlertDialogHeader as AlertDialogHeaderCore,
  AlertDialogContent as AlertDialogContentCore,
  AlertDialogOverlay as AlertDialogOverlayCore,
  AlertDialogCloseButton as AlertDialogCloseButtonCore,
  forwardRef,
} from './Chakra'
import { ComponentProps } from 'react'
import { Button, ButtonProps } from './Button'

/**
 * I don't see a first class way to edit this component :(. Wrapped and
 * put in styles instead.
 *
 * https://chakra-ui.com/docs/components/alert-dialog/usage
 */

export type AlertDialogProps = AlertDialogPropsCore

export const AlertDialog = ({ ...props }: AlertDialogProps) => {
  return <AlertDialogCore isCentered {...props} />
}

export type AlertDialogBodyProps = ComponentProps<typeof AlertDialogBodyCore>

export const AlertDialogBody = ({ ...props }: AlertDialogBodyProps) => {
  return <AlertDialogBodyCore {...props} />
}

export type AlertDialogHeaderProps = ComponentProps<
  typeof AlertDialogHeaderCore
>

export const AlertDialogHeader = ({ ...props }: AlertDialogHeaderProps) => {
  return (
    <AlertDialogHeaderCore
      fontSize="lg"
      pt="6"
      pb="0"
      fontFamily={'heading'}
      size="md"
      {...props}
    />
  )
}

export type AlertDialogFooterProps = ComponentProps<
  typeof AlertDialogFooterCore
>

export const AlertDialogFooter = ({ ...props }: AlertDialogFooterProps) => {
  return <AlertDialogFooterCore pb="6" gap="3" display="flex" {...props} />
}

export type AlertDialogContentProps = ComponentProps<
  typeof AlertDialogContentCore
>

export const AlertDialogContent = ({ ...props }: AlertDialogContentProps) => {
  return <AlertDialogContentCore {...props} />
}

export type AlertDialogOverlayProps = ComponentProps<
  typeof AlertDialogOverlayCore
>

export const AlertDialogOverlay = ({ ...props }: AlertDialogOverlayProps) => {
  return <AlertDialogOverlayCore {...props} />
}

export type AlertDialogCloseButtonProps = ComponentProps<
  typeof AlertDialogCloseButtonCore
>

export const AlertDialogCloseButton = ({
  ...props
}: AlertDialogCloseButtonProps) => {
  return <AlertDialogCloseButtonCore {...props} />
}

export type AlertDialogPrimaryButtonProps = ButtonProps

export const AlertDialogPrimaryButton = forwardRef<
  AlertDialogPrimaryButtonProps,
  'button'
>(({ ...props }, ref) => {
  return <Button ref={ref} size="lg" flexGrow="1" {...props} />
})

export type AlertDialogSecondaryButtonProps = ButtonProps

export const AlertDialogSecondaryButton = forwardRef<
  AlertDialogSecondaryButtonProps,
  'button'
>(({ ...props }, ref) => {
  return (
    <Button ref={ref} size="lg" variant={'outline'} flexGrow="1" {...props} />
  )
})
