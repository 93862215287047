import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerProps,
  DrawerBody,
  Text,
  Box,
  Heading,
  HandshakeIcon,
  HandWavingIcon,
  ShopIcon,
  WrenchIcon,
  DrawerHeader,
  DrawerHeading,
  DrawerDescription,
  DrawerCloseButton,
} from '@bounty/brands-design-system'
import { Flex, Link } from '@bounty/brands-design-system'
import { useNavigate } from 'react-router-dom'
import { LearnMoreLink } from '../../../../components/LearnMoreLink/LearnMoreLink'

const linkBoxes = [
  // {
  //   link: 'creators-you-know',
  //   heading: 'Invite creators you know to Bounty',
  //   description:
  //     'Invite creators you already work with to join your community on Bounty.',
  //   icon: <HandshakeIcon size={32} />,
  // },
  // {
  //   link: 'creators-you-dont-know',
  //   heading: 'Invite creators you do not know to Bounty',
  //   description:
  //     'Add creators you don’t know and we’ll try to activate them for your brand.',
  //   icon: <HandWavingIcon size={32} />,
  // },
  // {
  //   link: 'activate-previous-customers',
  //   heading: 'Activate previous customers',
  //   description:
  //     'Activate previous customers so they can complete a Bounty for a previous purchase.',
  //   icon: <ShopIcon size={32} />,
  // },
  {
    link: 'advanced',
    heading: 'Advanced',
    description:
      'Generate unique invite links to give gifts to people through SMS, DMs, email, and more.',
    icon: <WrenchIcon size={32} />,
  },
]

export type InviteCreatorsDrawerProps = Omit<
  DrawerProps,
  'children' | 'isOpen' | 'onClose'
> & {
  rootPath: string
}

export const InviteCreatorsDrawer = ({
  rootPath,
  ...rest
}: InviteCreatorsDrawerProps) => {
  const navigate = useNavigate()

  return (
    <Drawer size="lg" isOpen onClose={() => navigate(rootPath)} {...rest}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <DrawerHeading mb="4">Invite</DrawerHeading>
          <DrawerDescription mb="4">
            Invites are specialized message flows designed to incentivize
            different segments people to join your community of creators on
            Bounty.{' '}
            <LearnMoreLink href="https://brands.support.bounty.co/hc/en-us/articles/11770189830427-What-are-Invites-" />
          </DrawerDescription>
          <DrawerDescription>
            Choose how you’d like to invite creators.{' '}
            <Link
              to="/creators/invite/history"
              isExternal={false}
              textDecoration="underline"
            >
              View history
            </Link>
            .
          </DrawerDescription>
          <DrawerCloseButton />
        </DrawerHeader>
        <DrawerBody pt="0" pb="12">
          <Box
            pt="px"
            display="grid"
            gridTemplateColumns="1fr"
            gridAutoRows="1fr"
            gridGap="7"
          >
            {linkBoxes.map(({ link, heading, description, icon }) => (
              <Link
                isExternal={false}
                textDecoration="none"
                to={link}
                display="block"
                border="1px solid"
                borderColor="gray.200"
                borderRadius="md"
                _hover={{
                  boxShadow: '0px 0px 0px 1px #4318FF',
                  borderColor: '#4318FF',
                  textDecoration: 'none',
                }}
              >
                <Flex p="4">
                  <Box pl="2" pr="6" width="auto">
                    {icon}
                  </Box>
                  <Box>
                    <Heading as="h4" size="sm" mb="2">
                      {heading}
                    </Heading>
                    <Text color="gray.600">{description}</Text>
                  </Box>
                </Flex>
              </Link>
            ))}
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
