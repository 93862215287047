import { getBackendClient } from './backend/client'
import { getShopifyAdminClient } from './shopify-admin/client'
import {
  mergedTheme,
  createStandaloneToast,
} from '@bounty/brands-design-system'

export const {
  ToastContainer: ApolloStandaloneToast,
  toast: apolloErrorToast,
} = createStandaloneToast({
  theme: mergedTheme,
  defaultOptions: { isClosable: true, duration: 15000, status: 'error' },
})

export const logout = () => {
  return Promise.all([
    getBackendClient().clearStore(),
    getShopifyAdminClient().clearStore(),
  ]).then(() => {
    localStorage.removeItem('authToken')
    window.location.assign('/login')
  })
}
