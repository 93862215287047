import {
  Box,
  Button,
  Flex,
  Input,
  Form,
  FormErrorMessage,
  FormControl,
  PageSkeleton,
} from '@bounty/brands-design-system'
import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import {
  BrandedHashtagQueryDocument,
  UpdateStoreBrandedHashtagDocument,
} from '../../generated/backendGraphql'
import { shopifyStoreBrandedHashtagSchema } from '@bounty/schemas'
import * as z from 'zod'

type SchemaType = z.infer<typeof shopifyStoreBrandedHashtagSchema>

export type BrandedHashtagFormProps = {
  onSubmitSuccess?: (values: SchemaType) => void
  variant: 'large' | 'regular'
}

export const BrandedHashtagForm = ({
  onSubmitSuccess,
  variant,
}: BrandedHashtagFormProps) => {
  const {
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    control,
  } = useForm<SchemaType>({
    resolver: zodResolver(shopifyStoreBrandedHashtagSchema),
  })

  const [updateBrandedHashtag, { loading: updateBrandedHashtagLoading }] =
    useMutationBackend(UpdateStoreBrandedHashtagDocument)
  const { data } = useQueryBackend(BrandedHashtagQueryDocument)

  useEffect(() => {
    if (!data?.currentStore.brandedHashtag) return

    reset({
      ...getValues(),
      brandedHashtag: data.currentStore.brandedHashtag,
    })
  }, [data, reset, getValues])

  if (!data) return <PageSkeleton />

  return (
    <Box>
      <Form
        data-testid="branded-hashtag-form"
        onSubmit={handleSubmit(async (d) => {
          await updateBrandedHashtag({
            variables: d,
            optimisticResponse: {
              __typename: 'Mutation',
              updateBrandedHashtag: {
                ...d,
                __typename: 'ShopifyStore',
                id: data?.currentStore.id,
              },
            },
          })

          onSubmitSuccess?.(d)
        })}
      >
        <Flex alignItems="flex-start" maxWidth="310px">
          <FormControl isInvalid={!!errors.brandedHashtag} marginRight="2">
            <Controller
              defaultValue={data.currentStore.brandedHashtag ?? '#bounty'}
              control={control}
              name="brandedHashtag"
              render={({ field }) => (
                <Input
                  data-testid="branded-hashtag-form-input"
                  {...field}
                  onChange={(e) => {
                    const { value } = e.target
                    const changeValue = value
                      ? value.startsWith('#')
                        ? value
                        : `#${value}`
                      : ''

                    field.onChange(changeValue)
                  }}
                />
              )}
            />
            {errors.brandedHashtag && (
              <FormErrorMessage>
                {errors.brandedHashtag.message}
              </FormErrorMessage>
            )}
          </FormControl>

          <Button
            isLoading={updateBrandedHashtagLoading}
            size={variant === 'large' ? 'lg' : 'md'}
            type="submit"
            variant="outline"
            data-testid="submit-branded-hashtag-form-button"
            event="Branded Hashtag Form Submitted"
          >
            Submit
          </Button>
        </Flex>
      </Form>
    </Box>
  )
}
