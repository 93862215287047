import { Link } from '@bounty/brands-design-system'

type LearnMoreLinkProps = {
  href: string
  text?: string
}

export const LearnMoreLink = ({ href, text }: LearnMoreLinkProps) => {
  return (
    <Link href={href} isExternal whiteSpace="nowrap">
      {text ?? 'Learn more'}
    </Link>
  )
}
