import {
  BoxProps,
  Heading,
  Text,
  Tile,
  Skeleton,
  Box,
  Flex,
} from '@bounty/brands-design-system'
import { CodeSnippet } from '../../../components/CodeSnippet'
import { useQueryBackend } from '../../../apollo/backend/hooks'
import { ManualInstallDocument } from '../../../generated/backendGraphql'
import { InstallSnippetToggleButton } from '../InstallSnippetToggleButton'

type SnippetPreviewProps = BoxProps

export const ManualInstall = (props: SnippetPreviewProps) => {
  const { data, loading: queryLoading } = useQueryBackend(ManualInstallDocument)

  return (
    <Tile position="relative" height="100%" px="10" py="8" {...props}>
      <Text
        color="green.600"
        opacity="0.6"
        fontSize="2xl"
        fontWeight="bold"
        marginBottom="2"
      >
        Option 1
      </Text>
      <Heading as="h3" size="lg">
        Manual Installation
      </Heading>
      <Box mb="4">
        <Text mb="2">First, you need to install the widget script.</Text>
        {queryLoading || data == null ? (
          <Skeleton height="35px" width="90px" />
        ) : (
          <Flex alignItems="center">
            <InstallSnippetToggleButton
              code="PRODUCT"
              showToggle={false}
              prettyName="Product Display Widget"
            />
          </Flex>
        )}
      </Box>
      <Box>
        <Text mb="2">
          Next, add this snippet to your product page. We suggest under your add
          to cart button. But it’s up to you!
        </Text>
      </Box>
      <CodeSnippet />
    </Tile>
  )
}
