import { useState } from 'react'
import {
  Page,
  PageHeader,
  PageHeaderContents,
  PageHeaderDescription,
  PageHeaderTitle,
  PageHeaderInformation,
  PageHeaderActions,
  useFastForm,
  Flex,
  Box,
  useToast,
} from '@bounty/brands-design-system'
import {
  UpdateStorefrontDealsDocument,
  DealsType,
} from '../../generated/backendGraphql'
import { DealsSchema } from '@bounty/schemas'
import { useMutationBackend } from '../../apollo/backend/hooks'

type DealsProps = {
  dealsData: readonly DealsType[]
}

export const Deals = ({ dealsData }: DealsProps) => {
  const toast = useToast()
  const [selectedDeal] = useState(0)
  const [dealsArr] = useState(dealsData)

  const [updateDeals, { loading: updateDealsLoading }] = useMutationBackend(
    UpdateStorefrontDealsDocument,
  )

  const {
    Form,
    Input,
    Textarea,
    SubmitButton,
    DateInput,
    methods: {
      formState: { isDirty },
    },
  } = useFastForm({
    schema: DealsSchema,
    defaultValues: {
      deals: dealsArr?.map((deal, i) => {
        return {
          title: deal.title ?? '',
          dealsUrl: deal.dealsUrl ?? '',
          description: deal.description ?? '',
          terms: deal.terms ?? '',
          startDate: deal.startDate ?? '',
          endDate: deal.endDate ?? '',
        }
      }),
    },
  })

  return (
    <Form
      onSubmit={async (params) => {
        const { data } = await updateDeals({
          variables: {
            input: {
              deals: params.deals,
            },
          },
        })

        if (data) {
          toast({
            title: 'Deals customize success',
            description: 'Successfully updated deals.',
            status: 'success',
          })
        }
      }}
    >
      <Page
        name="Customize Storefront Deals"
        data-testid="customize-storefront-deals-page"
        fixedHeight="all"
        addBottomSpacing={false}
        display="flex"
        flexDir="column"
        fluid
      >
        <Flex justifyContent="space-between" alignItems="flex-start" pr="12">
          <PageHeader
            breadcrumbs={[
              {
                name: 'Storefront',
                to: '/storefront',
              },
              {
                name: 'Current',
                to: '',
                isCurrentPage: true,
              },
            ]}
          >
            <PageHeaderContents>
              <PageHeaderInformation>
                <PageHeaderTitle>Deals</PageHeaderTitle>
                {/* TODO: Needs a page description */}
                <PageHeaderDescription></PageHeaderDescription>
              </PageHeaderInformation>
              <PageHeaderActions>
                <SubmitButton
                  data-testid="customize-storefront-deals-save"
                  event="Customize Storefront Deals Save Clicked"
                  isDisabled={isDirty === false || updateDealsLoading}
                  isLoading={updateDealsLoading}
                >
                  Save
                </SubmitButton>
              </PageHeaderActions>
            </PageHeaderContents>
          </PageHeader>
        </Flex>
        <Box
          display="flex"
          flex="1"
          minH="0"
          borderTop="1px solid"
          borderColor="gray.300"
          justifyContent="center"
        >
          <Box width="xl" overflowY="auto" py="6" px="10">
            <Input
              label="Title"
              name={`deals.${selectedDeal}.title`}
              placeholder="Buy 1 Get 50% off"
            />
            <Input
              label="Deals Url"
              name={`deals.${selectedDeal}.dealsUrl`}
              placeholder="URL to homepage,deal page, etc."
            />
            <Textarea
              label="Description"
              name={`deals.${selectedDeal}.description`}
              placeholder="Tell shoppers a little more about the deal or an idea of what to purchase."
            />
            <Input
              label="Legal Disclaimer"
              name={`deals.${selectedDeal}.terms`}
              placeholder="List any terms and conditions or rules for qualifying."
              isRequired={false}
            />
            <DateInput
              label="Start Date"
              name={`deals.${selectedDeal}.startDate`}
              isRequired={false}
            />
            <DateInput
              label="End Date"
              name={`deals.${selectedDeal}.endDate`}
              isRequired={false}
            />
          </Box>
        </Box>
      </Page>
    </Form>
  )
}
