/**
 * Builds a product slug for a store.
 */
export const buildProductSlug = (
  storeUrl: string,
  productHandle: string,
  includeProtocol = true,
) => {
  return `${includeProtocol ? 'https://' : ''}${storeUrl}${
    storeUrl.endsWith('/') ? '' : '/'
  }products/${productHandle}`
}

export const buildShortShopUrl = (shopUrl: string) => {
  return shopUrl.replace('.myshopify.com', '')
}
