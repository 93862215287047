import { useEffect } from 'react'
import { z } from 'zod'

import {
  Alert,
  AlertDescription,
  AlertTitle,
  Box,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useFastForm,
  useToast,
} from '@bounty/brands-design-system'

import {
  useMutationBackend,
  useQueryBackend,
} from '../../../apollo/backend/hooks'
import {
  GiftListDocument,
  OfferGiftBulkCreatorsDocument,
} from '../../../generated/backendGraphql'
import { GiftDisplay } from '../Invites/components/GiftDisplay/GiftDisplay'

const offerGiftFormSchema = z.object({
  giftId: z.string(),
  message: z.string().max(1000).optional(),
})

type OfferCreatorsGiftModalProps = {
  isOpen: boolean
  onClose: () => void
  userIds: string[]
}

export const OfferCreatorsGiftModal = ({
  isOpen,
  onClose,
  userIds,
}: OfferCreatorsGiftModalProps) => {
  const { data: { enabledStoreGifts = [] } = {} } =
    useQueryBackend(GiftListDocument)

  const [mutation, { loading: offerGiftLoading }] = useMutationBackend(
    OfferGiftBulkCreatorsDocument,
    {
      update(cache, { data }) {
        userIds.forEach((userId) => {
          if (data) {
            cache.modify({
              id: `UserDetails:${userId}`,
              fields: {
                numGifts(prev = 0) {
                  return prev + 1
                },
              },
            })

            cache.modify({
              id: `StoreBountyUser:${userId}`,
              fields: {
                numGifts(prev = 0) {
                  return prev + 1
                },
              },
            })

            cache.modify({
              id: `CreatorNetworkSearchUser:${userId}`,
              fields: {
                numGifts(prev = 0) {
                  return prev + 1
                },
              },
            })
          }
        })
      },
    },
  )

  const toast = useToast()

  const {
    Form,
    Select,
    SubmitButton,
    Textarea,
    methods: { watch, reset },
  } = useFastForm({
    schema: offerGiftFormSchema,
    defaultValues: {
      giftId: enabledStoreGifts?.[0]?.id ?? '',
      message: '',
    },
  })

  useEffect(() => {
    reset()
  }, [isOpen, reset])

  const [watchGiftId] = watch(['giftId'])
  const selectedGift = enabledStoreGifts.find((el) => el.id === watchGiftId)

  return (
    <Modal
      size="3xl"
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      allowPinchZoom
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Offer Gift</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Form
            onSubmit={async ({ giftId, message }) => {
              mutation({
                variables: {
                  giftId,
                  userIds,
                  message,
                },
                onCompleted: () => {
                  toast({
                    title: 'Gift Sent!',
                    description:
                      'Your gift invitation has been sent to the creators',
                    status: 'success',
                  })

                  onClose()
                },
                onError: () => {
                  toast({
                    title: 'Gift Could Not Be Sent',
                    description: 'Please try again or contact support',
                    status: 'error',
                  })
                },
              })
            }}
          >
            {enabledStoreGifts.length === 0 ? (
              <Alert status="warning">
                <AlertTitle>
                  You don't have any gifts configured yet!
                </AlertTitle>
                <AlertDescription>
                  <Link
                    isExternal={false}
                    to={'/gifts/new'}
                    textDecoration={'underline'}
                  >
                    create a gift
                  </Link>
                </AlertDescription>
              </Alert>
            ) : (
              <Select
                label="Gift"
                name="giftId"
                placeholder="Bounty"
                options={enabledStoreGifts.map((x) => ({
                  label: x.name,
                  value: x.id,
                }))}
                helpText={
                  <Text color="gray.600" size="sm" mb="2">
                    Select a gift for the creator or{' '}
                    <Link
                      isExternal={false}
                      to="/gifts/new"
                      textDecoration="underline"
                      color="inherit"
                    >
                      create one
                    </Link>
                    .
                  </Text>
                }
              />
            )}
            <Box mb="4">
              {selectedGift && <GiftDisplay gift={selectedGift} />}
            </Box>
            <Textarea
              name="message"
              label="Message To Creators"
              isRequired={false}
              maxCharacterLimit={1000}
            />
            <SubmitButton
              mt="2rem"
              isDisabled={offerGiftLoading}
              event="Offer Creators a Gift Submission"
            >
              Send Gift to {userIds.length} Selected{' '}
              {userIds.length > 1 ? 'Creators' : 'Creator'}
            </SubmitButton>
          </Form>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
