import { isNil } from '@bounty/utils'
import dlv from 'dlv'
import { forwardRef, useState } from 'react'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import {
  Slider,
  SliderFilledTrack,
  SliderProps,
  SliderThumb,
  SliderTrack,
  Tooltip,
} from '../../Chakra'
import { FastFormControl } from './FastFormControl'
import { FastFormElementProps } from './types'
import { getLabelFromName } from './utils'

export type FastFormSliderBaseProps = Omit<SliderProps, 'name' | 'children'> & {
  showTooltip?: boolean
  name?: string
}

export const FastFormSliderBase = forwardRef<
  HTMLDivElement,
  FastFormSliderBaseProps
>(
  // Consistent with Chakra
  (
    {
      value,
      showTooltip: showTooltipProp = true,
      min = 0,
      max = 100,
      name,
      ...rest
    },
    ref,
  ) => {
    const [showTooltip, setShowTooltip] = useState(false)

    const getTooltipValue = () => {
      // This is what Chakra does under the hood
      if (isNil(value)) {
        return (min + max) / 2
      }

      return value
    }

    return (
      <Slider
        ref={ref}
        aria-label={`slider-${name}`}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        name={name}
        min={min}
        max={max}
        value={value}
        {...rest}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        {showTooltipProp ? (
          <Tooltip
            hasArrow={false}
            placement="top"
            bg="white"
            color="neutral.900"
            isOpen={showTooltip}
            label={`${getTooltipValue()}`}
          >
            <SliderThumb />
          </Tooltip>
        ) : (
          <SliderThumb />
        )}
      </Slider>
    )
  },
)

FastFormSliderBase.displayName = 'FastFormSliderBase'

export type FastFormSliderProps<
  TFieldValues extends FieldValues = FieldValues,
> = FastFormElementProps<FastFormSliderBaseProps, TFieldValues> &
  Pick<FastFormSliderBaseProps, 'min' | 'max' | 'step' | 'showTooltip'>
export const FastFormSlider = <TFieldValues extends FieldValues = FieldValues>({
  label,
  name,
  helpText,
  labelProps,
  tooltipText,
  errorProps,
  isRequired,
  isDisabled,
  formControlProps,
  formElementProps,
  min,
  max,
  step,
  showLabel,
}: FastFormSliderProps<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>()
  const {
    control,
    formState: { errors },
  } = methods
  const error = dlv(errors, name)

  return (
    <FastFormControl
      error={error}
      helpText={helpText}
      label={label ?? getLabelFromName(name)}
      labelProps={labelProps}
      tooltipText={tooltipText}
      errorProps={errorProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
      showLabel={showLabel}
      {...formControlProps}
    >
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <FastFormSliderBase
              min={min}
              max={max}
              step={step}
              {...formElementProps}
              {...field}
            />
          )
        }}
      />
    </FastFormControl>
  )
}
