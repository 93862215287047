import {
  BOUNTY_LANDING_PAGE_SNIPPET_REMOTE_CONFIG_SETTINGS,
  BOUNTY_PRODUCT_SNIPPET_REMOTE_CONFIG_SETTINGS,
  BOUNTY_THANK_YOU_SNIPPET_REMOTE_CONFIG_SETTINGS,
  RemoteConfigSnippetSetting,
  RemoveConfigSnippetSettingValue,
  SnippetPropConfigurationObject,
} from '@bounty/constants'
import { parseBoolean, isString, isNil, isBoolean } from '@bounty/utils'
import dlv from 'dlv'

const isNotEmptyStringOrNil = <T>(x: T | null | undefined): x is T => {
  if (isNil(x)) return false
  if (isString(x)) return x !== ''
  if (isBoolean(x)) return true

  return false
}

/**
 * Takes in a config setting, embed configuration, and injected snippet props and
 * resolves a value for a snippet setting.
 *
 * Priority is as follows:
 * 1. injectedSnippetProps: Props directly on the snippet or something from an App Block (they're injected as script props)
 * 2. embedConfiguration: This comes from remote config and setting in the brand portal UI
 * 3. default value on the item: All config props carry a default it's eventually resolved to
 */
export const resolveSnippetSettingValue = ({
  setting,
  embedConfiguration,
  injectedSnippetProps,
}: {
  setting: RemoteConfigSnippetSetting
  embedConfiguration?: any | null | undefined
  /**
   * Injected snippet props are props that come from:
   *
   * 1. Somewhere in the document markup for a snippet like a data-prop or script prop tag
   * 2. App blocks
   */
  injectedSnippetProps?: any | null | undefined
}): RemoveConfigSnippetSettingValue => {
  try {
    /**
     * We can't use ?? because we need to guard against empty string
     * We can't use || because false is a value
     * We want only '', null, undefined values to be falsey
     */
    const maybeInjectedValue = dlv(injectedSnippetProps, `${setting.id}`)
    const maybeEmbedConfig = dlv(embedConfiguration, `meta.${setting.id}`)

    const prioritizedValue = isNotEmptyStringOrNil(maybeInjectedValue)
      ? maybeInjectedValue
      : isNotEmptyStringOrNil(maybeEmbedConfig)
      ? maybeEmbedConfig
      : setting.default

    // Data props will come in as string potentially so if the setting type is checkbox
    // we need to parse it to a boolean value always
    if (setting.type === 'checkbox') {
      return parseBoolean(prioritizedValue)
    }

    return prioritizedValue
  } catch (error) {
    return setting.default
  }
}

export const resolveSnippetSettingsForConfiguration =
  (settings: RemoteConfigSnippetSetting[]) =>
  (
    props: SnippetPropConfigurationObject,
  ): Array<[RemoveConfigSnippetSettingValue, RemoteConfigSnippetSetting]> => {
    return settings.map((setting) => {
      return [resolveSnippetSettingValue({ setting, ...props }), setting]
    })
  }

export const resolveSnippetSettingsForConfigurationProduct =
  resolveSnippetSettingsForConfiguration(
    BOUNTY_PRODUCT_SNIPPET_REMOTE_CONFIG_SETTINGS,
  )

export const resolveSnippetSettingsForConfigurationThankYou =
  resolveSnippetSettingsForConfiguration(
    BOUNTY_THANK_YOU_SNIPPET_REMOTE_CONFIG_SETTINGS,
  )

export const resolveSnippetSettingsForConfigurationLandingPage =
  resolveSnippetSettingsForConfiguration(
    BOUNTY_LANDING_PAGE_SNIPPET_REMOTE_CONFIG_SETTINGS,
  )

export const buildRemoteConfigSnippetSettingsObjectProduct = (
  props: SnippetPropConfigurationObject,
) => {
  const obj: { [x: string]: any } = {}

  resolveSnippetSettingsForConfigurationProduct(props).forEach(
    ([value, setting]) => {
      obj[setting.id] = value
    },
  )

  return obj
}

export const buildRemoteConfigSnippetSettingsObjectThankYou = (
  props: SnippetPropConfigurationObject,
) => {
  const obj: { [x: string]: any } = {}

  resolveSnippetSettingsForConfigurationThankYou(props).forEach(
    ([value, setting]) => {
      obj[setting.id] = value
    },
  )

  return obj
}

export const buildRemoteConfigSnippetSettingsObjectLandingPage = (
  props: SnippetPropConfigurationObject,
) => {
  const obj: { [x: string]: any } = {}

  resolveSnippetSettingsForConfigurationLandingPage(props).forEach(
    ([value, setting]) => {
      obj[setting.id] = value
    },
  )

  return obj
}
