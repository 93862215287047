export const GIFT_STATUS_OPTIONS = [
  { label: 'View All', value: null },
  { label: 'Claimed', value: 'CLAIMED' },
  { label: 'Redeemed', value: 'COMPLETED' },
  { label: 'Open', value: 'OPEN' },
  { label: 'Rejected', value: 'REJECTED' },
]

export const BOUNTY_STATUS_OPTIONS = [
  { label: 'View All', value: null },
  { label: 'Completed', value: 'COMPLETED' },
  { label: 'Rejected', value: 'REJECTED' },
  { label: 'Accepted', value: 'ACCEPTED' },
  { label: 'Approved', value: 'APPROVED' },
  { label: 'Hold', value: 'HOLD' },
  { label: 'Live', value: 'LIVE' },
  { label: 'Pending', value: 'PENDING' },
]

export const GIFT_SOURCE_OPTIONS = [
  { label: 'View All', value: null },
  { label: 'Invite', value: 'EXTERNAL_INVITE' },
  { label: 'Prospect', value: 'PROSPECT' },
  { label: 'Creators Table', value: 'USER' },
]
