import {
  Tile,
  Flex,
  PageHeader,
  Heading,
  Text,
  PageSkeleton,
  useFastForm,
  Box,
  PageHeaderActions,
  PageHeaderPrimaryAction,
  useToast,
} from '@bounty/brands-design-system'
import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import { Page } from '@bounty/brands-design-system'
import {
  ContentPricingSettingsDocument,
  ContentPricingSettingsQuery,
  UpdateMinBountyPayoutDocument,
} from '../../generated/backendGraphql'
import { createStateContext } from '@bounty/hooks'
import { BONUS_TYPES } from '@bounty/constants'
import { z } from 'zod'
import { prettyCurrency } from '@bounty/utils'

export type ContentPricingProps = unknown

export const [ContentPricingContext, useContentPricing] = createStateContext({
  multiplier: 0,
  initialPayout: 0,
  bonuses: BONUS_TYPES,
  penalties: [],
})

export const ContentPricingComponent = ({
  data,
}: {
  data: ContentPricingSettingsQuery
}) => {
  const toast = useToast()

  const [updateMinBountyPayout, { loading: updateMinBountyPayoutLoading }] =
    useMutationBackend(UpdateMinBountyPayoutDocument)
  const {
    Form,
    Slider,
    methods: {
      formState: { isDirty },
      watch,
    },
  } = useFastForm({
    schema: z.object({
      minimumPayout: z.number().min(0).max(100),
    }),
    defaultValues: {
      minimumPayout: data.currentStore.minBountyPayment,
    },
  })

  const minimumPayout = watch('minimumPayout')

  return (
    <Form
      onSubmit={async (e) => {
        await Promise.all([
          updateMinBountyPayout({
            variables: {
              minBountyPayment: e.minimumPayout,
            },
          }),
        ])

        toast({
          title: 'Your offer saved!',
          description: 'New bounties will use these incentives.',
          status: 'success',
        })
      }}
    >
      <Page data-testid="content-pricing-page" name="Your Offer">
        <PageHeader
          title="Your Offer"
          description={
            <Text as="span">
              Anyone who purchases an eligible product can opt in to your offer
              through user experiences on your website, your Bounty storefront,
              invite links or anywhere else you promote it.
            </Text>
          }
          actions={
            <PageHeaderActions>
              <PageHeaderPrimaryAction
                type="submit"
                isLoading={updateMinBountyPayoutLoading}
                event="Your Offer Save Incentives"
                isDisabled={!isDirty}
              >
                Save
              </PageHeaderPrimaryAction>
            </PageHeaderActions>
          }
        />

        <Tile mb="6">
          <Heading as="h2" size="md" mb="2">
            First Time Minimum Payout
          </Heading>
          <Text mb="3">
            A guaranteed payout for the first time a creator creates an approved
            video. Your biggest lever to increase conversion rate.
          </Text>
          <Text>
            <Text as="span" fontWeight={'semibold'}>
              Recommended:{' '}
            </Text>
            $10 minimum, higher if your products cost over $50.
          </Text>
          <Flex mt={8}>
            <Box flex={1}>
              <Flex
                flexDirection={'row'}
                width="100%"
                justifyContent={'space-between'}
              >
                <Text color="gray.600" fontWeight={'semibold'}>
                  $0
                </Text>
                <Text color="gray.600" fontWeight={'semibold'}>
                  $100
                </Text>
              </Flex>
              <Slider
                showLabel={false}
                name="minimumPayout"
                min={0}
                max={100}
                step={5}
              />
              <Flex justifyContent={'center'}>
                <Box
                  backgroundColor={'neutral.50'}
                  borderRadius="5"
                  borderWidth={1}
                  borderColor="neutral.300"
                  p={4}
                >
                  {prettyCurrency(minimumPayout)}
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Tile>
      </Page>
    </Form>
  )
}

export const ContentPricing = () => {
  const { data, loading } = useQueryBackend(ContentPricingSettingsDocument)
  if (!data || loading) return <PageSkeleton />

  return <ContentPricingComponent data={data} />
}
