import { defineStyleConfig } from '@chakra-ui/react'

export const radioTheme = defineStyleConfig({
  sizes: {
    lg: {
      label: { fontSize: 'md' },
    },
  },
  defaultProps: {
    colorScheme: 'primary',
    size: 'lg',
  },
})
