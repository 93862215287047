import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { API_URL, RUN_WITH_MOCKED_BACKEND } from '../../config/env'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { ErrorLink, onError } from '@apollo/client/link/error'
import { logger } from '../../utils/logger'
import { apolloErrorToast, logout } from '../utils'
import { uniqWith } from '@bounty/utils'
import { StrictTypedTypePolicies } from '../../generated/backendGraphql'

const httpLink = RUN_WITH_MOCKED_BACKEND
  ? new HttpLink({
      uri: `${API_URL}/graphql-api`,
    })
  : new BatchHttpLink({
      uri: `${API_URL}/graphql-api`,
    })

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('authToken')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // The localStorage hook stringifies a string so we need to parse it before using
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

/**
 * When the JWT expires you will get a "AuthenticationError" named error from the backend.
 * You need to redirect to:
 * https://${API_URI}/auth?shop=${shopUrl}
 */
export const handleErrors: ErrorLink.ErrorHandler = ({
  graphQLErrors,
  networkError,
}) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ name, message, extensions }) => {
      if (
        name === 'AuthenticationError' ||
        extensions?.code === 'UNAUTHENTICATED'
      ) {
        logger.log(
          'Got authentication error from the server, clearing cache and logging out.',
        )

        logout()
      }

      apolloErrorToast({
        status: 'error',
        title: `Bounty Error: ${name}`,
        description: message,
      })
    })
  }
  if (networkError) {
    logger.log(`[Network error]: ${networkError}`)
    apolloErrorToast({
      status: 'error',
      title: `Bounty Error: ${networkError.name}`,
      description: networkError.message,
    })
  }
}
const errorLink = onError(handleErrors)

const additiveLink = from([authLink, errorLink, httpLink])

const typePolicies: StrictTypedTypePolicies = {
  Query: {
    fields: {
      contentLibrary: {
        keyArgs: [
          'params',
          [
            'orderBy',
            'orderByDirection',
            'filterBountyStatus',
            'filterByProfileNames',
            'filterByCreatedAt',
            'filterByType',
            'filterByPlatform',
            'isFavorited',
            'briefId',
          ],
        ],
        merge(existing = { items: [] }, incoming, options) {
          const { mergeObjects } = options

          return {
            ...mergeObjects(existing, incoming),
            items: uniqWith(
              [...existing.items, ...incoming.items],
              (a, b) => a.__ref === b.__ref,
            ),
          }
        },
      },
    },
  },
}

const client = new ApolloClient({
  link: additiveLink,
  /**
   * Pagination: https://www.apollographql.com/docs/react/pagination/core-api/
   * KeyArgs: https://www.apollographql.com/docs/react/pagination/key-args
   */
  cache: new InMemoryCache({
    typePolicies,
  }),
})

export const getBackendClient = () => client
