import {
  useQuery,
  useMutation,
  useLazyQuery,
  DocumentNode,
  TypedDocumentNode,
  OperationVariables,
  QueryHookOptions,
  MutationHookOptions,
  ApolloCache,
  DefaultContext,
  MutationTuple,
  QueryResult,
  LazyQueryHookOptions,
  QueryTuple,
} from '@apollo/client'
import { getBackendClient } from './client'

export const useApolloClientBackend = () => getBackendClient()

export const useQueryBackend = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables> | undefined,
): QueryResult<TData, TVariables> =>
  useQuery(query, { client: getBackendClient(), ...options })

export const useLazyQueryBackend = <
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables> | undefined,
): QueryTuple<TData, TVariables> =>
  useLazyQuery(query, { client: getBackendClient(), ...options })

export const useMutationBackend = <
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?:
    | MutationHookOptions<TData, TVariables, TContext, ApolloCache<any>>
    | undefined,
): MutationTuple<TData, TVariables, TContext, ApolloCache<any>> =>
  useMutation(mutation, { client: getBackendClient(), ...options })
