import {
  Breadcrumb as BreadcrumbCore,
  BreadcrumbItem as BreadcrumbItemCore,
  BreadcrumbLink as BreadcrumbLinkCore,
  BreadcrumbSeparator as BreadcrumbSeparatorCore,
  BreadcrumbProps as BreadcrumbPropsCore,
  BreadcrumbItemProps as BreadcrumbItemPropsCore,
  BreadcrumbLinkProps as BreadcrumbLinkPropsCore,
  BreadcrumbSeparatorProps as BreadcrumbSeparatorPropsCore,
} from './Chakra'
import { Link, LinkProps } from 'react-router-dom'
import { CaretRightIcon } from '../icons'
import { forwardRef, defineStyleConfig } from '@chakra-ui/react'

export const breadcrumbTheme = defineStyleConfig({
  baseStyle: () => {
    return {
      link: {
        fontWeight: 'semibold',
        fontSize: 'sm',
      },
      separator: {
        lineHeight: 0,
      },
    }
  },
})

export type BreadcrumbProps = BreadcrumbPropsCore

export const Breadcrumb = forwardRef<BreadcrumbProps, 'nav'>((props, ref) => {
  return (
    <BreadcrumbCore
      ref={ref}
      separator={<CaretRightIcon color="neutral.400" boxSize={'18px'} />}
      {...props}
    />
  )
})

export type BreadcrumbItemProps = BreadcrumbItemPropsCore

export const BreadcrumbItem = forwardRef<BreadcrumbItemProps, 'li'>(
  (props, ref) => {
    return (
      <BreadcrumbItemCore
        textDecoration={'none'}
        color={props.isCurrentPage ? 'primary.500' : 'neutral.700'}
        ref={ref}
        {...props}
      />
    )
  },
)

export type BreadcrumbLinkProps = BreadcrumbLinkPropsCore & LinkProps

export const BreadcrumbLink = forwardRef<BreadcrumbLinkProps, 'a'>(
  (props, ref) => {
    return (
      <BreadcrumbLinkCore
        _hover={{
          textDecoration: 'none',
        }}
        as={Link}
        ref={ref}
        {...props}
      />
    )
  },
)

// TODO: ??
export type BreadcrumbSeparatorProps = BreadcrumbSeparatorPropsCore

export const BreadcrumbSeparator = forwardRef<BreadcrumbSeparatorProps, 'span'>(
  (props, ref) => {
    return <BreadcrumbSeparatorCore bg="red" ref={ref} {...props} />
  },
)
