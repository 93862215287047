export const baseFontSize = '16px'

export const typography = {
  letterSpacings: {
    tighter: '-0.03em',
    tight: '-0.01em',
    normal: '0',
    wide: '0.01em',
    wider: '0.03em',
    widest: '0.05em',
  },

  lineHeights: {
    normal: 'normal',
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: '2',
    '3': '.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '7': '1.75rem',
    '8': '2rem',
    '9': '2.25rem',
    '10': '2.5rem',
  },

  fontWeights: {
    regular: 400,
    semibold: 600,
    bold: 700,
  },

  fonts: {
    heading: `Mona Sans, Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    body: `Figtree, Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    mono: `SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace`,
  },

  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1rem',
    lg: '1.125rem',
    xl: '1.25rem',
    '2xl': '1.5rem',
    '3xl': '1.875rem',
    '4xl': '2.25rem',
    '5xl': '3rem',
    '6xl': '3.75rem',
  },
}

export type Typography = typeof typography
export type LetterSpacing = keyof Typography['letterSpacings']
export type LineHeight = keyof Typography['lineHeights']
export type FontWeight = keyof Typography['fontWeights']
export type Font = keyof Typography['fonts']
export type FontSize = keyof Typography['fontSizes']
