import { useParamsOrThrow } from '@bounty/hooks'
import {
  isNil,
  prettyCurrency,
  prettyFloatingDate,
  prettyPercentage,
  UnreachableCaseError,
} from '@bounty/utils'
import {
  Badge,
  Box,
  Button,
  HStack,
  Grid,
  Link,
  PageSkeleton,
  Tag,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  theme,
  VStack,
  Image,
  Tooltip,
  IconButton,
  InfoIcon,
} from '@bounty/brands-design-system'
import b256 from '../../../images/b-256.png'
import { useNavigate } from 'react-router-dom'
import { useQueryBackend } from '../../../apollo/backend/hooks'
import {
  GiftDetailsDocument,
  GiftDetailsQuery,
} from '../../../generated/backendGraphql'
import { CLOUDINARY_URL } from '../../../config/env'
import { CellRendererSelectorFunc, ColDef } from 'ag-grid-community'
import { useMemo } from 'react'
import { PayoutDetails } from '../../../components/GiftForm'

type OffersList = GiftDetailsQuery['giftOffersByGift']
type OffersItem = GiftDetailsQuery['giftOffersByGift'][0]
type CellRendererParams = Parameters<CellRendererSelectorFunc<OffersItem>>[0]

const GiftOffersByGiftTable = ({ offers }: { offers: OffersList }) => {
  const columnDefs = useMemo(
    (): ColDef<OffersItem>[] => [
      {
        headerName: 'Status',
        field: 'status',
        cellRenderer: (params: CellRendererParams) => {
          const value: OffersItem['status'] = params.value
          switch (value) {
            case 'OPEN':
              return (
                <Tag color="white" background="gray.500" fontWeight="semibold">
                  UNCLAIMED
                </Tag>
              )
            case 'REJECTED':
              return (
                <Tag
                  color="white"
                  background="blackAlpha.700"
                  fontWeight="semibold"
                >
                  REJECTED
                </Tag>
              )
            case 'CLAIMED':
              return (
                <Tag color="white" background="green.500" fontWeight="semibold">
                  CLAIMED
                </Tag>
              )
            case 'COMPLETED':
              return (
                <Tag
                  color="white"
                  background="purple.500"
                  fontWeight="semibold"
                >
                  COMPLETED
                </Tag>
              )
            default:
              throw new UnreachableCaseError(value)
          }
        },
      },
      {
        headerName: 'Creator',
        field: 'user',
        cellRenderer: (params: CellRendererParams) => {
          if (
            isNil(params.data?.user) ||
            isNil(params.data?.user.profileName)
          ) {
            return 'N/A'
          }
          return (
            <Link
              color="inherit"
              isExternal={false}
              to={`/creators/details/${params.data?.user.id}`}
            >
              {params.data?.user.profileName}
            </Link>
          )
        },
      },
      {
        headerName: 'Date Created',
        filter: 'agDateColumnFilter',
        field: 'createdAt',
        valueGetter: (params) => {
          // default filter uses midnight as comparator
          const getMidnight = (d: Date) => new Date(d.setHours(0, 0, 0, 0))
          return getMidnight(new Date(params.data!.createdAt))
        },
        cellRenderer: (params: CellRendererParams) => {
          return new Date(params.data!.createdAt).toLocaleDateString()
        },
      },
    ],
    [],
  )

  return (
    <Grid<OffersItem>
      containerProps={{ height: '50vh' }}
      rowStyle={{ cursor: 'pointer' }}
      rowData={[...offers]}
      columnDefs={columnDefs}
      showQuickSearch={false}
      enableCellTextSelection={true}
    />
  )
}

export const GiftDetails = () => {
  const navigate = useNavigate()
  const { giftId } = useParamsOrThrow(['giftId'])

  const { data, loading } = useQueryBackend(GiftDetailsDocument, {
    variables: { id: giftId },
  })

  if (loading || !data) return <PageSkeleton />
  const details = data.getStoreGiftDetails

  const brief = data.briefs?.find((brief) => brief.id === details.gift.briefId)

  const detailsMap: Record<string, any> = {
    Name: details.gift.name,
    Brief: brief?.name ?? 'N/A',
    'Created at': prettyFloatingDate(details.gift.createdAt),
    'Offer expiration days': details.gift.offerExpirationDays,
    'Total gifts granted': details.totalGranted,
    'Gift conversion rate': prettyPercentage(details.conversionRate),
  }

  if (details.gift.__typename === 'StoreGiftBounty') {
    detailsMap['Minimum payout'] = prettyCurrency(
      details.gift.paymentSnapshot.minBountyPayment,
    )
  }

  if (details.gift.__typename === 'StoreGiftProduct') {
    detailsMap['Shopify tags'] = details.gift.shopifyTags.join(', ')
    detailsMap['Shopify notes'] = details.gift.shopifyNote
  }

  return (
    <VStack alignItems="flex-start" width="100%">
      <Tabs width="100%">
        <TabList>
          <Tab>Stats</Tab>
          <Tab>Bounties</Tab>
          <Tab>History</Tab>
        </TabList>

        <TabPanels mt={4}>
          <TabPanel p={0} pb={4}>
            <VStack width="100%" alignItems="flex-start">
              {Object.entries(detailsMap)
                .filter(([_, value]) => !!value)
                .map(([title, value]) => (
                  <HStack
                    width="100%"
                    key={title}
                    justifyContent="space-between"
                  >
                    <Text as="span">
                      {title}:
                      {title === 'Gift conversion rate' && (
                        <Tooltip
                          label={`The percentage of gifts claimed out of the total number of gifts granted. If they do not finish the claim flow completely (filling out their address to receive a gift, for example), they will still be counted here.`}
                          placement="auto-start"
                          aria-label={`'Gift conversion rate`}
                        >
                          <IconButton
                            mx="0"
                            p="0"
                            aria-label={`'Gift conversion rate more information`}
                            variant={'link'}
                          >
                            <InfoIcon size={theme.space[4]} weight="duotone" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Text>
                    <Text as="span">{value}</Text>
                  </HStack>
                ))}
              <Box pt="2" mb="1">
                {details.gift.__typename === 'StoreGiftProduct' && (
                  <PayoutDetails
                    hasMinPayment={
                      details.gift.productPaymentSnapshot?.productGiftConfig
                        ?.hasMinPayment ?? false
                    }
                    hasPayPerView={
                      details.gift.productPaymentSnapshot?.productGiftConfig
                        ?.payPerViews ?? false
                    }
                    minBountyPayment={
                      details.gift.productPaymentSnapshot?.minBountyPayment
                    }
                  />
                )}
              </Box>
            </VStack>
          </TabPanel>
          <TabPanel>
            {details.bounties.length > 0 ? (
              details.bounties.map((bounty) => {
                return (
                  <VStack key={bounty.id}>
                    <Box
                      p="4"
                      borderBottom={'1px solid'}
                      borderColor="gray.400"
                      display={'flex'}
                      width="100%"
                    >
                      <Box flexShrink={0}>
                        {bounty.tikTokVideo ? (
                          <Link
                            isExternal={false}
                            to={`/content-library/video/${bounty.id}`}
                          >
                            <Image
                              height="70px"
                              width="40px"
                              mr="4"
                              objectFit={'cover'}
                              src={`${CLOUDINARY_URL}/video_id_${bounty.tikTokVideo.videoId}.jpg`}
                            />
                          </Link>
                        ) : (
                          <Image
                            height="70px"
                            width="40px"
                            mr="4"
                            objectFit={'cover'}
                            src={b256}
                          />
                        )}
                      </Box>
                      <Box>
                        <Badge colorScheme={'purple'}>{bounty.status}</Badge>
                        <Text>
                          Bounty type:{' '}
                          {bounty.type === 'SHOPIFY_AD_HOC'
                            ? 'Ad-hoc'
                            : 'Order'}
                        </Text>
                        <Text>
                          Created: {prettyFloatingDate(bounty.createdAt)}
                        </Text>
                        <Text>{bounty.tikTokVideo?.videoDescription}</Text>
                      </Box>
                    </Box>
                  </VStack>
                )
              })
            ) : (
              <Text>
                No bounties created for this gift yet. Check back later!
              </Text>
            )}
          </TabPanel>
          <TabPanel>
            {data.giftOffersByGift.length === 0 ? (
              <Text>
                No offers have been made for this gift yet. Check back later!
              </Text>
            ) : (
              <GiftOffersByGiftTable offers={data.giftOffersByGift} />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Button
        width="100%"
        event="Edit Gift Clicked"
        onClick={() => navigate(`../edit/${giftId}`)}
      >
        Edit Gift
      </Button>
    </VStack>
  )
}
