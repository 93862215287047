import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerHeading,
  DrawerOverlay,
  Text,
  VStack,
} from '@bounty/brands-design-system'
import { InviteRowItem } from './ReviewInvites'

type InviteDetailsDrawerProps = {
  data: InviteRowItem
  isOpen: boolean
  onClose: () => void
}

export const InviteDetailsDrawer = ({
  data,
  isOpen,
  onClose,
}: InviteDetailsDrawerProps) => {
  return (
    <Drawer isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <DrawerHeading>Invite Details</DrawerHeading>
          <DrawerCloseButton />
        </DrawerHeader>
        <DrawerBody>
          <VStack spacing={4} align="stretch">
            <Box>
              <Text fontSize="lg" fontWeight="semibold">
                Email
              </Text>
              <Text fontSize="md" wordBreak="break-word">
                {data.email}
              </Text>
            </Box>
            {data.name && (
              <Box>
                <Text fontSize="lg" fontWeight="semibold">
                  Name
                </Text>
                <Text fontSize="md">{data.name}</Text>
              </Box>
            )}
            <Box>
              <Text fontSize="lg" fontWeight="semibold">
                Gift
              </Text>
              <Text fontSize="md">{data.gift}</Text>
            </Box>
            <Box>
              <Text fontSize="lg" fontWeight="semibold">
                Message
              </Text>
              <Text fontSize="md">{data.message}</Text>
            </Box>
          </VStack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
