import { FC, useEffect } from 'react'
import {
  Box,
  Flex,
  Text,
  useFastForm,
  VStack,
  Skeleton,
  Link,
  useToast,
} from '@bounty/brands-design-system'
import {
  PreviousCustomersFormDocument,
  SubmitPreviousCustomerInviteRequestDocument,
  PreviewPreviousCustomerInviteRequestMutation,
} from '../../../../generated/backendGraphql'
import {
  useQueryBackend,
  useMutationBackend,
} from '../../../../apollo/backend/hooks'
import {
  PrevCustomerInviteRequestParamsSchema,
  PrevCustomerInviteRequestParamsSchemaType,
} from '@bounty/schemas'
import { PreviousCustomerFormModal } from './PreviousCustomerFormModal'
import { FormDataDisplayWrapper } from '../components/FormDataDisplay/FormDataDisplayWrapper'
import { FormWrapper } from '../components/FormDataDisplay/FormWrapper'
import { FormDataDisplay } from '../components/FormDataDisplay/FormDataDisplay'
import { MailIcon } from '../components/icons/MailIcon'
import { GiftIcon } from '../components/icons/GiftIcon'
import { PhoneEmailDisplay } from '../components/PhoneEmailDisplay/PhoneEmailDisplay'
import { GiftDisplay } from '../components/GiftDisplay/GiftDisplay'
import { INVITE_MESSAGE_PREVIEW_TEXT_DISCLAIMER } from '../constants/invites.constants'
import { endOfToday, format, startOfMonth } from 'date-fns'

export type PreviousCustomersFormProps = {
  isOpen: boolean
  onClose: () => void
  setIsFormValid: (isValid: boolean) => void
  setFormValues: (formValues: PrevCustomerInviteRequestParamsSchemaType) => void
  previewData: PreviewPreviousCustomerInviteRequestMutation | null
}

export const PreviousCustomersForm: FC<PreviousCustomersFormProps> = ({
  isOpen,
  onClose,
  setIsFormValid,
  setFormValues,
  previewData,
}) => {
  const toast = useToast()

  const {
    data: {
      enabledStoreProducts = [],
      enabledStoreGifts = [],
      currentStore,
    } = {},
    loading,
  } = useQueryBackend(PreviousCustomersFormDocument)

  const emailMessage = `${currentStore?.name} is super appreciative of your support! They're inviting you to partner with them through Bounty and get paid a minimum when you post a TikTok. All you have to do is to sign up!`

  // the one you use to submit after they confirm
  const [
    createPrevCustomerInvite,
    { loading: createPrevCustomerInviteLoading },
  ] = useMutationBackend(SubmitPreviousCustomerInviteRequestDocument)

  const {
    Form,
    DateInput,
    Select,
    CreatableSelect,
    Switch,
    SubmitButton,
    methods: {
      watch,
      setValue,
      formState: { isValid },
    },
  } = useFastForm({
    schema: PrevCustomerInviteRequestParamsSchema,
    defaultValues: {
      startDate: format(startOfMonth(new Date()), 'yyyy-MM-dd'),
      endDate: format(endOfToday(), 'yyyy-MM-dd'),
      productIds: [],
      customerTags: [],
      emailSubscriptionStatusSubscribed: true,
      giftId: '',
    },
    mode: 'all',
  })

  useEffect(() => {
    setIsFormValid(isValid)
  }, [isValid, setIsFormValid])

  const formValues = watch()

  useEffect(() => {
    setFormValues(formValues)
  }, [formValues, setFormValues])

  const selectedGift = enabledStoreGifts.find(
    (el) => el.id === formValues.giftId,
  )

  useEffect(() => {
    if (!loading && enabledStoreGifts != null && enabledStoreGifts.length > 0)
      setValue('giftId', enabledStoreGifts[0].id)
  }, [loading, setValue, enabledStoreGifts])

  return (
    <Box>
      {loading ||
      !enabledStoreProducts ||
      !enabledStoreGifts ||
      !currentStore ? (
        <VStack mt={8} spacing={4}>
          <Skeleton width="100%" height="10" />
          <Skeleton width="100%" height="10" />
          <Skeleton width="100%" height="10" />
          <Skeleton width="100%" height="10" />
        </VStack>
      ) : (
        <Form
          data-testid="activate-previous-customers-form"
          id="invite-form"
          onSubmit={async (params) => {
            const { data } = await createPrevCustomerInvite({
              variables: {
                params,
              },
            })
            if (data && data.submitPreviousCustomerInviteRequest.success) {
              toast({
                title: 'Success',
                status: 'success',
                description: 'Your invites are being sent!',
              })
            } else {
              toast({
                title: 'Server Error',
                status: 'error',
                description:
                  'There was a server error, please try again later.',
              })
            }

            onClose()
          }}
        >
          <FormDataDisplayWrapper>
            <FormWrapper>
              <Box width="400px" maxWidth="100%">
                <Select
                  label="Gift"
                  name="giftId"
                  placeholder="Bounty"
                  options={enabledStoreGifts.map((gift) => ({
                    label: gift.name,
                    value: gift.id,
                  }))}
                  helpText={
                    <>
                      <Text mb="3" color="gray.600" fontSize="sm">
                        Select a gift for the creator or{' '}
                        <Link
                          isExternal={false}
                          to="create-gift"
                          textDecoration="underline"
                          color="inherit"
                        >
                          create one
                        </Link>
                        .
                      </Text>
                      <Text color="gray.600" fontSize="sm">
                        The gift's name and description are not shown to
                        creators.
                      </Text>
                    </>
                  }
                />
              </Box>
            </FormWrapper>
            {selectedGift && (
              <FormDataDisplay icon={<GiftIcon />} description="Gift details">
                <GiftDisplay gift={selectedGift} />
              </FormDataDisplay>
            )}
          </FormDataDisplayWrapper>

          <FormDataDisplayWrapper>
            <FormWrapper>
              <Text mb="6" fontSize="md" color="neutral.600">
                Invite customers who've already purchased your product to join
                your community of creators on Bounty and post a TikTok about
                your brand.
              </Text>
              <DateInput
                label="Start Date"
                name="startDate"
                placeholder="30"
                helpText={'Filter customers by date of purchase'}
                isRequired
              />
              <DateInput
                label="End Date"
                name="endDate"
                placeholder="30"
                helpText={'Filter customers by date of purchase'}
                isRequired
              />
              <Select
                label="Products"
                name="productIds"
                isMulti
                isClearable
                isRequired={false}
                helpText="Filter customers by products purchased"
                options={enabledStoreProducts.map((product) => ({
                  label: product.productName,
                  value: product.productId,
                }))}
              />
              <CreatableSelect
                label="Customer Tags"
                name="customerTags"
                isMulti
                isClearable
                isRequired={false}
              />
              <Flex alignItems="center">
                <Box>
                  <Switch name="emailSubscriptionStatusSubscribed" />
                </Box>
                <Text fontSize="sm" ml="2" mb="2">
                  Is Opted Into Marketing
                </Text>
              </Flex>
              <Text fontSize={'xs'} color="gray.600">
                {INVITE_MESSAGE_PREVIEW_TEXT_DISCLAIMER}
              </Text>
            </FormWrapper>
            <FormDataDisplay icon={<MailIcon />} description="Message preview">
              <Flex
                mb="-8"
                pt="14"
                px="8"
                flexGrow={1}
                direction="column"
                align="center"
                justify="flex-end"
                minHeight="328px"
                height="auto"
                position="relative"
              >
                <PhoneEmailDisplay
                  email={'All matching customers'}
                  message={emailMessage}
                  subject={
                    currentStore
                      ? `Partner with ${currentStore.name}?`
                      : 'Partner with Store Name'
                  }
                  variant="ActivatePreviousCustomers"
                />
              </Flex>
            </FormDataDisplay>
          </FormDataDisplayWrapper>
        </Form>
      )}
      <PreviousCustomerFormModal
        isOpen={isOpen}
        onClose={onClose}
        loading={createPrevCustomerInviteLoading}
        SubmitButton={SubmitButton}
        previewData={previewData}
        shopUrl={currentStore?.shopUrl}
      />
    </Box>
  )
}
