import { useParamsOrThrow } from '@bounty/hooks'
import {
  updateStoreGiftBountyInputSchema,
  updateStoreGiftProductInputSchema,
} from '@bounty/schemas'
import {
  Box,
  PageSkeleton,
  useToast,
  useFastForm,
  Flex,
  VStack,
  Skeleton,
} from '@bounty/brands-design-system'
import {
  useMutationBackend,
  useQueryBackend,
} from '../../../apollo/backend/hooks'
import {
  EditGiftDocument,
  GiftDetailsDocument,
  StoreGiftBountyPartsFragment,
  StoreGiftProductPartsFragment,
  UpdateStoreGiftBountyDocument,
  UpdateStoreGiftProductDocument,
} from '../../../generated/backendGraphql'
import { useNavigate } from 'react-router-dom'
import { PayoutDetails } from '../../../components/GiftForm'
type Submitable = { onSubmit: () => void }

const EditBountyGiftForm = ({
  id,
  enabled,
  name,
  offerExpirationDays,
  briefId,
  onSubmit,
}: StoreGiftBountyPartsFragment & Submitable) => {
  const toast = useToast()
  const { Form, Input, Switch, NumberInput, SubmitButton, Select } =
    useFastForm({
      schema: updateStoreGiftBountyInputSchema,
      defaultValues: {
        id,
        enabled,
        name,
        offerExpirationDays,
        briefId,
      },
    })
  const [updateStoreGiftBounty, { loading }] = useMutationBackend(
    UpdateStoreGiftBountyDocument,
  )
  const { data } = useQueryBackend(EditGiftDocument, {
    fetchPolicy: 'no-cache',
  })

  if (loading || !data)
    return (
      <VStack>
        <Skeleton height="3rem" width="100%" />
        <Skeleton height="3rem" width="100%" />
        <Skeleton height="3rem" width="100%" />
      </VStack>
    )

  return (
    <Form
      data-testid="bounty-gift-edit-form"
      onSubmit={async (input) => {
        const { data } = await updateStoreGiftBounty({
          variables: {
            input,
          },
        })
        if (data) {
          toast({
            status: 'success',
            description: 'Gift updated!',
          })
          onSubmit()
        }
      }}
    >
      <Box>
        <Input label="Name" name="name" placeholder="Gift Name" />

        <NumberInput
          label="Offer Expiration Days"
          name="offerExpirationDays"
          placeholder="30"
        />

        <Select
          label="Brief"
          name="briefId"
          placeholder="Briefs"
          options={data.briefs.map((brief) => ({
            value: brief.id,
            label: brief.name,
          }))}
          isRequired
        />

        <Switch label="Enabled" name="enabled" />

        <Flex justifyContent="flex-end">
          <SubmitButton
            event="Edit Bounty Gift Form Submitted"
            data-testid="gift-form-button"
            isLoading={loading}
          >
            Save
          </SubmitButton>
        </Flex>
      </Box>
    </Form>
  )
}

const EditProductGiftForm = ({
  id,
  enabled,
  name,
  shopifyTags,
  shopifyNote,
  offerExpirationDays,
  productPaymentSnapshot,
  briefId,
  onSubmit,
}: StoreGiftProductPartsFragment & Submitable) => {
  const toast = useToast()
  const {
    Form,
    Input,
    Switch,
    NumberInput,
    CreatableSelect,
    Textarea,
    SubmitButton,
    Select,
  } = useFastForm({
    schema: updateStoreGiftProductInputSchema,
    defaultValues: {
      id,
      enabled,
      name,
      shopifyNote,
      shopifyTags,
      offerExpirationDays,
      briefId,
    },
  })
  const [updateStoreGiftProduct, { loading }] = useMutationBackend(
    UpdateStoreGiftProductDocument,
  )
  const { data } = useQueryBackend(EditGiftDocument, {
    fetchPolicy: 'no-cache',
  })

  if (loading || !data)
    return (
      <VStack>
        <Skeleton height="3rem" width="100%" />
        <Skeleton height="3rem" width="100%" />
        <Skeleton height="3rem" width="100%" />
      </VStack>
    )

  return (
    <Form
      data-testid="bounty-product-edit-form"
      onSubmit={async (input) => {
        const { data } = await updateStoreGiftProduct({
          variables: {
            input,
          },
        })
        if (data) {
          toast({
            status: 'success',
            description: 'Gift updated!',
          })
          onSubmit()
        }
      }}
    >
      <Box>
        <Input label="Name" name="name" placeholder="Gift Name" />

        {(productPaymentSnapshot?.productGiftConfig?.hasMinPayment ||
          productPaymentSnapshot?.productGiftConfig?.payPerViews) && (
          <PayoutDetails
            hasMinPayment={
              productPaymentSnapshot?.productGiftConfig?.hasMinPayment ?? false
            }
            hasPayPerView={
              productPaymentSnapshot?.productGiftConfig?.payPerViews ?? false
            }
            minBountyPayment={productPaymentSnapshot?.minBountyPayment}
          />
        )}

        <NumberInput
          label="Offer Expiration Days"
          name="offerExpirationDays"
          placeholder="30"
        />

        <CreatableSelect
          name="shopifyTags"
          label="Shopify Order Tags"
          isMulti
          helpText="Orders created from this gift will be given the tags you add here."
        />

        <Textarea
          label="Note"
          name="shopifyNote"
          placeholder="Add a note for your gift"
          maxCharacterLimit={500}
          helpText="Shopify orders created from this gift will have this note attached to them in your Shopify dashboard."
        />

        <Select
          label="Brief"
          name="briefId"
          placeholder="Briefs"
          options={data.briefs.map((brief) => ({
            value: brief.id,
            label: brief.name,
          }))}
          isRequired
        />

        <Switch label="Enabled" name="enabled" />

        <Flex justifyContent="flex-end">
          <SubmitButton
            event="Edit Product Gift Form Submitted"
            data-testid="gift-form-button"
            isLoading={loading}
          >
            Save
          </SubmitButton>
        </Flex>
      </Box>
    </Form>
  )
}

export const EditGift = () => {
  const navigate = useNavigate()
  const { giftId } = useParamsOrThrow(['giftId'])
  const { data, loading } = useQueryBackend(GiftDetailsDocument, {
    variables: { id: giftId },
  })

  const onSubmit = () => navigate(`../details/${giftId}`)

  if (loading || !data) return <PageSkeleton />
  const details = data.getStoreGiftDetails

  if (details.gift.__typename === 'StoreGiftBounty') {
    return <EditBountyGiftForm onSubmit={onSubmit} {...details.gift} />
  }

  return <EditProductGiftForm onSubmit={onSubmit} {...details.gift} />
}
