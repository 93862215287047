import {
  DEFAULT_MINIMUM_BOUNTY_PAYOUT,
  MINIMUM_BOUNTY_PAYOUT_MAX,
  MINIMUM_BOUNTY_PAYOUT_MIN,
  OFFER_EXPIRATION_DAYS_DEFAULT,
  OFFER_EXPIRATION_DAYS_MAX,
  OFFER_EXPIRATION_DAYS_MIN,
} from '@bounty/constants'
import * as z from 'zod'

const storeGiftInputBaseSchema = z.object({
  name: z.string().min(1, { message: 'Must add a gift name.' }),
  description: z
    .string()
    .max(500, { message: 'Description must be 500 characters or less.' })
    .optional(),
  briefId: z.string().uuid().optional(),
  offerExpirationDays: z
    .number()
    .int()
    .min(OFFER_EXPIRATION_DAYS_MIN)
    .max(OFFER_EXPIRATION_DAYS_MAX)
    .default(OFFER_EXPIRATION_DAYS_DEFAULT),
})

export const storeGiftInputSchema = storeGiftInputBaseSchema
  .extend({
    products: z
      .object({
        productId: z.string().min(1),
        quantity: z.number().positive().default(1),
      })
      .array()
      .min(1),
    shopifyTags: z.string().array().optional(),
    shopifyNote: z
      .string()
      .max(500, { message: 'Note must be 500 characters or less.' })
      .optional(),
    minBountyPayment: z
      .number()
      .min(MINIMUM_BOUNTY_PAYOUT_MIN)
      .max(MINIMUM_BOUNTY_PAYOUT_MAX)
      .optional(),
    hasMinPayment: z.boolean(),
    hasPayPerView: z.boolean(),
  })
  .strict()

const updateInputSchema = z.object({
  id: z.string().uuid(),
  enabled: z.boolean(),
})

export const createStoreGiftProductInputSchema = storeGiftInputSchema
export const updateStoreGiftProductInputSchema =
  createStoreGiftProductInputSchema
    .omit({ products: true, hasMinPayment: true, hasPayPerView: true })
    .merge(updateInputSchema)

export const createStoreGiftBountyInputSchema = storeGiftInputBaseSchema
  .extend({
    bountyProductIds: z.string().min(1).array().optional(),
    minBountyPayment: z
      .number()
      .min(MINIMUM_BOUNTY_PAYOUT_MIN)
      .max(MINIMUM_BOUNTY_PAYOUT_MAX)
      .default(DEFAULT_MINIMUM_BOUNTY_PAYOUT),
  })
  .strict()
export const updateStoreGiftBountyInputSchema =
  storeGiftInputBaseSchema.merge(updateInputSchema)
