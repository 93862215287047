import {
  Input,
  InputProps,
  InputGroup,
  InputLeftElement,
  Select,
  Image,
  Box,
  Text,
} from '../Chakra'
import { forwardRef } from 'react'
import {
  PhoneCountryCode,
  PhoneCountry,
  COUNTRIES_LOGIN_ALLOWED,
  COUNTRIES_SIGNUP_ALLOWED,
} from '@bounty/constants'

export type PhoneInputProps = InputProps & {
  selectedCountryCode: PhoneCountryCode
  isLoginMode: boolean
  onSelectedCountryChange: (val: PhoneCountry) => void
}

const LEFT_SECTION_SIZE = '75px'

export const PhoneInput = forwardRef<any, PhoneInputProps>(
  (
    {
      selectedCountryCode,
      onSelectedCountryChange,
      onChange,
      size,
      isLoginMode,
      ...rest
    },
    ref,
  ) => {
    const COUNTRIES_SELECTION = isLoginMode
      ? COUNTRIES_LOGIN_ALLOWED
      : COUNTRIES_SIGNUP_ALLOWED

    const selectedCountry = COUNTRIES_SELECTION.find(
      (country) => country.code === selectedCountryCode,
    )

    if (!selectedCountry) {
      throw new Error(`Cannot find country for code ${selectedCountryCode}!`)
    }

    return (
      <InputGroup>
        <InputLeftElement
          height="100%"
          display="block"
          p="0"
          width={LEFT_SECTION_SIZE}
        >
          <Box
            position="absolute"
            top="0"
            left="2"
            bottom="0"
            display="flex"
            alignItems="center"
          >
            <Image
              alt={selectedCountry.code}
              src={selectedCountry.flagSrc}
              maxWidth="30px"
            />
          </Box>
          <Select
            value={selectedCountryCode}
            cursor="pointer"
            size={size}
            onChange={(e) => {
              const newSelectedCountry = COUNTRIES_SELECTION.find(
                (country) => country.code === e.target.value,
              )

              if (!newSelectedCountry) {
                throw new Error(
                  `Cannot find country for code ${selectedCountryCode}!`,
                )
              }
              onSelectedCountryChange(newSelectedCountry)
            }}
            opacity="0"
            iconSize="10px"
            width="60px"
            // Chakra doesn't map these right when used as props
            style={{
              border: 'none',
              outline: 'none',
              padding: 0,
            }}
          >
            {COUNTRIES_SELECTION.map((country) => (
              <option key={country.code} value={country.code}>
                {country.name}
              </option>
            ))}
          </Select>
          <Text
            position="absolute"
            right="2px"
            top="0"
            bottom="0"
            display="flex"
            fontSize={size}
            alignItems="center"
            lineHeight="initial"
          >
            {selectedCountry.prefix}
          </Text>
        </InputLeftElement>
        <Input
          pl={LEFT_SECTION_SIZE}
          type="tel"
          size={size}
          onChange={(e) => {
            onChange?.(e)
          }}
          {...rest}
          ref={ref}
        />
      </InputGroup>
    )
  },
)

PhoneInput.displayName = 'PhoneInput'
