import { Flex, FlexProps, Text, useClipboard } from '../Chakra'
import { CheckIcon, CopyClipboardIcon } from '../icons'

export type CopyClipboardProps = {
  label?: string
  value: string
  containerProps?: FlexProps
}

export const CopyClipboard = ({
  label,
  value,
  containerProps,
}: CopyClipboardProps) => {
  const { onCopy, hasCopied } = useClipboard(value)

  return (
    <Flex
      h="24px"
      align="center"
      gap="4px"
      bg="neutral.200"
      px="8px"
      borderRadius="md"
      {...containerProps}
    >
      <Text whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
        {label ?? value}
      </Text>
      {hasCopied ? (
        <CheckIcon />
      ) : (
        <CopyClipboardIcon fontSize="xl" cursor="pointer" onClick={onCopy} />
      )}
    </Flex>
  )
}
