// TODO: Marcus to create a useContext hook that hides all of this away for these use cases
import { createContext, ReactNode, useContext, useState } from 'react'
import { InviteCreatorsYouKnowSchema } from './schemas'

export type InviteCreatorsYouKnowState = InviteCreatorsYouKnowSchema

const InviteCreatorsYouKnowStateContext =
  createContext<InviteCreatorsYouKnowState | null>(null)

export type InviteCreatorsYouKnowDispatch = {
  setInviteCreatorsYouKnowState: React.Dispatch<
    React.SetStateAction<InviteCreatorsYouKnowSchema>
  >
}

const InviteCreatorsYouKnowDispatchContext =
  createContext<InviteCreatorsYouKnowDispatch | null>(null)

export const InviteCreatorsYouKnowProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [state, setInviteCreatorsYouKnowState] =
    useState<InviteCreatorsYouKnowState>({
      giftId: '',
      message: '',
      rowData: [],
    })

  return (
    <InviteCreatorsYouKnowStateContext.Provider value={state}>
      <InviteCreatorsYouKnowDispatchContext.Provider
        value={{ setInviteCreatorsYouKnowState }}
      >
        {children}
      </InviteCreatorsYouKnowDispatchContext.Provider>
    </InviteCreatorsYouKnowStateContext.Provider>
  )
}

export const useInviteCreatorsYouKnowState = (): InviteCreatorsYouKnowState => {
  const context = useContext(InviteCreatorsYouKnowStateContext)

  if (!context) {
    throw new Error(
      'useInviteCreatorsYouKnowState must be used within a InviteCreatorsYouKnowProvider.',
    )
  }

  return context
}

export const useInviteCreatorsYouKnowDispatch =
  (): InviteCreatorsYouKnowDispatch => {
    const context = useContext(InviteCreatorsYouKnowDispatchContext)

    if (!context) {
      throw new Error(
        'useInviteCreatorsYouKnowDispatch must be used within a InviteCreatorsYouKnowProvider.',
      )
    }

    return context
  }

export const useInviteCreatorsYouKnow = (): [
  InviteCreatorsYouKnowState,
  InviteCreatorsYouKnowDispatch,
] => {
  return [useInviteCreatorsYouKnowState(), useInviteCreatorsYouKnowDispatch()]
}
