import { useFastForm, Flex } from '@bounty/brands-design-system'
import * as z from 'zod'
import { useMutationBackend } from '../../apollo/backend/hooks'
import { UpdateRetailBriefDocument } from '../../generated/backendGraphql'
import { useNavigate } from 'react-router-dom'

export const retailBriefSchema = z
  .object({
    headline: z.string().min(1).max(280),
    features: z.array(z.string().max(280)).max(3),
  })
  .strict()
export type RetailBriefSchemaType = z.infer<typeof retailBriefSchema>

export const RetailBriefForm = ({
  headline,
  features,
  rootPath,
}: {
  headline: string
  features: readonly string[]
  rootPath: string
}) => {
  const navigate = useNavigate()
  const { Form, Input, Textarea, SubmitButton } = useFastForm({
    schema: retailBriefSchema,
    defaultValues: {
      headline,
      features,
    },
  })

  // need to refetch because currentStore is replaced not merged
  const [update, { loading: updateLoading }] = useMutationBackend(
    UpdateRetailBriefDocument,
    { refetchQueries: ['RetailBrief'] },
  )

  return (
    <Form
      data-testid="retail-brief-edit-form"
      marginBottom="4"
      onSubmit={async (variables) => {
        await update({ variables })
        navigate(rootPath)
      }}
    >
      <Input
        name="headline"
        label="Headline"
        placeholder={'The one-liner for your product goes here'}
      />
      <Textarea
        name="features.0"
        label="Instruction 1"
        placeholder="Primary feature"
        maxCharacterLimit={280}
        rows={2}
      />
      <Textarea
        name="features.1"
        label="Instruction 2"
        placeholder="Secondary feature"
        maxCharacterLimit={280}
        rows={2}
      />
      <Textarea
        name="features.2"
        label="Instruction 3"
        placeholder="Third feature"
        maxCharacterLimit={280}
        resize={'none'}
        rows={2}
      />
      <Flex justifyContent="flex-end">
        <SubmitButton
          data-testid="retail-brief-edit-submit-button"
          event={'Retail Brief Edit Form Submitted'}
          isLoading={updateLoading}
        >
          Update
        </SubmitButton>
      </Flex>
    </Form>
  )
}
