import { defineStyleConfig } from '@chakra-ui/react'

export const progressTheme = defineStyleConfig({
  variants: {
    gradient: (_props) => ({
      filledTrack: {
        bgGradient: 'linear(to right, blue.700,purple.500)',
      },
    }),
  },
})
