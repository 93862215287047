import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerProps,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  DrawerHeading,
  DrawerDescription,
} from '@bounty/brands-design-system'
import { useNavigate } from 'react-router-dom'
import { UnreachableCaseError } from '@bounty/utils'
import { AddBrief, UpdateBrief } from './BriefForms'
import { BriefDetails } from './BriefDetails'

type BriefsDrawerMode = 'details' | 'add' | 'update'
export type BriefsDrawerProps = Omit<
  DrawerProps,
  'children' | 'isOpen' | 'onClose'
> & {
  mode: BriefsDrawerMode
  rootPath: string
}

const getTitle = (mode: BriefsDrawerMode) => {
  switch (mode) {
    case 'details':
      return 'Brief Details'
    case 'add':
      return 'Create new Bounty'
    case 'update':
      return 'Update Bounty'
    default:
      throw new UnreachableCaseError(mode)
  }
}

const getBody = (mode: BriefsDrawerMode) => {
  switch (mode) {
    case 'details':
      return <BriefDetails />
    case 'add':
      return <AddBrief />
    case 'update':
      return <UpdateBrief />
    default:
      throw new UnreachableCaseError(mode)
  }
}

export const BriefDrawer = ({ rootPath, mode, ...rest }: BriefsDrawerProps) => {
  const navigate = useNavigate()

  return (
    <Drawer size="lg" isOpen onClose={() => navigate(rootPath)} {...rest}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <DrawerHeading>{getTitle(mode)}</DrawerHeading>
          <DrawerDescription mb="4" color={'neutral.600'}>
            Creators will be able to choose this Bounty when creating their
            video
          </DrawerDescription>
        </DrawerHeader>
        <DrawerBody>{getBody(mode)}</DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
