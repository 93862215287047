import { z } from 'zod'

export const surveyAnswersSchema = z.object({ purchaseLocation: z.string() })
export type SurveyAnswers = z.infer<typeof surveyAnswersSchema>

export const createRetailBountyInputSchema = z.object({
  storeId: z.string().uuid(),
  productId: z.string().uuid().optional(),
  surveyAnswers: surveyAnswersSchema.optional(),
})

export type CreateRetailBountyInputType = z.infer<
  typeof createRetailBountyInputSchema
>
