import { z } from 'zod'

export const GrantSingleAtHocBountyRequestSchema = z.object({
  userId: z.string(),
  shopifyStoreProductId: z.string().optional(),
  minPayment: z.number().gte(0).lte(1000).optional(),
  message: z.string().optional(),
})

export type GrantSingleAtHocBountyRequestSchemaType = z.infer<
  typeof GrantSingleAtHocBountyRequestSchema
>
