import * as z from 'zod'

export const AdHocUserSmsSchema = z
  .object({
    userId: z.string(),
    smsMessage: z.string(),
  })
  .strict()

export type AdHocUserSmsSchemaRequest = z.infer<typeof AdHocUserSmsSchema>

export const AdHocUserSmsSchemaMulti = z
  .object({
    userIds: z.array(z.string()),
    smsMessage: z.string(),
  })
  .strict()

export type AdHocUserSmsSchemaMultiRequest = z.infer<
  typeof AdHocUserSmsSchemaMulti
>
