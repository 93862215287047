import { Box, BoxProps } from '../Chakra'

export type TileProps = BoxProps

export const Tile = ({ children, ...props }: TileProps) => {
  return (
    <Box
      px="6"
      py="5"
      border="1px solid"
      borderColor="neutral.200"
      borderRadius="md"
      {...props}
    >
      {children}
    </Box>
  )
}
