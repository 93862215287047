import { CellRendererSelectorFunc, CellStyle, ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useMemo, useRef } from 'react'
import {
  Flex,
  Grid,
  PageSkeleton,
  PlayFillIcon,
  Text,
  useTheme,
} from '@bounty/brands-design-system'
import {
  GiftHistoryQueryRecord,
  GiftOfferHistoryTableDocument,
} from '../../../../../generated/backendGraphql'
import { useQueryBackend } from '../../../../../apollo/backend/hooks'
import format from 'date-fns/format'
import { parseISO } from 'date-fns'
import {
  getBountyStatusDisplay,
  getGiftStatusDisplay,
  getGiftTypeDisplay,
  getRefTypeDisplay,
} from '../../helpers'
import { ShopifyIcon } from '../../icons/ShopifyIcon'
import { isNotNil } from '@bounty/utils'
import { useNavigate } from 'react-router-dom'
import { useGiftsHistoryState } from '../../useGiftsHistory'
import { GiftsHistoryTableHead } from './GiftsHistoryTableHead'

type GiftsHistoryItem = GiftHistoryQueryRecord

type CellRendererParams = Parameters<
  CellRendererSelectorFunc<GiftsHistoryItem>
>[0]

export const GiftsHistoryTable = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { value } = useGiftsHistoryState()

  const { data: { giftOfferHistory, currentStore } = {}, loading } =
    useQueryBackend(GiftOfferHistoryTableDocument, {
      variables: {
        params: value.params,
      },
      fetchPolicy: 'no-cache',
    })

  const gridRef = useRef<AgGridReact<GiftsHistoryItem>>(null)

  const components = useMemo<{
    [p: string]: any
  }>(() => {
    return {
      agColumnHeader: GiftsHistoryTableHead,
    }
  }, [])

  const columnDefs = useMemo((): ColDef<GiftsHistoryItem>[] => {
    const cellStyles: CellStyle = {
      height: '100%',
      display: 'flex ',
      justifyContent: 'flex-start',
      alignItems: 'center ',
      fontSize: theme.fontSizes['md'],
      fontWeight: theme.fontWeights['normal'],
      color: theme.colors['gray']['900'],
    }
    return [
      {
        headerName: 'Creator',
        field: 'profileName',
        filter: false,
        sortable: false,
        cellStyle: cellStyles,
        cellRenderer: (params: CellRendererParams) => {
          return <Text as="span">{params.data!.profileName}</Text>
        },
      },
      {
        headerName: 'Date',
        field: 'createdAt',
        filter: false,
        sortable: true,
        cellStyle: cellStyles,
        cellRenderer: (params: CellRendererParams) => {
          return (
            <Text as="span">
              {format(parseISO(params.data!.createdAt), 'MM/dd/yyyy')}
            </Text>
          )
        },
      },
      {
        headerName: 'Gift Name',
        field: 'storeGiftName',
        filter: false,
        sortable: false,
        cellStyle: cellStyles,
      },
      {
        headerName: 'Gift Type',
        field: 'storeGiftType',
        filter: false,
        sortable: false,
        cellStyle: cellStyles,
        cellRenderer: (params: CellRendererParams) => {
          return (
            <Text as="span">
              {getGiftTypeDisplay(params.data!.storeGiftType ?? undefined)}
            </Text>
          )
        },
      },
      {
        headerName: 'Gift Status',
        field: 'giftOfferStatus',
        filter: false,
        sortable: false,
        cellStyle: cellStyles,
        cellRenderer: (params: CellRendererParams) => {
          return isNotNil(params.data!.shopifyStoreOrderId) &&
            isNotNil(currentStore) ? (
            <Flex justify="center" align="center" gridGap={3}>
              <Text as="span">
                {getGiftStatusDisplay(
                  params.data!.giftOfferStatus ?? undefined,
                )}
              </Text>
              <ShopifyIcon />
            </Flex>
          ) : (
            <Text as="span">
              {getGiftStatusDisplay(params.data!.giftOfferStatus ?? undefined)}
            </Text>
          )
        },
      },
      {
        headerName: 'Bounty Status',
        field: 'bountyStatus',
        filter: false,
        sortable: false,
        cellStyle: cellStyles,
        cellRenderer: (params: CellRendererParams) => {
          return params.data!.tikTokVideoId ? (
            <Flex gridGap={3} align="center" justify="center">
              {getBountyStatusDisplay(params.data!.bountyStatus ?? undefined)}
              <PlayFillIcon
                color={theme.colors.purple[500]}
                size="18"
                weight="fill"
              />
            </Flex>
          ) : (
            <Text as="span">
              {getBountyStatusDisplay(params.data!.bountyStatus ?? undefined)}
            </Text>
          )
        },
      },
      {
        headerName: 'Source',
        field: 'refType',
        filter: false,
        sortable: false,
        cellStyle: cellStyles,
        cellRenderer: (params: CellRendererParams) => {
          return (
            <Text as="span">
              {getRefTypeDisplay(params.data!.refType ?? undefined)}
            </Text>
          )
        },
      },
    ]
  }, [currentStore, theme])

  if (!giftOfferHistory || loading) return <PageSkeleton />

  const tableData = giftOfferHistory.map((el) => {
    return {
      ...el,
      id: `${el.id}__${el.userBountyId}`,
    }
  })

  return (
    <Grid<GiftsHistoryItem>
      gridRef={gridRef}
      onRowClicked={(e) =>
        isNotNil(e.data) &&
        navigate(`gift-offer-details/${e.data.id.split('__')[0]}`)
      }
      rowData={tableData}
      columnDefs={columnDefs}
      components={components}
      containerProps={{
        height: `calc(720px + 49px + 20px)`,
        flex: 'none',
      }}
      rowStyle={{ cursor: 'pointer' }}
      rowHeight={72}
      showQuickSearch={false}
      showFooter={false}
    />
  )
}
