import {
  Box,
  Button,
  Flex,
  Input,
  Form,
  FormErrorMessage,
  FormControl,
  PageSkeleton,
} from '@bounty/brands-design-system'
import { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import {
  TikTokPageQueryDocument,
  UpdateStoreTikTokDocument,
} from '../../generated/backendGraphql'
import { shopifyStoreTikTokSchema } from '@bounty/schemas'
import * as z from 'zod'

type SchemaType = z.infer<typeof shopifyStoreTikTokSchema>

export type TikTokFormProps = {
  onSubmitSuccess?: (values: SchemaType) => void
}

export const TikTokForm = ({ onSubmitSuccess }: TikTokFormProps) => {
  const {
    handleSubmit,
    formState: { errors },
    getValues,
    reset,
    control,
  } = useForm<SchemaType>({
    resolver: zodResolver(shopifyStoreTikTokSchema),
  })

  const [updateTikTok, { loading: updateTikTokLoading }] = useMutationBackend(
    UpdateStoreTikTokDocument,
  )
  const { data } = useQueryBackend(TikTokPageQueryDocument)

  useEffect(() => {
    if (!data?.currentStore.tikTokUsername) return

    reset({
      ...getValues(),
      tikTokUsername: data.currentStore.tikTokUsername,
    })
  }, [data, reset, getValues])

  if (!data) return <PageSkeleton />

  return (
    <Box>
      <Form
        data-testid="tik-tok-form"
        onSubmit={handleSubmit(async (d) => {
          await updateTikTok({
            variables: d,
            optimisticResponse: {
              __typename: 'Mutation',
              updateStore: {
                ...d,
                __typename: 'ShopifyStore',
                id: data?.currentStore.id,
              },
            },
          })

          onSubmitSuccess?.(d)
        })}
      >
        <Flex alignItems="flex-start" maxWidth="310px">
          <FormControl isInvalid={!!errors.tikTokUsername} marginRight="2">
            <Controller
              defaultValue=""
              control={control}
              name="tikTokUsername"
              render={({ field }) => (
                <Input
                  data-testid="tik-tok-form-input"
                  placeholder="@username"
                  {...field}
                  onChange={(e) => {
                    const { value } = e.target
                    const changeValue = value
                      ? value.startsWith('@')
                        ? value
                        : `@${value}`
                      : ''

                    field.onChange(changeValue)
                  }}
                />
              )}
            />
            {errors.tikTokUsername && (
              <FormErrorMessage>
                {errors.tikTokUsername.message}
              </FormErrorMessage>
            )}
          </FormControl>

          <Button
            isLoading={updateTikTokLoading}
            type="submit"
            variant="solid"
            data-testid="submit-tik-tok-form-button"
            event="Update TikTok Submitted"
          >
            Submit
          </Button>
        </Flex>
      </Form>
    </Box>
  )
}
