import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalProps,
  Button,
  ModalFooter,
  Box,
  Link,
  Alert,
  AlertDescription,
} from '@bounty/brands-design-system'
import { ProductsGridSearchWrapper } from '../Products/ProductsGrid'
type FeaturedProductsModalProps = Omit<ModalProps, 'children'> & {
  variant: 'storefront'
  productsOrder: string[]
  setProductsOrder: React.Dispatch<React.SetStateAction<string[]>>
  generateAndSetRowData: (productIds: string[]) => void
  onClose: () => void
}

export const FeaturedProductsModal = ({
  onClose,
  variant,
  productsOrder,
  setProductsOrder,
  generateAndSetRowData,
  ...rest
}: FeaturedProductsModalProps) => {
  return (
    <Modal
      size="6xl"
      onClose={() => {
        onClose()
      }}
      isCentered
      allowPinchZoom
      scrollBehavior="inside"
      {...rest}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select From Enabled Products</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Box height={'5xl'}>
            <ProductsGridSearchWrapper
              variant="storefront"
              productsOrder={productsOrder}
              setProductsOrder={setProductsOrder}
              generateAndSetRowData={generateAndSetRowData}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button
            event="Storefront Products Modal Done Clicked"
            mr={3}
            onClick={onClose}
          >
            Add
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
