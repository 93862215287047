import { Outlet, Route, Routes } from 'react-router-dom'
import { GiftsHistoryPerformancePage } from './GiftsHistoryPerformancePage'
import { GiftsHistoryProvider } from './useGiftsHistory'

export const GiftsHistoryPerformanceRoutes = () => {
  return (
    <GiftsHistoryProvider>
      <Routes>
        <Route
          path="/*"
          element={
            <>
              <GiftsHistoryPerformancePage />
              <Outlet />
            </>
          }
        />
      </Routes>
    </GiftsHistoryProvider>
  )
}
