/**
 * This is the authoritative no-dependency
 * deterministically derived link builder for UGC assets
 * profile pics, etc.
 */

export const getLinkForTikTokThumbnail = (
  cloudinaryUrl: string,
  videoId: string,
) => {
  return `${cloudinaryUrl}/video_id_${videoId}.jpg`
}

export const getLinkForTikTokVideo = (
  videoBucketUrl: string,
  videoId: string,
) => {
  return `${videoBucketUrl}/${videoId}.mp4`
}

export const getInstagramReelThumbnail = (
  cloudinaryUrl: string,
  contentId: string,
) => {
  return `${cloudinaryUrl}/content_id_instagram_${contentId}.jpg`
}

export const getInstagramReelVideo = (
  videoBucketUrl: string,
  contentId: string,
) => {
  return `${videoBucketUrl}/instagram_reel_${contentId}.mp4`
}

export const getTikTokProfileImage = (
  cloudinaryUrl: string,
  userId: string,
) => {
  return `${cloudinaryUrl}/user_id_${userId}.jpg`
}

export const getInstagramProfileImage = (
  cloudinaryUrl: string,
  userId: string,
) => {
  return `${cloudinaryUrl}/user_id_instagram_${userId}.jpg`
}
