import {
  AspectRatio,
  Avatar,
  Box,
  EyeIcon,
  Flex,
  HeartIcon,
  Link,
  MessageIcon,
  ReplyIcon,
  Skeleton,
  Text,
} from '@bounty/brands-design-system'
import { prettyNumber, prettyViews } from '@bounty/utils'
import { useEffect, useRef, useState } from 'react'
import { useQueryBackend } from '../../../../../apollo/backend/hooks'
import { S3_BUCKET_URL } from '../../../../../config/env'
import { ContentLibraryItemDrawerDocument } from '../../../../../generated/backendGraphql'
import {
  getCloudinaryUserImage,
  getCloudinaryVideoImageUrl,
} from '../../../../../utils/urls'

export const GiftOfferDetailsContent = ({ bountyId }: { bountyId: string }) => {
  const { data, loading } = useQueryBackend(ContentLibraryItemDrawerDocument, {
    variables: { bountyId },
  })

  const videoRef = useRef<HTMLVideoElement>(null)
  const [shouldPlayVideo, setShouldPlayVideo] = useState(false)

  useEffect(() => {
    if (shouldPlayVideo) {
      videoRef.current?.play()
    } else {
      videoRef?.current?.pause()
    }
  }, [shouldPlayVideo])

  if (!data || loading)
    return <Skeleton h="510px" w="285px" borderRadius="lg" />

  const { contentLibraryItem } = data

  return (
    <Flex width="100%" maxW="320px" direction="column" gridGap={4}>
      <Flex width="100%" justifyContent="center" alignItems="flex-end">
        <Box width="100%">
          <AspectRatio
            ratio={0.56}
            cursor="pointer"
            mb="2"
            onClick={() => setShouldPlayVideo((x) => !x)}
          >
            <video
              poster={getCloudinaryVideoImageUrl(
                contentLibraryItem.externalContentId,
              )}
              autoPlay={false}
              loop={true}
              ref={videoRef}
              preload="none"
              controls
              controlsList="nodownload"
              style={{ borderRadius: 12 }}
            >
              <source
                src={`${S3_BUCKET_URL}/${contentLibraryItem.externalContentId}.mp4`}
                type="video/mp4"
              ></source>
            </video>
          </AspectRatio>
        </Box>
        <Flex ml={4} flexDir="column">
          <Flex mb={5} flexDir="column">
            <HeartIcon width={18} height={18} color="gray.500" />
            <Text fontSize="xl" fontWeight="bold">
              {prettyNumber(contentLibraryItem.likeCount ?? 0)}
            </Text>
          </Flex>
          <Flex mb={5} flexDir="column">
            <MessageIcon
              width={18}
              height={18}
              fill="gray.500"
              color="gray.500"
            />
            <Text fontSize="xl" fontWeight="bold">
              {prettyNumber(contentLibraryItem.commentCount ?? 0)}
            </Text>
          </Flex>
          <Flex mb={5} flexDir="column">
            <ReplyIcon width={18} height={18} color="gray.500" />
            <Text fontSize="xl" fontWeight="bold">
              {prettyNumber(contentLibraryItem.shareCount ?? 0)}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        width="85%"
        alignItems="center"
        justifyContent="space-between"
        mb="6"
      >
        <Flex alignItems="center">
          <EyeIcon />
          <Text ml="2" fontSize="2xl" fontWeight="semibold">
            {prettyViews(contentLibraryItem.viewCount ?? 0)}
          </Text>
        </Flex>
        <Avatar
          size="sm"
          as={Link}
          isExternal={false}
          name={contentLibraryItem.profileName}
          to={`../creator-details/${contentLibraryItem.userId}`}
          src={getCloudinaryUserImage(contentLibraryItem.userId)}
        />
      </Flex>
    </Flex>
  )
}
