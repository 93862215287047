export const MailIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.2"
        d="M21.5 5.25L12.5 13.5L3.5 5.25H21.5Z"
        fill="#11047A"
      />
      <path
        d="M21.5 5.25L12.5 13.5L3.5 5.25"
        stroke="#11047A"
        strokeWidth="1.48437"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 5.25H21.5V18C21.5 18.1989 21.421 18.3897 21.2803 18.5303C21.1397 18.671 20.9489 18.75 20.75 18.75H4.25C4.05109 18.75 3.86032 18.671 3.71967 18.5303C3.57902 18.3897 3.5 18.1989 3.5 18V5.25Z"
        stroke="#11047A"
        strokeWidth="1.48437"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8594 12L3.73438 18.5344"
        stroke="#11047A"
        strokeWidth="1.48437"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.2656 18.5344L14.1406 12"
        stroke="#11047A"
        strokeWidth="1.48437"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
