import {
  FormControl,
  FormErrorMessage,
  FormErrorMessageProps,
  FormHelperText,
  FormHelperTextProps,
  FormLabel,
  FormLabelProps,
  Tooltip,
  FormControlProps,
} from '../../Chakra'
import { ReactNode } from 'react'
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form'
import { InfoIcon } from '../../icons'
import { isString } from '@bounty/utils'

export type FastFormControlProps = FormControlProps & {
  showLabel?: boolean
  error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>
  helpText?: ReactNode
  label?: string
  tooltipText?: string
  labelProps?: FormLabelProps
  errorProps?: FormErrorMessageProps
  helpTextProps?: FormHelperTextProps
  children: ReactNode
}

export const FastFormControl = ({
  showLabel = true,
  error,
  helpText,
  label,
  children,
  labelProps,
  /**
   * @deprecated - Use help text instead
   */
  tooltipText,
  errorProps,
  helpTextProps,
  ...rest
}: FastFormControlProps) => {
  return (
    <FormControl {...rest} isInvalid={!!error}>
      {showLabel && (
        <FormLabel
          fontWeight={'semibold'}
          display="flex"
          alignItems={'center'}
          mb="1"
          {...labelProps}
        >
          {label}
          {tooltipText ? (
            <Tooltip label={tooltipText}>
              {/* Gotcha! https://v1.chakra-ui.com/docs/components/overlay/tooltip#with-an-icon */}
              <span>
                <InfoIcon ml="2" />
              </span>
            </Tooltip>
          ) : null}
        </FormLabel>
      )}
      {children}
      {error && isString(error.message) ? (
        <FormErrorMessage mt="1" fontSize="sm" {...errorProps}>
          {error.message}
        </FormErrorMessage>
      ) : (
        <FormHelperText mt="1" fontSize="sm" {...helpTextProps}>
          {helpText}
        </FormHelperText>
      )}
    </FormControl>
  )
}
