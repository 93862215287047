import { useRef, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Drawer,
  DrawerProps,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  AspectRatio,
  Text,
  Flex,
  Avatar,
  Link,
  IconButton,
  IconButtonProps,
  PageSkeleton,
  DrawerFooter,
  DrawerFooterPrimaryButton,
  DrawerFooterSecondaryButton,
  DrawerBackButton,
  Image,
} from '@bounty/brands-design-system'
import {
  HeartIcon,
  MessageIcon,
  ReplyIcon,
  StarIcon,
} from '@bounty/brands-design-system'
import { prettyCurrency, prettyNumber, isNil, prettyCount } from '@bounty/utils'
import {
  ContentLibraryItemDrawerDocument,
  ContentLibraryItemDrawerRequestsDocument,
  ContentLibraryResponse,
} from '../../generated/backendGraphql'
import { format } from 'date-fns'
import { Maybe } from 'graphql/jsutils/Maybe'
import { useAuthState } from '../../hooks/useAuth'
import { SparkCodeRequest } from '../../components/SparkCodeRequest/SparkCodeRequest'
import { useQueryBackend } from '../../apollo/backend/hooks'
import { useParamsOrThrow } from '@bounty/hooks'
import {
  getBountyContentTypeDisplayName,
  getBountyTypeDisplayName,
} from '../../utils/codesToDisplayMapings'
import {
  contentTypeToAccountType,
  getContentVideoLocation,
  getPreviewImageLinkForContent,
  getUserProfileImage,
} from '../../utils/externalContentLinksGetters'
import {
  getSocialMediaFromContentType,
  getSocialMediaLogo,
} from '../../utils/preferredSocialProfileUtils'

export type ContentLibraryFavoriteButtonProps = Omit<
  IconButtonProps,
  'aria-label'
> & {
  isFavorited: boolean
  mode?: 'default' | 'expanded'
}

export const ContentLibraryFavoriteButton = ({
  isFavorited,
  mode = 'default',
  ...rest
}: ContentLibraryFavoriteButtonProps) => {
  const boxSize = mode === 'expanded' ? '14px' : ['20px', '12px']

  return (
    <IconButton
      aria-label="Favorite video"
      icon={
        isFavorited ? (
          <StarIcon boxSize={boxSize} color={'primary.500'} weight={'fill'} />
        ) : (
          <StarIcon boxSize={boxSize} color={'neutral.900'} weight={'bold'} />
        )
      }
      {...(mode === 'expanded'
        ? {
            _after: {
              content: isFavorited
                ? '"Remove from Favorites"'
                : '"Add to Favorites"',
              lineHeight: boxSize,
              color: 'gray.500',
              opacity: 0,
              transition: 'all 250ms linear',
            },
          }
        : {})}
      _hover={{
        _after: {
          opacity: 1,
        },
      }}
      variant="unstyled"
      {...rest}
    />
  )
}

type ContentLibraryItemDrawerProps = Omit<DrawerProps, 'children'> & {
  onFavoriteClicked: (item: ContentLibraryResponse['items'][0]) => void
}

export const ContentLibraryItemDrawer = ({
  onFavoriteClicked,
  ...rest
}: ContentLibraryItemDrawerProps) => {
  const { bountyId } = useParamsOrThrow(['bountyId'])

  const { data, loading } = useQueryBackend(ContentLibraryItemDrawerDocument, {
    variables: { bountyId },
  })

  const videoRef = useRef<HTMLVideoElement>(null)
  const [shouldPlayVideo, setShouldPlayVideo] = useState(false)
  const [showSparkCodeForm, setShowSparkCodeForm] = useState(false)
  const { shopifyStoreUrl } = useAuthState()

  useEffect(() => {
    if (shouldPlayVideo) {
      videoRef.current?.play()
    } else {
      videoRef?.current?.pause()
    }
  }, [shouldPlayVideo])

  if (loading === true || !data) {
    return <PageSkeleton />
  }

  const { contentLibraryItem } = data

  const formatCurrency = (value?: Maybe<number>) =>
    isNil(value) ? 'N/A' : prettyCurrency(value, { precision: 2 })

  const makeOrderUrl = (orderName?: Maybe<string>) =>
    isNil(orderName)
      ? null
      : `https://${shopifyStoreUrl}/admin/orders/${contentLibraryItem.shopifyOrderId}`

  const tags = [
    {
      title: 'Bounty Type',
      value: getBountyTypeDisplayName(contentLibraryItem.bountyType),
    },
    {
      title: 'Content Type',
      value: contentLibraryItem.bountyContentType
        ? getBountyContentTypeDisplayName(contentLibraryItem.bountyContentType)
        : 'N/A',
    },
    { title: 'Payout', value: formatCurrency(contentLibraryItem.value) },
    // NOTE: cpm is available here but we decided to remove it from view
    {
      title: 'Post Date',
      value: format(new Date(contentLibraryItem.liveAt), 'MM/dd/yy'),
    },
    {
      title: 'Order',
      value: (
        <Link
          isExternal
          href={makeOrderUrl(contentLibraryItem.shopifyOrderName) ?? ''}
        >
          {contentLibraryItem.shopifyOrderName ?? 'N/A'}
        </Link>
      ),
    },
    {
      title: 'Order Total',
      value: formatCurrency(contentLibraryItem.orderTotal),
    },
  ]

  const SparkCodeRequestButton = ({ contentId }: { contentId: string }) => {
    const { data, loading } = useQueryBackend(
      ContentLibraryItemDrawerRequestsDocument,
      {
        variables: { contentId },
      },
    )

    if (loading === true || !data) {
      return null
    }
    // has a request and last request is not in a finished state
    const requestInProgress =
      (data.getSparkCodeRequestsForVideo.length ?? 0) > 0 &&
      !['PAID_OUT', 'CREATOR_REJECTED'].includes(
        data.getSparkCodeRequestsForVideo[0].status ?? '',
      )

    return (
      <DrawerFooterSecondaryButton
        event={{
          eventName: 'Content Library Detail Drawer Spark Code Clicked',
          mode: 'Request Spark Code',
        }}
        onClick={() => setShowSparkCodeForm(true)}
      >
        {requestInProgress ? 'View Spark Code Request' : 'Request Spark Code'}
      </DrawerFooterSecondaryButton>
    )
  }

  const thumbnailImage = getPreviewImageLinkForContent(
    contentLibraryItem.bountyContentType!,
    contentLibraryItem.externalContentId,
  )!
  const videoSrc = getContentVideoLocation(
    contentLibraryItem.bountyContentType!,
    contentLibraryItem.externalContentId,
  )!
  const userProfilePic = getUserProfileImage(
    contentLibraryItem.userId,
    contentTypeToAccountType(contentLibraryItem.bountyContentType!)!,
  )!

  return (
    <Drawer {...rest}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        {showSparkCodeForm && (
          // @ts-expect-error This is right but I can't get chakra to infer correctly
          <DrawerBackButton
            as={Button}
            variant="outline"
            event="Content Library Drawer Spark Code Clicked"
            onClick={() => {
              setShowSparkCodeForm(false)
            }}
          />
        )}
        <DrawerHeader></DrawerHeader>
        <DrawerBody
          data-testid={`content-library-drawer-for-${contentLibraryItem.id}`}
          data-active={rest.isOpen}
        >
          <Flex flexDir="column" alignItems="center">
            {showSparkCodeForm ? (
              <SparkCodeRequest
                item={contentLibraryItem}
                onSubmit={() => setShowSparkCodeForm(false)}
              />
            ) : (
              <Box width="65%">
                <Flex
                  width={['255px', '305px']}
                  mb="4"
                  justifyContent="space-between"
                  align="center"
                >
                  <Flex alignItems="center">
                    <Avatar
                      size="sm"
                      as={Link}
                      isExternal={false}
                      name={contentLibraryItem.profileName}
                      to={`/creators/details/${contentLibraryItem.userId}`}
                      src={userProfilePic}
                      mr="2"
                    />
                    <Text
                      fontSize="sm"
                      fontWeight="semibold"
                      color="neutral.600"
                    >
                      {contentLibraryItem.profileName}
                    </Text>
                  </Flex>
                  {contentLibraryItem.bountyContentType && (
                    <Button
                      event={{
                        eventName:
                          'Content Library Detail Drawer View On Social Media Platform Clicked',
                        platform: getSocialMediaFromContentType(
                          contentLibraryItem.bountyContentType,
                        ),
                      }}
                      as={Link}
                      isExternal={true}
                      // @ts-expect-error after nx migrate 16
                      href={contentLibraryItem.url}
                      variant="outline"
                    >
                      <Image
                        height="6"
                        width="6"
                        objectFit={'contain'}
                        src={getSocialMediaLogo(
                          contentLibraryItem.bountyContentType,
                        )}
                      />
                    </Button>
                  )}
                </Flex>
                <Flex
                  width="100%"
                  justifyContent="center"
                  alignItems="flex-end"
                >
                  <Box width="100%">
                    <AspectRatio
                      ratio={0.56}
                      cursor="pointer"
                      mb="2"
                      onClick={() => setShouldPlayVideo((x) => !x)}
                    >
                      <video
                        poster={thumbnailImage}
                        autoPlay={false}
                        loop={true}
                        ref={videoRef}
                        preload="none"
                        controls
                        controlsList="nodownload"
                        style={{ borderRadius: 12 }}
                      >
                        <source src={videoSrc} type="video/mp4"></source>
                      </video>
                    </AspectRatio>
                  </Box>
                  <Flex ml={4} flexDir="column">
                    <Flex mb={5} flexDir="column">
                      <HeartIcon width={18} height={18} color="gray.500" />
                      <Text fontSize="xl" fontWeight="bold">
                        {prettyNumber(contentLibraryItem.likeCount ?? 0)}
                      </Text>
                    </Flex>
                    <Flex mb={5} flexDir="column">
                      <MessageIcon
                        width={18}
                        height={18}
                        fill="gray.500"
                        color="gray.500"
                      />
                      <Text fontSize="xl" fontWeight="bold">
                        {prettyNumber(contentLibraryItem.commentCount ?? 0)}
                      </Text>
                    </Flex>
                    <Flex mb={5} flexDir="column">
                      <ReplyIcon width={18} height={18} color="gray.500" />
                      <Text fontSize="xl" fontWeight="bold">
                        {prettyNumber(contentLibraryItem.shareCount ?? 0)}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
                <Flex
                  width="85%"
                  alignItems="center"
                  justifyContent="space-between"
                  mb="6"
                >
                  <Flex>
                    <Text fontWeight="semibold" color="neutral.600" mr="1">
                      {prettyCount(contentLibraryItem.viewCount ?? 0)}
                    </Text>
                    <Text color="neutral.600">views</Text>
                  </Flex>
                  <Flex
                    border="1px solid"
                    borderColor={'neutral.400'}
                    borderRadius={'6px'}
                    boxSize={'8'}
                    pl="2"
                    justifyContent={'left'}
                    alignItems={'center'}
                  >
                    <ContentLibraryFavoriteButton
                      isFavorited={contentLibraryItem.isFavorited}
                      onClick={() => onFavoriteClicked(contentLibraryItem)}
                      mode="expanded"
                      objectFit={'contain'}
                    />
                  </Flex>
                </Flex>

                <Text my="6">{contentLibraryItem.title}</Text>
                <Text mb={4} fontWeight="bold" fontSize="2xl">
                  Stats
                </Text>
                <Flex flexDir="column" mb={100}>
                  {tags.map((t) => (
                    <Flex key={t.title} mb={2} justifyContent="space-between">
                      <Text as="span">{t.title}:</Text>
                      <Text as="span">{t.value}</Text>
                    </Flex>
                  ))}
                </Flex>
              </Box>
            )}
          </Flex>
        </DrawerBody>
        {contentLibraryItem.status === 'APPROVED' && !showSparkCodeForm && (
          <DrawerFooter>
            {contentLibraryItem.bountyContentType === 'TIKTOK_VIDEO' && (
              <SparkCodeRequestButton
                contentId={contentLibraryItem.internalContentId}
              />
            )}

            <DrawerFooterPrimaryButton
              event="Usage Rights Download Video Clicked"
              as="a"
              download
              href={videoSrc}
            >
              Download video
            </DrawerFooterPrimaryButton>
          </DrawerFooter>
        )}
      </DrawerContent>
    </Drawer>
  )
}
