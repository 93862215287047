import dlv from 'dlv'
import { ComponentProps } from 'react'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import { Checkbox, forwardRef } from '../../Chakra'
import { Upload, UploadProps } from '../Upload'
import { FastFormControl } from './FastFormControl'
import { FastFormElementProps } from './types'
import { getLabelFromName } from './utils'

export type FastFormCheckboxType = ComponentProps<typeof Checkbox>
export const FastFormCheckboxOption = Checkbox

export type FastFormUploadBaseProps = UploadProps

export const FastFormUploadBase = forwardRef<FastFormUploadBaseProps, 'input'>(
  ({ ...rest }, ref) => {
    return <Upload ref={ref} {...rest} />
  },
)

FastFormUploadBase.displayName = 'FastFormUploadBase'

export type FastFormUploadProps<
  TFieldValues extends FieldValues = FieldValues,
> = FastFormElementProps<FastFormUploadBaseProps, TFieldValues> & {
  removed?: boolean
  setRemoved?: React.Dispatch<React.SetStateAction<boolean>>
}

export const FastFormUpload = <TFieldValues extends FieldValues = FieldValues>({
  label,
  name,
  helpText,
  labelProps,
  tooltipText,
  errorProps,
  isRequired,
  isDisabled,
  formControlProps,
  formElementProps,
  showLabel,
  removed,
  setRemoved,
}: FastFormUploadProps<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>()
  const {
    formState: { errors },
    control,
  } = methods
  const error = dlv(errors, name)

  return (
    <FastFormControl
      error={error}
      helpText={helpText}
      label={label ?? getLabelFromName(name)}
      labelProps={labelProps}
      tooltipText={tooltipText}
      errorProps={errorProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
      showLabel={showLabel}
      {...formControlProps}
    >
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <Upload
              isDisabled={isDisabled}
              removed={removed}
              setRemoved={setRemoved}
              {...formElementProps}
              {...field}
            />
          )
        }}
      />
    </FastFormControl>
  )
}
