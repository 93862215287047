// Sometimes JSDOM and Jest throw up importing directly from here. Import from
// @chakra-ui/react if you need to
import {
  extendTheme,
  useTheme as useThemeCore,
  ThemeOverride,
} from '@chakra-ui/react'
import { BaseThemeTypings } from '@chakra-ui/styled-system'
import {
  foundations,
  baseFontSize,
  semanticColorTokens,
  SemanticColorToken,
  Shadow,
  Spacing,
  Border,
  Breakpoints,
  Font,
  FontSize,
  FontWeight,
  LetterSpacing,
  LineHeight,
  Radius,
  ZIndex,
} from '../foundations'
import { alertTheme } from './Alert'
import { linkTheme } from './Link'
import { drawerStyle } from './Drawer'
import { buttonTheme } from './Button'
import { menuTheme } from './Menu'
import { inputTheme } from './Input'
import { tabsTheme } from './Tabs'
import { selectTheme } from './Select'
import { textareaTheme } from './Textarea'
import { numberInputTheme } from './NumberInput'
import { avatarTheme } from './Avatar'
import { badgeTheme } from './Badge'
import { switchTheme } from './Switch'
import { progressTheme } from './Progress'
import { formControlTheme } from './FormControl'
import { checkboxTheme } from './Checkbox'
import { breadcrumbTheme } from './Breadcrumb'
import { tooltipTheme } from './Tooltip'
import { statTheme } from './Stat'
import { radioTheme } from './Radio'
import { sliderTheme } from './Slider'

// Turn this on with the design system to limit the properties coming from chakra.
// Use {} where you pass the CSSType generic in chakra's source to limit all the other nonsense
// node_modules/@chakra-ui/styled-system/dist/index.d.ts
declare module '@chakra-ui/react' {
  export interface CustomThemeTypings extends BaseThemeTypings {
    borders: Border
    // We want to only show semantic color values to enforce usage
    colors: SemanticColorToken
    breakpoints: Breakpoints
    colorSchemes: string
    fonts: Font
    fontSizes: FontSize
    fontWeights: FontWeight
    layerStyles: string
    letterSpacings: LetterSpacing
    lineHeights: LineHeight
    radii: Radius
    shadows: Shadow
    sizes: string
    space: Spacing
    textStyles: string
    zIndices: ZIndex

    components: {
      Button: {
        // Example custom component sizes and variants
        sizes: 'xs' | 'sm' | 'md' | 'lg'
        variants: 'solid' | 'outline' | 'ghost' | 'link' | 'unstyled'
      }
      Input: {
        sizes: 'md'
        variants: 'outline'
      }
      // Heading: {
      //   sizes: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl'
      // }
      // ...
    }
  }
}

export {
  AbsoluteCenter,
  type AbsoluteCenterProps,
  Accordion,
  AccordionButton,
  type AccordionButtonProps,
  AccordionIcon,
  AccordionItem,
  type AccordionItemProps,
  AccordionPanel,
  type AccordionPanelProps,
  type AccordionProps,
  AccordionProvider,
  Alert,
  AlertDescription,
  type AlertDescriptionProps,
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  type AlertDialogProps,
  AlertIcon,
  type AlertIconProps,
  type AlertProps,
  type AlertStatus,
  AlertTitle,
  type AlertTitleProps,
  type As,
  AspectRatio,
  type AspectRatioProps,
  Avatar,
  AvatarBadge,
  type AvatarBadgeProps,
  AvatarGroup,
  type AvatarGroupProps,
  type AvatarProps,
  type BackgroundProps,
  Badge,
  type BadgeProps,
  type BaseThemeTypings,
  type BaseThemeWithExtensions,
  type BorderProps,
  Box,
  type BoxProps,
  Breadcrumb,
  BreadcrumbItem,
  type BreadcrumbItemProps,
  BreadcrumbLink,
  type BreadcrumbLinkProps,
  type BreadcrumbOptions,
  type BreadcrumbProps,
  BreadcrumbSeparator,
  type BreadcrumbSeparatorProps,
  Button,
  ButtonGroup,
  type ButtonGroupProps,
  type ButtonOptions,
  type ButtonProps,
  ButtonSpinner,
  CSSPolyfill,
  CSSReset,
  CSSVars,
  type CSSVarsProps,
  type CSSWithMultiValues,
  Card,
  CardBody,
  type CardBodyProps,
  CardFooter,
  type CardFooterProps,
  CardHeader,
  type CardHeaderProps,
  type CardProps,
  Center,
  type CenterProps,
  ChakraBaseProvider,
  type ChakraComponent,
  type ChakraProps,
  ChakraProvider,
  type ChakraProviderProps,
  type ChakraStyledOptions,
  type ChakraTheme,
  Checkbox,
  CheckboxGroup,
  type CheckboxGroupContext,
  type CheckboxGroupProps,
  CheckboxIcon,
  type CheckboxIconProps,
  type CheckboxProps,
  type CheckboxState,
  Circle,
  CircularProgress,
  CircularProgressLabel,
  type CircularProgressLabelProps,
  type CircularProgressProps,
  CloseButton,
  type CloseButtonProps,
  Code,
  type CodeProps,
  Collapse,
  type CollapseOptions,
  type CollapseProps,
  type ColorHues,
  type ColorMode,
  ColorModeContext,
  type ColorModeContextType,
  ColorModeProvider,
  type ColorModeProviderProps,
  ColorModeScript,
  type ColorModeScriptProps,
  type ColorModeWithSystem,
  type ColorProps,
  type Colors,
  type ComponentDefaultProps,
  type ComponentMultiStyleConfig,
  type ComponentSingleStyleConfig,
  type ComponentStyleConfig,
  type ComponentWithAs,
  Container,
  type ContainerProps,
  ControlBox,
  type ControlBoxOptions,
  type ControlBoxProps,
  type CreateStandAloneToastParam,
  type CreateStandaloneToastReturn,
  type CreateStyleContextReturn,
  type CreateToastFnReturn,
  type CreateToastOptions,
  type CustomThemeTypings,
  DarkMode,
  type DeepPartial,
  Divider,
  type DividerProps,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  type DrawerContentProps,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  type DrawerProps,
  EASINGS,
  Editable,
  EditableInput,
  type EditableInputProps,
  EditablePreview,
  type EditablePreviewProps,
  type EditableProps,
  EditableTextarea,
  type EditableTextareaProps,
  type EffectProps,
  EnvironmentProvider,
  type EnvironmentProviderProps,
  type EventListenerEnv,
  type ExpandedIndex,
  Fade,
  type FadeProps,
  type FilterProps,
  type FlatToken,
  type FlatTokens,
  type FlattenTokensParam,
  Flex,
  type FlexProps,
  type FlexboxProps,
  FormControl,
  type FormControlOptions,
  type FormControlProps,
  FormErrorIcon,
  FormErrorMessage,
  type FormErrorMessageProps,
  FormHelperText,
  type FormHelperTextProps,
  FormLabel,
  type FormLabelProps,
  type FunctionCSSInterpolation,
  GenericAvatarIcon,
  GlobalStyle,
  Grid as ChakraGrid,
  GridItem as ChakraGridItem,
  type GridItemProps as ChakraGridItemProps,
  type GridProps as ChakraGridProps,
  HStack,
  type HTMLChakraComponents,
  type HTMLChakraProps,
  Heading,
  type HeadingProps,
  Hide,
  type HideProps,
  Highlight,
  type HighlightProps,
  type ICollapse,
  Icon,
  IconButton,
  type IconButtonProps,
  type IconProps,
  Image,
  type ImageProps,
  Img,
  type ImgProps,
  Input,
  InputAddon,
  type InputAddonProps,
  type InputElementProps,
  InputGroup,
  type InputGroupProps,
  InputLeftAddon,
  InputLeftElement,
  type InputProps,
  InputRightAddon,
  InputRightElement,
  type InteractivityProps,
  type Interpolation,
  Kbd,
  type KbdProps,
  type LayoutProps,
  LightMode,
  Link,
  LinkBox,
  type LinkBoxProps,
  LinkOverlay,
  type LinkOverlayProps,
  type LinkProps,
  List,
  ListIcon,
  ListItem,
  type ListItemProps,
  type ListProps,
  type LogicalToastPosition,
  Mark,
  type MarkProps,
  Menu,
  MenuButton,
  type MenuButtonProps,
  MenuCommand,
  type MenuCommandProps,
  MenuDescendantsProvider,
  MenuDivider,
  type MenuDividerProps,
  MenuGroup,
  type MenuGroupProps,
  MenuIcon,
  MenuItem,
  MenuItemOption,
  type MenuItemOptionProps,
  type MenuItemProps,
  MenuList,
  type MenuListProps,
  MenuOptionGroup,
  type MenuOptionGroupProps,
  type MenuProps,
  MenuProvider,
  type MergeWithAs,
  Modal,
  ModalBody,
  type ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  type ModalContentProps,
  ModalContextProvider,
  ModalFocusScope,
  ModalFooter,
  type ModalFooterProps,
  ModalHeader,
  type ModalHeaderProps,
  ModalOverlay,
  type ModalOverlayProps,
  type ModalProps,
  type MultiStyleConfig,
  NumberDecrementStepper,
  type NumberDecrementStepperProps,
  NumberIncrementStepper,
  type NumberIncrementStepperProps,
  NumberInput,
  NumberInputField,
  type NumberInputFieldProps,
  type NumberInputProps,
  NumberInputStepper,
  type NumberInputStepperProps,
  type OmitCommonProps,
  type OmitSpaceXY,
  type Operand,
  OrderedList,
  type OtherProps,
  type PartsStyleFunction,
  type PartsStyleInterpolation,
  type PartsStyleObject,
  PinInput,
  type PinInputContext,
  PinInputDescendantsProvider,
  PinInputField,
  type PinInputFieldProps,
  type PinInputProps,
  PinInputProvider,
  type Placement,
  type PlacementWithLogical,
  type PlainToken,
  Popover,
  PopoverAnchor,
  PopoverArrow,
  type PopoverArrowProps,
  PopoverBody,
  type PopoverBodyProps,
  PopoverCloseButton,
  type PopoverCloseButtonProps,
  PopoverContent,
  type PopoverContentProps,
  PopoverFooter,
  type PopoverFooterProps,
  PopoverHeader,
  type PopoverHeaderProps,
  type PopoverProps,
  PopoverTrigger,
  Portal,
  PortalManager,
  type PortalManagerProps,
  type PortalProps,
  type PositionProps,
  Progress,
  type ProgressFilledTrackProps,
  ProgressLabel,
  type ProgressLabelProps,
  type ProgressProps,
  type ProgressTrackProps,
  type PropsOf,
  type Pseudos,
  Radio,
  RadioGroup,
  type RadioGroupContext,
  type RadioGroupProps,
  type RadioProps,
  RangeSlider,
  RangeSliderFilledTrack,
  type RangeSliderInnerTrackProps,
  RangeSliderMark,
  type RangeSliderMarkProps,
  type RangeSliderProps,
  RangeSliderProvider,
  RangeSliderThumb,
  type RangeSliderThumbProps,
  RangeSliderTrack,
  type RangeSliderTrackProps,
  type RecursiveCSSObject,
  type RecursiveCSSSelector,
  type RecursiveObject,
  type RecursiveProperty,
  type RecursivePseudo,
  RequiredIndicator,
  type RequiredIndicatorProps,
  type ResponsiveArray,
  type ResponsiveObject,
  type ResponsiveValue,
  type RightJoinProps,
  type RingProps,
  ScaleFade,
  type ScaleFadeProps,
  type ScrollProps,
  Select,
  SelectField,
  type SelectFieldProps,
  type SelectProps,
  type SemanticToken,
  type SemanticValue,
  Show,
  type ShowProps,
  SimpleGrid,
  type SimpleGridProps,
  Skeleton,
  SkeletonCircle,
  type SkeletonOptions,
  type SkeletonProps,
  SkeletonText,
  type SkeletonTextProps,
  Slide,
  type SlideDirection,
  SlideFade,
  type SlideFadeProps,
  type SlideOptions,
  type SlideProps,
  Slider,
  SliderFilledTrack,
  type SliderInnerTrackProps,
  SliderMark,
  type SliderMarkProps,
  type SliderProps,
  SliderProvider,
  SliderThumb,
  type SliderThumbProps,
  SliderTrack,
  type SliderTrackProps,
  type SpaceProps,
  Spacer,
  type SpacerProps,
  Spinner,
  type SpinnerProps,
  Square,
  type SquareProps,
  Stack,
  type StackDirection,
  StackDivider,
  type StackDividerProps,
  StackItem,
  type StackProps,
  Stat,
  StatArrow,
  type StatArrowProps,
  StatDownArrow,
  StatGroup,
  type StatGroupProps,
  StatHelpText,
  type StatHelpTextProps,
  StatLabel,
  type StatLabelProps,
  StatNumber,
  type StatNumberProps,
  type StatProps,
  StatUpArrow,
  type StepperProps,
  Stepper,
  type StepProps,
  Step,
  type StepIndicatorProps,
  StepIndicator,
  type StepStatusProps,
  StepStatus,
  StepIcon,
  StepNumber,
  type StepTitleProps,
  StepTitle,
  type StepDescriptionProps,
  StepDescription,
  type StepSeparatorProps,
  StepSeparator,
  type StyleConfig,
  type StyleFunctionProps,
  type StyleObjectOrFn,
  type StyleProps,
  Switch,
  type SwitchProps,
  type SystemCSSProperties,
  type SystemProps,
  type SystemStyleFunction,
  type SystemStyleInterpolation,
  type SystemStyleObject,
  type SystemStyleObjectRecord,
  Tab,
  TabIndicator,
  type TabIndicatorProps,
  TabList,
  type TabListProps,
  TabPanel,
  type TabPanelProps,
  TabPanels,
  type TabPanelsProps,
  type TabProps,
  Table,
  type TableBodyProps,
  TableCaption,
  type TableCaptionProps,
  type TableCellProps,
  type TableColumnHeaderProps,
  TableContainer,
  type TableContainerProps,
  type TableFooterProps,
  type TableHeadProps,
  type TableOptions,
  type TableProps,
  type TableRowProps,
  Tabs,
  TabsDescendantsProvider,
  type TabsProps,
  TabsProvider,
  Tag,
  TagCloseButton,
  type TagCloseButtonProps,
  TagLabel,
  type TagLabelProps,
  TagLeftIcon,
  type TagProps,
  TagRightIcon,
  Tbody,
  Td,
  Text,
  type TextDecorationProps,
  type TextProps,
  Textarea,
  type TextareaProps,
  Tfoot,
  Th,
  Thead,
  type Theme,
  type ThemeComponentFunction,
  type ThemeComponentProps,
  type ThemeComponents,
  type ThemeConfig,
  type ThemeDirection,
  type ThemeExtension,
  type ThemeOverride,
  ThemeProvider,
  type ThemeProviderProps,
  type ThemeScale,
  type ThemeThunk,
  type ThemeTransitions,
  type ThemeTypings,
  type ThemingProps,
  type ThemingPropsThunk,
  Toast,
  type ToastId,
  type ToastMessage,
  type ToastMethods,
  type ToastOptions,
  type ToastPosition,
  type ToastPositionWithLogical,
  type ToastProps,
  ToastProvider,
  type ToastProviderProps,
  type ToastState,
  Tooltip,
  type TooltipProps,
  Tr,
  type TransformProps,
  type TransitionProperties,
  type TransitionProps,
  type TypographyProps,
  UnorderedList,
  type UseAccordionItemProps,
  type UseAccordionItemReturn,
  type UseAccordionProps,
  type UseAccordionReturn,
  type UseAnimationStateProps,
  type UseBreakpointOptions,
  type UseCheckboxGroupProps,
  type UseCheckboxGroupReturn,
  type UseCheckboxProps,
  type UseCheckboxReturn,
  type UseClipboardOptions,
  type UseControllableStateProps,
  type UseCounterProps,
  type UseCounterReturn,
  type UseDisclosureProps,
  type UseDisclosureReturn,
  type UseEditableProps,
  type UseEditableReturn,
  type UseFocusEffectOptions,
  type UseFocusOnHideOptions,
  type UseFocusOnMouseDownProps,
  type UseFocusOnShowOptions,
  type UseFormControlProps,
  type UseImageProps,
  type UseImageReturn,
  type UseMediaQueryOptions,
  type UseMenuButtonProps,
  type UseMenuItemProps,
  type UseMenuListProps,
  type UseMenuOptionGroupProps,
  type UseMenuOptionOptions,
  type UseMenuOptionProps,
  type UseMenuProps,
  type UseMenuReturn,
  type UseModalProps,
  type UseModalReturn,
  type UseNumberInputProps,
  type UseNumberInputReturn,
  type UseOutsideClickProps,
  type UsePanGestureProps,
  type UsePinInputFieldProps,
  type UsePinInputProps,
  type UsePinInputReturn,
  type UsePopoverProps,
  type UsePopoverReturn,
  type UsePopperProps,
  type UsePopperReturn,
  type UseQueryProps,
  type UseRadioGroupProps,
  type UseRadioGroupReturn,
  type UseRadioProps,
  type UseRadioReturn,
  type UseRangeSliderProps,
  type UseRangeSliderReturn,
  type UseShortcutProps,
  type UseSliderProps,
  type UseSliderReturn,
  type UseTabListProps,
  type UseTabListReturn,
  type UseTabOptions,
  type UseTabPanelsProps,
  type UseTabProps,
  type UseTabsProps,
  type UseTabsReturn,
  type UseToastOptions,
  type UseTooltipProps,
  type UseTooltipReturn,
  VStack,
  VisuallyHidden,
  VisuallyHiddenInput,
  type WithCSSVar,
  Wrap,
  WrapItem,
  type WrapItemProps,
  type WrapProps,
  addPrefix,
  assignRef,
  background,
  baseTheme,
  border,
  calc,
  chakra,
  color,
  cookieStorageManager,
  cookieStorageManagerSSR,
  createCookieStorageManager,
  createIcon,
  createLocalStorageManager,
  createMultiStyleConfigHelpers,
  createRenderToast,
  createStandaloneToast,
  createStylesContext,
  createToastFn,
  css,
  cssVar,
  defineStyle,
  defineStyleConfig,
  effect,
  extendBaseTheme,
  extendTheme,
  fadeConfig,
  filter,
  flattenTokens,
  flexbox,
  forwardRef,
  getCSSVar,
  getCss,
  getScriptSrc,
  getSlideTransition,
  getToastPlacement,
  getToken,
  grid,
  interactivity,
  isChakraTheme,
  isStyleProp,
  keyframes,
  layout,
  layoutPropNames,
  list,
  localStorageManager,
  mergeThemeOverride,
  type omitThemingProps,
  others,
  popperCSSVars,
  position,
  propNames,
  pseudoPropNames,
  pseudoSelectors,
  requiredChakraThemeKeys,
  resolveStyleConfig,
  ring,
  scaleFadeConfig,
  scroll,
  shouldForwardProp,
  slideFadeConfig,
  space,
  styled,
  type systemProps,
  textDecoration,
  theme,
  toCSSObject,
  toCSSVar,
  toVarDefinition,
  toVarReference,
  tokenToCSSVar,
  transform,
  transition,
  typography,
  useAccordion,
  useAccordionContext,
  useAccordionItem,
  useAccordionItemState,
  useAccordionStyles,
  useAlertStyles,
  useAnimationState,
  useAvatarStyles,
  useBoolean,
  useBreadcrumbStyles,
  useBreakpoint,
  useBreakpointValue,
  useButtonGroup,
  useCallbackRef,
  useCardStyles,
  useChakra,
  useCheckbox,
  useCheckboxGroup,
  useClipboard,
  useColorMode,
  useColorModePreference,
  useColorModeValue,
  useComponentStyles__unstable,
  useConst,
  useControllableProp,
  useControllableState,
  useCounter,
  useDisclosure,
  useDrawerContext,
  useEditable,
  useEditableControls,
  useEditableState,
  useEditableStyles,
  useEnvironment,
  useEventListener,
  useEventListenerMap,
  useFocusEffect,
  useFocusOnHide,
  useFocusOnPointerDown,
  useFocusOnShow,
  useForceUpdate,
  useFormControl,
  useFormControlContext,
  type useFormControlProps,
  useFormControlStyles,
  useFormErrorStyles,
  useHighlight,
  useId,
  useIds,
  useImage,
  useInputGroupStyles,
  useInterval,
  useLatestRef,
  useListStyles,
  useMediaQuery,
  useMenu,
  useMenuButton,
  useMenuContext,
  useMenuDescendant,
  useMenuDescendants,
  useMenuDescendantsContext,
  useMenuItem,
  useMenuList,
  useMenuOption,
  useMenuOptionGroup,
  useMenuPositioner,
  useMenuState,
  useMenuStyles,
  useMergeRefs,
  useModal,
  useModalContext,
  useModalStyles,
  useMouseDownRef,
  useMultiStyleConfig,
  useNumberInput,
  useNumberInputStyles,
  useOptionalPart,
  useOutsideClick,
  usePanGesture,
  usePinInput,
  usePinInputContext,
  usePinInputField,
  usePointerEvent,
  usePopover,
  usePopoverContext,
  usePopoverStyles,
  usePopper,
  usePortalManager,
  usePrefersReducedMotion,
  usePrevious,
  useProgressStyles,
  useQuery,
  useRadio,
  useRadioGroup,
  useRadioGroupContext,
  useRangeSlider,
  useRangeSliderContext,
  useRangeSliderStyles,
  useSafeLayoutEffect,
  useShortcut,
  useSlider,
  useSliderContext,
  useSliderStyles,
  useStatStyles,
  useSteps,
  useStyleConfig,
  useStyles,
  useTab,
  useTabIndicator,
  useTabList,
  useTabPanel,
  useTabPanels,
  useTableStyles,
  useTabs,
  useTabsContext,
  useTabsDescendant,
  useTabsDescendants,
  useTabsDescendantsContext,
  useTabsStyles,
  useTagStyles,
  // useTheme,
  useTimeout,
  useToast,
  useToken,
  useTooltip,
  useUnmountEffect,
  useUpdateEffect,
  useWhyDidYouUpdate,
  visuallyHiddenStyle,
  withDefaultColorScheme,
  type withDefaultProps,
  withDefaultSize,
  withDefaultVariant,
  withDelay,
  useDimensions,
} from '@chakra-ui/react'

// For inspiration:
// https://github.com/chakra-ui/chakra-ui/tree/main/packages/components/theme/src
const themeOverrides: ThemeOverride = {
  ...foundations,
  styles: {
    global: {
      'html, body': {
        fontSize: baseFontSize,
        letterSpacing: 'tight',
      },
    },
  },
  semanticTokens: {
    colors: {
      ...semanticColorTokens,
      'chakra-body-text': {
        _light: 'gray.900',
      },
    },
  },
  components: {
    Alert: alertTheme,
    Avatar: avatarTheme,
    Badge: badgeTheme,
    Breadcrumb: breadcrumbTheme,
    Button: buttonTheme,
    Checkbox: checkboxTheme,
    Drawer: drawerStyle,
    Form: formControlTheme,
    Input: inputTheme,
    Link: linkTheme,
    Menu: menuTheme,
    NumberInput: numberInputTheme,
    Progress: progressTheme,
    Radio: radioTheme,
    Select: selectTheme,
    Slider: sliderTheme,
    Stat: statTheme,
    Switch: switchTheme,
    Tabs: tabsTheme,
    Textarea: textareaTheme,
    Tooltip: tooltipTheme,
  },
}

export const mergedTheme = extendTheme(themeOverrides)

// TODO: Strongly type the theme?
export const useTheme = () => useThemeCore()
