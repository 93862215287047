// https://graphql.myshopify.com/api/2021-10/graphql.json

import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { ErrorLink, onError } from '@apollo/client/link/error'
import { API_URL } from '../../config/env'
import { logger } from '../../utils/logger'
import { apolloErrorToast } from '../utils'

const httpLink = new HttpLink({
  uri: `${API_URL}/graphql-shopify`,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('authToken')
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      // The localStorage hook stringifies a string so we need to parse it before using
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})
export const handleErrors: ErrorLink.ErrorHandler = ({
  graphQLErrors,
  networkError,
}) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      apolloErrorToast({
        status: 'error',
        title: 'Shopify Error',
        description: message,
      })
    })
  }
  if (networkError) {
    logger.log(`[Network error]: ${networkError}`)
    apolloErrorToast({
      status: 'error',
      title: 'Shopify Error',
      description: networkError.message,
    })
  }
}
const errorLink = onError(handleErrors)

const additiveLink = from([authLink, errorLink, httpLink])

export const client = new ApolloClient({
  link: additiveLink,
  cache: new InMemoryCache(),
})

export const getShopifyAdminClient = () => client
