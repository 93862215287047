import { isFunction } from '@bounty/utils'
import dlv from 'dlv'
import { forwardRef, ReactNode } from 'react'
import { FieldValues, useFormContext, UseFormReturn } from 'react-hook-form'
import { Input, InputGroup, InputProps } from '../../Chakra'
import { FastFormControl } from './FastFormControl'
import { FastFormElementProps } from './types'
import { getLabelFromName } from './utils'

export type FastFormDateInputBaseProps = Omit<InputProps, 'name'>

export const FastFormDateInputBase = forwardRef<
  any,
  FastFormDateInputBaseProps
>((props, ref) => {
  return <Input type="date" ref={ref} {...props} />
})

FastFormDateInputBase.displayName = 'FastFormDateInputBase'

export type FastFormDateInputProps<
  TFieldValues extends FieldValues = FieldValues,
> = FastFormElementProps<FastFormDateInputBaseProps, TFieldValues> & {
  children?: (props: UseFormReturn<TFieldValues, object>) => ReactNode
  leftElement?: ReactNode
  placeholder?: string
}

export const FastFormDateInput = <
  TFieldValues extends FieldValues = FieldValues,
>({
  label,
  name,
  helpText,
  labelProps,
  tooltipText,
  errorProps,
  children,
  leftElement,
  isRequired,
  isDisabled,
  formControlProps,
  formElementProps,
  placeholder,
  showLabel,
}: FastFormDateInputProps<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>()
  const {
    register,
    formState: { errors },
  } = methods
  const error = dlv(errors, name)

  return (
    <FastFormControl
      error={error}
      helpText={helpText}
      label={label ?? getLabelFromName(name)}
      labelProps={labelProps}
      tooltipText={tooltipText}
      errorProps={errorProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
      showLabel={showLabel}
      {...formControlProps}
    >
      {children != null && isFunction(children) ? (
        children(methods)
      ) : leftElement ? (
        <InputGroup>
          {leftElement}
          <FastFormDateInputBase
            placeholder={placeholder}
            pl={leftElement ? '8' : '4'}
            {...formElementProps}
            {...register(name)}
          />
        </InputGroup>
      ) : (
        <FastFormDateInputBase
          placeholder={placeholder}
          {...formElementProps}
          {...register(name)}
        />
      )}
    </FastFormControl>
  )
}
