import { useState, useEffect } from 'react'
import {
  Box,
  Skeleton,
  Text,
  Form,
  Button,
  FancySelect,
  useToast,
  Tooltip,
} from '@bounty/brands-design-system'
import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import {
  CategoriesAndTagsDocument,
  UpdateCategoriesAndTagsDocument,
} from '../../generated/backendGraphql'
import { useNavigate } from 'react-router-dom'
import { InfoButton } from '../InfoButton/InfoButton'

export type CategoriesAndTagsProps = {
  pathTo?: string
}

export const CategoriesAndTags = ({ pathTo }: CategoriesAndTagsProps) => {
  const toast = useToast()
  const navigate = useNavigate()
  const [selectedCategories, setSelectedCategories] = useState<
    readonly string[]
  >([])
  const [selectedTags, setSelectedTags] = useState<readonly string[]>([])

  const { loading, data } = useQueryBackend(CategoriesAndTagsDocument)
  const [updateCategoriesAndTags, { loading: UpdateCategoriesAndTagsLoading }] =
    useMutationBackend(UpdateCategoriesAndTagsDocument)

  useEffect(() => {
    if (data?.currentStore.categories) {
      setSelectedCategories(data?.currentStore.categories)
      setSelectedTags(data?.currentStore.tags)
    }
  }, [data])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const { data } = await updateCategoriesAndTags({
      variables: {
        categories: selectedCategories,
        tags: selectedTags,
      },
    })
    if (data && pathTo) {
      navigate(pathTo)
    } else if (data) {
      toast({
        title: 'Updated Categories and Tags',
        status: 'success',
      })
    }
  }
  return (
    <Box>
      {loading || !data ? (
        <Skeleton height="10" width="100%" mb="18" />
      ) : (
        <Box>
          <Form data-testid="categories-and-tags-form" onSubmit={handleSubmit}>
            <Text mb="2" fontWeight="semibold">
              Categories
            </Text>
            <Box mb="4" width="300px">
              <FancySelect
                defaultValue={data.currentStore.categories.map((category) => ({
                  label: data.storeCategories.find(
                    (storeCategory) => storeCategory.value === category,
                  )?.label,
                  value: category,
                }))}
                placeholder="Select Categories"
                options={data.storeCategories.map((category) => ({
                  label: category.label,
                  value: category.value,
                }))}
                isMulti
                closeMenuOnSelect={false}
                isSearchable
                isClearable
                isOptionDisabled={() => selectedCategories.length >= 5}
                onChange={(options) => {
                  const selectedOptions = options.map((option) => {
                    return option.value
                  })
                  setSelectedCategories(selectedOptions)
                }}
              />
              <Text p="2" color="gray.500">
                Select up to five.
              </Text>
            </Box>
            <Text fontWeight="semibold" mb="2">
              Tags{' '}
              <Tooltip
                label="
              Tags help creators find the brands who share their values.
            "
              >
                <InfoButton />
              </Tooltip>
            </Text>
            <Box mb="4" width="300px">
              <FancySelect
                defaultValue={data.currentStore.tags.map((tag) => ({
                  label: data.storeTags.find(
                    (storeTag) => storeTag.value === tag,
                  )?.label,
                  value: tag,
                }))}
                placeholder="Select Tags"
                options={data.storeTags.map((tag) => ({
                  label: tag.label,
                  value: tag.value,
                }))}
                isMulti
                closeMenuOnSelect={false}
                isSearchable
                isClearable
                onChange={(options) => {
                  const selectedOptions = options.map((option) => {
                    return option.value
                  })
                  setSelectedTags(selectedOptions)
                }}
              />
            </Box>
            <Button
              type="submit"
              variant="solid"
              event="Update Categories and Tags Clicked"
              isLoading={UpdateCategoriesAndTagsLoading}
              data-testid="categories-and-tags-save-button"
              flexGrow={0}
              alignSelf="flex-start"
            >
              Submit
            </Button>
          </Form>
        </Box>
      )}
    </Box>
  )
}
