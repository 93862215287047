import {
  ChakraGrid,
  ChakraGridItem,
  Divider,
  ChakraGridProps,
  Tile,
  Heading,
  Page,
} from '@bounty/brands-design-system'
import { ManualInstall } from './ManualInstall'
import { ConciergeInstall } from './ConciergeInstall'
import { AppBlocksInstall } from './AppBlocksInstall'
import { ProductSnippetPreview } from './ProductSnippetPreview'

type ProductSnippetInstallerProps = ChakraGridProps

export const ProductSnippetInstaller = ({
  ...rest
}: ProductSnippetInstallerProps) => {
  return (
    <Page
      name="User Experiences"
      alignItems="center"
      data-testid="user-experiences-page"
    >
      <ChakraGrid
        gap="4"
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(2, 1fr)"
        {...rest}
      >
        <ChakraGridItem colSpan={[2, 2, 1]}>
          <ManualInstall />
        </ChakraGridItem>
        <ChakraGridItem colSpan={[2, 2, 1]} rowSpan={2}>
          <Tile p="0" height="100%">
            <ProductSnippetPreview
              interiorContent={
                <Heading color="gray.500" as="h3" size="lg" textAlign="center">
                  Widget Preview
                </Heading>
              }
            />
          </Tile>
        </ChakraGridItem>
        <ChakraGridItem colSpan={[2, 2, 1]}>
          <ConciergeInstall />
        </ChakraGridItem>
      </ChakraGrid>
      <Divider my="12" />
      <AppBlocksInstall />
    </Page>
  )
}
