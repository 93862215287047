import { Box, BoxProps, Heading, Button, Text, Link } from '../Chakra'
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react'

type ErrorPageProps = BoxProps & {
  supportEmail: string
  appName: string
}

export const ErrorBoundary = ({
  children,
  supportEmail,
  appName,
  ...rest
}: ErrorPageProps) => {
  return (
    <SentryErrorBoundary
      fallback={({ error }) => {
        return (
          <Box
            height="100vh"
            width="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            overflow="hidden"
            {...rest}
          >
            <Box maxWidth="650px" px="8">
              <Heading as="h1" size="xl">
                An error occurred :(
              </Heading>
              <Text mb="6">
                We've been notified of the error and are working on a fix. If
                this is urgent please{' '}
                <Link
                  href={`mailto:${supportEmail}?subject=Urgent | ${appName} error`}
                  isExternal
                >
                  email us
                </Link>
                .
              </Text>
              <Text mb="8">
                <Text as="span" fontWeight="bold">
                  Error:
                </Text>{' '}
                {error.name} {error.message}
              </Text>
              <Button
                variant="outline"
                size="lg"
                event="Error Boundary Try Again Button Clicked"
                onClick={() => {
                  window.location.reload()
                }}
              >
                Try again
              </Button>
            </Box>
          </Box>
        )
      }}
    >
      {children}
    </SentryErrorBoundary>
  )
}
