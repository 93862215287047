import { useFastForm } from '@bounty/brands-design-system'
import { useDebounce } from 'react-use'
import { z } from 'zod'
import {
  useGiftsHistoryDispatch,
  useGiftsHistoryState,
} from '../../useGiftsHistory'

const giftsHistoryFiltersSchema = z.object({
  searchString: z.string().optional(),
})

export const GiftsHistoryTableSearch = () => {
  const { value } = useGiftsHistoryState()
  const { setValues } = useGiftsHistoryDispatch()

  const {
    Form,
    Input,
    methods: { watch },
  } = useFastForm({
    defaultValues: {
      searchString: value.params.searchString ?? '',
    },
    schema: giftsHistoryFiltersSchema,
  })

  const query = watch('searchString')

  useDebounce(
    () => {
      setValues((prev) => ({
        value: {
          ...prev.value,
          params: {
            ...prev.value.params,
            searchString: query,
            skip: 0,
          },
        },
      }))
    },
    300,
    [query],
  )

  return (
    <Form onSubmit={() => null}>
      <Input name="searchString" label={'Search by Creator'} />
    </Form>
  )
}
