import * as z from 'zod'
import { CreateOrderRESTAddressPayloadSchema } from './address'

const LineItemSchema = z.object({
  variant_id: z.number(),
  quantity: z.number(),
  price: z.number(),
  // required in 2022-07 api
  name: z.string(),
  title: z.string(),
})

export const CreateOrderRESTPayloadSchema = z
  .object({
    email: z.string(),
    note: z.string(),
    tags: z.string(), // comma separated string values e.g. "test,bounty,whatever"
    line_items: z.array(LineItemSchema).min(1), // needs at least 1 entry
    billing_address: CreateOrderRESTAddressPayloadSchema,
    shipping_address: CreateOrderRESTAddressPayloadSchema,
  })
  .strict()

export type CreateOrderRESTPayload = z.infer<
  typeof CreateOrderRESTPayloadSchema
>
