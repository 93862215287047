import {
  Box,
  Page,
  PageHeader,
  Skeleton,
  Tag,
  Grid,
} from '@bounty/brands-design-system'
import { ColDef } from 'ag-grid-community'
import { format } from 'date-fns'
import { useMemo } from 'react'
import { useQueryBackend } from '../../../../apollo/backend/hooks'
import {
  GiftOffersHistoryDocument,
  GiftOffersHistoryQuery,
  GiftOfferStatus,
} from '../../../../generated/backendGraphql'
import { CellRendererParams } from '../components/AddCreatorsTable/types'

type GiftOffersHistoryList = GiftOffersHistoryQuery['giftOffersByRefType']
type GiftOffersHistoryListItem = GiftOffersHistoryList[0]

const colorMap: Record<GiftOfferStatus, string> = {
  CLAIMED: 'primary.500',
  COMPLETED: 'success.500',
  OPEN: 'neutral.500',
  REJECTED: 'error.500',
}

const GiftOffersHistoryTable = ({
  giftOffersByRefType,
}: {
  giftOffersByRefType: GiftOffersHistoryList
}) => {
  const columnDefs = useMemo(
    (): ColDef<GiftOffersHistoryListItem>[] => [
      {
        headerName: 'Status',
        field: 'status',
        cellRenderer: (params: CellRendererParams) => {
          const status = params.value as GiftOfferStatus
          const background = colorMap[status]
          return (
            <Tag color="white" background={background} fontWeight="semibold">
              {status}
            </Tag>
          )
        },
      },
      {
        headerName: 'Gift',
        valueGetter: (params) => {
          return params.data?.storeGift?.name ?? ''
        },
      },
      {
        headerName: 'Date added',
        filter: 'agDateColumnFilter',
        field: 'createdAt',
        valueGetter: (params) => {
          return format(new Date(params.data!.createdAt), 'MM/dd/yyyy')
        },
      },
    ],
    [],
  )

  return (
    <Grid<GiftOffersHistoryListItem>
      showQuickSearch={false}
      rowData={[...giftOffersByRefType]}
      columnDefs={columnDefs}
      enableCellTextSelection={true}
    />
  )
}

export const GiftOffersHistoryPage = () => {
  const { data, loading } = useQueryBackend(GiftOffersHistoryDocument)

  return (
    <Page
      name="Gift Offer History"
      fixedHeight="all"
      fluid
      addBottomSpacing={false}
      paddingBottom="3rem"
      width="100%"
    >
      <PageHeader
        title="Gift Offers History"
        description="Gift offer history contains the status and history of all gift offers
          sent to creators."
        breadcrumbs={[
          {
            name: 'Creators',
            to: '/creators',
          },
          {
            name: 'Invite',
            to: '/creators/invite',
          },
          {
            name: 'Current',
            to: '',
            isCurrentPage: true,
          },
        ]}
      />

      {!loading && data ? (
        <GiftOffersHistoryTable
          giftOffersByRefType={data.giftOffersByRefType}
        />
      ) : (
        <Box>
          <Skeleton width="100%" height="250" />
        </Box>
      )}
    </Page>
  )
}
