import { FC, useMemo, useCallback, useRef } from 'react'
import { CellRendererSelectorFunc, ColDef, RowNode } from 'ag-grid-community'
import { useQueryBackend } from '../../../apollo/backend/hooks'
import {
  CreatorsNetworkSearchDocument,
  CreatorsNetworkSearchQuery,
} from '../../../generated/backendGraphql'
import {
  Avatar,
  Link,
  Spinner,
  Grid,
  Image,
  Button,
  Box,
  FancySelect,
  HStack,
} from '@bounty/brands-design-system'
import { useAnalytics } from '@bounty/web-analytics'
import { FavoriteCreatorButton } from '../../../components/FavoriteCreatorButton/FavoriteCreatorButton'
import {
  getLinkToSocialAccount,
  getUserProfileImage,
} from '../../../utils/externalContentLinksGetters'
import { getSocialMediaLogo } from '../../../utils/preferredSocialProfileUtils'
import { AgGridReact } from 'ag-grid-react'

export type CreatorsGridProps = {
  searchText: string
}

export type CreatorItem = CreatorsNetworkSearchQuery['networkCreatorsSearch'][0]
type CellRendererParams = Parameters<CellRendererSelectorFunc<CreatorItem>>[0]

export const BountyNetworkTable: FC<CreatorsGridProps> = (props) => {
  const { track } = useAnalytics()
  const gridRef = useRef<AgGridReact<CreatorItem>>(null)
  const platformRef = useRef('ALL')

  const columnDefs = useMemo(
    (): ColDef<CreatorItem>[] => [
      {
        headerName: '',
        width: 30,
        flex: 0,
        field: 'starred',
        suppressMovable: true,
        cellRenderer: (params: CellRendererParams) => {
          return (
            <FavoriteCreatorButton
              userId={params.data!.id}
              data={params.data!}
            />
          )
        },
        resizable: false,
      },
      {
        headerName: '',
        field: 'id',
        width: 80,
        flex: 0,
        resizable: false,
        suppressMovable: true,
        cellRenderer: (params: CellRendererParams) => {
          if (!params.data) {
            return null
          }
          return (
            <Avatar
              as={Link}
              name={params.data!.profileName}
              isExternal
              href={getLinkToSocialAccount(
                params.data?.profileName,
                params.data.accountType,
              )}
              boxSize="40px"
              src={getUserProfileImage(params.value, params.data.accountType)!}
            />
          )
        },
      },
      {
        headerName: 'Creator',
        field: 'profileName',
        cellRenderer: (params: CellRendererParams) => {
          if (!params.data) {
            return null
          }

          return (
            <Link
              isExternal={false}
              fontWeight="medium"
              href={
                getLinkToSocialAccount(
                  params.data?.profileName,
                  params.data.accountType,
                )!
              }
              textDecoration="underline"
              to={`./details-network/${params.data?.id}`}
              onClick={() => {
                track('Creator Table Profile Name Link Clicked', {
                  tikTokUsername: params.data!.profileName,
                })
              }}
            >
              {params.data!.profileName}
            </Link>
          )
        },
      },
      {
        headerName: 'Platforms',
        field: 'accountsLinked',
        cellRenderer: (params: CellRendererParams) => {
          if (!params.data || params.data.accountsLinked.length === 0)
            return 'N/A'
          return (
            <HStack spacing={'1'}>
              {params.data.accountsLinked.map((account) => {
                return (
                  <Image
                    boxSize={'5'}
                    objectFit={'contain'}
                    src={getSocialMediaLogo(account)}
                    key={`${params.data?.profileName}-${account}`}
                  />
                )
              })}
            </HStack>
          )
        },
      },
      {
        headerName: 'Followers',
        field: 'followerCount',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Bounties',
        field: 'numBounties',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Gifts Sent',
        field: 'numGiftOffers',
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Rating',
        field: 'averageRating',
        valueGetter: (params) => {
          return params.data && (params.data?.averageRating ?? 0) > 0
            ? `${params.data.averageRating}`
            : ''
        },
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: 'Joined On',
        filter: 'agDateColumnFilter',
        field: 'createdAt',
        valueGetter: (params) => {
          // default filter uses midnight as comparator
          const getMidnight = (d: Date) => new Date(d.setHours(0, 0, 0, 0))
          return getMidnight(new Date(params.data!.createdAt))
        },
        cellRenderer: (params: CellRendererParams) => {
          return new Date(params.data!.createdAt).toLocaleDateString()
        },
      },
      {
        headerName: 'Bio',
        field: 'bioDescription',
        cellStyle: {
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          padding: 0,
        },
      },
      {
        headerName: 'Creator Details',
        cellRenderer: (params: CellRendererParams) => {
          return (
            <Button
              size="sm"
              as={Link}
              isExternal={false}
              variant="outline"
              to={`./details/${params.data?.id}`}
              event={{
                eventName: 'Creator Table Network Creator View Details Clicked',
                profileName: params.data!.profileName,
              }}
            >
              View Details
            </Button>
          )
        },
      },
    ],
    [track],
  )

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      filter: true,
    }
  }, [])

  const { data: { networkCreatorsSearch = [] } = {}, loading } =
    useQueryBackend(CreatorsNetworkSearchDocument, {
      variables: {
        skip: 0,
        take: 2500,
        searchTerm: props.searchText,
      },
    })

  const externalFilterChanged = useCallback((newValue: string) => {
    platformRef.current = newValue

    if (gridRef.current) {
      gridRef.current.api.onFilterChanged()
    }
  }, [])

  const isExternalFilterPresent = useCallback((): boolean => {
    // if platform is not ALL, then we are filtering
    return platformRef.current !== 'ALL'
  }, [])

  const doesExternalFilterPass = useCallback(
    (node: RowNode<CreatorItem>): boolean => {
      if (node.data) {
        switch (platformRef.current) {
          case 'ALL':
            return Boolean(node.data)
          case 'INSTAGRAM':
            return Boolean(node.data.accountsLinked.indexOf('INSTAGRAM') > -1)
          case 'TIKTOK':
            return Boolean(node.data.accountsLinked.indexOf('TIKTOK') > -1)
          default:
            return true
        }
      }
      return true
    },
    [platformRef],
  )

  if (loading) return <Spinner size="xl" />

  return (
    <Grid<CreatorItem>
      gridRef={gridRef}
      containerProps={{ height: '100%' }}
      rowStyle={{ cursor: 'pointer' }}
      rowData={[...networkCreatorsSearch]}
      columnDefs={columnDefs}
      showQuickSearch={false}
      enableCellTextSelection={true}
      defaultColDef={defaultColDef}
      isExternalFilterPresent={isExternalFilterPresent}
      doesExternalFilterPass={doesExternalFilterPass}
      leftComponent={
        <Box width={['100%', '220px', '220px', '220px']}>
          <FancySelect
            defaultValue={{ value: 'ALL', label: 'All' }}
            placeholder="Filter by platform..."
            options={[
              {
                value: 'ALL',
                label: 'All',
              },
              {
                value: 'TIKTOK',
                label: 'TikTok',
              },
              {
                value: 'INSTAGRAM',
                label: 'Instagram',
              },
            ]}
            onChange={(option) => {
              if (option?.value) {
                externalFilterChanged(option.value)
              }
            }}
          />
        </Box>
      }
    />
  )
}
