import React, { useState } from 'react'
import { IHeaderParams } from 'ag-grid-community'
import {
  Box,
  Button,
  CaretDownIcon,
  CaretUpIcon,
} from '@bounty/brands-design-system'
import {
  useGiftsHistoryDispatch,
  useGiftsHistoryState,
} from '../../useGiftsHistory'
import { OrderByDirection } from '../../../../../generated/backendGraphql'
import { UnreachableCaseError } from '@bounty/utils'

export const GiftsHistoryTableHead = (props: IHeaderParams) => {
  const { value } = useGiftsHistoryState()
  const { setValues } = useGiftsHistoryDispatch()

  const [sortDir, setSortDir] = useState<OrderByDirection>(
    value.params.orderByDirection ?? 'DESC',
  )

  const getNewDir = (oldDir: OrderByDirection) => {
    switch (oldDir) {
      case 'DESC':
        return 'ASC'
      case 'ASC':
        return 'DESC'
      default:
        throw new UnreachableCaseError(oldDir)
    }
  }

  const updateSortDir = (sortDir: OrderByDirection) => {
    setValues((prev) => ({
      value: {
        ...prev.value,
        params: {
          ...prev.value.params,
          ...{ orderByDirection: getNewDir(sortDir), skip: 0 },
        },
      },
    }))
    setSortDir(getNewDir(sortDir))
  }

  const SortButton = ({ text }: { text: string }) => {
    return (
      <Button
        onClick={() => updateSortDir(sortDir)}
        event={'Gift Offer Performance Table Sort By Date'}
        variant={'unstyled'}
        display="flex"
        alignItems="center"
        gridGap={4}
      >
        {text} {sortDir === 'DESC' ? <CaretDownIcon /> : <CaretUpIcon />}
      </Button>
    )
  }

  return props.enableSorting ? (
    <Box>
      <SortButton text={props.displayName} />
    </Box>
  ) : (
    <Box>{props.displayName}</Box>
  )
}
