import { ReactNode } from 'react'
import { Box, BoxProps, Link, Flex } from '../../Chakra'
import { AppRoute } from '../../types'
import { Logo } from '../Logo'
import { NavItems } from './NavItems'

export type NavProps = {
  logoLinkTo: string
  showBetaLogo?: boolean
  assumeSpacing?: boolean
  centerComponent?: ReactNode
  footerComponent?: ReactNode
  items: AppRoute[]
} & BoxProps

export const LeftNav = ({
  items,
  showBetaLogo = false,
  footerComponent,
  logoLinkTo,
  ...rest
}: NavProps) => {
  return (
    <Box
      px="6"
      pt="12"
      pb="6"
      bg="neutral.50"
      borderRight="1px"
      borderColor="neutral.200"
      height="100%"
      overflow="scroll"
      {...rest}
    >
      <Flex height="100%" flexDir={'column'} justifyContent="space-between">
        <Box>
          <Box mb="16" ml="4" width="128px">
            <Link isExternal={false} to={logoLinkTo}>
              <Logo style={{ width: '100%' }} showBeta={showBetaLogo} />
            </Link>
          </Box>
          <NavItems items={items} />
        </Box>
        <Box>{footerComponent}</Box>
      </Flex>
    </Box>
  )
}
