import {
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Text,
  Tooltip,
  useFastForm,
  useToast,
} from '@bounty/brands-design-system'
import { AdvancedInvitesSchema } from '@bounty/schemas'
import { isNotNil } from '@bounty/utils'
import { useEffect, useState } from 'react'
import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import {
  AdvancedInvitesDocument,
  GenerateBulkOffersDocument,
} from '../../generated/backendGraphql'
import { InfoButton } from '../InfoButton/InfoButton'
import { motion, AnimatePresence } from 'framer-motion'

type LinkDisplayProps = {
  size?: 'sm' | 'md'
  url?: string
}
const LinkDisplay = ({ size, url }: LinkDisplayProps) => {
  const toast = useToast()

  const handleClickCopyLink = (url?: string) => {
    if (isNotNil(url) && url.length > 1) {
      navigator.clipboard.writeText(url)
      toast({
        status: 'success',
        title: 'Link Copied',
        description: url,
      })
    } else {
      toast({
        status: 'error',
        title: 'Sorry, Copy Link Failed',
        description: 'Please try again',
      })
    }
  }

  return (
    <Box
      display="grid"
      gridTemplateColumns="calc(100% - 118px - .5rem) 118px"
      gridGap={2}
      width="100%"
    >
      <Flex
        px="3"
        h={size === 'sm' ? 8 : 10}
        width="100%"
        fontSize="sm"
        align="center"
        background="neutral.200"
        borderRadius="md"
        whiteSpace="nowrap"
        title={url}
      >
        <Text as="span" overflow="hidden" textOverflow="ellipsis">
          {url ?? ''}
        </Text>
      </Flex>
      <Button
        event="Share Store with Creator URL Click to Copy"
        onClick={() => handleClickCopyLink(url)}
        size={size}
        minWidth="112px"
        variant="outline"
      >
        Copy Link
      </Button>
    </Box>
  )
}

const ShareLinkTooltip = () => {
  return (
    <Tooltip label="Generates a one time gift link that is redeemable by anyone. People that redeem are added to your community.">
      <InfoButton color="primary.500" />
    </Tooltip>
  )
}

export const QuickShareLinkModule = () => {
  const toast = useToast()

  const { data: { enabledStoreGifts = [] } = {} } = useQueryBackend(
    AdvancedInvitesDocument,
  )

  const [generateBulkOffers, { loading: generateBulkOffersLoading }] =
    useMutationBackend(GenerateBulkOffersDocument)

  const {
    Form,
    Select,
    SubmitButton,
    methods: { setValue },
  } = useFastForm({
    schema: AdvancedInvitesSchema,
    defaultValues: {
      giftId: '',
      count: 1,
    },
  })

  useEffect(() => {
    setValue('giftId', enabledStoreGifts[0]?.id)
  }, [enabledStoreGifts, setValue])

  const [giftLink, setGiftLink] = useState('')

  return (
    <Flex w="100%" flexGrow={1} direction="column">
      <Heading mb="2" fontSize="xl">
        One Time Gift <ShareLinkTooltip />
      </Heading>
      <Form
        onSubmit={async ({ giftId }) => {
          const { data } = await generateBulkOffers({
            variables: {
              giftId,
              count: 1,
            },
          })
          if (
            isNotNil(data) &&
            data?.generateBulkOffers?.offers[0]?.link?.length > 0
          ) {
            setGiftLink(data?.generateBulkOffers.offers[0].link)

            if (data?.generateBulkOffers.offers[0].link.length > 1) {
              navigator.clipboard.writeText(
                data?.generateBulkOffers.offers[0].link,
              )
              toast({
                status: 'success',
                title: 'Link Copied',
                description: data?.generateBulkOffers.offers[0].link,
              })
            } else {
              toast({
                status: 'error',
                title: 'Sorry, Copy Link Failed',
                description: 'Please try again',
              })
            }
          } else {
            toast({
              status: 'error',
              title: 'Sorry, Link Generation Failed',
              description: 'Please try again',
            })
          }
        }}
      >
        <Box
          display="grid"
          gridTemplateColumns="calc(100% - 118px - .5rem) 118px"
          gridGap={2}
        >
          <Flex flex={1} mb={-6}>
            <Select
              showLabel={false}
              name="giftId"
              placeholder="Loading gifts..."
              options={enabledStoreGifts.map((gift) => ({
                label: gift.name,
                value: gift.id,
              }))}
              helpText={
                <Text color="gray.600" fontSize="sm">
                  Select a gift for the creator or{' '}
                  <Link
                    isExternal={false}
                    to="new-gift-from-dashboard"
                    textDecoration="underline"
                    color="inherit"
                  >
                    create one
                  </Link>
                  .
                </Text>
              }
            />
          </Flex>
          <SubmitButton
            alignSelf="top"
            event="Quick Share Link Module Get Gift Shortlink"
            isLoading={generateBulkOffersLoading}
            minWidth="112px"
          >
            Generate
          </SubmitButton>
        </Box>
      </Form>

      <AnimatePresence initial={false}>
        {giftLink && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
          >
            <Flex direction="column">
              {giftLink && (
                <motion.section
                  key="content"
                  initial="collapsed"
                  animate="open"
                  exit="collapsed"
                  variants={{
                    open: { opacity: 1, height: 'auto' },
                    collapsed: { opacity: 0, height: 'calc(40px + 1.5rem)' },
                  }}
                >
                  <Flex flex={1} pt={6}>
                    <LinkDisplay url={giftLink} size="md" />
                  </Flex>
                </motion.section>
              )}
            </Flex>
          </motion.section>
        )}
      </AnimatePresence>
    </Flex>
  )
}
