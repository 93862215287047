import * as z from 'zod'

export const productSnippetConfigBaseSchema = z
  .object({
    ctaFullWidth: z.boolean().optional().nullable(),
    showBrandingOnWidget: z.boolean().optional().nullable(),
    calculatedPayout: z.number().optional().nullable(),
  })
  .strict()

export type ProductSnippetBaseConfig = z.infer<
  typeof productSnippetConfigBaseSchema
>

export const productSnippetConfigCssSchema = z.object({
  ctaSpacingTop: z.string().optional().nullable(),
  ctaSpacingBottom: z.string().optional().nullable(),
  ctaSpacingLeft: z.string().optional().nullable(),
  ctaSpacingRight: z.string().optional().nullable(),
  ctaFontSize: z.string().optional().nullable(),
  ctaBackgroundColor: z.string().optional().nullable(),
  ctaHeaderFontColor: z.string().optional().nullable(),
  ctaBodyFontColor: z.string().optional().nullable(),
  ctaBorderRadius: z.string().optional().nullable(),
  ctaBorderColor: z.string().optional().nullable(),
  ctaBackgroundHover: z.string().optional().nullable(),
})

export type ProductSnippetCssConfig = z.infer<
  typeof productSnippetConfigCssSchema
>

export const productSnippetConfigSchema = productSnippetConfigBaseSchema.merge(
  productSnippetConfigCssSchema,
)

export type ProductSnippetConfig = z.infer<typeof productSnippetConfigSchema>

export const thankYouSnippetConfigBaseSchema = z
  .object({
    imgLink: z.string().optional().nullable(),
    thankYouFloatyImage1ImgLink: z.string().optional().nullable(),
    heroFloatyText1: z.string().optional().nullable(),
    buttonText: z.string().optional().nullable(),
    blocks: z.array(z.any()).optional().nullable(),
    showBrandingOnWidget: z.boolean().optional().nullable(),
    calculatedPayout: z.number().optional().nullable(),
  })
  .strict()

export type ThankYouSnippetBaseConfig = z.infer<
  typeof thankYouSnippetConfigBaseSchema
>

export const thankYouSnippetConfigCssSchema = z
  .object({
    ctaSpacingTop: z.string().optional().nullable(),
    ctaSpacingBottom: z.string().optional().nullable(),
    ctaSpacingLeft: z.string().optional().nullable(),
    ctaSpacingRight: z.string().optional().nullable(),
    ctaFontSize: z.string().optional().nullable(),
    ctaBackgroundColor: z.string().optional().nullable(),
    ctaHeaderFontColor: z.string().optional().nullable(),
    ctaBodyFontColor: z.string().optional().nullable(),
    ctaBorderRadius: z.string().optional().nullable(),
    ctaBorderColor: z.string().optional().nullable(),
    primaryColor: z.string().optional().nullable(),
    buttonFontColor: z.string().optional().nullable(),
  })
  .strict()

export type ThankYouSnippetCssConfig = z.infer<
  typeof thankYouSnippetConfigCssSchema
>

export const thankYouSnippetConfigSchema =
  thankYouSnippetConfigBaseSchema.merge(thankYouSnippetConfigCssSchema)

export type ThankYouSnippetConfig = z.infer<typeof thankYouSnippetConfigSchema>

export const landingPageSnippetConfigBaseSchema = z
  .object({
    heroImgLink: z.string().optional().nullable(),
    heroFloatyImage1ImgLink: z.string().optional().nullable(),
    heroFloatyImage2ImgLink: z.string().optional().nullable(),
    heroFloatyText1: z.string().optional().nullable(),
    heroFloatyText2: z.string().optional().nullable(),
    showBrandingOnWidget: z.boolean().optional().nullable(),
  })
  .strict()

export type LandingPageSnippetBaseConfig = z.infer<
  typeof landingPageSnippetConfigBaseSchema
>

export const landingPageSnippetConfigCssSchema = z.object({
  primaryColor: z.string().optional().nullable(),
  accentColor: z.string().optional().nullable(),
  blockBackgroundColor: z.string().optional().nullable(),
})

export type LandingPageSnippetCssConfig = z.infer<
  typeof landingPageSnippetConfigCssSchema
>

export const landingPageSnippetConfigSchema =
  landingPageSnippetConfigBaseSchema.merge(landingPageSnippetConfigCssSchema)

export type LandingPageSnippetConfig = z.infer<
  typeof landingPageSnippetConfigSchema
>
