// Wrappers of common components to make them framer-motion friendly
import { HTMLMotionProps, motion } from 'framer-motion'
import { Tile, TileProps } from './Tile'
import { Box, BoxProps, Avatar, AvatarProps } from '../Chakra'

export type MotionBoxProps = Omit<
  BoxProps,
  'transition' | 'style' | 'onDragStart' | 'onDragEnd' | 'onDrag'
> &
  Pick<
    HTMLMotionProps<'div'>,
    | 'style'
    | 'animate'
    | 'transition'
    | 'initial'
    | 'exit'
    | 'variants'
    | 'onAnimationStart'
    | 'onDragStart'
    | 'onDragEnd'
    | 'onDrag'
  >
// @ts-expect-error - Please add details why ignored
export const MotionBox = motion<MotionBoxProps>(Box)

export type MotionTileProps = Omit<
  TileProps,
  'transition' | 'style' | 'onDragStart' | 'onDragEnd' | 'onDrag'
> &
  Pick<
    HTMLMotionProps<'div'>,
    | 'style'
    | 'animate'
    | 'transition'
    | 'initial'
    | 'exit'
    | 'variants'
    | 'onAnimationStart'
    | 'onDragStart'
    | 'onDragEnd'
    | 'onDrag'
  >
// @ts-expect-error - Please add details why ignored
export const MotionTile = motion<MotionTileProps>(Tile)

export type MotionAvatarProps = Omit<
  AvatarProps,
  'transition' | 'style' | 'onDragStart' | 'onDragEnd' | 'onDrag'
> &
  Pick<
    HTMLMotionProps<'div'>,
    | 'style'
    | 'animate'
    | 'transition'
    | 'initial'
    | 'exit'
    | 'variants'
    | 'onAnimationStart'
    | 'onDragStart'
    | 'onDragEnd'
    | 'onDrag'
  >
// @ts-expect-error - Please add details why ignored
export const MotionAvatar = motion<MotionAvatarProps>(Avatar)
