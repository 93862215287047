import {
  Alert,
  AlertProps,
  Link,
  AlertTitle,
  AlertDescription,
} from '@bounty/brands-design-system'
import { useQueryBackend } from '../apollo/backend/hooks'
import { NoEmbedsConfiguredDocument } from '../generated/backendGraphql'
import { isNil } from '@bounty/utils'

type NoEmbedsConfiguredProps = AlertProps

export const NoEmbedsConfigured = ({ ...rest }: NoEmbedsConfiguredProps) => {
  const { data, loading } = useQueryBackend(NoEmbedsConfiguredDocument)

  if (loading || isNil(data)) {
    return null
  }

  const embeds = data.currentStore.embeds
  const onlyPixel = embeds.length === 1 && embeds[0].code === 'PIXEL'
  if (!onlyPixel && embeds.length > 0) {
    return null
  }

  return (
    <Alert flexShrink="0" mb="6" status="warning" {...rest}>
      <AlertTitle>
        Your store does not have any enabled user experiences.
      </AlertTitle>
      <AlertDescription>
        Go to&nbsp;
        <Link isExternal={false} to="/user-experiences">
          user experiences
        </Link>
        &nbsp;to activate placements.
      </AlertDescription>
    </Alert>
  )
}
