import { useCallback, useEffect, useMemo } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalProps,
  Text,
  Box,
  ModalFooter,
  Button,
  PageSkeleton,
  ListItem,
  UnorderedList,
  Flex,
} from '@bounty/brands-design-system'

type LatestUpdatesModalProps = Omit<ModalProps, 'children'>

export const LatestUpdatesModal = ({
  onClose: onCloseProp,
  ...rest
}: LatestUpdatesModalProps) => {
  const onClose = useCallback(() => {
    onCloseProp()
  }, [onCloseProp])

  const announcements = useMemo(
    () => [
      {
        title: 'Briefs are now called “Bounties”',
        description: (
          <UnorderedList paddingLeft="1">
            <ListItem
              css={{ '::marker': { fontSize: '12px' } }}
              fontWeight="bold"
            >
              You can create and publish multiple Bounties (FKA Briefs) in your
              Bounties tab.{' '}
            </ListItem>
            {/* <ListItem
              css={{ '::marker': { fontSize: '12px' } }}
              fontWeight="bold"
            >
              When you publish a new Bounty, you can schedule a notification to
              notify different segments of your creators about the campaign
            </ListItem> */}
            <ListItem
              css={{ '::marker': { fontSize: '12px' } }}
              fontWeight="bold"
            >
              The name of the Bounty is now shown to the creators{' '}
              <Text as="span" fontWeight="regular">
                (Old briefs will just show your brand’s name). So you can get
                creative with your campaigns when you name your Bounty “Grocery
                trip” “GRWM” etc.
              </Text>
            </ListItem>
          </UnorderedList>
        ),
      },
      {
        title: 'Storefront is now called “Brand Page”',
        description: (
          <UnorderedList paddingLeft="1">
            <ListItem
              css={{ '::marker': { fontSize: '12px' } }}
              fontWeight="bold"
            >
              Instead of featured products, your brand page now shows all your
              active Bounties.{' '}
              <Text as="span" fontWeight="regular">
                Creators can pick the Bounty that they want to create
              </Text>
            </ListItem>
            <ListItem
              css={{ '::marker': { fontSize: '12px' } }}
              fontWeight="bold"
            >
              If you don’t want creators to be able to see a Bounty, just set it
              to “inactive” in the Bounties tab.
            </ListItem>
          </UnorderedList>
        ),
      },
      {
        title: 'Configure your retailers and store locator in “Retailers” tab',
        description: (
          <UnorderedList paddingLeft="1">
            <ListItem
              css={{ '::marker': { fontSize: '12px' } }}
              fontWeight="bold"
            >
              Add your retailers and store locator URL{' '}
              <Text as="span" fontWeight="regular">
                in the “Retailers” tab.
              </Text>
            </ListItem>

            <ListItem
              css={{ '::marker': { fontSize: '12px' } }}
              fontWeight="bold"
            >
              If you want content for a specific retailer
              <Text as="span" fontWeight="regular">
                , just create a new Bounty named after that retailer and provide
                instructions! Your creators will be notified when you publish
                the Bounty.
              </Text>
            </ListItem>
          </UnorderedList>
        ),
      },
    ],
    [],
  )

  useEffect(() => {
    if (announcements) {
      localStorage.setItem(
        'latestSeenUpdateDate',
        new Date('2023-11-27T00:00:00Z').toISOString(),
      )
    }
  }, [announcements])

  if (!announcements) return <PageSkeleton />

  return (
    <Modal
      size="xl"
      {...rest}
      onClose={() => {
        onClose()
      }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent minWidth="90vw" maxHeight="80vh">
        <ModalHeader
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          columnGap="2"
        >
          <Text fontFamily="heading">Latest updates! </Text>
          <Text fontFamily="heading" fontWeight="regular">
            (Create your own campaigns with Bounties!)
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowWrap="anywhere" overflowY="auto">
          <Box>
            {announcements.map(({ title, description }, index: number) => (
              <Flex
                direction="column"
                padding="3"
                bg="neutral.200"
                borderRadius="lg"
                mb="3"
              >
                <Text
                  fontFamily="body"
                  fontSize="md"
                  fontWeight="bold"
                  color="primary.500"
                >{`${index + 1}. ${title}`}</Text>
                {/* Rich Text usage */}
                <Box mt="2">{description}</Box>
              </Flex>
            ))}
          </Box>
        </ModalBody>
        <ModalFooter
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text fontSize="md" color="neutral.600">
            Have suggestions or feature ideas? email abe@bounty.co
          </Text>
          <Button
            size="sm"
            event="Latest Updates Action Button Clicked"
            onClick={() => onClose()}
          >
            Let's go
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
