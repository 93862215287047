import { useNavigate } from 'react-router-dom'

import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  LeadingIcon,
  Skeleton,
  Text,
  useDisclosure,
  useFastForm,
  VStack,
  WarningIcon,
} from '@bounty/brands-design-system'
import { useParamsOrThrow } from '@bounty/hooks'
import { createBriefInput, updateBriefInput } from '@bounty/schemas'
import { prettyFloatingDate } from '@bounty/utils'
import { useAnalytics } from '@bounty/web-analytics'

import {
  useMutationBackend,
  useQueryBackend,
} from '../../../apollo/backend/hooks'
import {
  AddBriefDocument,
  BriefDetailsDocument,
  BriefsTableDocument,
  CreatorNotifyType,
  UpdateBriefDocument,
} from '../../../generated/backendGraphql'
import { CreateBountyConfirmModal } from '../CreateBountyConfirmModal/CreateBountyConfirmModal'

type CreatorNotifyTypeOption = {
  value: CreatorNotifyType
  label: string
}

const notifyTypeOptions: CreatorNotifyTypeOption[] = [
  {
    value: 'CREATORS_WITH_SOCIAL',
    label: 'Creators with verified social account',
  },
  {
    value: 'CREATORS_WITH_VIDEO',
    label: 'Creators with at least 1 video',
  },
  {
    value: 'CREATORS_FAVORITED',
    label: 'Favorited creators',
  },
]

export const visibilityOptions = [
  {
    value: 'PUBLIC',
    label: 'Public',
  },
  {
    value: 'PRIVATE',
    label: 'Private',
  },
  {
    value: 'INACTIVE',
    label: 'Inactive',
  },
] as const

export const fillBriefFieldsByVisibility = (
  visibility: VisibilityValueType,
) => {
  if (visibility === 'PRIVATE') {
    return {
      isActive: true,
      isPublic: false,
    }
  }

  if (visibility === 'PUBLIC') {
    return {
      isActive: true,
      isPublic: true,
    }
  }

  return {
    isActive: false,
    isPublic: false,
  }
}

export type VisibilityValueType = typeof visibilityOptions[number]['value']

export const AddBrief = () => {
  const navigate = useNavigate()
  const { track } = useAnalytics()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const {
    Form,
    Input,
    Textarea,
    SubmitButton,
    NumberInput,
    DateInput,
    Select,
    Switch,
    methods: {
      watch,
      formState: { isDirty },
    },
  } = useFastForm({
    schema: createBriefInput,
    defaultValues: {
      name: '',
      content: '',
      videoUrl: '',
      minPayout: 10,
      visibility: 'PUBLIC',
      shouldNotifyUsers: true,
      notifyType: notifyTypeOptions[0].value,
      notifyDate: prettyFloatingDate(new Date().toISOString(), 'yyyy-MM-dd'),
    },
  })

  const [addBrief, { loading }] = useMutationBackend(AddBriefDocument, {
    refetchQueries: [{ query: BriefsTableDocument }],
  })

  const watchValues = watch()

  return (
    <>
      <Form
        data-testid="create-new-brief-form"
        onSubmit={async () => {
          if (watchValues.shouldNotifyUsers) {
            onOpen()
            return
          }
          return handleSubmit()
        }}
      >
        <Box backgroundColor={'neutral.50'} mb="6">
          <Input
            name="name"
            label="Name (Public)"
            placeholder="Enter a name..."
            formElementProps={{
              backgroundColor: 'white',
            }}
            helpText={'Creators will be able to see this.'}
          />
          <Textarea
            name="content"
            label="Brief"
            rows={10}
            maxCharacterLimit={400}
            formElementProps={{
              backgroundColor: 'white',
            }}
            placeholder="Share your experience with the product. Talking to the camera or including a voiceover performs best."
          />
          <Input
            name="videoUrl"
            label="Example Video"
            formElementProps={{
              backgroundColor: 'white',
            }}
            helpText={
              'Help creators make the content you want to see by providing an example video.'
            }
          />
          <NumberInput
            name="minPayout"
            label="Minimum Payout ($)"
            helpText={
              'Help creators make the content you want to see by providing an example video.'
            }
          />
        </Box>
        <Flex
          direction="column"
          borderTop={'1px solid'}
          borderColor="neutral.200"
          py="4"
          gap="6"
        >
          <Select
            formControlProps={{
              mb: 0,
            }}
            name="visibility"
            label="Set Visibility"
            options={visibilityOptions}
          />
          <DisplayAlertByBriefVisibility
            visibility={watchValues.visibility as VisibilityValueType}
          />
          <Flex
            direction="column"
            border={'1px solid'}
            borderColor="neutral.200"
            borderRadius="xl"
            p="4"
            gap="2"
          >
            <Flex alignItems="center" gap="2">
              <Switch
                name="shouldNotifyUsers"
                label="Notify Creators"
                formControlProps={{
                  marginBottom: '-4px',
                }}
              />
            </Flex>
            {watchValues.shouldNotifyUsers && (
              <Flex direction="column" width="400px">
                <Select
                  name="notifyType"
                  label=""
                  placeholder="Bulk Action"
                  options={notifyTypeOptions}
                />
                <DateInput
                  label="Date"
                  name="notifyDate"
                  helpText="Will be sent at optimal time on date selected"
                />
              </Flex>
            )}
          </Flex>
          <Flex width="full" justifyContent="flex-end" gap="2">
            <SubmitButton
              event="Create New Bounty Form Submitted"
              data-testid="create-new-brief-submit-button"
              isLoading={loading}
              isDisabled={!isDirty}
            >
              Create Bounty
            </SubmitButton>
          </Flex>
        </Flex>
      </Form>
      <CreateBountyConfirmModal
        isOpen={isOpen}
        onClose={onClose}
        bountyName={watchValues.name}
        notifyType={watchValues.notifyType}
        notifyDate={watchValues.notifyDate}
        onConfirm={() => handleSubmit()}
      />
    </>
  )

  async function handleSubmit() {
    const { notifyType, notifyDate, visibility, ...input } = watchValues

    const updatedInput = {
      ...input,
      // Pass undefined if 'shouldNotifyUsers' is false
      notifyType: watchValues.shouldNotifyUsers ? notifyType : undefined,
      notifyDate: watchValues.shouldNotifyUsers ? notifyDate : undefined,
      ...fillBriefFieldsByVisibility(visibility as VisibilityValueType),
    }

    track('New Bounty Created', { input: updatedInput })
    await addBrief({
      variables: {
        input: updatedInput,
      },
    })

    navigate(`../`)
  }
}

export const UpdateBriefForm = ({
  id,
  defaultValues,
}: {
  id: string
  defaultValues: {
    name: string
    content: string
    videoUrl: string
    minPayout: number
    visibility: VisibilityValueType
  }
}) => {
  const navigate = useNavigate()
  const { track } = useAnalytics()
  const {
    Form,
    Input,
    Textarea,
    Select,
    SubmitButton,
    methods: {
      watch,
      formState: { isDirty },
    },
  } = useFastForm({
    schema: updateBriefInput,
    defaultValues: {
      id,
      name: defaultValues.name,
      content: defaultValues.content,
      videoUrl: defaultValues.videoUrl,
      visibility: defaultValues.visibility,
    },
  })

  const [updateBrief, { loading }] = useMutationBackend(UpdateBriefDocument, {
    update(cache, { data }) {
      cache.modify({
        id: `Brief:${data?.updateBrief?.id}`,
        fields: {
          // Todo: check with arjay if this is correct
          isActive: () => data?.updateBrief?.isActive ?? false,
          isPublic: () => data?.updateBrief?.isPublic ?? false,
        },
      })
    },
  })

  const watchValues = watch()

  return (
    <Form
      data-testid="update-new-brief-form"
      onSubmit={async (input) => {
        track('Bounty Updated', { input })
        await updateBrief({
          variables: {
            input: {
              id: id,
              content: input.content,
              name: input.name,
              videoUrl: input.videoUrl,
              ...fillBriefFieldsByVisibility(input.visibility),
            },
          },
        })

        navigate('../')
      }}
    >
      <Box backgroundColor={'neutral.50'} mb="6" pb="6">
        <Input
          name="name"
          label="Name (Public)"
          placeholder="Enter a name..."
          formElementProps={{
            backgroundColor: 'white',
          }}
          helpText={'Creators will be able to see this.'}
        />
        <Textarea
          name="content"
          label="Brief"
          rows={10}
          maxCharacterLimit={400}
          formElementProps={{
            backgroundColor: 'white',
          }}
          placeholder="Share your experience with the product. Talking to the camera or including a voiceover performs best."
        />
        <Input
          name="videoUrl"
          label="Example Video"
          formElementProps={{
            backgroundColor: 'white',
          }}
          helpText={
            'Help creators make the content you want to see by providing an example video.'
          }
        />
        <Select
          name="visibility"
          label="Set Visibility"
          options={visibilityOptions}
        />
        <DisplayAlertByBriefVisibility
          visibility={watchValues.visibility as VisibilityValueType}
        />
        <Text mt="3">
          Minimum payment:{' '}
          {defaultValues.minPayout ? `$${defaultValues.minPayout}` : 'N/A'}
        </Text>
      </Box>
      <Flex
        justifyContent="flex-end"
        borderTop={'1px solid'}
        borderColor="neutral.200"
        py="4"
      >
        <SubmitButton
          event="Updated Bounty Form Submitted"
          data-testid="update-brief-submit-button"
          isLoading={loading}
          isDisabled={!isDirty}
        >
          Update Bounty
        </SubmitButton>
      </Flex>
    </Form>
  )
}

export const UpdateBrief = () => {
  const { briefId } = useParamsOrThrow(['briefId'])
  const { data, loading } = useQueryBackend(BriefDetailsDocument, {
    variables: { id: briefId },
  })

  if (loading || !data)
    return (
      <VStack>
        <Skeleton height="3rem" width="100%" />
        <Skeleton height="3rem" width="100%" />
        <Skeleton height="3rem" width="100%" />
      </VStack>
    )

  return (
    <UpdateBriefForm
      id={data.brief.id}
      defaultValues={{
        content: data.brief.content,
        name: data.brief.name,
        videoUrl: data.brief.videoUrl ?? '',
        minPayout: data.brief.minPayout ?? 0,
        visibility: data.brief.isActive
          ? data.brief.isPublic
            ? 'PUBLIC'
            : 'PRIVATE'
          : 'INACTIVE',
      }}
    />
  )
}

export type DisplayAlertByBriefVisibilityProps = {
  visibility: VisibilityValueType
}

export const DisplayAlertByBriefVisibility = ({
  visibility,
}: DisplayAlertByBriefVisibilityProps) => {
  if (visibility === 'PUBLIC')
    return (
      <Alert colorScheme="success" showIcon={false}>
        <Box display="flex">
          <AlertIcon as={LeadingIcon} />
          <Box w="100%">
            <AlertTitle>This Bounty will be public</AlertTitle>
            <AlertDescription>
              Anyone can participate in a public Bounty. It will be join-able
              via signup widgets, pages, and your listing in our directory
            </AlertDescription>
          </Box>
        </Box>
      </Alert>
    )

  if (visibility === 'PRIVATE')
    return (
      <Alert colorScheme="warning">
        <AlertTitle>This Bounty will be private</AlertTitle>
        <AlertDescription>
          A private Bounty can only be joined via the direct link. Use for
          Bounties you only want to share with specific lists
        </AlertDescription>
      </Alert>
    )

  if (visibility === 'INACTIVE')
    return (
      <Alert colorScheme="error" showIcon={false}>
        <Box display="flex" gap="3">
          <AlertIcon as={WarningIcon} />
          <Box w="100%">
            <AlertTitle>This Bounty will be inactive</AlertTitle>
            <AlertDescription>
              An inactive Bounty cannot be joined. You can always change this
              later
            </AlertDescription>
          </Box>
        </Box>
      </Alert>
    )

  return null
}
