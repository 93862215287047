import { z } from 'zod'
import { CreateOrderRESTAddressPayloadSchema } from '../shopify/create-order/address'

export const redeemGiftOfferProductSchema = z.object({
  email: z.string().email(),
  address: CreateOrderRESTAddressPayloadSchema,
  key: z.string(),
  variants: z.array(z.object({ productId: z.string(), variantId: z.string() })),
})

export type RedeemGiftOfferProduct = z.infer<
  typeof redeemGiftOfferProductSchema
>

export const redeemGiftOfferBountySchema = z.object({ key: z.string() })
