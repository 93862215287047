import { ReactNode, useEffect, useRef } from 'react'
import { Box, BoxProps, Flex, useDimensions, Button, Link } from '../../Chakra'
import { AppRoute } from '../../types'
import { motion, SVGMotionProps, useCycle, Variants } from 'framer-motion'
import styled from '@emotion/styled'
import { useLocation } from 'react-router-dom'
import { Logo } from '../Logo'
import { NavItems } from './NavItems'

const Path = (props: SVGMotionProps<SVGPathElement>) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
)

export type MobileNavMenuToggleProps = {
  toggle: () => void
}

export const MobileNavMenuToggle = ({ toggle }: MobileNavMenuToggleProps) => (
  <Button
    onClick={toggle}
    display="block"
    variant="unstyled"
    minWidth="auto"
    height="auto"
    boxShadow="none !important"
    event="Mobile Nav Menu Toggle Clicked"
  >
    <svg width="23" height="23" viewBox="0 0 23 23">
      <Path
        variants={{
          closed: { d: 'M 2 2.5 L 20 2.5' },
          open: { d: 'M 3 16.5 L 17 2.5' },
        }}
      />
      <Path
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        variants={{
          closed: { d: 'M 2 16.346 L 20 16.346' },
          open: { d: 'M 3 2.5 L 17 16.346' },
        }}
      />
    </svg>
  </Button>
)

const navigationVariants: Variants = {
  open: {
    y: 0,
    transition: {
      stiffness: 100,
      staggerChildren: 0.07,
      delayChildren: 0.2,
    },
  },
  closed: {
    y: '-100%',
    transition: {
      delay: 0.4,
      stiffness: 100,
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
}

type MobileNavNavigationProps = {
  items: AppRoute[]
  mobileNavHeight: string
  topNavMenuComponent?: ReactNode
  bottomNavMenuComponent?: ReactNode
}

const StyledNavigation = styled(motion.ul)<{ height: string }>`
  padding-top: 2rem;
  position: absolute;
  background: white;
  left: 0;
  right: 0;
  top: ${(props) => props.height};
  height: calc(100vh - ${(props) => props.height});
  overflow-y: auto;
`

export const MobileNavNavigation = ({
  items,
  mobileNavHeight,
  topNavMenuComponent = null,
  bottomNavMenuComponent = null,
}: MobileNavNavigationProps) => (
  <StyledNavigation variants={navigationVariants} height={mobileNavHeight}>
    {topNavMenuComponent != null && (
      <Flex justifyContent="center" mb="6">
        <Box>{topNavMenuComponent}</Box>
      </Flex>
    )}
    <Box px="4">
      <Box mb="50px">
        <NavItems items={items} />
      </Box>
      <Box borderTop="1px" borderColor="neutral.200">
        {bottomNavMenuComponent != null && bottomNavMenuComponent}
      </Box>
    </Box>
  </StyledNavigation>
)

type MobileNavProps = BoxProps & {
  logoLinkTo: string
  mainItems?: AppRoute[]
  showLogo?: boolean
  showBetaLogo?: boolean
  centerNavComponent?: ReactNode
  rightNavComponent?: ReactNode
  mobileNavHeight: string
  topNavMenuComponent?: ReactNode
  bottomNavMenuComponent?: ReactNode
}

const StyledNav = styled(motion(Box))`
  position: sticky;
  top: 0px;
  z-index: 100;
`

export const MobileNav = ({
  mainItems,
  logoLinkTo,
  centerNavComponent = null,
  rightNavComponent = null,
  showLogo = true,
  showBetaLogo = false,
  mobileNavHeight,
  topNavMenuComponent = null,
  bottomNavMenuComponent = null,
  ...rest
}: MobileNavProps) => {
  const [isOpen, toggleOpen] = useCycle(false, true)
  const containerRef = useRef(null)
  const dimensions = useDimensions(containerRef)
  const location = useLocation()

  // Close nav on location changes
  useEffect(() => {
    toggleOpen(2)
  }, [location, toggleOpen])

  return (
    <StyledNav
      initial={false}
      animate={isOpen ? 'open' : 'closed'}
      custom={dimensions?.borderBox.height}
      ref={containerRef}
      {...rest}
    >
      <Flex
        width="100%"
        height={mobileNavHeight}
        px="4"
        alignItems="center"
        justifyContent="space-between"
        backgroundColor="white"
        position="relative"
        zIndex={30}
      >
        <Box flex="1">
          {showLogo && (
            <Link isExternal={false} to={logoLinkTo}>
              <Logo
                style={{
                  height: `calc(${mobileNavHeight} - 20px`,
                }}
                showBeta={showBetaLogo}
              />
            </Link>
          )}
        </Box>
        <Box flex="1">{centerNavComponent}</Box>
        <Flex flex="1" justifyContent="flex-end">
          {rightNavComponent}
          <MobileNavMenuToggle toggle={toggleOpen} />
        </Flex>
      </Flex>
      {mainItems && (
        <MobileNavNavigation
          mobileNavHeight={mobileNavHeight}
          items={mainItems}
          topNavMenuComponent={topNavMenuComponent}
          bottomNavMenuComponent={bottomNavMenuComponent}
        />
      )}
    </StyledNav>
  )
}
