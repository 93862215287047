// TODO: Move to brands-design-system once the marketing site is off of remix
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import './Grid.css'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './App'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from './hooks/useAuth'
import { init } from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import {
  BountyProvider,
  Fonts,
  ZohoSalesIQ,
} from '@bounty/brands-design-system'
import {
  APP_NAME,
  DEPLOY_ENV,
  IS_BUILT_TARGETING_AN_ENVIRONMENT,
  SENTRY_DSN,
  API_URL,
  RUN_WITH_MOCKED_BACKEND,
  COMMIT_REF,
  CLARITY_PROJECT_ID,
  GTAG_MEASUREMENT_ID,
  MIXPANEL_PROJECT_TOKEN,
  ZOHO_WIDGET_ID,
} from './config/env'
import { HelmetProvider } from 'react-helmet-async'
import { SUPPORT_EMAIL } from './const'
import { initClarity, initGa4, initMixpanel } from '@bounty/web-analytics'
import { logger } from './utils/logger'
import { ApolloStandaloneToast } from './apollo/utils'

async function prepare() {
  /**
   * if its DEMO mode & run with mocked backend even it got built with production we will do this
   */
  if (
    (IS_BUILT_TARGETING_AN_ENVIRONMENT === false || DEPLOY_ENV === 'demo') &&
    RUN_WITH_MOCKED_BACKEND
  ) {
    console.log(
      '%c RUNNING MOCKED BACKEND!!',
      'font-weight: bold; font-size: 40px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) ',
      '\n\nThis means all network requests are intercepted before hitting our backend. If you would like to turn this off, please flip VITE_RUN_WITH_MOCKED_BACKEND to false and restart the app.',
    )
    const {
      isOnboardedUserWorker,
      // isNotOnboardedUserWorker,
    } = await import('./mocks/browser')

    const server = isOnboardedUserWorker
    return server.start({
      quiet: false,
      onUnhandledRequest: (req) => {
        if (req.url.includes(API_URL)) {
          logger.log(
            `You are missing a mock for query, ${
              // @ts-expect-error - Please add details why ignored
              req.body?.[0]?.operationName || req.body?.operationName || ''
            }, for ${API_URL}! If you're running in mock mode this is pulling in data from the dev server and can cause unexpected results`,
            req.body,
          )
        }
      },
      waitUntilReady: true,
    })
  }
}

if (IS_BUILT_TARGETING_AN_ENVIRONMENT) {
  initClarity({ isEnabled: true, projectId: CLARITY_PROJECT_ID })
  initGa4({ measurementId: GTAG_MEASUREMENT_ID })
  initMixpanel(MIXPANEL_PROJECT_TOKEN)
  init({
    dsn: SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: DEPLOY_ENV,
    debug: false,
    release: COMMIT_REF,
  })
} else {
  initMixpanel('local', { test: true })
}

prepare().then(() => {
  const root = createRoot(document.getElementById('root')!)
  root.render(
    <React.StrictMode>
      <HelmetProvider>
        <BrowserRouter>
          <BountyProvider supportEmail={SUPPORT_EMAIL} appName={APP_NAME}>
            <AuthProvider>
              {({ isAuthed, isLoading }) => {
                return (
                  <>
                    <Fonts />
                    <ApolloStandaloneToast />
                    <App />
                    {/* {isLoading === false &&
                      IS_BUILT_TARGETING_AN_ENVIRONMENT && (
                        <ZohoSalesIQ widgetId={ZOHO_WIDGET_ID} />
                      )} */}
                  </>
                )
              }}
            </AuthProvider>
          </BountyProvider>
        </BrowserRouter>
      </HelmetProvider>
    </React.StrictMode>,
  )
})
