import { PageSkeleton, useTheme } from '@bounty/brands-design-system'
import { isNotNil } from '@bounty/utils'
import { useQueryBackend } from '../../../../apollo/backend/hooks'
import {
  ConversionFunnelMetrics,
  UgcConversionFunnelMetricsDocument,
} from '../../../../generated/backendGraphql'
import { UGC_CONVERSION_FUNNEL_SECTIONS } from './constants'
import { ResponsiveFunnel } from '@nivo/funnel'
import { useAnalytics } from '@bounty/web-analytics'

export interface FunnelDatum {
  id: string | number
  value: number
  label?: string
}

const prepareChartData = (data: ConversionFunnelMetrics) => {
  if (isNotNil(data.metrics)) {
    const arr = data.metrics.slice(1)
    return arr.map((el) => {
      return {
        id: el.step,
        value: el.count,
        label: getChartLabel(el.step) ?? el.step,
      }
    })
  } else return []
}

const getChartLabel = (step: string) => {
  const obj = UGC_CONVERSION_FUNNEL_SECTIONS.find((x) => x.id === step)
  return obj?.heading
}

type UgcConversionFunnelProps = {
  updateSelectedMetric: (part: FunnelDatum) => void
}

export const UgcConversionFunnel = ({
  updateSelectedMetric,
}: UgcConversionFunnelProps) => {
  const theme = useTheme()
  const { track } = useAnalytics()

  const handleFunnelClick = (funnelSection: FunnelDatum) => {
    updateSelectedMetric(funnelSection)

    track(`UGC Conversion Funnel Click: ${funnelSection.label}`)
  }

  const { data } = useQueryBackend(UgcConversionFunnelMetricsDocument)

  if (!data) return <PageSkeleton />

  const chartData = prepareChartData(data.conversionFunnelMetrics)

  const colors = [
    theme.colors.purple[800],
    theme.colors.purple[700],
    theme.colors.purple[600],
    theme.colors.purple[500],
    theme.colors.purple[400],
    theme.colors.purple[300],
  ]

  return (
    <ResponsiveFunnel
      data={chartData}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      valueFormat=">-.2s"
      colors={colors}
      borderWidth={0}
      labelColor={'white'}
      beforeSeparatorLength={40}
      beforeSeparatorOffset={20}
      afterSeparatorLength={40}
      afterSeparatorOffset={20}
      currentPartSizeExtension={20}
      currentBorderWidth={0}
      motionConfig="gentle"
      direction="horizontal"
      onClick={(part) => handleFunnelClick(part.data)}
    />
  )
}
