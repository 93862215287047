import {
  useFastForm,
  Box,
  Flex,
  Link,
  Text,
  Skeleton,
  SkeletonText,
  PageHeader,
  Heading,
  PageHeaderActions,
  PageHeaderPrimaryAction,
  PageHeaderSecondaryAction,
} from '@bounty/brands-design-system'
import { useEffect } from 'react'
import { useQueryBackend } from '../../../../../../apollo/backend/hooks'
import { GiftListDocument } from '../../../../../../generated/backendGraphql'
import { useNavigate } from 'react-router-dom'
import { useInviteCreatorsYouDontKnowDispatch } from '../../useInviteCreatorsYouDontKnow'
import { messageSchema } from '../../schemas'
import { isNotNil } from '@bounty/utils'
import { useCheckForTableData } from '../../hooks/useCheckForTableData'
import { FormDataDisplayWrapper } from '../../../components/FormDataDisplay/FormDataDisplayWrapper'
import { FormWrapper } from '../../../components/FormDataDisplay/FormWrapper'
import { FormDataDisplay } from '../../../components/FormDataDisplay/FormDataDisplay'
import { PhoneEmailDisplay } from '../../../components/PhoneEmailDisplay/PhoneEmailDisplay'
import { GiftDisplay } from '../../../components/GiftDisplay/GiftDisplay'
import { GiftIcon } from '../../../components/icons/GiftIcon'
import { MailIcon } from '../../../components/icons/MailIcon'
import { INVITE_MESSAGE_PREVIEW_TEXT_DISCLAIMER } from '../../../constants/invites.constants'

export const AddGift = () => {
  useCheckForTableData()

  const navigate = useNavigate()

  const { data: { enabledStoreGifts = [], currentStore } = {}, loading } =
    useQueryBackend(GiftListDocument)

  const { setInviteCreatorsYouDontKnowState } =
    useInviteCreatorsYouDontKnowDispatch()

  const {
    Form,
    Select,
    methods: { setValue, watch },
  } = useFastForm({
    defaultValues: {
      giftId: '',
    },
    schema: messageSchema,
  })

  const [watchGiftId] = watch(['giftId'])

  const selectedGift = enabledStoreGifts.find((el) => el.id === watchGiftId)

  useEffect(() => {
    if (!loading && enabledStoreGifts != null && enabledStoreGifts.length > 0)
      setValue(
        'giftId',
        enabledStoreGifts.filter((el) => el.type === 'PRODUCT')[0]?.id,
      )
  }, [loading, setValue, enabledStoreGifts])

  return (
    <Form
      onSubmit={(submitData) => {
        if (isNotNil(submitData.giftId) && submitData.giftId.length > 0) {
          setInviteCreatorsYouDontKnowState((prevState) => ({
            ...prevState,
            ...submitData,
          }))

          navigate('../review')
        }
      }}
    >
      <PageHeader
        title={'Invite creators you do not know to Bounty'}
        description="Add creators you don’t know and we’ll try to activate them for your
        brand."
        breadcrumbs={[
          {
            name: 'Creators',
            to: '/creators',
          },
          {
            name: 'Invite',
            to: '/creators/invite',
          },
          {
            name: 'Current',
            to: '',
            isCurrentPage: true,
          },
        ]}
        actions={
          <PageHeaderActions>
            <PageHeaderSecondaryAction
              event={'Invites Add Creators You Know Second Step Back Clicked'}
              onClick={() => navigate('..')}
            >
              Back
            </PageHeaderSecondaryAction>
            <PageHeaderPrimaryAction
              event={'Invites Add Creators You Know Second Step Next Clicked'}
              type="submit"
            >
              Next
            </PageHeaderPrimaryAction>
          </PageHeaderActions>
        }
      />
      <Box>
        <FormDataDisplayWrapper>
          <FormWrapper>
            <Box width="400px" maxWidth="100%">
              {loading ? (
                <>
                  <SkeletonText
                    mb="2"
                    w="30%"
                    borderRadius="md"
                    noOfLines={1}
                    skeletonHeight="4"
                  />
                  <Skeleton w="100%" h="10" mb="5" borderRadius="md" />
                </>
              ) : (
                <Select
                  name="giftId"
                  label="Gift"
                  isRequired={true}
                  options={enabledStoreGifts
                    .filter((el) => el.type === 'PRODUCT')
                    .map((x) => ({
                      label: x.name,
                      value: x.id,
                    }))}
                  helpText={
                    <Text color="gray.600" fontSize="sm">
                      Select a gift for the creator or{' '}
                      <Link
                        isExternal={false}
                        to="create-gift"
                        textDecoration="underline"
                        color="inherit"
                      >
                        create one
                      </Link>
                      .
                    </Text>
                  }
                />
              )}
            </Box>
          </FormWrapper>
          {selectedGift && (
            <FormDataDisplay icon={<GiftIcon />} description="Gift details">
              <GiftDisplay gift={selectedGift} />
            </FormDataDisplay>
          )}
        </FormDataDisplayWrapper>

        <FormDataDisplayWrapper>
          <FormWrapper>
            <Box
              maxW="400px"
              flexDirection="column"
              justifyContent="center"
              flexGrow={1}
            >
              <Heading size="sm" mb="1">
                How it works
              </Heading>
              <Text>
                Once you invite prospective creators we’ll run an automated
                email outreach flow allowing them to redeem the gift in exchange
                for sharing it with their audience through Bounty.
              </Text>
            </Box>
            <Text fontSize={'xs'} color="gray.600">
              {INVITE_MESSAGE_PREVIEW_TEXT_DISCLAIMER}
            </Text>
          </FormWrapper>
          <FormDataDisplay icon={<MailIcon />} description="Message preview">
            <Flex
              mb="-8"
              pt="3"
              px="8"
              flexGrow={1}
              direction="column"
              align="center"
              justify="flex-end"
              minHeight="328px"
              height="auto"
              position="relative"
            >
              {loading ? (
                <div></div>
              ) : (
                <PhoneEmailDisplay
                  email={'marcus@creator.com'}
                  message={''}
                  subject={
                    currentStore
                      ? `${currentStore.name} - Partnership Opportunity`
                      : 'Partnership Opportunity'
                  }
                  variant="CreatorsYouDontKnow"
                />
              )}
            </Flex>
          </FormDataDisplay>
        </FormDataDisplayWrapper>
      </Box>
    </Form>
  )
}
