import { useInviteCreatorsYouKnow } from '../../useInviteCreatorsYouKnow'
import { AddCreatorsTable } from '../../../components/AddCreatorsTable/AddCreatorsTable'

export const AddCreatorsYouKnow = () => {
  const [{ rowData, message, giftId }, { setInviteCreatorsYouKnowState }] =
    useInviteCreatorsYouKnow()

  return (
    <AddCreatorsTable
      state={{ rowData, message, giftId }}
      updateState={setInviteCreatorsYouKnowState}
      variant="CreatorsYouKnow"
    />
  )
}
