import { Button as ButtonCore, ButtonProps as ButtonPropsCore } from './Chakra'
import { useAnalytics } from '@bounty/web-analytics'
import { defineStyle, defineStyleConfig, forwardRef } from '@chakra-ui/react'
import { isString } from '@bounty/utils'
import { MouseEventHandler, useCallback } from 'react'
import { isArray } from '@chakra-ui/utils'

const outline = defineStyle((p) => {
  const { colorScheme: c } = p
  return {
    color: `${c}.900`,
    borderColor: `${c}.400`,
  }
})
const solid = defineStyle({})
const ghost = defineStyle((p) => {
  const { colorScheme: c } = p
  return {
    color: `${c}.900`,
  }
})
const unstyled = defineStyle({
  minH: 'auto',
  height: 'auto',
  color: 'neutral.900',
  bg: 'transparent',
  fontWeight: 'normal',
  _hover: { bg: 'transparent' },
  _active: { bg: 'transparent' },
})

export const buttonTheme = defineStyleConfig({
  variants: {
    outline,
    solid,
    ghost,
    unstyled,
  },
  baseStyle: () => {
    return {
      _hover: {
        textDecoration: 'none',
      },
    }
  },
  defaultProps: {
    variant: 'solid',
    size: 'md',
    colorScheme: 'primary',
  },
})

// TODO: Get from hook once we get segment out of there
type EventObject = { eventName: string; [x: string]: any }

export type ButtonProps = ButtonPropsCore & {
  /**
   * Event to send to analytics services. Please name them title cast in the following format:
   * Title Case in object action syntax
   *
   * @example Request Spark Code Clicked
   *
   * @docs https://segment.com/academy/collecting-data/naming-conventions-for-clean-data/
   */
  event: string | string[] | EventObject | EventObject[] | null
}

const getColorSchemeForVariant = (variant: ButtonProps['variant']) => {
  if (!variant) throw new Error('variant required')

  switch (variant) {
    case 'ghost':
      return 'neutral'
    case 'link':
      return 'neutral'
    case 'outline':
      return 'neutral'
    case 'solid':
      return 'primary'
    case 'unstyled':
      return 'neutral'
  }
}

/**
 * @deprecated - Only use on the marketing site because we don't run analytics on there
 * due to the cost of Mixpanel
 */
export const ButtonWithoutAnalytics = forwardRef<
  Omit<ButtonProps, 'event'>,
  'button'
>(({ variant = 'solid', ...props }, ref) => {
  return (
    <ButtonCore
      variant={variant}
      colorScheme={getColorSchemeForVariant(variant)}
      ref={ref}
      textDecoration={'none'}
      {...props}
    />
  )
})

export const Button = forwardRef<ButtonProps, 'button'>(
  ({ variant = 'solid', event, onClick: onClickProp, ...props }, ref) => {
    const { track } = useAnalytics()

    const onClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
      (e) => {
        // We don't wait on any of these since it's events and should be non blocking
        // to the user's experience
        const sendSingleEvent = (singleEvent: ButtonProps['event']) => {
          if (!singleEvent || isArray(singleEvent)) return

          if (isString(singleEvent)) {
            track(singleEvent)
          } else {
            const { eventName, ...rest } = singleEvent

            track(eventName, rest)
          }
        }

        if (event) {
          if (isArray(event)) {
            Promise.all(event.map((x) => sendSingleEvent(x)))
          } else {
            sendSingleEvent(event)
          }
        }

        onClickProp?.(e)
      },
      [onClickProp, event, track],
    )

    return (
      <ButtonWithoutAnalytics
        variant={variant}
        colorScheme={getColorSchemeForVariant(variant)}
        ref={ref}
        textDecoration={'none'}
        onClick={onClick}
        {...props}
      />
    )
  },
)
