import { INVITES_CSV_HEADING } from '@bounty/constants'
import { isNotNil } from '@bounty/utils'
import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Link,
  PageHeader,
  Text,
  useFastForm,
  useTheme,
  useToast,
  Grid,
  PageHeaderActions,
  PageHeaderPrimaryAction,
} from '@bounty/brands-design-system'
import { CellStyle, ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import dlv from 'dlv'
import { uniqBy } from 'lodash'
import { parse } from 'papaparse'
import { useMemo, useRef } from 'react'
import { useFieldArray } from 'react-hook-form'
import { FaRegTrashAlt } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { InviteCreatorsYouDontKnowState } from '../../InviteCreatorsYouDontKnow/useInviteCreatorsYouDontKnow'
import { InviteSchema, inviteSchema } from '../../InviteCreatorsYouKnow/schemas'
import { InviteCreatorsYouKnowState } from '../../InviteCreatorsYouKnow/useInviteCreatorsYouKnow'
import { UploadCreatorsCsv } from '../UploadCreatorsCsv/UploadCreatorsCsv'
import { UploadCreatorsCsvError } from '../UploadCreatorsCsv/UploadCreatorsCsvError'
import { CellEditorFastFormInput } from './CellEditorFastFormInput'
import { CellRendererParams, InviteFlowVariant, InviteRowItem } from './types'

type WizardState = InviteCreatorsYouDontKnowState | InviteCreatorsYouKnowState
type AddCreatorsTableProps = {
  state: WizardState
  updateState: (prevState: WizardState) => void
  variant: InviteFlowVariant
}

export const AddCreatorsTable = ({
  state,
  updateState,
  variant,
}: AddCreatorsTableProps) => {
  const navigate = useNavigate()
  const toast = useToast()
  const theme = useTheme()
  const gridRef = useRef<AgGridReact<InviteRowItem>>(null)

  const { rowData } = state

  const {
    Form,
    methods: {
      clearErrors,
      control,
      formState: { errors },
      trigger,
      getValues,
      setValue,
    },
  } = useFastForm({
    defaultValues: {
      rowData,
    },
    schema: inviteSchema,
    // Cannot be onBlur because the callback for cellStyle is called before the state rerender of errors
    mode: 'all',
  })
  const { replace, fields, prepend } = useFieldArray<InviteSchema>({
    name: 'rowData',
    control,
  })

  const columnDefs = useMemo((): ColDef<InviteRowItem>[] => {
    const cellStyles: CellStyle = {
      height: '100%',
      display: 'flex ',
      justifyContent: 'flex-start',
      alignItems: 'center ',
      fontSize: theme.fontSizes.md.toString(),
      color: theme.colors['gray']['900'],
    }
    return [
      {
        headerName: 'Email (Required)',
        field: 'email',
        editable: true,
        singleClickEdit: true,
        cellEditor: CellEditorFastFormInput,
        cellStyle: (params) => {
          // TODO: Get precise design system error color
          if (
            isNotNil(
              dlv(errors, `rowData.${params.rowIndex}.${params.colDef.field}`),
            )
          ) {
            return {
              ...cellStyles,
              ...{ backgroundColor: theme.colors.red[50] },
            }
          } else return cellStyles
        },
      },
      {
        headerName: 'Name',
        field: 'name',
        editable: true,
        singleClickEdit: true,
        cellEditor: CellEditorFastFormInput,
        cellStyle: (params) => {
          // TODO: Get precise design system error color
          if (
            isNotNil(
              dlv(errors, `rowData.${params.rowIndex}.${params.colDef.field}`),
            )
          ) {
            return {
              ...cellStyles,
              ...{ backgroundColor: theme.colors.red[50] },
            }
          } else return cellStyles
        },
      },
      {
        headerName: '',
        field: 'delete',
        editable: false,
        maxWidth: 60,
        cellStyle: cellStyles,
        cellRenderer: (params: CellRendererParams) => {
          return (
            <IconButton
              aria-label="delete creator"
              icon={<FaRegTrashAlt size={theme.space['5'].toString()} />}
              background="transparent"
              color="gray.500"
              size="lg"
              isRound={true}
              onClick={() => {
                const selectedNode = params.node
                const selectedData = selectedNode.data
                if (selectedData != null) {
                  const rows = [...getValues('rowData')]
                  rows.splice(params.rowIndex, 1)
                  setValue('rowData', rows)
                  clearErrors('rowData')
                  trigger()
                }
              }}
            />
          )
        },
      },
    ]
  }, [theme, errors, setValue, getValues, clearErrors, trigger])

  const handleUpdateInvitesCsv = (csvString: string): void => {
    if (csvString.startsWith(INVITES_CSV_HEADING) !== true) {
      toast({
        title: 'Invalid CSV template',
        description: 'Please try again using our CSV template',
        status: 'error',
      })

      return
    }

    // If this ever becomes slow, this has the option to offload processing to a web worker
    const result = parse<Omit<InviteRowItem, 'id'>>(csvString, {
      header: true,
      skipEmptyLines: true,
    })

    // Any errors will be handled at the zo
    const { data, errors } = result

    const dataNoRepeatEmails = uniqBy(data, 'email')

    if (errors != null && errors.length > 0) {
      toast({
        title: 'Error parsing CSV',
        description: errors[0].message
          ? `${errors[0].message} at line ${errors[0].row + 1}`
          : 'Please try uploading again',
        status: 'error',
      })

      return
    }

    if (dataNoRepeatEmails.length === 0) {
      toast({
        title: 'No data found in CSV',
        description: 'Please try uploading again with data',
        status: 'error',
      })

      return
    }

    replace(dataNoRepeatEmails)

    // Validate on upload!
    trigger()
  }

  const numberOfRowsToDisplay = 5
  const rowHeight = 72

  return (
    <Form
      onSubmit={(submitData) => {
        updateState({
          ...state,
          ...submitData,
        })
        navigate('message')
      }}
    >
      <PageHeader
        title={
          variant === 'CreatorsYouKnow'
            ? `Invite creators you know to Bounty`
            : `Invite creators you do not know to Bounty`
        }
        description={
          variant === 'CreatorsYouKnow'
            ? `Invite creators you already work with to join your community on Bounty.`
            : `Add creators you don’t know and we’ll try to activate them for your
      brand.`
        }
        breadcrumbs={[
          {
            name: 'Creators',
            to: '/creators',
          },
          {
            name: 'Invite',
            to: '/creators/invite',
          },
          {
            name: 'Current',
            to: '',
            isCurrentPage: true,
          },
        ]}
        actions={
          <PageHeaderActions>
            <PageHeaderPrimaryAction
              event={
                variant === 'CreatorsYouKnow'
                  ? `Invites Add Creators You Know First Step Next Clicked`
                  : `Invites Add Creators You Don't Know First Step Next Clicked`
              }
              type="submit"
            >
              Next
            </PageHeaderPrimaryAction>
          </PageHeaderActions>
        }
      />

      <Box>
        {isNotNil(errors.rowData) ? (
          <UploadCreatorsCsvError updateInvitesCsv={handleUpdateInvitesCsv} />
        ) : (
          <UploadCreatorsCsv updateInvitesCsv={handleUpdateInvitesCsv} />
        )}
        <Box
          mb="8"
          p="7"
          backgroundColor="gray.50"
          border="1px solid"
          borderColor="gray.200"
        >
          <Flex direction="row" gridGap="4" alignItems="baseline">
            <Heading mb="6" as="h4" size="sm" flexGrow={1}>
              Creators
            </Heading>
            <Button
              size="sm"
              event="Invites Add Creators Add A Creator Button Clicked"
              onClick={() => {
                // set data to force display name cell onBlur
                setValue('rowData', [...getValues('rowData')])
                // Prepend so they don't have to scroll forever to see the field to complete
                prepend({ email: '', name: '' })
              }}
            >
              Add a Creator
            </Button>
          </Flex>
          <Grid<InviteRowItem>
            gridRef={gridRef}
            containerProps={{
              height: `calc(${rowHeight}px * ${numberOfRowsToDisplay} + 58px)`,
              flex: 'none',
              borderRadius: 'md',
            }}
            rowHeight={rowHeight}
            rowData={fields}
            columnDefs={columnDefs}
            suppressCellFocus={false}
            showQuickSearch={false}
          />
        </Box>
        <Box p="7" border="1px solid" borderColor="gray.200" borderRadius="md">
          <Heading as="h4" size="sm" mb="2">
            Don’t have emails?
          </Heading>
          <Text color="gray.700" m="0">
            Use the{' '}
            <Button
              variant="link"
              as={Link}
              to="/creators/invite/advanced"
              fontSize="inherit"
              fontWeight="inherit"
              letterSpacing="inherit"
              color="gray.700"
              textDecoration="underline"
              event="Invites Add Creators Advanced Flow CTA Clicked"
            >
              advanced invite flow
            </Button>{' '}
            to generate unique invite links to give gifts to people through SMS,
            DMs, email, and more.
          </Text>
        </Box>
      </Box>
    </Form>
  )
}
