import { defineStyleConfig } from '@chakra-ui/react'

export const checkboxTheme = defineStyleConfig({
  defaultProps: {
    colorScheme: 'primary',
    size: 'lg',
  },
  sizes: {
    lg: {
      label: { fontSize: 'md' },
    },
  },
  baseStyle: () => ({
    control: {
      backgroundColor: 'white',
      borderRadius: 'md',
    },
  }),
})
