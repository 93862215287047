import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthDispatch } from '../../hooks/useAuth'

export type LoginSuccessProps = unknown

export const LoginSuccess = () => {
  const { handleLoginSuccess } = useAuthDispatch()
  const navigate = useNavigate()

  // We redirect in the effect instead of the callback because React Router
  // throws a warning and the tests do not work. However, it works just fine
  // running the app. You also can't make handleLoginSuccess a promise without
  // breaking the tests :(
  useEffect(() => {
    const { redirectUrl } = handleLoginSuccess()
    navigate(redirectUrl)
  }, [handleLoginSuccess, navigate])

  return null
}
