import { FastFormInputBase, useMergeRefs } from '@bounty/brands-design-system'
import { ICellEditorParams } from 'ag-grid-community'
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { InviteRowItem } from './types'

export const CellEditorFastFormInput = forwardRef(
  (
    { value, rowIndex, colDef, data }: ICellEditorParams<InviteRowItem>,
    ref,
  ) => {
    const { register } = useFormContext()

    const refInput = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
      // focus on the input
      refInput.current?.focus()
      refInput.current?.setSelectionRange(0, value.length)
    }, [value])

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
      return {
        // the final value to send to the grid, on completion of editing
        getValue() {
          // this simple editor doubles any value entered into the input
          return refInput.current?.value
        },

        // Gets called once before editing starts, to give editor a chance to
        // cancel the editing before it even starts.
        isCancelBeforeStart() {
          return false
        },

        // Gets called once when editing is finished (eg if Enter is pressed).
        // If you return true, then the result of the edit will be ignored.
        isCancelAfterEnd() {
          // our editor will reject any value greater than 1000
          return false
        },
      }
    })

    const { ref: hookFormInputRef, ...rest } = register(
      `rowData.${rowIndex}.${colDef.field}`,
    )

    return (
      <FastFormInputBase
        key={data.id}
        ref={useMergeRefs(hookFormInputRef, refInput)}
        style={{ width: '100%', height: '72px' }}
        m="0"
        px="0"
        borderRadius="none"
        border="none"
        _focus={{ boxShadow: 'none' }}
        {...rest}
      />
    )
  },
)
