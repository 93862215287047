import { z } from 'zod'

export const requestABrandBody = z.object({
  userId: z.string().uuid(),
  brandName: z.string().min(1).max(200),
  comment: z.string().max(500).optional(),
  notifyWhenAdded: z.boolean(),
})

export const rejectedAPartnershipBody = z.object({
  userId: z.string().uuid(),
  partnershipId: z.string().uuid(),
  reason: z.array(z.string()),
  reasonOther: z.string().max(500).optional(),
  comment: z.string().max(500).optional(),
})

export const didNotCompleteBountyBody = z.object({
  bountyId: z.string().uuid(),
  userId: z.string().uuid(),
  shopUrl: z.string().min(1).max(200),
  reason: z.array(z.string().min(1).max(200)),
  reasonOther: z.string().max(500).optional(),
  comment: z.string().max(500).optional(),
})

export const rejectedGiftBody = z.object({
  userId: z.string().uuid(),
  giftKey: z.string().min(1).max(200),
  shopUrl: z.string().min(1).max(200),
  reason: z.array(z.string()),
  reasonOther: z.string().max(500).optional(),
  offerType: z.string().min(1).max(100),
  comment: z.string().max(500).optional(),
})

export const generalFeedbackBody = z.object({
  userId: z.string().uuid(),
  nps: z.number(),
  comment: z.string().max(500).optional(),
  permissionToFollowUp: z.boolean(),
})
