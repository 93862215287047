import { Box, Heading, PageSkeleton } from '@bounty/brands-design-system'
import { Page } from '@bounty/brands-design-system'
import { GetStartedDocument } from '../../../../generated/backendGraphql'
import { useQueryBackend } from '../../../../apollo/backend/hooks'
import { useScript } from '@bounty/hooks'

export const GetStarted = () => {
  useScript({
    src: '//js.hsforms.net/forms/embed/v2.js',
    type: 'text/javascript',
    id: 'hubspot-embed',
    onLoad: () => {
      // @ts-expect-error - Added on script initiation
      hbspt.forms.create({
        region: 'na1',
        portalId: '24082817',
        formId: '0747ed24-0e28-4a32-84d5-278564162811',
        target: '#brand-form',
      })
    },
  })
  const { data, loading } = useQueryBackend(GetStartedDocument)
  if (!data || loading) return <PageSkeleton />
  return (
    <Page
      data-testid="get-started-page"
      name="Get Started"
      background="neutral.100"
      maxW="100%"
      addBottomSpacing={false}
      height="calc(100vh - 75px)"
      px={0}
    >
      <Box
        px={[4, 8, 0]}
        marginX="auto"
        background="white"
        pb={10}
        maxWidth="740px"
      >
        <Box
          marginX="auto"
          background="white"
          maxWidth="540px"
          paddingTop={'10'}
        >
          <Heading as="h1" mb="4" size="lg">
            Thank you for installing Bounty! Next step is to book time with our
            team.
          </Heading>
          <Box id="brand-form"></Box>
        </Box>
      </Box>
    </Page>
  )
}
