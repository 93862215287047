import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerHeading,
  DrawerOverlay,
  DrawerProps,
  Flex,
  Link,
  PageSkeleton,
} from '@bounty/brands-design-system'
import { useParamsOrThrow } from '@bounty/hooks'
import { buildShortShopUrl, isNil, isNotNil } from '@bounty/utils'
import { first } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useQueryBackend } from '../../../../../apollo/backend/hooks'
import { GiftOfferDetailsDocument } from '../../../../../generated/backendGraphql'
import { GiftOfferDetailsContent } from './GiftOfferDetailsContent'
import { GiftOfferDetailsList } from './GiftOfferDetailsList'

export type GiftOfferDetailsDrawerProps = Omit<
  DrawerProps,
  'children' | 'isOpen' | 'onClose'
> & {
  rootPath: string
}
export const GiftOfferDetailsDrawer = ({
  rootPath,
  ...rest
}: GiftOfferDetailsDrawerProps) => {
  const navigate = useNavigate()
  const giftOfferId = useParamsOrThrow(['giftOfferId']).giftOfferId

  const { data: { giftOfferDetails, currentStore } = {}, loading } =
    useQueryBackend(GiftOfferDetailsDocument, { variables: { giftOfferId } })

  if (!giftOfferDetails || loading) return null

  const getBounty = () => {
    const bounty = first(giftOfferDetails.userBounties) ?? null
    return bounty
  }

  const getTikTokVideo = () => {
    const bounty = getBounty()
    if (isNil(bounty)) return null
    const tikTokVideo = bounty.tikTokVideo ?? null
    return tikTokVideo
  }
  const bounty = getBounty()
  const tikTokVideo = getTikTokVideo()

  return (
    <Drawer size="lg" isOpen onClose={() => navigate(rootPath)} {...rest}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <DrawerHeading>Gift Details</DrawerHeading>
        </DrawerHeader>
        <DrawerBody overflowY="auto">
          <Flex gridGap={6} direction="column">
            <GiftOfferDetailsList data={giftOfferDetails} />
            {bounty && tikTokVideo && (
              <GiftOfferDetailsContent bountyId={bounty.id} />
            )}
          </Flex>
        </DrawerBody>
        <DrawerFooter>
          {isNotNil(currentStore) &&
            isNotNil(giftOfferDetails.shopifyStoreOrder?.shopifyOrderId) && (
              <Button
                as={Link}
                variant="outline"
                mr={4}
                href={`https://admin.shopify.com/store/${buildShortShopUrl(
                  currentStore.shopUrl,
                )}/orders/${
                  giftOfferDetails.shopifyStoreOrder?.shopifyOrderId
                }`}
                event={'Click View Shopify Order from Gift Offer Detail Drawer'}
                isExternal={true}
              >
                View Shopify Order
              </Button>
            )}
          {isNotNil(bounty) &&
            isNotNil(tikTokVideo) &&
            isNotNil(giftOfferDetails.profileName) && (
              <Button
                as={Link}
                variant="solid"
                to={`/content-library/video/${bounty.id}`}
                event={'Click View Shopify Order from Gift Offer Detail Drawer'}
                isExternal={false}
              >
                View in Content Library
              </Button>
            )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
