import dlv from 'dlv'
import { FieldValues, useFormContext } from 'react-hook-form'
import { Textarea, TextareaProps } from '../../Chakra'
import { FastFormControl } from './FastFormControl'
import { FastFormElementProps } from './types'
import { getLabelFromName } from './utils'

export type FastFormTextareaProps<
  TFieldValues extends FieldValues = FieldValues,
> = FastFormElementProps<TextareaProps, TFieldValues> &
  Pick<TextareaProps, 'placeholder' | 'maxCharacterLimit' | 'rows' | 'resize'>

export const FastFormTextarea = <
  TFieldValues extends FieldValues = FieldValues,
>({
  label,
  name,
  helpText,
  labelProps,
  tooltipText,
  errorProps,
  isRequired,
  isDisabled,
  formControlProps,
  formElementProps,
  placeholder,
  maxCharacterLimit,
  rows,
  resize,
  showLabel,
}: FastFormTextareaProps<TFieldValues>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<TFieldValues>()
  const error = dlv(errors, name)

  return (
    <FastFormControl
      error={error}
      helpText={helpText}
      label={label ?? getLabelFromName(name)}
      labelProps={labelProps}
      tooltipText={tooltipText}
      errorProps={errorProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
      showLabel={showLabel}
      {...formControlProps}
    >
      <Textarea
        maxCharacterLimit={maxCharacterLimit}
        placeholder={placeholder}
        rows={rows}
        resize={resize}
        {...formElementProps}
        {...register(name)}
      />
    </FastFormControl>
  )
}
