import {
  Box,
  HStack,
  Image,
  Text,
  Tile,
  VStack,
  Checkbox,
  ChakraGrid,
  Flex,
} from '@bounty/brands-design-system'
import { useNavigate } from 'react-router-dom'
import { useQueryBackend } from '../apollo/backend/hooks'
import { ChecklistQuery, ChecklistDocument } from '../generated/backendGraphql'
import BountyChecklist from '../images/bounty-checklist.png'

const CHECKLIST_MAP: Record<
  keyof Omit<ChecklistQuery['onboardingState'], '__typename' | 'id'>,
  { description: string; link: string }
> = {
  hasCategories: {
    description: 'Add some categories for your brand.',
    link: '/settings',
  },
  hasTikTokHandle: {
    description: 'Add your TikTok handle.',
    link: '/settings',
  },
  hasExperiencesEnabled: {
    description: 'Set up your customer experiences.',
    link: '/user-experiences/overview',
  },
  hasCreatedGifts: {
    description: 'Create some gifts for your creators.',
    link: '/gifts',
  },
  hasEmails: {
    description: 'Update your notification preferences.',
    link: '/notifications',
  },
  /*
  hasStorefront: {
    description: 'Create the storefront for your brand.',
    link: '/',
  },
  */
}

export const Checklist = () => {
  const navigate = useNavigate()
  const { data, loading } = useQueryBackend(ChecklistDocument, {
    fetchPolicy: 'network-only',
  })

  // don't show anything if loading because todo might be empty
  if (!data || loading) return null

  const todoKeys = Object.entries(data.onboardingState)
    .filter(([_k, v]) => v === false)
    .map(([k, _v]) => k as keyof typeof CHECKLIST_MAP)

  if (todoKeys.length === 0) return null

  const todoItems = todoKeys.map((k) => {
    const checklistItem = CHECKLIST_MAP[k]
    return { ...checklistItem, completed: false }
  })

  return (
    <Tile mb={4} background="neutral.100">
      <ChakraGrid templateColumns={{ xl: 'repeat(2, 1fr)' }} gap={4}>
        <HStack>
          <Image src={BountyChecklist} alt="checklist" width={'100px'} />
          <VStack pl={8} maxW={{ xl: '550px' }} alignItems={'flex-start'}>
            <Text size="xl" fontWeight="semibold">
              Quick Start Checklist
            </Text>
            <Text size="xl">
              Welcome to Bounty! Complete the checklist below in order to get
              the most out of Bounty on your store.
            </Text>
          </VStack>
        </HStack>
        <ChakraGrid templateColumns={{ md: 'repeat(2, 1fr)' }} gap={2}>
          {todoItems.map((t, i) => (
            <Box onClick={() => navigate(t.link)} key={i}>
              <Tile
                background="white"
                _hover={{ border: '1px solid black' }}
                px={2}
                cursor="pointer"
                height="100%"
                key={t.description}
                py={3}
              >
                <Flex alignItems="center" h="full">
                  <Checkbox borderColor="neutral.300" mr={4} /> {t.description}
                </Flex>
              </Tile>
            </Box>
          ))}
        </ChakraGrid>
      </ChakraGrid>
    </Tile>
  )
}
