import { Box, FlexProps, Flex } from '@bounty/brands-design-system'

export type NotificationEmailPreviewProps = FlexProps

export const NotificationEmailPreview = (
  props: NotificationEmailPreviewProps,
) => {
  return (
    <Flex
      position="relative"
      height="100%"
      px="10"
      py="4"
      flexDirection="column"
      minHeight="150px"
      {...props}
    >
      <Flex flex={1} justifyContent="center" alignItems="center">
        <Box>
          <div
            dangerouslySetInnerHTML={{
              __html: `<table role="presentation" border="0" cellpadding="0" cellspacing="0" class="bounty__body" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;" width="100%">
              <tr>
                <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">&nbsp;</td>
                <td class="bounty__container" style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">
                  <div class="bounty__content">
                    <table align="center" role="presentation" class="bounty__main" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background: #ffffff; border-radius: 4px; border-top: 2px solid #eaeaea; border-bottom: 2px solid #eaeaea; width: 560px; text-align: center; border-spacing: 0; border-collapse: collapse; margin: 0 auto;" width="560">
                      <tr>
                        <td class="bounty__wrapper" style="font-family: sans-serif; font-size: 14px; vertical-align: top; box-sizing: border-box; padding: 20px;" valign="top">
                          <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: 100%;" width="100%">
                            <tr>
                              <td align="center" style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">
                                <a href="https://creators.earnbounty.com/invite?shop={{shop.permanent_domain}}&shopId={{shop.id}}&shopUrl={{shop.domain}}&orderId={{order.id}}&email={{order.email}}&ref=ORDERNOTIFICATIONEMAIL" target="_blank" style="color: #181818; text-decoration: underline;">
                                  <img class="bounty__hero-img" src="https://d21jj9ods4nmqi.cloudfront.net/misc-assets/email-placement-image.png" alt="bounty sign up image" width="180" height="190" border="0" style="border: none; -ms-interpolation-mode: bicubic; max-width: 100%; width: 180px; height: 190px; margin-top: 10px; margin-bottom: 24px;"></a>
                              </td>
                            </tr>
                            <tr>
                              <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">
                                <h2 class="bounty__header" style="font-family: inherit; margin: 0; font-size: 28px; font-weight: 800; text-align: center; color: #181818; margin-bottom: 4px; line-height: 1.3;">Post a TikTok</h2>
                                <p class="bounty__sub-header" style="font-family: sans-serif; font-weight: normal; margin: 0; font-size: 24px; text-align: center; color: #181818; margin-bottom: 24px; line-height: 1.3;">
                                  Get paid for your views
                                </p>
                                <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="bounty__btn bounty__btn-primary" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; box-sizing: border-box; width: 100%; margin-bottom: 20px;" width="100%">
                                  <tbody>
                                    <tr>
                                      <td align="center" style="font-family: sans-serif; font-size: 14px; vertical-align: top; padding-bottom: 15px;" valign="top">
                                        <table role="presentation" border="0" cellpadding="0" cellspacing="0" style="border-collapse: separate; mso-table-lspace: 0pt; mso-table-rspace: 0pt; width: auto;">
                                          <tbody>
                                            <tr>
                                              <td style="font-family: sans-serif; font-size: 14px; vertical-align: top; border-radius: 5px; text-align: center; background-color: #181818;" valign="top" align="center" bgcolor="#181818">
                                                <a href="https://creators.earnbounty.com/invite?shop={{shop.permanent_domain}}&shopId={{shop.id}}&shopUrl={{shop.domain}}&orderId={{order.id}}&email={{order.email}}&ref=ORDERNOTIFICATIONEMAIL" target="_blank" style="border: solid 1px #181818; border-radius: 2px; box-sizing: border-box; cursor: pointer; display: inline-block; font-size: 18px; font-weight: 500; margin: 0; padding: 12px 25px; text-decoration: none; background-color: #181818; border-color: #181818; color: #ffffff;">Sign up</a>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td align="center" style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">
                                <a href="https://bounty.co?ref=ORDERNOTIFICATIONEMAIL" target="_blank" style="color: #181818; text-decoration: underline;">
                                  <img class="bounty__powered-by-bounty-img" alt="bounty sign up image" width="162" height="36" border="0" src="https://d21jj9ods4nmqi.cloudfront.net/misc-assets/powered-by-bounty.png" style="border: none; -ms-interpolation-mode: bicubic; max-width: 100%; width: 162px; height: 36px;">
                                </a>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                    </table>
                
                  </div>
                </td>
                <td style="font-family: sans-serif; font-size: 14px; vertical-align: top;" valign="top">&nbsp;</td>
              </tr>
            </table>
            <!-- Bounty email widget end -->`,
            }}
          ></div>
        </Box>
      </Flex>
    </Flex>
  )
}
