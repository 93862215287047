import { giftType } from '@bounty/constants'
import { Box, Flex, Text } from '@bounty/brands-design-system'
import { add, format } from 'date-fns'
import {
  StoreGiftBounty,
  StoreGiftProduct,
} from '../../../../../generated/backendGraphql'

type GiftPreviewProps = {
  gift: Partial<StoreGiftBounty | StoreGiftProduct>
}

export const GiftDisplay = ({ gift }: GiftPreviewProps) => {
  return (
    <>
      <Flex direction="row" gridGap={4}>
        {gift.type && (
          <Box flexGrow={1}>
            <Text mb="1" size="md" fontWeight="semibold" color="gray.700">
              Gift type
            </Text>
            <Text color="gray.900" wordBreak="break-word">
              {giftType[gift.type]}
            </Text>
          </Box>
        )}
        {gift.offerExpirationDays && (
          <Box minWidth={['auto', '156px', null, null, '226px']}>
            <Text mb="1" size="md" fontWeight="semibold" color="gray.700">
              Expiration
            </Text>
            <Text color="gray.900">
              {format(
                add(new Date(), { days: gift.offerExpirationDays }),
                'MM/dd/yyyy',
              )}
            </Text>
          </Box>
        )}
      </Flex>
      {gift.description && (
        <Box>
          <Text mb="1" size="md" fontWeight="semibold" color="gray.700">
            Description
          </Text>
          <Text color="gray.900" wordBreak="break-word">
            {gift.description}
          </Text>
        </Box>
      )}
    </>
  )
}
