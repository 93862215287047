import { useInviteCreatorsYouDontKnow } from '../../useInviteCreatorsYouDontKnow'
import { AddCreatorsTable } from '../../../components/AddCreatorsTable/AddCreatorsTable'

export const AddCreatorsYouDontKnow = () => {
  const [{ rowData, message, giftId }, { setInviteCreatorsYouDontKnowState }] =
    useInviteCreatorsYouDontKnow()

  return (
    <AddCreatorsTable
      state={{ rowData, message, giftId }}
      updateState={setInviteCreatorsYouDontKnowState}
      variant="CreatorsYouDontKnow"
    />
  )
}
