import { ComponentProps } from 'react'
import {
  Stat as StatCore,
  StatLabel as StatLabelCore,
  StatNumber as StatNumberCore,
  StatHelpText as StatHelpTextCore,
  StatArrow as StatArrowCore,
  StatGroup as StatGroupCore,
  Flex,
  FlexProps,
  Text,
} from './Chakra'
import { defineStyleConfig, defineStyle } from '@chakra-ui/react'
import { Tile } from '../components/Tile'

const baseStyleLabel = defineStyle({
  color: 'neutral.600',
})

const baseStyleHelpText = defineStyle({
  borderRadius: 'full',
  px: 3,
  py: '1',
  fontWeight: 'semibold',
})

const baseStyleNumber = defineStyle({
  color: 'neutral.900',
  fontWeight: 'bold',
})

const baseStyleIcon = defineStyle({})

export const statTheme = defineStyleConfig({
  baseStyle: {
    container: {},
    label: baseStyleLabel,
    helpText: baseStyleHelpText,
    number: baseStyleNumber,
    icon: baseStyleIcon,
  },
  sizes: {
    md: {
      label: { fontSize: 'md' },
      helpText: { fontSize: 'sm' },
      number: { fontSize: '3xl' },
    },
  },
})

export type StatProps = ComponentProps<typeof StatCore>

export const Stat = ({ ...props }: StatProps) => {
  return <StatCore as={Tile} {...props} />
}

export type StatLabelProps = ComponentProps<typeof StatLabelCore>

export const StatLabel = ({ ...props }: StatLabelProps) => {
  return <StatLabelCore {...props} />
}

export type StatNumberProps = ComponentProps<typeof StatNumberCore>

export const StatNumber = ({ ...props }: StatNumberProps) => {
  return <StatNumberCore {...props} />
}

export type StatNumberRowProps = FlexProps

export const StatNumberRow = ({ ...props }: StatNumberRowProps) => {
  return (
    <Flex justifyContent={'space-between'} alignItems="center" {...props} />
  )
}

export type StatHelpTextProps = ComponentProps<typeof StatHelpTextCore> & {
  type: 'positive' | 'negative'
}

export const StatHelpText = ({
  type,
  children,
  ...props
}: StatHelpTextProps) => {
  return (
    <StatHelpTextCore
      bg={type === 'positive' ? 'success.100' : 'error.100'}
      color={type === 'positive' ? 'success.900' : 'error.900'}
      {...props}
    >
      <Text
        fontWeight={'semibold'}
        color={type === 'positive' ? 'success.900' : 'error.900'}
        as="span"
        pr="1"
      >
        {type === 'positive' ? '+' : '-'}
      </Text>
      {children}
    </StatHelpTextCore>
  )
}

export type StatArrowProps = ComponentProps<typeof StatArrowCore>

export const StatArrow = ({ ...props }: StatArrowProps) => {
  return <StatArrowCore {...props} />
}

export type StatGroupProps = ComponentProps<typeof StatGroupCore>

export const StatGroup = ({ ...props }: StatGroupProps) => {
  return <StatGroupCore gap="5" {...props} />
}
