import {
  Box,
  BoxProps,
  Heading,
  Text,
  Link,
  Button,
  Skeleton,
  Flex,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  UnorderedList,
  ListItem,
  useDisclosure,
} from '@bounty/brands-design-system'
import { useQueryBackend } from '../../../apollo/backend/hooks'
import { AppBlocksInstallDocument } from '../../../generated/backendGraphql'
import AppBlocksImage from '../../../images/app-blocks-image.png'

type AppBlocksInstallProps = BoxProps

export const AppBlocksInstall = ({ ...rest }: AppBlocksInstallProps) => {
  const { data, loading } = useQueryBackend(AppBlocksInstallDocument)
  const { onOpen, onClose, isOpen } = useDisclosure()

  if (loading || !data) {
    return (
      <Box {...rest}>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
    )
  }

  return (
    <>
      <Box
        width="90%"
        marginX="auto"
        data-testid="app-blocks-install"
        {...rest}
      >
        <Flex
          flexDir={['column', 'column', 'row']}
          justifyContent="space-between"
        >
          <Box paddingRight={['0', '0', '10']}>
            <Heading color="gray.500" as="h3" size="lg" mb="4" textAlign="left">
              Option 3 (Shopify 2.0 Themes Only)
            </Heading>
            <Text fontSize="2xl" fontWeight="bold" textAlign="left">
              Add an App Block to your product page.
            </Text>
            <Box mb="8">
              <Text textAlign="left" mb="4">
                Bounty provides an App Block for Shopify 2.0 Product Page
                Templates for easy installation of the Bounty Sign Up Widget.
                You can place it anywhere on your product page, but we suggest
                placing it directly beneath your add to cart button.
              </Text>
              <Text textAlign="left" mb="4">
                With App Blocks you can easily change colors and spacing, or
                reorder the App Block on your page.
              </Text>
              <Button
                mb={['10', '10', '0']}
                variant="link"
                _hover={{
                  textDecoration: 'none',
                }}
                size="md"
                textDecoration="underline"
                textAlign="left"
                whiteSpace="initial"
                event="Learn More About App Blocks Clicked"
                onClick={onOpen}
              >
                Click here to learn more about customizing App Blocks
              </Button>
            </Box>
            <Button
              as={Link}
              mb={['10', '10', '0']}
              data-testid="app-blocks-install-link"
              variant="outline"
              href={`https://${data.currentStore.shopUrl}/admin/themes/current/editor?template=product`}
              _hover={{
                textDecoration: 'none',
              }}
              size="lg"
              isExternal
              event="Install Snippet With App Blocks Clicked"
            >
              Take me there
            </Button>
          </Box>
          <Box ml="4" flexShrink={0}>
            <Image
              width="225px"
              src={AppBlocksImage}
              alt="Install app block image"
            />
          </Box>
        </Flex>
      </Box>
      <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>More About App Blocks</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box mb="8">
              <Heading mb="2" as="h3" size="sm">
                When your Bounty App Block is enabled it will show up only if
                the following conditions are met:
              </Heading>
              <UnorderedList pl="4">
                <ListItem>
                  Snippets are globally enabled (Inside the Snippets page of
                  your Bounty dashboard)
                </ListItem>
                <ListItem>
                  The Product Page you’re viewing is for a product that is
                  enabled in your Bounty dashboard. (You can enable / disable
                  the products you want to promote with Bounty individually.)
                </ListItem>
                <ListItem>
                  You’re underneath your target budget - Once you’ve hit your
                  budget the Bounty App Block will automatically stop showing up
                  on your product page templates until your budget renews in the
                  next month.
                </ListItem>
              </UnorderedList>
            </Box>
            <Box mb="8">
              <Heading mb="2" as="h3" size="sm">
                Adding, Removing and Deactivating App Blocks:
              </Heading>
              <Text mb="6">
                To add an app block, see the following screenshot below. Remove
                and re-ordering app blocks is simple and works like any other
                Shopify 2.0 app block docs.{' '}
                <Link
                  isExternal
                  href="https://help.shopify.com/en/manual/online-store/themes/theme-structure/extend/apps#app-blocks"
                >
                  Read more about App Blocks from Shopify.
                </Link>
              </Text>
              <Image
                display="block"
                mx="auto"
                width="90%"
                src={
                  'https://res.cloudinary.com/earn-bounty/image/upload/v1640649739/adding-bounty-app-block_qprczy.gif'
                }
              />
            </Box>
            <Box>
              <Text mb="6">
                Once added, the app block can be removed through the Shopify
                theme admin panel, or re-arranged with the drag-and-drop
                functionality.
              </Text>
              <Image
                display="block"
                mx="auto"
                width="90%"
                src={
                  'https://res.cloudinary.com/earn-bounty/image/upload/v1640649739/customize-app-block_jhjiic.gif'
                }
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button
              event="Product Place App Blocks Install Modal Cancel Clicked"
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
