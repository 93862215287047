import { Box, BoxProps, Text, Flex } from '@bounty/brands-design-system'
import { useLocation, Link } from 'react-router-dom'
import { WELCOME_ROUTE_STEPS } from './const'

type WelcomeNavigatorProps = BoxProps

export const WelcomeNavigator = (props: WelcomeNavigatorProps) => {
  const { pathname } = useLocation()

  // Do not render this component for all welcome route steps
  if (WELCOME_ROUTE_STEPS.some((x) => pathname.includes(x.path)) === false) {
    return null
  }

  const currentStep =
    WELCOME_ROUTE_STEPS.findIndex((step) => pathname.includes(step.path)) + 1
  const totalSteps = WELCOME_ROUTE_STEPS.length

  return (
    <Box {...props}>
      <Text fontWeight="semibold" textAlign="center">
        Step {currentStep} / {totalSteps}
      </Text>
      <Flex>
        {WELCOME_ROUTE_STEPS.map((step, index) => {
          const isPreviousOrCurrentStep = index + 1 <= currentStep

          const stepPill = (
            <Box py="2">
              <Box
                height="3px"
                width="25px"
                backgroundColor={isPreviousOrCurrentStep ? 'black' : 'gray.500'}
              ></Box>
            </Box>
          )
          return (
            <Box px="1" key={step.path}>
              {isPreviousOrCurrentStep ? (
                <Link to={`./${step.path}`}>{stepPill}</Link>
              ) : (
                stepPill
              )}
            </Box>
          )
        })}
      </Flex>
    </Box>
  )
}
