import { TextProps, Text } from '@bounty/brands-design-system'
import { prettyFloatingDate } from '@bounty/utils'

export type DateRangeDisplayProps = TextProps & {
  start: Date
  end: Date
}

export const DateRangeDisplay = ({
  start,
  end,
  ...rest
}: DateRangeDisplayProps) => {
  return (
    <Text {...rest}>
      {prettyFloatingDate(start.toISOString())} &#10230;{' '}
      {prettyFloatingDate(end.toISOString())}
    </Text>
  )
}
