import { isNil } from '@bounty/utils'
import { useParams } from 'react-router-dom'

export const useParamsOrThrow = <T extends string>(params: T[]) => {
  const rawParams = useParams<string>()

  // @ts-expect-error - We build this object up with T directly below but it wants those keys now
  const returnParams: Record<T, string> = {}
  params.forEach((p) => {
    const maybeParam = rawParams[p]

    if (isNil(maybeParam)) {
      throw new Error(`Param, ${p}, could not be found!`)
    }

    returnParams[p] = maybeParam
  })

  return returnParams
}
