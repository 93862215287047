import { CellRendererParams } from './ProductsGrid'
import { Storefront } from '../../generated/backendGraphql'

export type FeaturedProductIdType = Storefront['featuredProductIds'][0]
export type FeaturedProductIdsType = Storefront['featuredProductIds']

export const filterFeaturedProductIds = (
  params: CellRendererParams,
  featuredProductIds: FeaturedProductIdsType | undefined,
) => {
  const allRowsData = params.api.getRenderedNodes().map((row) => row.data)

  const disabledProductIds: FeaturedProductIdType[] = []
  allRowsData.forEach((row) => {
    if (row?.enabled === false) {
      disabledProductIds.push(row.id)
    }
  })

  if (
    params.data &&
    params.data!.enabled &&
    !disabledProductIds.includes(params.data.id)
  ) {
    disabledProductIds.push(params.data?.id)
  }

  const filteredFeaturedProductIds: FeaturedProductIdType[] = []

  if (featuredProductIds) {
    featuredProductIds.forEach((id) => {
      if (!disabledProductIds.includes(id)) {
        filteredFeaturedProductIds.push(id)
      }
    })
  }
  return filteredFeaturedProductIds
}
