import { Pseudos } from '@chakra-ui/react'

export const colors = {
  transparent: 'transparent',
  current: 'currentColor',
  black: '#000000',
  white: '#FFFFFF',

  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.8)',
    900: 'rgba(255, 255, 255, 0.92)',
  },
  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.8)',
    900: 'rgba(0, 0, 0, 0.92)',
  },
  gray: {
    50: '#FBFCFC',
    100: '#F8F9FB',
    200: '#F2F3F6',
    300: '#EAECF0',
    400: '#CFD4DC',
    500: '#98A1B2',
    600: '#576275',
    700: '#344053',
    800: '#1D2838',
    900: '#0F1728',
  },
  red: {
    50: '#FFF0F0',
    100: '#FED7D7',
    200: '#FEB2B2',
    300: '#FC8181',
    400: '#F56565',
    500: '#E53E3E',
    600: '#C53030',
    700: '#9B2C2C',
    800: '#822727',
    900: '#63171B',
  },
  yellow: {
    50: '#FFF7EC',
    100: '#FFEFDA',
    200: '#FFE0B5',
    300: '#FFD190',
    400: '#FFC26B',
    500: '#FFB347',
    600: '#CC8F38',
    700: '#996B2A',
    800: '#65471C',
    900: '#33230E',
  },
  green: {
    50: '#EAFFE0',
    100: '#D4FBC1',
    200: '#B9F29D',
    300: '#A6EC84',
    400: '#8FDA6A',
    500: '#73BD4E',
    600: '#5FA93A',
    700: '#50942E',
    800: '#3F7723',
    900: '#32611B',
  },
  purple: {
    50: '#F1F1FE',
    100: '#DADAFC',
    200: '#B5B6FA',
    300: '#9192F8',
    400: '#6C6EF6',
    500: '#484AF4',
    600: '#393BC3',
    700: '#2B2C92',
    800: '#1C1D61',
    900: '#0E0E30',
  },
}

type TokenType = 'primary' | 'success' | 'warning' | 'error' | 'neutral'
type TokenTint = '50' | `${1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9}00`
export type SemanticColorToken = `${TokenType}.${TokenTint}`

export type SemanticColorTokenValue = Partial<Pseudos> & { default: string }

export const buildSemanticColorTokens = (): Record<
  SemanticColorToken,
  SemanticColorTokenValue
> => {
  const tokenToColorMap = [
    ['primary', 'purple'],
    ['success', 'green'],
    ['warning', 'yellow'],
    ['error', 'red'],
    ['neutral', 'gray'],
  ] as const

  const availableColorTints = [
    '50',
    '100',
    '200',
    '300',
    '400',
    '500',
    '600',
    '700',
    '800',
    '900',
  ] as const

  // @ts-expect-error - Can't figure out how to build a strongly typed object. Open to ideas!
  return tokenToColorMap.reduce((acc, [token, color]) => {
    availableColorTints.forEach((tint, index) => {
      // @ts-expect-error - Can't figure out how to build a strongly typed object. Open to ideas!
      acc[`${token}.${tint}`] = {
        default: `${color}.${availableColorTints[index]}`,
      }
    })
    return acc
  }, {})
}

export const semanticColorTokens = buildSemanticColorTokens()

export const getSemanticColorValue = (
  color: SemanticColorToken,
  key: keyof SemanticColorTokenValue = 'default',
) => {
  const colorValue = semanticColorTokens[color][key]

  if (!colorValue) {
    throw new Error(`Could not find color for ${color}`)
  }

  const [rootColor, rootValue] = colorValue.split('.') as [
    keyof typeof colors,
    string,
  ]

  if (!rootValue) {
    return colors[rootColor]
  }

  try {
    // @ts-expect-error - Can't get the index types to work but I guard above for the one wrong state
    return colors[rootColor][rootValue]
  } catch (error) {
    return '#000000'
  }
}

// TODO: Fancy compute?
export type Color = keyof typeof colors
