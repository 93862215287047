import { GroupBase } from 'chakra-react-select'
import dlv from 'dlv'
import { forwardRef } from 'react'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import { FastFormControl } from './FastFormControl'
import { FancySelect, FancySelectProps } from '../FancySelect'
import { FastFormElementProps } from './types'
import { getLabelFromName } from './utils'

export type FastFormFancySelectBaseProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
> = {
  /**
   * React Select by default returns the entire object. In a lot of cases we only want to return the string value(s). This shims that
   * functionality into something the is react hook form friendly
   */
  stringMode?: boolean
} & FancySelectProps<Option, IsMulti, Group>

export const FastFormFancySelectBase = forwardRef<
  any,
  FastFormFancySelectBaseProps<any, any, any>
>(
  (
    { stringMode = true, onChange, isMulti = false, options, value, ...rest },
    ref,
  ) => {
    return (
      <FancySelect
        selectRef={ref}
        isClearable={false}
        {...rest}
        isMulti={isMulti}
        selectedOptionColor="purple"
        value={
          stringMode
            ? isMulti
              ? options?.filter((option) => value?.includes(option.value))
              : options?.find((option) => option.value === value)
            : value
        }
        options={options}
        onChange={(newValue, meta) => {
          if (stringMode) {
            onChange?.(
              isMulti ? newValue.map((v: any) => v.value) : newValue.value,
              meta,
            )
          } else {
            onChange?.(newValue, meta)
          }
        }}
      />
    )
  },
)

FastFormFancySelectBase.displayName = 'FastFormFancySelectBase'

export type FastFormFancySelectProps<
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
  TFieldValues extends FieldValues = FieldValues,
> = FastFormElementProps<
  FastFormFancySelectBaseProps<Option, IsMulti, Group>,
  TFieldValues
> &
  Pick<
    FastFormFancySelectBaseProps<Option, IsMulti, Group>,
    'isMulti' | 'isClearable' | 'isSearchable' | 'options'
  > & {
    placeholder?: string
  }

export const FastFormFancySelect = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
  TFieldValues extends FieldValues = FieldValues,
>({
  label,
  name,
  helpText,
  labelProps,
  tooltipText,
  errorProps,
  isRequired,
  isDisabled,
  formControlProps,
  formElementProps,
  placeholder,
  showLabel,
  ...rest
}: FastFormFancySelectProps<Option, IsMulti, Group, TFieldValues>) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<TFieldValues>()
  const error = dlv(errors, name)

  return (
    <FastFormControl
      error={error}
      helpText={helpText}
      label={label ?? getLabelFromName(name)}
      labelProps={labelProps}
      tooltipText={tooltipText}
      errorProps={errorProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
      showLabel={showLabel}
      {...formControlProps}
    >
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <FastFormFancySelectBase
              placeholder={placeholder}
              {...formElementProps}
              {...rest}
              {...field}
            />
          )
        }}
      />
    </FastFormControl>
  )
}
