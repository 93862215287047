import {
  FormErrorMessage as FormErrorMessageCore,
  FormErrorMessageProps,
} from './Chakra'
import { defineStyleConfig, forwardRef } from '@chakra-ui/react'

export const formControlTheme = defineStyleConfig({
  baseStyle: () => {
    return {
      container: {
        marginBottom: '6',
      },
      requiredIndicator: {
        color: 'neutral.600',
      },
    }
  },
})

export const FormErrorMessage = forwardRef<FormErrorMessageProps, any>(
  ({ children, ...rest }, ref) => {
    return (
      <FormErrorMessageCore
        data-element-name="form-error-message"
        ref={ref}
        {...rest}
      >
        {children}
      </FormErrorMessageCore>
    )
  },
)
