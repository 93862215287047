import { Flex } from '@bounty/brands-design-system'

type FormWrapperProps = {
  children: React.ReactNode
}

export const FormWrapper = ({ children }: FormWrapperProps) => {
  return (
    <Flex
      p="8"
      align="flex-start"
      justify="center"
      direction="column"
      width={['100%', null, '45%']}
      borderBottom={['1px solid', null, 'none']}
      borderRight={['none', null, '1px solid']}
      borderColor={['gray.200', null, 'gray.200']}
    >
      {children}
    </Flex>
  )
}
