import {
  UnreachableCaseError,
  getLastWeek,
  getLastMonth,
  getLastYear,
  getLast30Days,
  getLast3Months,
  getLast7Days,
  getYesterday,
  getToday,
} from '@bounty/utils'
import { useMemo, useState } from 'react'
import { TimeSeriesBin } from '../../../../../generated/backendGraphql'
import { Select } from '@bounty/brands-design-system'
import { useGiftsHistoryDispatch } from '../../useGiftsHistory'

export type DateRangeType =
  | 'TODAY'
  | 'YESTERDAY'
  | 'WEEK'
  | '7_DAYS'
  | 'MONTH'
  | '30_DAYS'
  | '3_MONTHS'
  | '12_MONTHS'

const getValuesForRange = (
  range: DateRangeType,
): { values: [Date, Date]; bin: TimeSeriesBin; comparison: [Date, Date] } => {
  switch (range) {
    case 'TODAY':
      return { values: getToday(), bin: 'HOUR', comparison: getYesterday() }
    case 'YESTERDAY':
      return {
        values: getYesterday(),
        bin: 'HOUR',
        comparison: getYesterday(getYesterday()[0]),
      }
    case 'WEEK':
      return {
        values: getLastWeek(),
        bin: 'DAY',
        comparison: getLastWeek(getLastWeek()[0]),
      }
    case '7_DAYS':
      return {
        values: getLast7Days(),
        bin: 'DAY',
        comparison: getLast7Days(getLast7Days()[0]),
      }
    case '30_DAYS':
      return {
        values: getLast30Days(),
        bin: 'DAY',
        comparison: getLast30Days(getLast30Days()[0]),
      }
    case 'MONTH':
      return {
        values: getLastMonth(),
        bin: 'DAY',
        comparison: getLastMonth(getLastMonth()[0]),
      }
    case '3_MONTHS':
      return {
        values: getLast3Months(),
        bin: 'WEEK',
        comparison: getLast3Months(getLast3Months()[0]),
      }
    case '12_MONTHS':
      return {
        values: getLastYear(),
        bin: 'MONTH',
        comparison: getLastYear(getLastYear()[0]),
      }
    default:
      throw new UnreachableCaseError(range)
  }
}

export const DateRangeSelect = () => {
  const { setValues } = useGiftsHistoryDispatch()

  const [range, setRange] = useState<DateRangeType>('30_DAYS')

  const {
    values: [startDate, endDate],
    comparison: [comparisonStartDate, comparisonEndDate],
    bin,
  } = useMemo(() => getValuesForRange(range), [range])

  const handleSelectOption = (val: DateRangeType) => {
    setRange(val)

    const [startDate, endDate] = getValuesForRange(val).values

    setValues((prev) => ({
      ...prev,
      value: {
        ...prev.value,
        params: {
          ...prev.value.params,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          skip: 0,
        },
      },
    }))
  }

  return (
    <Select
      width="100%"
      maxWidth="175px"
      value={range.toLowerCase()}
      onChange={(e) =>
        handleSelectOption(e.target.value.toUpperCase() as DateRangeType)
      }
      borderRadius="sm"
      size="lg"
    >
      <option value="today">Today</option>
      <option value="yesterday">Yesterday</option>
      <option value="7_days">Last 7 Days</option>
      <option value="30_days">Last 30 Days</option>
      <option value="week">Last Week</option>
      <option value="month">Last Month</option>
      <option value="3_months">Last 3 Months</option>
      <option value="12_months">Last 12 Months</option>
    </Select>
  )
}
