import dlv from 'dlv'
import { forwardRef } from 'react'
import { FieldValues, useFormContext } from 'react-hook-form'
import { Switch, SwitchProps } from '../../Chakra'
import { FastFormControl } from './FastFormControl'
import { FastFormElementProps } from './types'
import { getLabelFromName } from './utils'

export type FastFormSwitchBaseProps = SwitchProps
export const FastFormSwitchBase = forwardRef<any, FastFormSwitchBaseProps>(
  (props, ref) => {
    return <Switch ref={ref} {...props} />
  },
)

FastFormSwitchBase.displayName = 'FastFormSwitchBase'

export type FastFormSwitchProps<
  TFieldValues extends FieldValues = FieldValues,
> = FastFormElementProps<SwitchProps, TFieldValues>

export const FastFormSwitch = <TFieldValues extends FieldValues = FieldValues>({
  label,
  name,
  helpText,
  labelProps,
  tooltipText,
  errorProps,
  isRequired,
  isDisabled,
  formControlProps,
  formElementProps,
  showLabel,
}: FastFormSwitchProps<TFieldValues>) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<TFieldValues>()
  const error = dlv(errors, name)

  return (
    <FastFormControl
      error={error}
      helpText={helpText}
      label={label ?? getLabelFromName(name)}
      labelProps={labelProps}
      tooltipText={tooltipText}
      errorProps={errorProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
      showLabel={showLabel}
      {...formControlProps}
    >
      <FastFormSwitchBase {...formElementProps} {...register(name)} />
    </FastFormControl>
  )
}
