import {
  Box,
  BoxProps,
  SkeletonCircle,
  Skeleton,
  Flex,
  HStack,
  Avatar,
  Text,
  Link,
  useTheme,
  VStack,
  Heading,
} from '@bounty/brands-design-system'
import { useQueryBackend } from '../apollo/backend/hooks'
import {
  RecentActivityDocument,
  RecentActivityQuery,
  ActivityFeedEntryType,
} from '../generated/backendGraphql'
import { UnreachableCaseError } from '@bounty/utils'
import TimeAgo from 'react-timeago'
import { first } from 'lodash'
import { getUserProfileImage } from '../utils/externalContentLinksGetters'

const getPrettyNameForActivityType = (type: ActivityFeedEntryType) => {
  switch (type) {
    case 'SIGNUP':
      return 'New Creator'
    case 'SPARK_CODE_COMPLETED':
      return 'New Spark Code'
    case 'BOUNTY_APPROVED':
      return 'New Bounty'
    default:
      throw new UnreachableCaseError(type)
  }
}

type RecentActivityItemProps = BoxProps & {
  activity: RecentActivityQuery['activityFeed']['activities'][0]
}

export const RecentActivityItem = ({
  activity,
  ...rest
}: RecentActivityItemProps) => {
  const theme = useTheme()

  const socialProfile = first(activity.socialProfiles)
  const profilePic = getUserProfileImage(
    activity.user?.id ?? '',
    socialProfile?.type ?? 'TIKTOK',
  )

  return (
    <HStack
      width="100%"
      height="100%"
      borderBottom="1px solid"
      borderColor="gray.200"
      minHeight="90px"
      px="2"
      spacing="3"
      alignContent="center"
      {...rest}
    >
      <Box flexShrink={0}>
        <Avatar
          boxSize="40px"
          src={profilePic ?? undefined}
          name={socialProfile?.profileName ?? undefined}
        />
      </Box>
      <Box minWidth="0" flex="1" flexShrink={0}>
        {activity.bounty?.tikTokVideo?.videoUrl ? (
          <Text fontSize="sm" noOfLines={1} wordBreak="break-all">
            <Link
              fontWeight="semibold"
              isExternal={false}
              to={`/content-library/video/${activity.bounty.id}`}
            >
              View Bounty
            </Link>
          </Text>
        ) : null}
        {socialProfile ? (
          <Text
            fontSize="lg"
            fontWeight="semibold"
            noOfLines={1}
            wordBreak="break-all"
          >
            <Link
              color="inherit"
              isExternal={false}
              to={`./creators/details/${activity.user?.id}`}
            >
              {socialProfile.profileName}
            </Link>
          </Text>
        ) : null}
      </Box>
      <Flex flex="1">
        <Text fontSize="md" textAlign="center">
          {getPrettyNameForActivityType(activity.type)}
        </Text>
      </Flex>
      <Box flex="0.5">
        <TimeAgo
          style={{ fontSize: theme.fontSizes.xs }}
          date={activity.relevantDate}
        />
      </Box>
    </HStack>
  )
}

export const RecentActivityItemSkeleton = ({ ...rest }: BoxProps) => {
  return (
    <HStack
      borderBottom="1px solid"
      borderColor="gray.200"
      minHeight="90px"
      px="2"
      spacing="3"
      alignContent="center"
      {...rest}
    >
      <Box flexShrink={0}>
        <SkeletonCircle boxSize="12px" />
      </Box>
      <Box flexShrink={0}>
        <SkeletonCircle boxSize="40px" />
      </Box>
      <Box minWidth="0" flex="1" flexShrink={0}>
        <Skeleton mb="4" height="1rem" />
        <Skeleton width="100%" height="1rem" />
      </Box>
      <Flex flexShrink={0} flex="1">
        <Skeleton width="100%" height="1rem" />
      </Flex>
      <Box flexShrink={0} flex="0.5">
        <Skeleton width="100%" height="1rem" />
      </Box>
    </HStack>
  )
}

type RecentActivityProps = BoxProps

export const RecentActivity = ({ ...rest }: RecentActivityProps) => {
  const { data, loading } = useQueryBackend(RecentActivityDocument)

  return (
    <VStack width="100%" justifyContent="flex-start" minHeight="0">
      <Heading pb={3} width="100%" fontSize="xl" fontWeight="bold">
        Creators Stream
      </Heading>
      {/* {isAvailable && <ProspectsWidget />} */}
      <VStack
        width="100%"
        display="flex"
        flexDirection="column"
        minHeight="0"
        overflowY="auto"
        py="0"
        px="2"
        spacing={0}
        {...rest}
      >
        {loading || !data ? (
          <Box width="100%">
            <RecentActivityItemSkeleton />
            <RecentActivityItemSkeleton />
            <RecentActivityItemSkeleton />
            <RecentActivityItemSkeleton />
            <RecentActivityItemSkeleton />
            <RecentActivityItemSkeleton />
            <RecentActivityItemSkeleton />
            <RecentActivityItemSkeleton />
          </Box>
        ) : data.activityFeed.activities.length === 0 ? (
          <Box p="4">
            <Text>
              As creators sign up and create content you will see it appear
              here.
            </Text>
          </Box>
        ) : (
          data.activityFeed.activities.map((activity) => (
            <RecentActivityItem
              key={`${activity.relevantDate}-${activity.user?.id}-${activity.type}-${activity.bounty?.id}`}
              activity={activity}
            />
          ))
        )}
      </VStack>
    </VStack>
  )
}
