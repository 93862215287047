import { BONUS_TYPES, PENALTY_TYPES, PROBLEM_TYPES } from '@bounty/constants'
import * as z from 'zod'

export const BountyModifiersSchema = z
  .object({
    bonuses: z.array(z.enum(BONUS_TYPES)),
    penalties: z.array(z.enum(PENALTY_TYPES)),
    problems: z.array(z.enum(PROBLEM_TYPES)),
    overridePayout: z.number().optional(),
    overrideReason: z.string().optional(),
    moderationUser: z.string().email().optional(),
  })
  .strict()

export type BountyModifier = z.infer<typeof BountyModifiersSchema>

export const BountyPaymentPreviewSchema = z
  .object({
    bountyId: z.string().uuid(),
    modifiers: BountyModifiersSchema.optional(),
  })
  .strict()

export const BountyChangeSMS = z
  .object({
    accountId: z.string().uuid(),
    sms: z.string(),
    forceMigrate: z.boolean().optional().default(false),
  })
  .strict()

export const BountyModerationApproveSchema = z
  .object({
    bountyId: z.string().uuid(),
    feedbackMessage: z.string(),
    feedbackRating: z.number().int().min(0).max(10),
    modifiers: BountyModifiersSchema.optional(),
  })
  .strict()

export type BountyModerationApproveSchemaBody = z.infer<
  typeof BountyModerationApproveSchema
>

export const BountyModerationRejectSchema = z
  .object({
    bountyId: z.string().uuid(),
    feedbackMessage: z.string(),
    feedbackRating: z.number().int().min(0).max(10),
    modifiers: BountyModifiersSchema.optional(),
  })
  .strict()

export type BountyModerationRejectSchemaBody = z.infer<
  typeof BountyModerationApproveSchema
>
