import * as z from 'zod'

export type BriefCreatorNotificationTypes =
  | 'CREATORS_WITH_SOCIAL'
  | 'CREATORS_WITH_VIDEO'
  | 'CREATORS_FAVORITED'

export const createBriefInput = z
  .object({
    name: z.string().max(100).min(1),
    content: z.string().max(400).min(1),
    videoUrl: z.union([z.string().url().nullish(), z.literal('')]),
    isActive: z.boolean().default(true),
    isPublic: z.boolean().default(true),
    // this is for frontend only, should not submit to backend
    visibility: z.enum(['PUBLIC', 'PRIVATE', 'INACTIVE']).optional(),
    minPayout: z.number().int().min(0).max(200).optional(),
    shouldNotifyUsers: z.boolean().optional(),
    notifyType: z
      .enum([
        'CREATORS_WITH_SOCIAL',
        'CREATORS_WITH_VIDEO',
        'CREATORS_FAVORITED',
      ])
      .optional(),
    notifyDate: z
      .string()
      .refine((value) => !isNaN(Date.parse(value)), { message: 'Invalid date' })
      .transform((value) => new Date(value))
      .optional(),
  })
  .strict()

export const createBriefInput_backend = createBriefInput.extend({
  notifyDate: z.date().optional(),
})

export type CreateBriefInput = z.infer<typeof createBriefInput>

export const updateBriefInput = z
  .object({
    id: z.string().uuid(),
    name: z.string().max(100).min(1),
    content: z.string().max(400).min(1),
    videoUrl: z.union([z.string().url().nullish(), z.literal('')]),
    isActive: z.boolean().default(true),
    isPublic: z.boolean().default(true),
    // this is for frontend only, should not submit to backend
    visibility: z.enum(['PUBLIC', 'PRIVATE', 'INACTIVE']).optional(),
  })
  .strict()

export type UpdateBriefInput = z.infer<typeof updateBriefInput>
