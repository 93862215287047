import { isNotNil } from '@bounty/utils'
import { first } from 'lodash'
import { RefObject } from 'react'

export const handleFileUpload = (params: {
  e: React.ChangeEvent<HTMLInputElement>
  updateInvitesCsv: (csv: string) => void
  inputRef: RefObject<HTMLInputElement | null>
}): void => {
  const { e, updateInvitesCsv, inputRef } = params

  const reader = new FileReader()
  const file = first(e.target.files)

  if (isNotNil(file)) {
    reader.readAsText(file)
    reader.onload = (file) => {
      updateInvitesCsv(`${file?.target?.result}`)

      // Clear input so it can be called again by the same file
      if (inputRef.current) {
        inputRef.current.value = ''
      }
    }
  }
}
