import {
  Box,
  Flex,
  FastForm,
  FastFormButton,
  FastFormInput,
  FastFormTextarea,
  Skeleton,
  Button,
  useToast,
  FastFormFancySelect,
  FastFormCreatableSelect,
  FastFormNumberInput,
  FastFormCheckboxOption,
  VStack,
  Text,
  Alert,
  AlertTitle,
  AlertDescription,
  Link,
} from '@bounty/brands-design-system'
import * as z from 'zod'
import {
  CreateStoreGiftProductDocument,
  GiftFormDocument,
  GiftTableDocument,
  ProspectsTableDocument,
} from '../generated/backendGraphql'
import { useQueryBackend, useMutationBackend } from '../apollo/backend/hooks'
import { storeGiftInputSchema } from '@bounty/schemas'
import { Control, useFieldArray } from 'react-hook-form'
import { OFFER_EXPIRATION_DAYS_DEFAULT } from '@bounty/constants'

type StoreGiftInputSchemaType = z.infer<typeof storeGiftInputSchema>

export type GiftFormProps = {
  onSubmitSuccess: () => void
}

const StoreProductSelector = ({
  control,
}: {
  control: Control<StoreGiftInputSchemaType>
}) => {
  const { data, loading } = useQueryBackend(GiftFormDocument, {
    // They could toggle a bunch of stuff on the product table causing this to get out of sync. Not refetching there
    // because there could be many tweaks causing a lot of extra queries.
    fetchPolicy: 'network-only',
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'products',
  })

  if (loading || !data) {
    return <Skeleton height="40px" mb="4" />
  }

  return (
    <Box>
      {fields.map((field, index) => {
        return (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            key={field.id}
          >
            <Box width="md" mr="2">
              <FastFormFancySelect<
                { label: string; value: string },
                false,
                any,
                StoreGiftInputSchemaType
              >
                name={`products.${index}.productId`}
                label="Product"
                options={data.enabledStoreProducts.map((product) => {
                  return {
                    label: product.productName,
                    value: product.productId,
                  }
                })}
                isRequired
              />
            </Box>
            <Box width="md" mr="2">
              <FastFormNumberInput<StoreGiftInputSchemaType>
                name={`products.${index}.quantity`}
                label="Quantity"
              />
            </Box>
            <Button
              variant={'outline'}
              minWidth="min-content"
              event={'Gift Product Create Remove Product Clicked'}
              isDisabled={fields.length === 1}
              onClick={() => {
                remove(index)
              }}
            >
              Remove
            </Button>
          </Flex>
        )
      })}

      <Button
        width="100%"
        mb="4"
        type="button"
        event="Gift Product Create Add Product Clicked"
        onClick={() => {
          append({
            productId: '',
            quantity: 1,
          })
        }}
      >
        + Add Product
      </Button>
    </Box>
  )
}

type PaymentDetailsProps = {
  hasMinPayment: boolean
  hasPayPerView: boolean
  minBountyPayment: number | undefined
}

const paymentDescriptions = ({
  hasMinPayment,
  hasPayPerView,
  minBountyPayment,
}: PaymentDetailsProps) => {
  if (hasMinPayment && hasPayPerView && minBountyPayment) {
    return (
      <AlertDescription>
        <Text as="span">
          Creators will receive free product and an additional{' '}
        </Text>
        <Text as="span" fontWeight="bold">
          guaranteed payout
        </Text>{' '}
        <Text as="span">of</Text>{' '}
        <Text as="span" fontWeight="bold">
          ${minBountyPayment}
        </Text>{' '}
        <Text as="span">
          when they post an approved video. If they earn more than
        </Text>
        <Text as="span" fontWeight="bold">
          {' '}
          ${minBountyPayment}
        </Text>{' '}
        based on
        <Text as="span" fontWeight="bold">
          {' '}
          views and quality
        </Text>{' '}
        they will be rewarded that amount instead. You can edit this reward
        amount{' '}
        <Link
          isExternal={false}
          to="/you-offer"
          textDecoration="underline"
          color="inherit"
        >
          here
        </Link>
        .
      </AlertDescription>
    )
  }
  if (hasMinPayment && minBountyPayment) {
    return (
      <AlertDescription>
        <Text as="span">
          Creators will receive free product and receive an additional{' '}
        </Text>
        <Text as="span" fontWeight="bold">
          guaranteed payout
        </Text>
        <Text as="span"> of </Text>
        <Text as="span" fontWeight="bold">
          ${minBountyPayment}
        </Text>{' '}
        <Text as="span">when they post an approved video.</Text>
      </AlertDescription>
    )
  }
  if (hasMinPayment && !minBountyPayment) {
    return (
      <AlertDescription>
        <Text>Must add a minimum payment amount to your offer.</Text>
      </AlertDescription>
    )
  }

  if (hasPayPerView) {
    return (
      <AlertDescription>
        <Text as="span">
          Creators will receive free product and an additional reward based on{' '}
        </Text>
        <Text as="span" fontWeight="bold">
          views and quality
        </Text>{' '}
        <Text as="span">
          when they post an approved video. You can edit this reward amount{' '}
          <Link
            isExternal={false}
            to="/your-offer"
            textDecoration="underline"
            color="inherit"
          >
            here
          </Link>
        </Text>
        .
      </AlertDescription>
    )
  }

  return (
    <AlertDescription>
      <Text as="span">Creators will only receive free product.</Text>
    </AlertDescription>
  )
}

export const PayoutDetails = ({
  hasMinPayment,
  hasPayPerView,
  minBountyPayment,
}: PaymentDetailsProps) => {
  return (
    <Alert status="payment" mb="8">
      <AlertTitle>Payout Details </AlertTitle>
      {paymentDescriptions({ hasMinPayment, hasPayPerView, minBountyPayment })}
    </Alert>
  )
}

export const GiftForm = ({ onSubmitSuccess }: GiftFormProps) => {
  const toast = useToast()
  const { data, loading } = useQueryBackend(GiftFormDocument)
  const [createStoreGiftMutation, { loading: createStoreGiftLoading }] =
    useMutationBackend(CreateStoreGiftProductDocument, {
      refetchQueries: [
        { query: GiftTableDocument },
        { query: ProspectsTableDocument },
      ],
    })

  return (
    <Box>
      {!data || loading ? (
        <VStack mt={8} spacing={4}>
          <Skeleton width="100%" height="10" />
          <Skeleton width="100%" height="10" />
          <Skeleton width="100%" height="10" />
          <Skeleton width="100%" height="10" />
        </VStack>
      ) : (
        <FastForm<StoreGiftInputSchemaType>
          data-testid="gift-form"
          schema={storeGiftInputSchema}
          formProps={{
            defaultValues: {
              products: [{ productId: '', quantity: 1 }],
              offerExpirationDays: OFFER_EXPIRATION_DAYS_DEFAULT,
              hasMinPayment: false,
              hasPayPerView: true,
              briefId: '',
            },
          }}
          onSubmit={async ({
            name,
            products,
            shopifyTags,
            shopifyNote,
            hasMinPayment,
            hasPayPerView,
            minBountyPayment,
            briefId,
          }) => {
            const { data } = await createStoreGiftMutation({
              variables: {
                input: {
                  name: name,
                  products: products,
                  shopifyNote: shopifyNote,
                  shopifyTags: shopifyTags ?? [],
                  hasMinPayment: hasMinPayment ?? false,
                  hasPayPerView: hasPayPerView ?? true,
                  minBountyPayment: hasMinPayment
                    ? minBountyPayment
                    : undefined,
                  briefId: briefId,
                },
              },
            })
            if (data) {
              toast({
                status: 'success',
                description: 'New gift created!',
              })
              onSubmitSuccess()
            }
          }}
        >
          {({ control, watch, register }) => {
            const hasMinPayment = watch('hasMinPayment')
            const hasPayPerView = watch('hasPayPerView')
            const minBountyPayment = watch('minBountyPayment')
            return (
              <Box>
                <FastFormInput<StoreGiftInputSchemaType>
                  label="Gift Name"
                  name="name"
                  placeholder="Gift Name"
                  helpText={"Internal only - creators won't see this"}
                  isRequired
                />

                <FastFormFancySelect<
                  { label: string; value: string },
                  false,
                  any,
                  StoreGiftInputSchemaType
                >
                  label="Brief"
                  name="briefId"
                  placeholder="Briefs"
                  options={data.briefs.map((brief) => ({
                    value: brief.id,
                    label: brief.name,
                  }))}
                  isRequired
                  helpText={
                    <Text color="gray.600" size="sm" mb="2">
                      Select a brief for the creator or{' '}
                      <Link
                        isExternal={false}
                        to="/briefs/new"
                        textDecoration="underline"
                        color="inherit"
                      >
                        create one
                      </Link>
                      .
                    </Text>
                  }
                />

                <Box mb="4">
                  <Text as="span">In addition to free product, </Text>
                  <Text as="span" fontWeight="bold">
                    how do you want to reward creators when they post?
                  </Text>
                </Box>
                <Box mb="4">
                  <VStack alignItems="flex-start" spacing={'4'}>
                    <FastFormCheckboxOption {...register('hasMinPayment')}>
                      Guaranteed payout
                    </FastFormCheckboxOption>
                    <FastFormCheckboxOption {...register('hasPayPerView')}>
                      Pay for quality + views
                    </FastFormCheckboxOption>
                  </VStack>
                </Box>

                {hasMinPayment && (
                  <FastFormNumberInput<StoreGiftInputSchemaType>
                    label="Minimum Payment Amount ($)"
                    name="minBountyPayment"
                    placeholder="15"
                    isRequired
                    helpText={
                      'Creators will receive this amount at a minimum for posting an approved piece of content about this gift.'
                    }
                  />
                )}
                <PayoutDetails
                  hasMinPayment={hasMinPayment}
                  hasPayPerView={hasPayPerView}
                  minBountyPayment={minBountyPayment}
                />

                <FastFormNumberInput<StoreGiftInputSchemaType>
                  label="Offer Expiration (Days)"
                  name="offerExpirationDays"
                  placeholder="30"
                />

                <StoreProductSelector control={control} />

                <FastFormCreatableSelect
                  name="shopifyTags"
                  label="Shopify Order Tags"
                  isMulti
                  helpText="Orders created from this gift will be given the tags you add here."
                />

                <FastFormTextarea<StoreGiftInputSchemaType>
                  label="Note"
                  name="shopifyNote"
                  placeholder="Add a note for your gift"
                  maxCharacterLimit={500}
                  helpText="Shopify orders created from this gift will have this note attached to them in your Shopify dashboard."
                />
                <Flex justifyContent="flex-end">
                  <FastFormButton
                    event="Gift Product Create Save Clicked"
                    data-testid="gift-form-button"
                    isLoading={createStoreGiftLoading}
                  >
                    Save
                  </FastFormButton>
                </Flex>
              </Box>
            )
          }}
        </FastForm>
      )}
    </Box>
  )
}
