import { defineStyle, defineStyleConfig, forwardRef } from '@chakra-ui/react'
import { getSemanticColorValue } from '../foundations'
import {
  Text,
  Textarea as TextareaCore,
  TextareaProps as ChakraTextareaProps,
} from './Chakra'
import { useState } from 'react'

const outline = defineStyle({
  borderColor: 'neutral.300',
  _hover: {
    borderColor: 'neutral.400',
  },
  _invalid: {
    borderColor: 'error.500',
    boxShadow: `0 0 0 1px ${getSemanticColorValue('error.500')}`,
  },
  _focusVisible: {
    zIndex: 1,
    borderColor: 'primary.500',
    boxShadow: `0 0 0 1px ${getSemanticColorValue('primary.500')}`,
  },
})

export const textareaTheme = defineStyleConfig({
  variants: {
    outline,
  },
})

export type TextareaProps = ChakraTextareaProps & { maxCharacterLimit?: number }

export const Textarea = forwardRef<TextareaProps, any>(
  (
    {
      onChange,
      maxCharacterLimit,
      isInvalid = false,
      marginBottom,
      marginLeft,
      marginRight,
      marginTop,
      mt,
      my,
      mr,
      mx,
      ml,
      mb,
      ...rest
    },
    ref,
  ) => {
    const [value, setValue] = useState('')

    return (
      <>
        <TextareaCore
          ref={(innerRef) => {
            if (typeof ref === 'function') {
              ref(innerRef)
            } else if (ref) {
              ref.current = innerRef
            }

            setValue(innerRef?.value ?? '')
          }}
          onChange={(e) => {
            setValue(e.target.value)
            onChange?.(e)
          }}
          {...rest}
        />
        {/* This assumes usage in a form control and positions relative to that */}
        {maxCharacterLimit != null && maxCharacterLimit > 0 && (
          <Text
            position="absolute"
            bottom={'0px'}
            right="6px"
            fontSize="xs"
            color="gray.600"
          >
            {value.length}/{maxCharacterLimit}
          </Text>
        )}
      </>
    )
  },
)
