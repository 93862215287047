import { StoreObject } from '@apollo/client'
import { IconButton, StarIcon, Tooltip } from '@bounty/brands-design-system'

import { useMutationBackend } from '../../apollo/backend/hooks'
import {
  AddFavoriteToCreatorDocument,
  CreatorsTableDocument,
  CreatorsTableFavoritedDocument,
  RemoveFavoriteFromCreatorDocument,
} from '../../generated/backendGraphql'

interface Data extends StoreObject {
  starred: boolean
}

interface FavoriteCreatorButtonProps {
  userId: string
  data: Data
}

export const FavoriteCreatorButton = ({
  userId,
  data,
}: FavoriteCreatorButtonProps) => {
  const [addFavoriteToCreator] = useMutationBackend(
    AddFavoriteToCreatorDocument,
  )
  const [removeFavoriteFromCreator] = useMutationBackend(
    RemoveFavoriteFromCreatorDocument,
  )

  const onFavoriteClicked = async () => {
    const variables = {
      userId,
    }
    if (data.starred) {
      removeFavoriteFromCreator({
        variables,
        optimisticResponse: {
          __typename: 'Mutation',
          removeFavoriteFromCreator: true,
        },
        update(cache) {
          cache.modify({
            id: `UserDetails:${userId}`,
            fields: {
              starred() {
                return false
              },
            },
          })

          cache.modify({
            id: `StoreBountyUser:${userId}`,
            fields: {
              starred() {
                return false
              },
            },
          })

          cache.modify({
            id: `CreatorNetworkSearchUser:${userId}`,
            fields: {
              starred() {
                return false
              },
            },
          })

          const creatorsFavorited = cache.readQuery({
            query: CreatorsTableFavoritedDocument,
          })

          cache.writeQuery({
            query: CreatorsTableFavoritedDocument,
            data: {
              __typename: 'Query',
              storeCreatorsFavorited:
                creatorsFavorited?.storeCreatorsFavorited.filter(
                  (x) => x.id !== userId,
                ) ?? [],
            },
          })
        },
      })
    } else {
      addFavoriteToCreator({
        variables,
        optimisticResponse: {
          __typename: 'Mutation',
          addFavoriteToCreator: true,
        },
        update(cache) {
          cache.modify({
            id: `UserDetails:${userId}`,
            fields: {
              starred() {
                return true
              },
            },
          })

          cache.modify({
            id: `StoreBountyUser:${userId}`,
            fields: {
              starred() {
                return true
              },
            },
          })

          cache.modify({
            id: `CreatorNetworkSearchUser:${userId}`,
            fields: {
              starred() {
                return true
              },
            },
          })

          const creators = cache.readQuery({
            query: CreatorsTableDocument,
          })

          const creatorsFavorited = cache.readQuery({
            query: CreatorsTableFavoritedDocument,
          })

          const userData = creators?.storeCreators.find((x) => x.id === userId)

          cache.writeQuery({
            query: CreatorsTableFavoritedDocument,
            data: {
              __typename: 'Query',
              storeCreatorsFavorited: userData
                ? [
                    userData,
                    ...(creatorsFavorited?.storeCreatorsFavorited ?? []),
                  ]
                : creatorsFavorited?.storeCreatorsFavorited ?? [],
            },
          })
        },
      })
    }
  }
  return (
    <Tooltip hasArrow label="Favorite creator">
      <IconButton
        aria-label="Favorite button"
        variant="unstyled"
        minWidth="auto"
        minHeight="auto"
        _focus={{
          boxShadow: 'none',
        }}
        height="auto"
        onClick={onFavoriteClicked}
        icon={
          data.starred ? (
            <StarIcon weight="fill" boxSize="20px" />
          ) : (
            <StarIcon fill="gray.500" boxSize="20px" />
          )
        }
      />
    </Tooltip>
  )
}
