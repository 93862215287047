import { useState, useRef, useEffect } from 'react'
import {
  Box,
  BoxProps,
  Skeleton,
  SkeletonCircle,
  ChakraGrid,
  ChakraGridProps,
  Flex,
  Text,
  Avatar,
  AspectRatio,
  Image,
} from '@bounty/brands-design-system'
import { ContentLibraryResponse } from '../../generated/backendGraphql'
import { prettyCount } from '@bounty/utils'
import {
  ContentLibraryFavoriteButton,
  ContentLibraryItemDrawer,
} from './ContentLibraryItemDrawer'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import { MAX_FEATURED_BOUNTIES } from '@bounty/constants'
import {
  contentTypeToAccountType,
  getContentVideoLocation,
  getPreviewImageLinkForContent,
  getUserProfileImage,
} from '../../utils/externalContentLinksGetters'
import {
  getSocialMediaFromContentType,
  getSocialMediaLogo,
} from '../../utils/preferredSocialProfileUtils'

export const ContentLibraryItemLoading = ({
  sentryRef,
}: {
  sentryRef?: any
}) => {
  return (
    <Box ref={sentryRef}>
      <Skeleton height="400px" width="100%" mb="4" />
      <Flex justifyContent="space-between">
        <Skeleton height="20px" width="90px" />
        <SkeletonCircle size="25px" />
      </Flex>
    </Box>
  )
}

export const ContentLibraryItemsLoading = ({
  sentryRef,
}: {
  sentryRef: any
}) => {
  return (
    <>
      <ContentLibraryItemLoading sentryRef={sentryRef} />
      <ContentLibraryItemLoading />
      <ContentLibraryItemLoading />
      <ContentLibraryItemLoading />
      <ContentLibraryItemLoading />
      <ContentLibraryItemLoading />
      <ContentLibraryItemLoading />
    </>
  )
}

export type ContentLibraryItemProps = BoxProps & {
  item: ContentLibraryResponse['items'][0]
  onFavoriteClicked: (item: ContentLibraryResponse['items'][0]) => void
  shouldPlayVideo: boolean
  variant?: 'storefront'
  selectedVideoIds?: string[]
  setSelectedVideoIds?: React.Dispatch<React.SetStateAction<string[]>>
}

// https://stackoverflow.com/questions/6877403/how-to-tell-if-a-video-element-is-currently-playing/6877530#6877530
const isVideoPlaying = (video: HTMLVideoElement) =>
  !!(
    video.currentTime > 0 &&
    !video.paused &&
    !video.ended &&
    video.readyState > 2
  )

export const ContentLibraryItem = ({
  item,
  onFavoriteClicked,
  shouldPlayVideo,
  variant,
  selectedVideoIds,
  setSelectedVideoIds,
  ...rest
}: ContentLibraryItemProps) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const navigate = useNavigate()

  useEffect(() => {
    if (!videoRef.current) return

    if (shouldPlayVideo) {
      videoRef.current?.play()
    } else {
      if (isVideoPlaying(videoRef.current) === false) return

      videoRef?.current?.pause()
    }
  }, [shouldPlayVideo])

  const onOpen = () => {
    if (variant === 'storefront' && selectedVideoIds && setSelectedVideoIds) {
      if (
        !selectedVideoIds?.includes(item.id) &&
        selectedVideoIds.length < MAX_FEATURED_BOUNTIES
      ) {
        setSelectedVideoIds([...selectedVideoIds, item.id])
      } else {
        setSelectedVideoIds(
          selectedVideoIds.filter(
            (selectedVideoId) => selectedVideoId !== item.id,
          ),
        )
      }
    } else {
      navigate(`./video/${item.id}`)
    }
  }

  const thumbnailImage = getPreviewImageLinkForContent(
    item.bountyContentType!,
    item.externalContentId,
  )!
  const videoSrc = getContentVideoLocation(
    item.bountyContentType!,
    item.externalContentId,
  )!
  const userProfilePic = getUserProfileImage(
    item.userId,
    contentTypeToAccountType(item.bountyContentType!)!,
  )!

  return (
    <Box
      position="relative"
      isolation="isolate"
      {...rest}
      boxShadow={
        selectedVideoIds?.includes(item.id) ? '0 0 0 3px #9192F8 ' : 'none'
      }
      backgroundColor={'neutral.200'}
      borderRadius="6px"
      p="6"
    >
      <Box
        data-testid="content-library-item"
        mb="2"
        cursor="pointer"
        onClick={onOpen}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onOpen()
          }
        }}
      >
        <Flex
          alignItems="center"
          justifyContent="space-between"
          mb="2"
          flexShrink={1}
          wordBreak={'break-word'}
        >
          <Flex alignItems="center">
            <Avatar
              size="sm"
              name={item.profileName}
              src={userProfilePic}
              mr="2"
            />

            <Text fontSize="sm" fontWeight="semibold" color="neutral.600">
              {item.profileName}
            </Text>
          </Flex>
          {item.bountyContentType && (
            <Image
              height="6"
              width="6"
              objectFit={'contain'}
              src={getSocialMediaLogo(item.bountyContentType)}
              alt={getSocialMediaFromContentType(item.bountyContentType)}
            />
          )}
        </Flex>
        <AspectRatio ratio={0.56}>
          <video
            poster={thumbnailImage}
            autoPlay={false}
            loop={true}
            ref={videoRef}
            muted
            preload="none"
          >
            <source src={videoSrc} type="video/mp4"></source>
          </video>
        </AspectRatio>
      </Box>
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <Flex>
          <Text fontWeight="semibold" color="neutral.600" mr="1">
            {prettyCount(item.viewCount ?? 0)}
          </Text>
          <Text color="neutral.600">views</Text>
        </Flex>
        <Flex
          border="1px solid"
          borderColor={'neutral.400'}
          borderRadius={'6px'}
          boxSize={'8'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <ContentLibraryFavoriteButton
            isFavorited={item.isFavorited}
            objectFit={'contain'}
            onClick={() => onFavoriteClicked(item)}
          />
        </Flex>
      </Flex>
    </Box>
  )
}

type ContentLibraryItemsProps = ChakraGridProps & {
  items?: readonly ContentLibraryItemProps['item'][]
  loading: boolean
  hasNextPage: boolean
  error: boolean
  onLoadMore: () => void
  onFavoriteClicked: ContentLibraryItemProps['onFavoriteClicked']
  variant?: 'storefront'
  selectedVideoIds?: string[]
  setSelectedVideoIds?: React.Dispatch<React.SetStateAction<string[]>>
}

export const ContentLibraryItemsComponent = ({
  items,
  loading,
  hasNextPage,
  error,
  onLoadMore,
  onFavoriteClicked,
  variant,
  selectedVideoIds,
  setSelectedVideoIds,
  ...rest
}: ContentLibraryItemsProps) => {
  const [playVideoId, setPlayVideoId] = useState<string | null>(null)
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: error,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 200px 0px',
  })

  if (!!items && items.length === 0) {
    return <Text>No content found for search.</Text>
  }

  return (
    <>
      <ChakraGrid
        templateColumns={[
          'repeat(1, 1fr)',
          'repeat(2, 1fr)',
          'repeat(3, 1fr)',
          'repeat(4, 1fr)',
        ]}
        gap={6}
        {...rest}
      >
        {!items
          ? null
          : items.map((item) => (
              <ContentLibraryItem
                shouldPlayVideo={playVideoId === item.id}
                onMouseEnter={() => setPlayVideoId(item.id)}
                onMouseLeave={() => setPlayVideoId(null)}
                onFavoriteClicked={onFavoriteClicked}
                key={item.id}
                item={item}
                variant={variant}
                selectedVideoIds={selectedVideoIds}
                setSelectedVideoIds={setSelectedVideoIds}
              />
            ))}
        {(loading || hasNextPage) && (
          <ContentLibraryItemsLoading sentryRef={sentryRef} />
        )}
      </ChakraGrid>
      <Outlet />
    </>
  )
}

export const ContentLibraryItems = (props: ContentLibraryItemsProps) => {
  const navigate = useNavigate()

  return (
    <Routes>
      <Route path="/" element={<ContentLibraryItemsComponent {...props} />}>
        <Route
          path="/video/:bountyId"
          element={
            <ContentLibraryItemDrawer
              onFavoriteClicked={props.onFavoriteClicked}
              onClose={() => navigate('/content-library')}
              isOpen
            />
          }
        />
      </Route>
    </Routes>
  )
}
