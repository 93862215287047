import { useToast } from '@bounty/brands-design-system'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useInviteCreatorsYouKnowState } from '../useInviteCreatorsYouKnow'

export const useCheckForTableData = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { rowData } = useInviteCreatorsYouKnowState()

  useEffect(() => {
    if (rowData == null || rowData.length === 0) {
      navigate('/creators/invite/creators-you-know')
      toast({
        title: 'No Creators Found',
        description: 'Please add creators using the table or upload a CSV',
        status: 'error',
      })
    }
  }, [navigate, rowData, toast])
}
