import { useCallback } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalProps,
  Text,
  Button,
  ButtonProps,
  Flex,
  VStack,
  Link,
  Alert,
  AlertTitle,
  AlertDescription,
} from '@bounty/brands-design-system'

import { last } from 'lodash'
import { PreviewPreviousCustomerInviteRequestMutation } from '../../../../generated/backendGraphql'

type PreviousCustomerFormModalProps = Omit<ModalProps, 'children'> & {
  loading: boolean
  SubmitButton: (props: ButtonProps) => JSX.Element
  previewData: PreviewPreviousCustomerInviteRequestMutation | null
  shopUrl: string | undefined
}

const confirmMessage = (
  <>
    <Text mb="2">
      We will email everyone that has purchased the following products to
      activate them for Bounty.
    </Text>
    <Text>
      Please be careful how many gifts you send. If a large number of customers
      respond it can really add up! Are you sure you want to proceed?
    </Text>
  </>
)

const createShopifyUrl = (
  shopUrl: string | undefined,
  segmentId: string | null | undefined,
) => {
  if (segmentId) {
    const id = last(segmentId.split('/'))
    return `https://${shopUrl}/admin/customers?segment_id=${id}`
  }
}

export const PreviousCustomerFormModal = ({
  loading,
  SubmitButton,
  onClose: onCloseProp,
  previewData,
  shopUrl,
  ...rest
}: PreviousCustomerFormModalProps) => {
  const onClose = useCallback(() => {
    onCloseProp()
  }, [onCloseProp])

  const makePrettyMessages = (code: string) => {
    switch (code) {
      case 'ERROR_CREATING_CUSTOMER_SEGMENT': {
        return 'Something went wrong. Please try again.'
      }
      case 'INVALID_GIFT': {
        return 'Gift not found. Please ensure the selected gift is enabled.'
      }
      case 'INVALID_PARAMS': {
        return 'Something went wrong. Please try again.'
      }
      case 'NO_RESULTS': {
        return 'No matching customers found. Broaden your search criteria and try again.'
      }
      case 'SERVER ERROR': {
        return 'No matching customers found.'
      }
      case 'SUCCESS': {
        return confirmMessage
      }
      default:
        return 'Something went wrong. Please try again.'
    }
  }

  const code = previewData?.previewPreviousCustomerInviteRequest.code ?? ''
  const count = previewData?.previewPreviousCustomerInviteRequest.count ?? 0

  return (
    <Modal
      size="xl"
      {...rest}
      onClose={() => {
        onClose()
      }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Send Invites</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Text mb="4">{makePrettyMessages(code)}</Text>

          {code === 'SUCCESS' && (
            <VStack spacing={4}>
              <Text fontWeight="semibold">
                This list contains {count} customers.
              </Text>
              <Link
                isExternal={true}
                href={`${createShopifyUrl(
                  shopUrl,
                  previewData?.previewPreviousCustomerInviteRequest.segmentId,
                )}`}
                target="_blank"
              >
                View full customer list on shopify
              </Link>{' '}
              {count > 10000 && (
                <Alert status="warning">
                  <AlertTitle>Segment too large!</AlertTitle>

                  <AlertDescription>
                    This segment contains more than 10,000 customers. Please
                    narrow your search criteria and try again.
                  </AlertDescription>
                </Alert>
              )}
              <Flex justifyContent="flex-end">
                <Button
                  mx="5"
                  event="Previous Customer Invite Form Cancel Clicked"
                  variant={'outline'}
                  onClick={() => {
                    onClose()
                  }}
                >
                  Cancel
                </Button>
                <SubmitButton
                  event="Previous Customer Invite Form Send Clicked"
                  form="invite-form"
                  isLoading={loading}
                  isDisabled={count > 10000}
                >
                  Send Invites
                </SubmitButton>
              </Flex>
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
