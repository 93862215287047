import {
  CreatorDetailUserTileQuery,
  UserBountyContentType,
} from '../generated/backendGraphql'
import InstagramLogo from '../images/instagram-logo.png'
import TikTokLogo from '../images/tiktok-logo.png'
import BountyLogo from '../images/b-256.png'
import { AccountTypes } from './externalContentLinksGetters'

export type SocialProfileBag =
  CreatorDetailUserTileQuery['creatorDetails']['tikTokProfile']

export const getPreferredSocialProfileBag = (
  creatorDetailsData: CreatorDetailUserTileQuery['creatorDetails'],
) => {
  if (creatorDetailsData.tikTokProfile) {
    return creatorDetailsData.tikTokProfile
  }
  if (creatorDetailsData.instagramProfile) {
    return creatorDetailsData.instagramProfile
  }
  return null
}

export const getSocialMediaFromContentType = (
  bountyContentType: UserBountyContentType,
) => {
  switch (bountyContentType) {
    case 'INSTAGRAM_REEL':
    case 'INSTAGRAM_STORY':
      return 'Instagram'
    case 'TIKTOK_VIDEO':
      return 'TikTok'
    default:
      throw new Error(`Bounty content type not found for ${bountyContentType}`)
  }
}

export const getSocialMediaLogo = (
  bountyContentType: UserBountyContentType | AccountTypes,
) => {
  switch (bountyContentType) {
    case 'INSTAGRAM':
    case 'INSTAGRAM_REEL':
    case 'INSTAGRAM_STORY':
      return InstagramLogo
    case 'TIKTOK':
    case 'TIKTOK_VIDEO':
      return TikTokLogo
    default:
      return BountyLogo
  }
}
