import { Flex, Link, Text, VStack } from '@bounty/brands-design-system'
import { buildShortShopUrl, isNotNil } from '@bounty/utils'
import { format, parseISO } from 'date-fns'
import { first } from 'lodash'
import { useQueryBackend } from '../../../../../apollo/backend/hooks'
import {
  GiftOfferDetailsDocument,
  GiftOfferDetailsQuery,
} from '../../../../../generated/backendGraphql'
import {
  getBountyStatusDisplay,
  getGiftStatusDisplay,
  getGiftTypeDisplay,
  getRefTypeDisplay,
} from '../../helpers'
import { ShopifyIcon } from '../../icons/ShopifyIcon'

type GiftDetailsListProps = {
  data: GiftOfferDetailsQuery['giftOfferDetails']
}

const GiftOfferDetailsListItemLabel = ({
  label,
}: {
  label: string | React.ReactNode
}) => {
  return (
    <Text
      as="span"
      display="inline-block"
      width="40%"
      fontSize="lg"
      color="neutral.600"
      fontWeight="semibold"
    >
      {label}
    </Text>
  )
}
const GiftOfferDetailsListItem = ({
  label,
  value,
}: {
  label: string | React.ReactNode
  value?: string | React.ReactNode
}) => {
  return (
    <Flex justify="flex-start" align="center" gridGap={6}>
      <GiftOfferDetailsListItemLabel label={label} />
      <Text as="span" fontSize="lg" color="neutral.900">
        {value}
      </Text>
    </Flex>
  )
}

export const GiftOfferDetailsList = ({ data }: GiftDetailsListProps) => {
  const { data: { currentStore } = {} } = useQueryBackend(
    GiftOfferDetailsDocument,
    { variables: { giftOfferId: data.id } },
  )

  const getBounty = () => {
    return first(data.userBounties) ?? null
  }

  return (
    <VStack spacing={2} align="left">
      <GiftOfferDetailsListItem
        label="Creator"
        value={
          <Link to={`../creator-details/${data.userId}`} isExternal={false}>
            {data.profileName}
          </Link>
        }
      />
      <GiftOfferDetailsListItem
        label="Date"
        value={format(parseISO(data.createdAt), 'MM/dd/yyyy')}
      />
      <GiftOfferDetailsListItem
        label="Gift Name"
        value={
          <Link to={`../gift-details/${data.storeGift.id}`} isExternal={false}>
            {data.storeGift.name}
          </Link>
        }
      />
      <GiftOfferDetailsListItem
        label="Gift Type"
        value={getGiftTypeDisplay(data.storeGift.type ?? undefined)}
      />
      <GiftOfferDetailsListItem
        label="Gift Status"
        value={getGiftStatusDisplay(data.status ?? undefined)}
      />

      {isNotNil(data.shopifyStoreOrder?.shopifyOrderId) &&
      isNotNil(currentStore) ? (
        <GiftOfferDetailsListItem
          label={
            <Flex align="center" gridGap={3}>
              <Text as="span">Shopify Order</Text>
              <ShopifyIcon />
            </Flex>
          }
          value={
            <Link
              href={`https://admin.shopify.com/store/${buildShortShopUrl(
                currentStore.shopUrl,
              )}/orders/${data.shopifyStoreOrder?.shopifyOrderId}`}
              isExternal={true}
            >
              View Order
            </Link>
          }
        />
      ) : (
        <GiftOfferDetailsListItem
          label="Shopify Order"
          value={getGiftStatusDisplay(data.status ?? undefined)}
        />
      )}
      {data.refType && (
        <GiftOfferDetailsListItem
          label="Source"
          value={getRefTypeDisplay(data.refType ?? undefined)}
        />
      )}
      {isNotNil(data.userBounties) && data.userBounties.length > 0 && (
        <GiftOfferDetailsListItem
          label="Bounty Status"
          value={getBountyStatusDisplay(getBounty()?.status ?? undefined)}
        />
      )}
    </VStack>
  )
}
