import { PageSkeleton } from '@bounty/brands-design-system'
import { ReactNode } from 'react'

import { Navigate, useLocation } from 'react-router-dom'
import { useQueryBackend } from '../../../apollo/backend/hooks'
import { RequireWelcomeFlowDocument } from '../../../generated/backendGraphql'

export type RequireWelcomeFlowProps = { children: ReactNode }

export const RequireWelcomeFlow = ({ children }: RequireWelcomeFlowProps) => {
  const location = useLocation()
  const { loading, data } = useQueryBackend(RequireWelcomeFlowDocument)

  if (loading || !data) {
    return <PageSkeleton data-testid="require-welcome-flow-loading" />
  }

  if (location.pathname.includes('/welcome')) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  // Only show them app if they have a current subscription
  if (data.currentSubscription != null) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>
  }

  return <Navigate to={'/welcome/get-started'} />
}
