import { z } from 'zod'

/**
 * Takes in a zod schema and sees if the name passed is required or not
 */
export const isFieldRequired = <T extends z.ZodTypeAny>(
  name: string,
  schema: T,
) => !schema._def.shape()[name]?.isOptional()

export const getLabelFromName = (s: string) => {
  return (
    s
      // insert a space before all caps
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, function (str) {
        return str.toUpperCase()
      })
  )
}
