import {
  StackProps,
  Metric,
  Stack,
  Text,
  Tooltip,
  Box,
} from '@bounty/brands-design-system'

import { useQueryBackend } from '../../apollo/backend/hooks'
import {
  EarnedMediaDocument,
  MetricsDocument,
} from '../../generated/backendGraphql'
import { prettyCount, prettyCurrency, prettyPercentage } from '@bounty/utils'
import { useNavigate } from 'react-router-dom'

type EarnedMediaProps = StackProps & {
  start: Date
  end: Date
}

export const EarnedMedia = (props: EarnedMediaProps) => {
  const navigate = useNavigate()
  const { data, loading } = useQueryBackend(EarnedMediaDocument, {
    variables: {
      params: {
        startDate: props.start,
        endDate: props.end,
      },
    },
  })

  return (
    <Stack spacing={3} direction={['column', 'column', 'row']} mt={3}>
      <Metric
        onClick={() => navigate('/content-library')}
        isLoading={loading || !data}
        title="Total Impressions"
        value={prettyCount(data?.earnedMediaValueStats.impressions ?? 0)}
      ></Metric>

      <Metric
        onClick={() => navigate('/creators')}
        isLoading={loading || !data}
        title="Total Payouts"
        value={prettyCurrency(
          data?.earnedMediaValueStats.payoutsProcessed ?? 0,
        )}
      ></Metric>

      <Metric
        onClick={() => navigate('/creators')}
        isLoading={loading || !data}
        title="Earned Media Value ($25 CPM)"
        value={prettyCurrency(
          data?.earnedMediaValueStats.earnedMediaValue ?? 0,
        )}
      ></Metric>
    </Stack>
  )
}

type MetricsProps = StackProps & {
  start: Date
  end: Date
  comparisonStartDate: Date
  comparisonEndDate: Date
}

export const Metrics = (props: MetricsProps) => {
  const navigate = useNavigate()
  const { data, loading } = useQueryBackend(MetricsDocument, {
    variables: {
      params: {
        startDate: props.start,
        endDate: props.end,
        comparisonStartDate: props.comparisonStartDate,
        comparisonEndDate: props.comparisonEndDate,
      },
    },
  })

  return (
    <Box>
      <Stack spacing={3} direction={['column', 'column', 'row']}>
        <Metric
          onClick={() => navigate('/content-library')}
          isLoading={loading || !data}
          title="New Assets"
          value={data?.storeStats.assets}
        >
          <MetricComparison
            comparison={prettyPercentage(
              data?.storeStats.assetsPercentChange ?? 0,
              { showPlus: true },
            )}
          />
        </Metric>

        <Metric
          onClick={() => navigate('/creators')}
          isLoading={loading || !data}
          title="New Creators"
          value={data?.storeStats.creators}
        >
          <MetricComparison
            comparison={prettyPercentage(
              data?.storeStats.creatorsPercentChange ?? 0,
              { showPlus: true },
            )}
          />
        </Metric>
      </Stack>
      {(data?.storeStats?.assets ?? 0) > 3 && (
        <EarnedMedia start={props.start} end={props.end} width="100%" />
      )}
    </Box>
  )
}

interface MetricComparisonProps {
  comparison: string
}

export const MetricComparison = ({ comparison }: MetricComparisonProps) => {
  return (
    <Tooltip label="Compared to last 30 days">
      <Text fontSize="large" fontWeight="semibold">
        {comparison === '+100000%' ? (
          <Text as="span" fontSize="26px">
            ∞
          </Text>
        ) : (
          comparison
        )}
      </Text>
    </Tooltip>
  )
}
