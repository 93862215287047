import { EmbedConfigEntry } from '@bounty/types'
import { isNil } from '@bounty/utils'

export const getStoreEmbeds = <T extends { embeds?: any }>(store: T) => {
  if (isNil(store.embeds)) return []

  return store.embeds as EmbedConfigEntry[]
}

export const isProductScriptTagActivated = (
  embeds: EmbedConfigEntry[],
): boolean => {
  return embeds.some(
    (embed) => embed.code === 'PRODUCT' && isNil(embed.scriptTagId) === false,
  )
}

export const isScriptTagActivated = (
  embeds: EmbedConfigEntry[],
  code: EmbedConfigEntry['code'],
): boolean => {
  return embeds.some(
    (embed) => embed.code === code && isNil(embed?.scriptTagId) === false,
  )
}
