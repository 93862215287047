import * as z from 'zod'

export const SparkAdConfirmCodeSchema = z.object({
  requestId: z.string(),
  code: z.string(),
})

export type SparkAdConfirmSchemaRequest = z.infer<
  typeof SparkAdConfirmCodeSchema
>
