import * as z from 'zod'

export const instagramSchema = z.object({
  instagramUsername: z
    .string()
    .regex(
      /^@[\w|_|-|.]+$/,
      'Instagram username must start with @ sign and contain no spaces.',
    ),
})
