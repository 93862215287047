import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  format as formatCore,
  startOfDay,
  startOfMonth,
  startOfWeek,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns'

type FormattedDateString = string

type DateRange = [start: Date, end: Date]

export const format = (date: Date): FormattedDateString => {
  return formatCore(date, 'yyyy-MM-dd')
}

export const getToday = (from = new Date()): DateRange => {
  return [startOfDay(from), from]
}

export const getYesterday = (from = new Date()): DateRange => {
  const value = subDays(from, 1)
  return [startOfDay(value), endOfDay(value)]
}

export const getLastWeek = (from = new Date()): DateRange => {
  const dayInTargetWeek = subWeeks(from, 1)

  return [startOfWeek(dayInTargetWeek), endOfWeek(dayInTargetWeek)]
}

export const getLastMonth = (from = new Date()): DateRange => {
  const dayInLastMonth = subMonths(from, 1)
  return [startOfMonth(dayInLastMonth), endOfMonth(dayInLastMonth)]
}

export const getLast3Months = (from = new Date()): DateRange => {
  const dayInLastMonth = subMonths(from, 1)
  return [
    subMonths(startOfMonth(dayInLastMonth), 2),
    endOfMonth(dayInLastMonth),
  ]
}

export const getLast7Days = (from = new Date()): DateRange => {
  return [subDays(from, 7), from]
}

export const getLast30Days = (from = new Date()): DateRange => {
  return [subDays(from, 30), from]
}

export const getLastYear = (from = new Date()): DateRange => {
  return [subMonths(from, 12), from]
}
