import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const line = defineStyle({
  tablist: {
    borderColor: 'primary.100',
  },
  tab: {
    color: 'neutral.700',
    fontWeight: 'semibold',
    _selected: { color: 'primary.500' },
  },
})

export const tabsTheme = defineStyleConfig({
  defaultProps: {
    colorScheme: 'primary',
  },
  variants: {
    line,
  },
})
