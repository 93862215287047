import {
  Box,
  Flex,
  BoxProps,
  FastForm,
  FastFormInput,
  FastFormTextarea,
  FastFormInputBase,
  FastFormButton,
  Text,
  VStack,
  Skeleton,
} from '@bounty/brands-design-system'
import { sparkCodeRequestSchema, SparkCodeRequest } from '@bounty/schemas'
import { Controller, SubmitHandler } from 'react-hook-form'
import { SendIcon } from '@bounty/brands-design-system'
import { useQueryBackend } from '../../apollo/backend/hooks'
import { UsageRightsPricesDocument } from '../../generated/backendGraphql'

type SparkCodeRequestFormProps = Omit<BoxProps, 'onSubmit'> & {
  tikTokVideoId: string
  isLoading: boolean
  status: string
  onSubmit: SubmitHandler<{
    tikTokVideoId: string
    offerPrice: string
    message?: string | undefined
  }>
}

export const SparkCodeRequestForm = ({
  onSubmit,
  tikTokVideoId,
  isLoading,
  status,
  ...rest
}: SparkCodeRequestFormProps) => {
  const { data, loading } = useQueryBackend(UsageRightsPricesDocument, {
    variables: { contentId: tikTokVideoId, contentType: 'TIKTOK_VIDEO' },
  })
  return (
    <Box {...rest}>
      <FastForm<SparkCodeRequest>
        schema={sparkCodeRequestSchema}
        formProps={{
          defaultValues: {
            tikTokVideoId,
          },
        }}
        onSubmit={onSubmit}
      >
        <Text fontSize="2xl" fontWeight="bold" mb={2}>
          Request Spark Code
        </Text>
        <Text mb={6}>
          Choose an amount to offer this creator for a 30 day Spark Code.
        </Text>
        {!data || loading ? (
          <VStack>
            <Skeleton height="3rem" width="100%" />
            <Skeleton height="3rem" width="100%" />
            <Skeleton height="3rem" width="100%" />
          </VStack>
        ) : (
          <>
            <FastFormInput<SparkCodeRequest>
              name="offerPrice"
              helpText="$20 minimum"
            >
              {() => (
                <Controller
                  defaultValue={data.usageRightsPrices.THIRTY_DAY_SPARKCODE.creatorPayout.toString()}
                  name="offerPrice"
                  render={({ field }) => (
                    <FastFormInputBase
                      width={150}
                      borderRadius={0}
                      placeholder="$20.00"
                      mb={5}
                      onChange={(e) => {
                        const currentValue = e.target.value
                        // value doesn't contain $ on the first onChange
                        const valueWithoutDollar = currentValue.startsWith('$')
                          ? currentValue.substring(1)
                          : currentValue
                        const isValueNotNumber = isNaN(
                          Number(valueWithoutDollar),
                        )
                        if (isValueNotNumber) {
                          e.target.value = ''
                          field.onChange(e)
                          return
                        }
                        e.target.value = valueWithoutDollar
                        field.onChange(e)
                      }}
                      value={field.value ? `$${field.value}` : ''}
                    />
                  )}
                />
              )}
            </FastFormInput>
            <FastFormTextarea<SparkCodeRequest>
              name="message"
              placeholder="Add a short message (optional)"
              maxCharacterLimit={250}
            />

            <Text>
              Upon delivery of the spark code, your account will be charged the
              offer amount plus the service fee on your current plan. This will
              be reflected as a line item on your monthly billing within
              Shopify.
            </Text>

            <FastFormButton
              borderRadius="full"
              isLoading={isLoading}
              isDisabled={status === 'LIVE'}
              mt="5"
              size="lg"
              px={3}
              event="Send Spark Code Button Clicked"
            >
              <Flex px={3}>
                <Text mr={2}>Send</Text>
                <SendIcon width="14px" height="18px"></SendIcon>
              </Flex>
            </FastFormButton>

            {status === 'LIVE' && (
              <Text pt="5"> Cannot request Spark Code for Live Bounty. </Text>
            )}
          </>
        )}
      </FastForm>
    </Box>
  )
}
