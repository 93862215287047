import { Button } from '@bounty/brands-design-system'
import { useMutationBackend } from '../apollo/backend/hooks'
import {
  SetCreatorBountyLimitDocument,
  CreatorDetailsOverviewDocument,
  CreatorDetailsOverviewQuery,
} from '../generated/backendGraphql'

type BanCreatorButtonProps = {
  userId: CreatorDetailsOverviewQuery['creatorDetails']['id']
  creatorStoreBountyLimit: CreatorDetailsOverviewQuery['creatorDetails']['creatorLimitForStore']
  storeBountyLimit: CreatorDetailsOverviewQuery['currentStore']['bountyLimitPerMonth']
}
export const BanCreatorButton = ({
  userId,
  creatorStoreBountyLimit,
  storeBountyLimit,
}: BanCreatorButtonProps) => {
  const [setCreatorBountyLimit, { loading: setCreatorBountyLimitLoading }] =
    useMutationBackend(SetCreatorBountyLimitDocument, {
      refetchQueries: [
        {
          query: CreatorDetailsOverviewDocument,
          variables: {
            userId,
          },
        },
      ],
    })
  return (
    <Button
      event={{
        eventName: 'Ban Creator Clicked',
        value: userId,
      }}
      size="sm"
      onClick={async () => {
        await setCreatorBountyLimit({
          variables: {
            limit: creatorStoreBountyLimit === 0 ? storeBountyLimit : 0,
            userId: userId,
          },
        })
      }}
      isLoading={setCreatorBountyLimitLoading}
      colorScheme={creatorStoreBountyLimit === 0 ? 'gray' : 'red'}
    >
      {creatorStoreBountyLimit === 0 ? 'Unblock Creator' : 'Block Creator'}
    </Button>
  )
}
