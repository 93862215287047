import { useCallback } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalProps,
  Text,
  Box,
  ModalFooter,
  Button,
  ListItem,
  UnorderedList,
} from '@bounty/brands-design-system'
import { useQueryBackend } from '../../../apollo/backend/hooks'
import {
  CreatorNotifyType,
  GetBriefBulkSendCostPreviewDocument,
} from '../../../generated/backendGraphql'
import { prettyFloatingDate, prettyNumber } from '@bounty/utils'

type CreateBountyConfirmModalProps = Omit<ModalProps, 'children'> & {
  bountyName: string
  notifyType: CreatorNotifyType
  notifyDate: string
  onConfirm: () => void
}

export const CreateBountyConfirmModal = ({
  onClose: onCloseProp,
  onConfirm: onConfirmProp,
  bountyName,
  notifyType,
  notifyDate,
  ...rest
}: CreateBountyConfirmModalProps) => {
  const onClose = useCallback(() => {
    onCloseProp()
  }, [onCloseProp])

  const onConfirm = useCallback(() => {
    onConfirmProp()
  }, [onConfirmProp])

  const { data } = useQueryBackend(GetBriefBulkSendCostPreviewDocument, {
    variables: { input: { notifyType } },
  })

  return (
    <Modal
      size="xl"
      {...rest}
      onClose={() => {
        onClose()
      }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        width={['full', 'full', 'fit-content']}
        maxWidth={['90%', '90%', '600px']}
      >
        <ModalHeader
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          columnGap="2"
        >
          <Text fontFamily="heading">Confirm and publish</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowWrap="anywhere" overflowY="auto">
          <Box bg="gray.200" borderRadius="10" padding="3">
            <Text>
              Your {bountyName} Bounty will be active, and a notification will
              be scheduled to be sent:
            </Text>
            <UnorderedList padding="2" mt="3">
              <ListItem
                css={{ '::marker': { fontSize: '12px' } }}
                fontWeight="bold"
              >
                {prettyNumber(data?.briefBulkSendCostPreview.count || 0)}{' '}
                creators{' '}
                <Text as="span" fontWeight="regular">
                  in your community will be notified
                </Text>
              </ListItem>
              <ListItem
                css={{ '::marker': { fontSize: '12px' } }}
                fontWeight="bold"
              >
                Notification will be sent on{' '}
                {prettyFloatingDate(notifyDate, 'MMM, dd, yyyy')}{' '}
                <Text as="span" fontWeight="regular">
                  at the optimal send time
                </Text>
              </ListItem>
              <ListItem
                css={{ '::marker': { fontSize: '12px' } }}
                fontWeight="bold"
              >
                This campaign will send 2 SMS messages per creator, and will
                spend ${data?.briefBulkSendCostPreview.cost || 0} of your
                monthly budget
              </ListItem>
            </UnorderedList>
          </Box>
        </ModalBody>
        <ModalFooter
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          gap="2"
        >
          <Button
            variant="outline"
            event="Cancel schedule bounty Clicked"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            event="Confirm and schedule bounty Clicked"
            onClick={() => onConfirm()}
          >
            Confirm and schedule
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
