import { useParamsOrThrow } from '@bounty/hooks'
import {
  DrawerHeader,
  DrawerBody,
  Box,
  Skeleton,
  DrawerHeading,
  useToast,
} from '@bounty/brands-design-system'
import { useNavigate } from 'react-router-dom'
import {
  GiftListDocument,
  OfferGiftNetworkCreatorDocument,
} from '../../../generated/backendGraphql'
import {
  useMutationBackend,
  useQueryBackend,
} from '../../../apollo/backend/hooks'
import { OfferCreatorGiftForm } from '../components/OfferCreatorGiftForm'

export const CreatorDetailsNetworkOfferGift = () => {
  const { userId } = useParamsOrThrow(['userId'])
  const navigate = useNavigate()
  const toast = useToast()
  const { data: { enabledStoreGifts = [] } = {}, loading } =
    useQueryBackend(GiftListDocument)

  const [mutation, { loading: offerGiftLoading }] = useMutationBackend(
    OfferGiftNetworkCreatorDocument,
  )

  return (
    <Box>
      <DrawerHeader fontSize={'2xl'}>
        <DrawerHeading>Offer Gift</DrawerHeading>
      </DrawerHeader>
      <DrawerBody>
        {loading ? (
          <Box>
            <Skeleton height="40px" mb="4" />
            <Skeleton height="40px" mb="4" />
            <Skeleton height="80px" mb="4" />
            <Skeleton height="30px" width="80px" mb="4" />
          </Box>
        ) : (
          <OfferCreatorGiftForm
            userId={userId}
            isNetwork={true}
            isLoading={offerGiftLoading}
            enabledStoreGifts={enabledStoreGifts.filter(
              (x) => x.type === 'PRODUCT',
            )}
            onSubmitSuccess={async (giftId) => {
              const { data } = await mutation({
                variables: {
                  giftId,
                  userId,
                },
              })

              if (data?.offerGiftNetworkCreator) {
                toast({
                  title: 'Gift Sent!',
                  description:
                    'Your gift invitation has been sent to the creator',
                  status: 'success',
                })
              } else {
                toast({
                  title: 'Gift Could Not Be Sent',
                  description: 'Please try again or contact support',
                  status: 'error',
                })
              }

              navigate('../')
            }}
          />
        )}
      </DrawerBody>
    </Box>
  )
}
