import {
  ErrorText,
  Form,
  Box,
  ButtonWithoutAnalytics,
  FormControl,
  Heading,
  Input,
  Image,
  Text,
  Link,
} from '@bounty/brands-design-system'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Page } from '@bounty/brands-design-system'
import { useAuthDispatch, useAuthState } from '../../hooks/useAuth'
import bountyAppIcon from '../../images/bounty-app-icon.jpg'

type LoginProps = {
  /**
   * The type of experience to show to the user
   *
   * @default 'login'
   */
  variant?: 'login' | 'signUp'
}

export const Login = ({ variant = 'login' }: LoginProps) => {
  const { login, setShopifyStoreUrl } = useAuthDispatch()
  const { shopifyStoreUrl, authError, isLoading, isAuthed } = useAuthState()
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoading === false && isAuthed === true) {
      navigate('/')
    }
  }, [navigate, isLoading, isAuthed])

  return (
    <Page
      addBottomSpacing={false}
      alignItems="center"
      data-testid="login-page"
      display="flex"
      height="100vh"
      justifyContent="center"
      name="Login"
      width="100%"
    >
      <Box margin="0 auto" width="325px">
        <Image
          alt="Bounty app install icon"
          height="85px"
          marginBottom="5"
          src={bountyAppIcon}
          width="85px"
        />
        <Heading as="h1" size="md">
          Get started
        </Heading>
        <Form
          onSubmit={(e) => {
            e.preventDefault()

            login()
          }}
        >
          <Text fontSize="md" marginBottom="4">
            Enter your shopify name to log in or sign up
          </Text>
          <FormControl marginBottom="4">
            <Input
              data-testid="login-url-input"
              onChange={(e) => setShopifyStoreUrl(e.target.value)}
              onPaste={(e) => {
                // Don't call on change when a user pastes something
                e.preventDefault()
                const val = e.clipboardData.getData('Text')

                try {
                  const url = new URL(val)
                  setShopifyStoreUrl(url.hostname)
                } catch (error) {
                  // Paste the value just like it would work normally
                  setShopifyStoreUrl(val)
                }
              }}
              placeholder="example.myshopify.com"
              value={shopifyStoreUrl}
            />
            {authError != null && (
              <ErrorText data-testid="login-error" mt="2">
                {authError}
              </ErrorText>
            )}
          </FormControl>
          <ButtonWithoutAnalytics
            data-testid="login-button"
            disabled={shopifyStoreUrl === ''}
            type="submit"
            isDisabled={isLoading}
            isLoading={isLoading}
          >
            Get started
          </ButtonWithoutAnalytics>
        </Form>
        <Text mt={5} fontSize="sm">
          Don't know your Shopify name? Install through the app listing{' '}
          <Link
            isExternal
            showIcon={false}
            href="https://apps.shopify.com/bounty-ugc-automation"
          >
            here
          </Link>
          .
        </Text>
      </Box>
    </Page>
  )
}
