import {
  Alert,
  Link,
  AlertTitle,
  AlertDescription,
} from '@bounty/brands-design-system'
import { SUPPORT_EMAIL } from '../const'

export const FeatureUnavailable = ({ ctaText }: { ctaText: string }) => (
  <Alert status="warning">
    <AlertTitle>This feature is not available on your plan.</AlertTitle>

    <AlertDescription>
      <Link
        isExternal={true}
        href={`mailto:${SUPPORT_EMAIL}?subject=Change my Plan`}
        textDecoration={'underline'}
      >
        {ctaText}
      </Link>
    </AlertDescription>
  </Alert>
)
