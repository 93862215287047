import {
  Box,
  PageHeader,
  useTheme,
  useToast,
  Skeleton,
  Grid,
  PageHeaderActions,
  PageHeaderSecondaryAction,
  PageHeaderPrimaryAction,
} from '@bounty/brands-design-system'
import { useNavigate } from 'react-router-dom'
import { useInviteCreatorsYouDontKnowState } from '../../useInviteCreatorsYouDontKnow'
import { useEffect, useMemo, useRef } from 'react'
import {
  CellStyle,
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import {
  useMutationBackend,
  useQueryBackend,
} from '../../../../../../apollo/backend/hooks'
import { ReviewSchema } from '../../schemas'
import {
  GiftInviteBatchParams,
  InviteValidationResultRecordInput,
  ReviewInvitesDocument,
  SubmitGiftInvitesUnknownCreatorsDocument,
} from '../../../../../../generated/backendGraphql'
import { useCheckForTableData } from '../../hooks/useCheckForTableData'
import { isNil } from '@bounty/utils'

type InviteRowItem = ReviewSchema['reviewData'][0] | any

export const ReviewInvites = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { giftId, rowData } = useInviteCreatorsYouDontKnowState()

  useCheckForTableData()

  useEffect(() => {
    if (isNil(giftId)) {
      navigate('/creators/invite/creators-you-dont-know/message')
      toast({
        title: 'No Gift Found',
        description: 'Please add a gift',
        status: 'error',
      })
    }
  }, [giftId, navigate, toast])

  const theme = useTheme()
  const gridRef = useRef<AgGridReact<InviteRowItem>>(null)

  const { data, loading: reviewInvitesLoading } = useQueryBackend(
    ReviewInvitesDocument,
    {
      variables: {
        id: giftId,
      },
    },
  )

  const columnDefs = useMemo((): ColDef<InviteRowItem>[] => {
    const cellStyles: CellStyle = {
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center ',
      fontSize: theme.fontSizes.md.toString(),
      color: theme.colors['gray']['900'],
    }

    return [
      {
        headerName: 'Email',
        field: 'email',
        editable: false,
        cellStyle: cellStyles,
        flex: 1,
      },
      {
        headerName: 'Gift',
        field: 'gift',
        editable: false,
        cellStyle: cellStyles,
        flex: 1,
      },
    ]
  }, [theme])

  const numberOfRowsToDisplay = 8
  const rowHeight = 72

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams) => params.data.email
  }, [])

  const [
    submitGiftInvitesUnknownCreators,
    { loading: submitGiftInvitesLoading },
  ] = useMutationBackend(SubmitGiftInvitesUnknownCreatorsDocument)

  const formData: GiftInviteBatchParams = {
    giftId: giftId,
    records: rowData.map((el: InviteValidationResultRecordInput) => {
      return {
        email: el.email,
        ...(!isNil(el.name) && el.name.length > 0 ? { name: el.name } : {}),
      }
    }),
  }

  return (
    <>
      <PageHeader
        title={'Invite creators you do not know to Bounty'}
        description="Add creators you don’t know and we’ll try to activate them for your
        brand."
        breadcrumbs={[
          {
            name: 'Creators',
            to: '/creators',
          },
          {
            name: 'Invite',
            to: '/creators/invite',
          },
          {
            name: 'Current',
            to: '',
            isCurrentPage: true,
          },
        ]}
        actions={
          <PageHeaderActions>
            <PageHeaderSecondaryAction
              event={'Invites Add Creators You Know Second Step Back Clicked'}
              onClick={() => navigate('..')}
            >
              Back
            </PageHeaderSecondaryAction>
            <PageHeaderPrimaryAction
              event={'Invites Add Creators You Know Second Step Next Clicked'}
              onClick={async () => {
                const res = await submitGiftInvitesUnknownCreators({
                  variables: { params: formData },
                })
                if (
                  res.data?.submitGiftInvitesUnknownCreators.errors.length === 0
                ) {
                  toast({
                    title: `Invites sent`,
                    description: `Your invites have been sent`,
                    status: 'success',
                  })
                  navigate('/creators')
                } else {
                  res.data?.submitGiftInvitesUnknownCreators.errors.map(
                    (err) => {
                      return toast({
                        title: `Error sending invites`,
                        description:
                          err.message ?? `Your invites failed to send`,
                        status: 'error',
                      })
                    },
                  )
                }
              }}
              isLoading={reviewInvitesLoading || submitGiftInvitesLoading}
            >
              Send Invites
            </PageHeaderPrimaryAction>
          </PageHeaderActions>
        }
      />
      <Box w="100%" h="500px">
        {reviewInvitesLoading || !data ? (
          <Skeleton
            w="100%"
            h={`calc(${rowHeight}px * ${numberOfRowsToDisplay} + 58px)`}
          />
        ) : (
          <Grid<InviteRowItem>
            gridRef={gridRef}
            columnDefs={columnDefs}
            containerProps={{
              height: `calc(${rowHeight}px * ${numberOfRowsToDisplay} + 58px)`,
              flex: 'none',
              borderRadius: 'md',
            }}
            rowHeight={rowHeight}
            rowData={rowData.map((row) => {
              return {
                ...row,
                gift: data.getStoreGift.name,
              }
            })}
            getRowId={getRowId}
            showQuickSearch={false}
            enableCellTextSelection={true}
          />
        )}
      </Box>
    </>
  )
}
