import { useEffect } from 'react'
import {
  Box,
  Page,
  PageHeader,
  Text,
  useFastForm,
  useDisclosure,
  PageSkeleton,
  Link,
  useToast,
  PageHeaderPrimaryAction,
  PageHeaderActions,
  PageHeaderSecondaryAction,
} from '@bounty/brands-design-system'
import { AdvancedInvitesSchema } from '@bounty/schemas'
import {
  useQueryBackend,
  useMutationBackend,
} from '../../../../apollo/backend/hooks'
import {
  AdvancedInvitesDocument,
  GenerateBulkOffersDocument,
} from '../../../../../src/generated/backendGraphql'
import { AdvancedInvitesModal } from './AdvancedInvitesModal'
import { generateEncodedUri } from '@bounty/constants'
import { FormDataDisplayWrapper } from '../components/FormDataDisplay/FormDataDisplayWrapper'
import { FormWrapper } from '../components/FormDataDisplay/FormWrapper'
import { FormDataDisplay } from '../components/FormDataDisplay/FormDataDisplay'
import { GiftIcon } from '../components/icons/GiftIcon'
import { GiftDisplay } from '../components/GiftDisplay/GiftDisplay'
import { Route, Routes } from 'react-router-dom'
import { GiftDrawer } from '../../../Gifts/GiftDrawer/GiftDrawer'

export type AdvancedInvitesProps = unknown

export const AdvancedInvites = (unknown: AdvancedInvitesProps) => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { data: { enabledStoreGifts = [] } = {}, loading } = useQueryBackend(
    AdvancedInvitesDocument,
  )

  const [generateBulkOffers, { loading: generateBulkOffersLoading }] =
    useMutationBackend(GenerateBulkOffersDocument)

  const {
    Form,
    NumberInput,
    Select,
    SubmitButton,
    methods: { watch, setValue },
  } = useFastForm({
    schema: AdvancedInvitesSchema,
    defaultValues: {
      giftId: '',
      count: 1,
    },
  })

  const [watchGiftId] = watch(['giftId'])
  const selectedGift = enabledStoreGifts.find((el) => el.id === watchGiftId)

  useEffect(() => {
    if (!loading && enabledStoreGifts != null && enabledStoreGifts.length > 0)
      setValue('giftId', enabledStoreGifts[0].id)
  }, [loading, setValue, enabledStoreGifts])

  if (loading || !enabledStoreGifts) return <PageSkeleton />

  return (
    <Page
      name="Advanced Invites"
      fixedHeight="all"
      fluid
      addBottomSpacing={false}
      paddingBottom="3rem"
      width="100%"
    >
      <Routes>
        <Route
          path="create-gift"
          element={
            <GiftDrawer mode="create" rootPath="/creators/invite/advanced" />
          }
        />
      </Routes>

      <PageHeader
        title="Advanced"
        description="Generate unique invite links to give gifts to people through SMS, DMs, email and more."
        breadcrumbs={[
          {
            name: 'Creators',
            to: '/creators',
          },
          {
            name: 'Invite',
            to: '/creators/invite',
          },
          {
            name: 'Current',
            to: '',
            isCurrentPage: true,
          },
        ]}
        actions={
          <PageHeaderActions>
            <PageHeaderSecondaryAction
              data-testid="gift-offer-history-button"
              colorScheme="blackAlpha"
              as={Link}
              event="Advanced Invites Gift Offer History Clicked"
              to={'/creators/invite/gift-offer/history'}
            >
              Gift Offers History
            </PageHeaderSecondaryAction>
            <PageHeaderPrimaryAction
              data-testid="download-invites-button"
              onClick={onOpen}
              px="7"
              event="Advanced Invites Download Invites Clicked"
            >
              Download Invites
            </PageHeaderPrimaryAction>
          </PageHeaderActions>
        }
      />

      <Box>
        <FormDataDisplayWrapper>
          <FormWrapper>
            <Form
              id="advanced-invites-form"
              data-testid="advanced-invites-form"
              onSubmit={async ({ giftId, count }) => {
                const { data: bulkOffersData } = await generateBulkOffers({
                  variables: {
                    giftId,
                    count,
                  },
                })
                if (
                  bulkOffersData?.generateBulkOffers.offers.length === count
                ) {
                  const csvContent = [
                    ['Key', 'Link'],
                    ...bulkOffersData.generateBulkOffers.offers.map((offer) => [
                      offer.key,
                      offer.link,
                    ]),
                  ]
                    .map((e) => e.join(','))
                    .join('\n')
                  const encodedUri = generateEncodedUri(csvContent)
                  const link = document.createElement('a')
                  link.setAttribute('href', encodedUri)
                  link.setAttribute(
                    'download',
                    'bounty_invite_creator_links.csv',
                  )
                  document.body.appendChild(link)

                  link.click()
                } else {
                  toast({
                    title: 'Links could not be generated',
                    description: 'Please try again.',
                    status: 'error',
                  })
                }
                onClose()
              }}
            >
              <Select
                label="Gift"
                name="giftId"
                placeholder="Bounty"
                options={enabledStoreGifts.map((gift) => ({
                  label: gift.name,
                  value: gift.id,
                }))}
                helpText={
                  <Text color="gray.600" fontSize="sm" mb="2">
                    Select a gift for the creator or{' '}
                    <Link
                      isExternal={false}
                      to="create-gift"
                      textDecoration="underline"
                      color="inherit"
                    >
                      create one
                    </Link>
                    .
                  </Text>
                }
              />
              <NumberInput
                label="Number of Invites"
                name="count"
                isRequired
                helpText="Send up to 5000 invites."
              />
            </Form>
          </FormWrapper>
          {selectedGift && (
            <FormDataDisplay icon={<GiftIcon />} description="Gift details">
              <GiftDisplay gift={selectedGift} />
            </FormDataDisplay>
          )}
        </FormDataDisplayWrapper>
      </Box>
      <AdvancedInvitesModal
        giftName={selectedGift?.name}
        SubmitButton={SubmitButton}
        loading={generateBulkOffersLoading}
        isOpen={isOpen}
        onClose={onClose}
      />
    </Page>
  )
}
