import { getLast30Days } from '@bounty/utils'
import { createContext, ReactNode, useContext, useState } from 'react'
import {
  GiftOfferHistoryFiltersInput,
  GiftOfferHistoryOrderByField,
  OrderByDirection,
} from '../../../generated/backendGraphql'

type GiftsHistoryProviderProps = { children: ReactNode }

type GiftsHistoryState = {
  value: {
    params: {
      filters?: GiftOfferHistoryFiltersInput[]
      take: number
      skip: number
      orderByField?: GiftOfferHistoryOrderByField
      orderByDirection?: OrderByDirection
      searchString?: string
      startDate?: string
      endDate?: string
    }
  }
}

const GIFTS_HISTORY_STATE_DEFAULT: GiftsHistoryState = {
  value: {
    params: {
      filters: [],
      take: 10,
      skip: 0,
      orderByField: 'CREATED_AT',
      orderByDirection: 'DESC',
      searchString: '',
      startDate: getLast30Days()[0].toISOString(),
      endDate: getLast30Days()[1].toISOString(),
    },
  },
}

const GiftsHistoryStateContext = createContext<GiftsHistoryState>(
  GIFTS_HISTORY_STATE_DEFAULT,
)

type GiftsHistoryDispatch = {
  setValues: React.Dispatch<React.SetStateAction<GiftsHistoryState>>
}
const GiftsHistoryDispatchContext = createContext<GiftsHistoryDispatch | null>(
  null,
)

export const GiftsHistoryProvider = ({
  children,
}: GiftsHistoryProviderProps) => {
  const [value, setValues] = useState<GiftsHistoryState>(
    GIFTS_HISTORY_STATE_DEFAULT,
  )

  return (
    <GiftsHistoryStateContext.Provider value={value}>
      <GiftsHistoryDispatchContext.Provider value={{ setValues }}>
        {children}
      </GiftsHistoryDispatchContext.Provider>
    </GiftsHistoryStateContext.Provider>
  )
}

export const useGiftsHistoryState = (): GiftsHistoryState => {
  const context = useContext(GiftsHistoryStateContext)

  if (!context) {
    throw new Error(
      'useGiftsHistoryState must be used within a GiftsHistoryProvider.',
    )
  }

  return context
}

export const useGiftsHistoryDispatch = (): GiftsHistoryDispatch => {
  const context = useContext(GiftsHistoryDispatchContext)

  if (!context) {
    throw new Error(
      'useGiftsHistoryDispatch must be used within a GiftsHistoryProvider.',
    )
  }

  return context
}

export const useGiftsHistory = (): [
  GiftsHistoryState,
  GiftsHistoryDispatch,
] => {
  return [useGiftsHistoryState(), useGiftsHistoryDispatch()]
}
