import {
  PageHeader,
  Flex,
  Box,
  Text,
  Button,
  Link as ChakraLink,
  PageSkeleton,
  FastForm,
  FastFormButton,
  FastFormColorInput,
  FastFormInput,
  FastFormSwitch,
  useToast,
  FastFormControl,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Portal,
  FastFormUpload,
  isFileWithPreview,
  PageHeaderContents,
  PageHeaderDescription,
  PageHeaderTitle,
  PageHeaderInformation,
  PageHeaderActions,
  PageHeaderPrimaryAction,
} from '@bounty/brands-design-system'
import { UnreachableCaseError } from '@bounty/utils'
import { ComponentPropsWithoutRef } from 'react'
import { Page } from '@bounty/brands-design-system'
import {
  EmbedLocation,
  SnippetCustomizeDocument,
  UpdateConfigForEmbedDocument,
} from '../../generated/backendGraphql'
import { useQueryBackend, useMutationBackend } from '../../apollo/backend/hooks'
import { ThankYouSnippetPreview } from './ThankYouPlacement/ThankYouSnippetPreview'
import { ProductSnippetPreview } from './ProductPlacement/ProductSnippetPreview'
import { getPlacementBag } from './SnippetCustomize.utils'
import { useWatch, useFormState, useController, useForm } from 'react-hook-form'
import { LandingPagePreview } from './LandingPagePlacement/LandingPageSnippetPreview'
import { StoreAssetUploader } from '../../utils/StoreAssetUploader'
import { RemoteConfigSnippetSetting } from '@bounty/constants'
import EmojiPickerCore from 'emoji-picker-react'
import { useFeatureForStore } from '../../hooks/useFeatureForStore'
import { useParamsOrThrow } from '@bounty/hooks'
import { UploadAndDeleteAsset } from '../../components/UploadAndDeleteAsset'

type SnippetCustomizeProps = {
  location: EmbedLocation
}

const mapParamToEmbedLocation = (param: string | undefined) => {
  if (!param) return null

  switch (param) {
    case 'product':
      return 'PRODUCT'
    case 'thank-you':
      return 'THANKYOU'
    case 'landing-page':
      return 'LANDINGPAGE'

    default:
      return null
  }
}

type LivePreviewProps = {
  location: EmbedLocation
}

const LivePreview = ({ location }: LivePreviewProps) => {
  const formValues = useWatch()

  const configProps: Record<string, any> = {}
  for (const prop in formValues) {
    const value = formValues[prop]

    if (isFileWithPreview(value)) {
      configProps[prop] = value.preview
    } else {
      configProps[prop] = value
    }
  }

  switch (location) {
    case 'PRODUCT':
      return <ProductSnippetPreview height="auto" configProps={configProps} />
    case 'THANKYOU':
      return <ThankYouSnippetPreview height="auto" configProps={configProps} />
    case 'LANDINGPAGE':
      return <LandingPagePreview height="auto" configProps={configProps} />
    case 'PIXEL':
      return null

    default:
      throw new UnreachableCaseError(location)
  }
}

const SubmitButton = ({
  isLoading = false,
  location,
  ...rest
}: ComponentPropsWithoutRef<typeof FastFormButton> & {
  location: EmbedLocation
}) => {
  const { isDirty } = useFormState()
  return (
    <PageHeaderPrimaryAction
      isDisabled={isLoading}
      isLoading={isLoading}
      type="submit"
      data-testid="snippetCustomizeSave"
      {...rest}
      event={{
        eventName: 'Customize User Experience Save',
        location,
      }}
    >
      Save
    </PageHeaderPrimaryAction>
  )
}

const EmojiPicker = ({ label, name }: { label: string; name: string }) => {
  const {
    field: { onChange, value },
    formState: { errors },
  } = useController({ name })
  const error = errors[name]

  return (
    <FastFormControl label={label} error={error}>
      <Popover>
        <PopoverTrigger>
          <Box display={'inline-block'} mb="4">
            <Button
              height="auto"
              variant={'unstyled'}
              background={'gray.50'}
              border="2px solid"
              borderRadius="md"
              borderColor={'gray.200'}
              padding={4}
              outline={'none'}
              fontSize={'18px'}
              cursor="pointer"
              event="Emoji Picker Triggered"
              _focus={{
                borderColor: 'blue.500',
                outline: 'none',
              }}
            >
              {value}
            </Button>
          </Box>
        </PopoverTrigger>
        <Portal>
          <PopoverContent border="none" boxShadow={'none'}>
            <EmojiPickerCore
              width={280}
              height={400}
              onEmojiClick={(e) => {
                onChange(e.emoji)
              }}
            />
          </PopoverContent>
        </Portal>
      </Popover>
    </FastFormControl>
  )
}

const buildAssetUrl = (storeId: string, uploadKey: string) => {
  return `https://shop-assets.bounty.co/${storeId}/${uploadKey}`
}

const getUploadKey = (setting: RemoteConfigSnippetSetting) => {
  if (setting.type !== 'image') {
    throw new Error('Cannot upload image for non image type!')
  }

  return setting.imageLookupKey
}

const uploadImagesAndReturnUrls = async ({
  data,
  configSettings,
  storeId,
}: {
  data: Record<string, any>
  configSettings: RemoteConfigSnippetSetting[]
  storeId: string
}) => {
  const filesToUpload: [setting: RemoteConfigSnippetSetting, file: File][] = []

  for (const key in data) {
    const val = data[key]

    if (isFileWithPreview(val)) {
      const foundSetting = configSettings.find((setting) => setting.id === key)

      if (!foundSetting) {
        throw new Error(`Cannot find setting for key, ${key}`)
      }

      filesToUpload.push([foundSetting, val])
    }
  }

  const uploadedConfigImages: Record<string, string> = {}
  await Promise.all(
    filesToUpload.map(async ([setting, file]) => {
      try {
        const key = getUploadKey(setting)
        const resp = await new StoreAssetUploader().uploadSnippetImage(
          key,
          file,
        )

        if (resp.code === 'FILE_UPLOADED') {
          uploadedConfigImages[setting.id] = buildAssetUrl(storeId, key)
        }
      } catch (error) {
        console.error(error)
      }
    }),
  )
  return uploadedConfigImages
}

export const SnippetCustomizeComponent = ({
  location,
}: SnippetCustomizeProps) => {
  const toast = useToast()
  const { data, loading } = useQueryBackend(SnippetCustomizeDocument)
  const [mutation, { loading: updateConfigForEmbedLoading }] =
    useMutationBackend(UpdateConfigForEmbedDocument)
  const { isAvailable: isNoBrandingOnWidgetAvailable } = useFeatureForStore(
    'NO_BRANDING_ON_WIDGET',
  )
  const { setValue } = useForm()

  if (loading || !data) return <PageSkeleton />
  const {
    currentStore: { embeds, id },
  } = data

  const embed = embeds.find((e) => e.code === location) || {
    configuration: { meta: {} },
  }
  const {
    schema,
    configSettings: configSettingsRaw,
    defaultValues,
  } = getPlacementBag({
    location,
    embed,
  })

  const configSettings = configSettingsRaw.filter((setting) => {
    if (
      isNoBrandingOnWidgetAvailable === false &&
      setting.id === 'showBrandingOnWidget'
    ) {
      return false
    }

    return true
  })

  return (
    <FastForm
      schema={schema}
      style={{ flex: 1 }}
      onSubmit={async (data) => {
        const values = await uploadImagesAndReturnUrls({
          data,
          configSettings,
          storeId: id,
        })

        await mutation({
          variables: {
            code: location,
            config: { ...data, ...values },
          },
        })

        toast({
          title: 'Snippet customize success',
          description: 'You should see updates on your shop within 5 minutes.',
          status: 'success',
          id: 'snippetCustomizeSuccess',
        })
      }}
      formProps={{
        defaultValues,
      }}
    >
      <Page
        name="Customize User Experience"
        data-testid="customize-user-experience-page"
        fixedHeight="all"
        addBottomSpacing={false}
        display="flex"
        flexDir="column"
        fluid
        pl={['1rem', '2rem', '3rem', '4rem']}
        px="0"
        pb="4"
      >
        <Flex justifyContent="space-between" alignItems="flex-start" pr="12">
          {/* The landing page hides h1 tags because of how shopify works. Since the script runs it does it here as well */}
          {/* <PageHeader as="h2">Customize User Experience</PageHeader> */}
          <PageHeader>
            <PageHeaderContents>
              <PageHeaderInformation>
                <PageHeaderTitle>Customize User Experience</PageHeaderTitle>
                <PageHeaderDescription></PageHeaderDescription>
              </PageHeaderInformation>
              <PageHeaderActions>
                <SubmitButton
                  location={location}
                  isLoading={updateConfigForEmbedLoading}
                />
              </PageHeaderActions>
            </PageHeaderContents>
          </PageHeader>
        </Flex>
        <Box
          display="flex"
          flex="1"
          borderTop="1px solid"
          borderColor="gray.300"
        >
          <Box flex="1" p="8">
            <LivePreview location={location} />
          </Box>
          <Box
            width="350px"
            overflowY="auto"
            py="6"
            px="10"
            borderLeft="1px solid"
            borderColor="gray.300"
          >
            {configSettings.map((item) => {
              const { type } = item

              switch (type) {
                case 'checkbox':
                  return (
                    <FastFormSwitch
                      key={item.id}
                      label={item.label}
                      name={item.id}
                    />
                  )
                case 'color':
                  return (
                    <FastFormColorInput
                      key={item.id}
                      label={item.label}
                      name={item.id}
                    />
                  )
                case 'text':
                  return (
                    <FastFormInput
                      key={item.id}
                      label={item.label}
                      name={item.id}
                    />
                  )
                case 'emoji':
                  return (
                    <EmojiPicker
                      key={item.id}
                      label={item.label}
                      name={item.id}
                    />
                  )
                case 'image':
                  return (
                    <UploadAndDeleteAsset
                      key={item.id}
                      assetKey={item.imageLookupKey}
                      label={item.label}
                      name={item.id}
                      setValue={setValue}
                    />
                  )

                default:
                  throw new UnreachableCaseError(type)
              }
            })}
          </Box>
        </Box>
      </Page>
    </FastForm>
  )
}

export const SnippetCustomize = () => {
  const { placement } = useParamsOrThrow(['placement'])
  const location = mapParamToEmbedLocation(placement)

  if (!location) {
    return (
      <Box data-testid="customizeSnippetError">
        <PageHeader title="Customize Snippet" />
        <Text>
          The user experience customizer is not available for <b>{placement}</b>
          . Please{' '}
          <ChakraLink isExternal={false} to="../">
            go back to user experiences
          </ChakraLink>{' '}
          to customize a different snippet.
        </Text>
      </Box>
    )
  }

  return <SnippetCustomizeComponent location={location} />
}
