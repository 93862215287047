import { ComponentType, ReactNode, useEffect } from 'react'
import {
  Box,
  Text,
  Link,
  Flex,
  Button,
  FlexProps,
  LinkProps,
  ButtonProps,
} from '../../Chakra'
import { matchPath, useLocation } from 'react-router-dom'
import {
  AppRoute,
  AppRouteChild,
  AppRouteExternal,
  AppRouteInternalGrouping,
  AppRouteInternalRoot,
} from '../../types'
import { PhosphorIconProps } from '../../icons'
import { createStateContext } from '@bounty/hooks'
import { motion, AnimatePresence } from 'framer-motion'

export const [NavItemsProvider, useNavItems] = createStateContext({
  rootActiveIndex: -1,
  rootExpandedIndex: -1,
})

const useActiveRoute = (routes: AppRoute[]) => {
  const [, setNav] = useNavItems()
  const location = useLocation()

  useEffect(() => {
    for (let index = 0; index < routes.length; index++) {
      const route = routes[index]

      // External routes can't be active
      if (route.isExternal === true) continue

      if (route.isGrouping === false) {
        if (matchPath(route.routerPath, location.pathname)) {
          setNav((x) => ({
            ...x,
            rootActiveIndex: index,
            rootExpandedIndex: index,
          }))
          return
        }
        continue
      }

      for (
        let childRouteIndex = 0;
        childRouteIndex < route.children.length;
        childRouteIndex++
      ) {
        const childRoot = route.children[childRouteIndex]

        // External routes can't be active
        if (childRoot.isExternal === true) continue

        if (matchPath(childRoot.routerPath, location.pathname)) {
          setNav((x) => ({
            ...x,
            rootActiveIndex: index,
            rootExpandedIndex: index,
          }))
        }
      }
    }
  }, [location, setNav, routes])
}

type NavItemProps = {
  isActive: boolean
  variant?: 'parent' | 'child'
  isParentActive?: boolean
  icon?: ComponentType<PhosphorIconProps>
  children: ReactNode
} & FlexProps

export const NavItem = ({
  isActive,
  icon: Icon,
  children,
  variant = 'parent',
  isParentActive = false,
  ...rest
}: NavItemProps) => {
  const resolveColor = () => {
    if (variant === 'parent') {
      return isActive ? 'white' : 'neutral.600'
    }

    if (isParentActive) {
      return isActive ? 'white' : 'whiteAlpha.700'
    }

    return 'neutral.600'
  }
  const resolveHoverColor = () => {
    if (variant === 'parent') {
      return isActive ? 'white' : 'neutral.800'
    }

    if (isParentActive) {
      return isActive ? 'white' : 'whiteAlpha.900'
    }

    return 'neutral.800'
  }

  return (
    <Flex px="4" py={variant === 'parent' ? '2' : '1'} role="group" {...rest}>
      <Flex alignItems="center" as="span" width="6" mr="3">
        {Icon ? (
          <Icon
            boxSize="24px"
            color={resolveColor()}
            _groupHover={{
              color: resolveHoverColor(),
            }}
          />
        ) : null}
      </Flex>
      <Text
        color={resolveColor()}
        _groupHover={{
          color: resolveHoverColor(),
        }}
      >
        {children}
      </Text>
    </Flex>
  )
}

type NavItemLinkProps = FlexProps &
  Pick<NavItemProps, 'variant' | 'isActive' | 'isParentActive'> & {
    route:
      | Omit<AppRouteInternalRoot, 'component'>
      | AppRouteExternal
      | AppRouteChild
    isActive: boolean
  }

export const NavItemLink = ({
  route,
  variant,
  isActive,
  isParentActive,
}: NavItemLinkProps) => {
  const linkProps: LinkProps = route.isExternal
    ? { isExternal: route.isExternal, href: route.href, showIcon: false }
    : { isExternal: route.isExternal, to: route.displayPath }

  // @ts-expect-error - Not sure how to get the union type to work here
  const icon = route?.icon as ComponentType<PhosphorIconProps> | undefined

  return (
    <Link variant="unstyled" {...linkProps}>
      <NavItem
        variant={variant}
        isActive={isActive}
        isParentActive={isParentActive}
        icon={icon}
      >
        {route.name}
      </NavItem>
    </Link>
  )
}

export type NavItemButtonProps = ButtonProps & {
  isActive: boolean
  icon: ComponentType<PhosphorIconProps>
  children: ReactNode
}

export const NavItemButton = ({
  isActive,
  icon,
  children,
  ...rest
}: NavItemButtonProps) => {
  return (
    <Button
      variant={'unstyled'}
      minH="auto"
      width="100%"
      height="auto"
      fontWeight="semibold"
      {...rest}
    >
      <NavItem icon={icon} isActive={isActive}>
        {children}
      </NavItem>
    </Button>
  )
}

type NavItemContainerProps = {
  isActive: boolean
  children: ReactNode
}

export const NavItemContainer = ({
  isActive,
  children,
}: NavItemContainerProps) => {
  return (
    <Box
      width={['100%', '100%', '100%', '200px']}
      borderRadius={'6px'}
      transition="background 250ms"
      background={isActive ? 'primary.500' : 'transparent'}
      color={isActive ? 'white' : 'neutral.600'}
      fontWeight="semibold"
    >
      {children}
    </Box>
  )
}

export type NavItemFooterLinkProps =
  // TODO: 12/13/22 Internal routes should not be allowed at the bottom of the page.
  // This is temporary until we get proper support and docs pages to redirect to.
  // Bottom pages cannot have active state.
  | {
      containerProps?: Omit<NavItemContainerProps, 'isActive'>
      isExternal: true
      href: string
      icon: ComponentType<PhosphorIconProps>
      children: string
    }
  | {
      containerProps?: Omit<NavItemContainerProps, 'isActive'>
      isExternal: false
      to: string
      icon: ComponentType<PhosphorIconProps>
      children: string
    }

export const NavItemFooterLink = ({
  containerProps,
  ...rest
}: NavItemFooterLinkProps) => {
  return (
    <NavItemContainer isActive={false} {...containerProps}>
      {rest.isExternal ? (
        <NavItemLink
          isActive={false}
          route={{
            isExternal: rest.isExternal,
            href: rest.href,
            icon: rest.icon,
            name: rest.children,
          }}
          {...rest}
        />
      ) : (
        <NavItemLink
          isActive={false}
          route={{
            isExternal: rest.isExternal,
            displayPath: rest.to,
            isGrouping: false,
            routerPath: rest.to,
            icon: rest.icon,
            name: rest.children,
          }}
          {...rest}
        />
      )}
    </NavItemContainer>
  )
}

export type NavItemFooterButtonProps = Omit<NavItemButtonProps, 'isActive'> & {
  containerProps?: Omit<NavItemContainerProps, 'isActive'>
}

export const NavItemFooterButton = ({
  containerProps,
  ...rest
}: NavItemFooterButtonProps) => {
  return (
    <NavItemContainer isActive={false} {...containerProps}>
      <NavItemButton isActive={false} {...rest} />
    </NavItemContainer>
  )
}

export const NavItemGroup = ({
  item,
  isActive,
  index,
}: {
  item: AppRouteInternalGrouping
  isActive: boolean
  index: number
}) => {
  const location = useLocation()
  const [{ rootExpandedIndex }, setNav] = useNavItems()
  const isExpanded = rootExpandedIndex === index

  return (
    <NavItemContainer isActive={isActive}>
      <NavItemButton
        isActive={isActive}
        icon={item.icon}
        event={{ eventName: 'Name Item Button Clicked', name: item.name }}
        onClick={() =>
          setNav((prev) => ({
            ...prev,
            rootExpandedIndex: prev.rootExpandedIndex === index ? -1 : index,
          }))
        }
      >
        {item.name}
      </NavItemButton>
      <AnimatePresence initial={false}>
        {isExpanded && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            // transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <Box pb="2">
              {item.children.map((x) => {
                return (
                  <NavItemLink
                    variant="child"
                    key={x.name}
                    isParentActive={isActive}
                    isActive={
                      x.isExternal
                        ? false
                        : !!matchPath(x.routerPath, location.pathname)
                    }
                    route={x}
                  />
                )
              })}
            </Box>
          </motion.section>
        )}
      </AnimatePresence>
    </NavItemContainer>
  )
}

export type NavItemsProps = { items: AppRoute[] }

export const NavItemsComponent = ({ items }: NavItemsProps) => {
  useActiveRoute(items)
  const [{ rootActiveIndex }] = useNavItems()
  return (
    <Box>
      {items.map((item, index) => {
        const isActive = rootActiveIndex === index
        if (item.isExternal === false && item.isGrouping) {
          return (
            <NavItemGroup
              key={item.name}
              item={item}
              index={index}
              isActive={isActive}
            />
          )
        }

        return (
          <NavItemContainer key={item.name} isActive={isActive}>
            <NavItemLink variant="parent" isActive={isActive} route={item} />
          </NavItemContainer>
        )
      })}
    </Box>
  )
}

export const NavItems = (props: NavItemsProps) => {
  return (
    <NavItemsProvider>
      <NavItemsComponent {...props} />
    </NavItemsProvider>
  )
}
