import {
  Drawer as DrawerCore,
  DrawerBody as DrawerBodyCore,
  DrawerFooter as DrawerFooterCore,
  DrawerHeader as DrawerHeaderCore,
  DrawerOverlay as DrawerOverlayCore,
  DrawerContent as DrawerContentCore,
  DrawerCloseButton as DrawerCloseButtonCore,
  Heading,
  HeadingProps,
  TextProps,
  Text,
} from './Chakra'
import { ComponentProps } from 'react'
import { defineStyleConfig, forwardRef } from '@chakra-ui/react'
import { Button, ButtonProps } from './Button'
import {
  BreadcrumbLinkProps,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from './Breadcrumb'
import { CaretLeftIcon } from '../icons'

export const drawerStyle = defineStyleConfig({
  baseStyle: () => {
    return {
      header: {
        pt: 8,
        pb: 8,
        px: 8,
        fontWeight: 'normal',
        fontSize: 'md',
      },
      closeButton: {
        top: 3,
        insetEnd: 6,
      },
      body: {
        px: 8,
      },
      footer: {
        borderTop: '1px',
        borderColor: 'neutral.200',
        py: 6,
        px: 8,
      },
    }
  },
  sizes: {
    lg: {
      dialog: {
        maxWidth: '37.5rem',
      },
    },
    xl: {
      dialog: {
        maxWidth: '45.5rem',
      },
    },
  },
  defaultProps: {
    size: 'lg',
  },
})

export type DrawerProps = ComponentProps<typeof DrawerCore>

export const Drawer = ({ ...props }: DrawerProps) => {
  return <DrawerCore {...props} />
}

export type DrawerBodyProps = ComponentProps<typeof DrawerBodyCore>

export const DrawerBody = ({ ...props }: DrawerBodyProps) => {
  return <DrawerBodyCore {...props} />
}

export type DrawerFooterProps = ComponentProps<typeof DrawerFooterCore>

export const DrawerFooter = ({ ...props }: DrawerFooterProps) => {
  return <DrawerFooterCore {...props} />
}

export type DrawerHeadingProps = HeadingProps

export const DrawerHeading = ({ ...props }: DrawerHeadingProps) => {
  return <Heading size="lg" {...props} />
}

export type DrawerDescriptionProps = TextProps

export const DrawerDescription = ({ ...props }: DrawerDescriptionProps) => {
  return <Text {...props} />
}

export type DrawerHeaderProps = ComponentProps<typeof DrawerHeaderCore>

export const DrawerHeader = ({ ...props }: DrawerHeaderProps) => {
  return <DrawerHeaderCore {...props} />
}

export type DrawerOverlayProps = ComponentProps<typeof DrawerOverlayCore>

export const DrawerOverlay = ({ ...props }: DrawerOverlayProps) => {
  return <DrawerOverlayCore {...props} />
}

export type DrawerContentProps = ComponentProps<typeof DrawerContentCore>

export const DrawerContent = ({ ...props }: DrawerContentProps) => {
  return <DrawerContentCore {...props} />
}

export type DrawerCloseButtonProps = ComponentProps<
  typeof DrawerCloseButtonCore
>

export const DrawerCloseButton = ({ ...props }: DrawerCloseButtonProps) => {
  return <DrawerCloseButtonCore {...props} />
}

export type DrawerBackButtonProps = BreadcrumbLinkProps

export const DrawerBackButton = forwardRef<DrawerBackButtonProps, 'a'>(
  ({ ...props }, ref) => {
    return (
      <Breadcrumb px="8" pt="4" m="0">
        <BreadcrumbItem>
          <BreadcrumbLink fontWeight={'semibold'} ref={ref} {...props}>
            <CaretLeftIcon
              color="neutral.700"
              display={'inline-block'}
              verticalAlign="text-top"
              boxSize={'16px'}
              weight="bold"
            />{' '}
            Back
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    )
  },
)

export type DrawerFooterPrimaryButtonProps = ButtonProps

export const DrawerFooterPrimaryButton = forwardRef<
  DrawerFooterPrimaryButtonProps,
  'button'
>(({ ...props }, ref) => {
  return <Button ref={ref} size="md" ml="3" {...props} />
})

export type DrawerFooterSecondaryButtonProps = ButtonProps

export const DrawerFooterSecondaryButton = forwardRef<
  DrawerFooterSecondaryButtonProps,
  'button'
>(({ ...props }, ref) => {
  return <Button ref={ref} size="md" ml="3" variant={'outline'} {...props} />
})

export type DrawerFooterTertiaryButtonProps = ButtonProps

export const DrawerFooterTertiaryButton = forwardRef<
  DrawerFooterTertiaryButtonProps,
  'button'
>(({ ...props }, ref) => {
  return <Button ref={ref} size="md" ml="3" variant={'ghost'} {...props} />
})
