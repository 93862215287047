import { useCallback } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalProps,
  Alert,
  Button,
  ModalFooter,
} from '@bounty/brands-design-system'
import { ContentLibrary } from '../ContentLibrary/ContentLibrary'
import { MAX_FEATURED_BOUNTIES } from '@bounty/constants'

type FeaturedBountiesModalProps = Omit<ModalProps, 'children'> & {
  selectedVideoIds: string[]
  setSelectedVideoIds: React.Dispatch<React.SetStateAction<string[]>>
}

export const FeaturedBountiesModal = ({
  onClose: onCloseProp,
  selectedVideoIds,
  setSelectedVideoIds,
  ...rest
}: FeaturedBountiesModalProps) => {
  const onClose = useCallback(() => {
    onCloseProp()
  }, [onCloseProp])
  return (
    <Modal
      size="6xl"
      onClose={() => {
        onClose()
      }}
      isCentered
      allowPinchZoom
      scrollBehavior="inside"
      {...rest}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Select Bounties</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {selectedVideoIds.length === MAX_FEATURED_BOUNTIES && (
            <Alert status="warning">
              Can only select up to five bounties to feature. Deselect a video
              to choose another one.
            </Alert>
          )}
          <ContentLibrary
            variant={'storefront'}
            selectedVideoIds={selectedVideoIds}
            setSelectedVideoIds={setSelectedVideoIds}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            event="Storefront Bounties Modal Remove Bounties Clicked"
            mr={3}
            onClick={() => {
              setSelectedVideoIds([])
            }}
          >
            Remove All
          </Button>
          <Button
            event="Storefront Bounties Modal Done Clicked"
            mr={3}
            onClick={onClose}
          >
            Done
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
