import { Global } from '@emotion/react'

export const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Figtree';
        src: url('/fonts/figtree/Figtree-VariableFont_wght.ttf')
          format('truetype-variations');
        font-weight: 125 950;
        font-stretch: 75% 125%;
        font-style: normal;
        font-display: swap;
      }
      
      @font-face {
        font-family: 'Figtree';
        src: url('/fonts/figtree/Figtree-Italic-VariableFont_wght.ttf')
          format('truetype-variations');
        font-weight: 125 950;
        font-stretch: 75% 125%;
        font-style: italic;
        font-display: swap;
      }
      
      @font-face {
        font-family: 'Mona Sans';
        src:
          url('/fonts/mona-sans/Mona-Sans.woff2') format('woff2 supports variations'),
          url('/fonts/mona-sans/Mona-Sans.woff2') format('woff2-variations');
        font-weight: 700;
        font-stretch: 125%;
      }
      
      @font-face {
        font-family: 'Mona Sans';
        src:
          url('/fonts/mona-sans/Mona-Sans.woff2') format('woff2 supports variations'),
          url('/fonts/mona-sans/Mona-Sans.woff2') format('woff2-variations');
        font-weight: 400;
        font-stretch: 125%;
      }
      `}
  />
)
