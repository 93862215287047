import { useState, useRef, useEffect } from 'react'
import {
  Box,
  BoxProps,
  Skeleton,
  SkeletonCircle,
  ChakraGrid,
  ChakraGridProps,
  Flex,
  Text,
  AspectRatio,
} from '@bounty/brands-design-system'
import { ContentLibraryResponse } from '../../../generated/backendGraphql'
import { CLOUDINARY_URL, S3_BUCKET_URL } from '../../../config/env'
import { PlayFillIcon } from '@bounty/brands-design-system'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { useNavigate } from 'react-router-dom'

export const VideoLoading = ({ sentryRef }: { sentryRef?: any }) => {
  return (
    <Box ref={sentryRef}>
      <Skeleton height="400px" width="100%" mb="4" />
      <Flex justifyContent="space-between">
        <Skeleton height="20px" width="90px" />
        <SkeletonCircle size="25px" />
      </Flex>
    </Box>
  )
}

export const ContentLibraryItemsLoading = ({
  sentryRef,
}: {
  sentryRef: any
}) => {
  return (
    <>
      <VideoLoading sentryRef={sentryRef} />
      <VideoLoading />
      <VideoLoading />
      <VideoLoading />
      <VideoLoading />
      <VideoLoading />
    </>
  )
}

export type ContentLibraryItemProps = BoxProps & {
  item: ContentLibraryResponse['items'][0]
  shouldPlayVideo: boolean
}

const isVideoPlaying = (video: HTMLVideoElement) =>
  !!(
    video.currentTime > 0 &&
    !video.paused &&
    !video.ended &&
    video.readyState > 2
  )

export const ContentLibraryItem = ({
  item,
  shouldPlayVideo,
  ...rest
}: ContentLibraryItemProps) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const navigate = useNavigate()

  useEffect(() => {
    if (!videoRef.current) return

    if (shouldPlayVideo) {
      videoRef.current?.play()
    } else {
      if (isVideoPlaying(videoRef.current) === false) return

      videoRef?.current?.pause()
    }
  }, [shouldPlayVideo])

  const onOpen = () => {
    // note: this used to be a tiktokvideo.id now its userbounty.id
    navigate(`/content-library/video/${item.id}`)
  }

  const formatVideoDuration = (s: number | undefined) => {
    if (!s) return '--:--'
    if (Number.isNaN(s)) return '--:--'
    const minutes = Math.floor(s / 60)
    const seconds = Math.floor(s) % 60
    return `${minutes}:${seconds.toString().padStart(2, '0')}`
  }

  return (
    <Box position="relative" isolation="isolate" {...rest}>
      <Box
        data-testid="content-library-item"
        mb="2"
        cursor="pointer"
        onClick={onOpen}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onOpen()
          }
        }}
      >
        <AspectRatio ratio={0.56}>
          <video
            poster={`${CLOUDINARY_URL}/video_id_${item.externalContentId}.jpg`}
            autoPlay={false}
            loop={true}
            ref={videoRef}
            muted
            preload="none"
          >
            <source
              src={`${S3_BUCKET_URL}/${item.externalContentId}.mp4`}
              type="video/mp4"
            ></source>
          </video>
        </AspectRatio>
        <Flex
          mt="-40px"
          ml={4}
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex
            alignItems="center"
            py={1}
            px={2}
            background="black"
            zIndex="100"
          >
            <PlayFillIcon fill="white" />
            <Text color="white">
              {formatVideoDuration(videoRef.current?.duration)}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Box>
  )
}

type CreatorVideoProps = ChakraGridProps & {
  items?: readonly ContentLibraryItemProps['item'][]
  loading: boolean
  hasNextPage: boolean
  error: boolean
  onLoadMore: () => void
}

export const CreatorVideos = ({
  items,
  loading,
  hasNextPage,
  error,
  onLoadMore,
  ...rest
}: CreatorVideoProps) => {
  const [playVideoId, setPlayVideoId] = useState<string | null>(null)
  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore,
    disabled: error,
    rootMargin: '0px 0px 200px 0px',
  })

  if (!!items && items.length === 0) {
    return null
  }

  return (
    <ChakraGrid
      templateColumns={['repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
      gap={0}
      {...rest}
    >
      {!items
        ? null
        : items.map((item) => (
            <ContentLibraryItem
              shouldPlayVideo={playVideoId === item.id}
              onMouseEnter={() => setPlayVideoId(item.id)}
              onMouseLeave={() => setPlayVideoId(null)}
              key={item.id}
              item={item}
            />
          ))}
      {(loading || hasNextPage) && (
        <ContentLibraryItemsLoading sentryRef={sentryRef} />
      )}
    </ChakraGrid>
  )
}
