type GtagFunction = (
  event: 'event' | 'config' | 'set',
  eventName: string | Record<string, any>,
  data?: Record<string, any>,
) => void

declare global {
  interface Window {
    gtag: GtagFunction
  }
}

export const initGa4 = ({ measurementId }: { measurementId: string }) => {
  const gtagElement = document.createElement('script')
  gtagElement.async = true
  gtagElement.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`

  document.head.appendChild(gtagElement)

  const gtagConfigScript = document.createElement('script')
  gtagConfigScript.type = 'text/javascript'
  gtagConfigScript.innerHTML = `
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', '${measurementId}');
`

  document.body.appendChild(gtagConfigScript)
}

// https://developers.google.com/analytics/devguides/collection/ga4/user-id?platform=websites
export const loginGa4 = ({ userId }: { userId: string }) => {
  window?.gtag?.('set', { user_id: userId })
}

export const eventGa4 = (eventName: string, data: Record<string, any>) => {
  window?.gtag?.('event', eventName, data)
}
