import {
  Box,
  FastFormUpload,
  Button,
  useToast,
} from '@bounty/brands-design-system'
import { DeleteAssetDocument } from '../generated/backendGraphql'
import { useMutationBackend } from '../apollo/backend/hooks'
import { useState } from 'react'
import { UseFormSetValue } from 'react-hook-form'

type UploadAndDeleteProps = {
  assetKey: string | undefined
  label?: string
  name: string
  helpText?: string | undefined
  setValue: UseFormSetValue<any>
}

export const UploadAndDeleteAsset = ({
  assetKey,
  label,
  name,
  helpText,
  setValue,
}: UploadAndDeleteProps) => {
  const [removeImage, setRemoveImage] = useState(false)
  const [deleteAsset, { loading: deleteAssetLoading }] =
    useMutationBackend(DeleteAssetDocument)
  const toast = useToast()

  return (
    <Box>
      <FastFormUpload
        label={label}
        name={name}
        helpText={helpText}
        removed={removeImage}
        setRemoved={setRemoveImage}
      />

      <Button
        variant="solid"
        size="sm"
        mb="2"
        width="100%"
        event={'Remove Image Button Clicked'}
        isDisabled={deleteAssetLoading}
        onClick={async () => {
          if (!assetKey) return
          setRemoveImage(true)
          setValue(name, null)
          const { data } = await deleteAsset({
            variables: {
              key: assetKey,
            },
          })
          if (!data?.deleteBrandAsset) {
            toast({
              title: 'Error deleting image',
              status: 'error',
            })
          }
        }}
      >
        Remove
      </Button>
    </Box>
  )
}
