import {
  Flex,
  Skeleton,
  Switch,
  Text,
  FlexProps,
} from '@bounty/brands-design-system'
import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import {
  IndividualEmbedTogglerDocument,
  EmbedLocation,
  EnableEmbedForLocationDocument,
  DisableEmbedForLocationDocument,
} from '../../generated/backendGraphql'
import { useAnalytics } from '@bounty/web-analytics'

export type IndividualEmbedTogglerSwitchProps = FlexProps & {
  code: EmbedLocation
  prettyName: string
}

// TODO: better types on the embeds array
const isEmbedEnabled = (code: EmbedLocation, embeds: readonly any[]) => {
  return embeds?.find((entry) => entry.code === code)?.enabled ?? false
}

export const IndividualEmbedTogglerSwitch = (
  props: IndividualEmbedTogglerSwitchProps,
) => {
  const { code, prettyName, ...restProps } = props
  const { loading, data } = useQueryBackend(IndividualEmbedTogglerDocument)
  const [enableMutation] = useMutationBackend(EnableEmbedForLocationDocument)
  const [disableMutation] = useMutationBackend(DisableEmbedForLocationDocument)
  const { track } = useAnalytics()

  return (
    <Flex alignItems="center" {...restProps}>
      {loading || !data ? (
        <Skeleton height="30px" width="90px" />
      ) : (
        <>
          <Switch
            isChecked={isEmbedEnabled(code, data.currentStore.embeds)}
            onChange={(e) => {
              track('Embed Code Snippet Toggled', {
                isEnabled: e.target.checked,
                location: code,
              })

              if (e.target.checked) {
                enableMutation({
                  variables: {
                    code: props.code,
                  },
                })
              } else {
                disableMutation({
                  variables: {
                    code: props.code,
                  },
                })
              }
            }}
            size="lg"
          />
          <Text as="span" fontSize="sm" ml="2">
            Toggle {prettyName}
          </Text>
        </>
      )}
    </Flex>
  )
}
