import { Divider, PageHeader, Box, Flex } from '@bounty/brands-design-system'

import { Page } from '@bounty/brands-design-system'
import { CurrentMonthlySpend } from './CurrentMonthlySpend'
import { MonthlyBudget } from './MonthlyBudget'
import { CurrentSubscriptionTile } from './CurrentSubscriptionTile'
import { ContentCharges } from './ContentCharges'

export type BillingProps = unknown

export const Billing = () => {
  return (
    <Page data-testid="billing-page" name="Billing">
      <PageHeader title="Billing" />

      <Flex
        alignItems={['center', 'center', 'center', 'center', 'flex-start']}
        flexDirection={['column', 'column', 'column', 'column', 'row']}
      >
        <Flex flexDirection="column" flex="2" mr="4">
          <CurrentMonthlySpend />
          <Box>
            <MonthlyBudget editMode={true} />
          </Box>
        </Flex>
        <Flex flex="1" width="100%">
          <CurrentSubscriptionTile />
        </Flex>
      </Flex>

      <Divider py="6" />
      <ContentCharges />
    </Page>
  )
}
