export type LogLevel = 'log' | 'warn' | 'error' | 'info'

/**
 * Create a lightweight logger. Perfect for use on the client.
 */
export const createLogger = ({
  name = 'Bounty',
  enabled = true,
}: {
  name?: string
  enabled?: boolean
} = {}) => {
  const noop = () => undefined

  const logger =
    (level: LogLevel) =>
    (...args: any[]) => {
      if (enabled === false) {
        return noop
      }

      console[level](
        `%c${name}:`,
        'font-weight: bold; color: burlywood;',
        ...args.map((arg) => {
          if (typeof arg === 'string') {
            return arg
          }
          if (typeof arg === 'number') {
            return arg
          }
          if (arg instanceof Error) {
            return arg
          }

          return `\n${JSON.stringify(arg, null, 2)}`
        }),
      )

      return
    }

  logger.log = logger('log')
  logger.info = logger('info')
  logger.warn = logger('warn')
  logger.error = logger('error')

  return logger
}
