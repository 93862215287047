import { isFunction } from '@bounty/utils'
import { zodResolver } from '@hookform/resolvers/zod'
import { ReactNode, Ref } from 'react'
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form'
import { Button, ButtonProps, forwardRef } from '../../Chakra'
import { ErrorText } from '../ErrorText'
import { Form as FormCore, FormProps } from '../Form'

export type FastFormProps<TFieldValues extends FieldValues = FieldValues> =
  Omit<FormProps, 'onSubmit' | 'children'> & {
    formProps?: UseFormProps<TFieldValues>
    formRef?: Ref<HTMLFormElement>
    schema: any
    onSubmit: SubmitHandler<TFieldValues>
    children:
      | ReactNode
      | ((props: UseFormReturn<TFieldValues, object>) => ReactNode)
  }

const getGenericError = (e: any) => {
  return e?.['&']?.[0]?.message
}

export const FastForm = <TFieldValues extends FieldValues = FieldValues>({
  children,
  formProps,
  onSubmit,
  schema,
  formRef,
  ...rest
}: FastFormProps<TFieldValues>) => {
  const methods = useForm<TFieldValues>({
    resolver: zodResolver(schema),
    ...formProps,
  })
  const genericError = getGenericError(methods.formState.errors)

  return (
    <FormProvider {...methods}>
      <FormCore
        noValidate
        {...rest}
        onSubmit={methods.handleSubmit(onSubmit)}
        ref={formRef}
      >
        {isFunction(children) ? children(methods) : children}
        {genericError && <ErrorText>{genericError}</ErrorText>}
      </FormCore>
    </FormProvider>
  )
}

FastForm.displayName = 'FastForm'

export type FastFormButtonProps = ButtonProps

export const FastFormButton = forwardRef<FastFormButtonProps, 'button'>(
  ({ children, ...rest }, ref) => {
    return (
      <Button type="submit" alignSelf={'end'} {...rest} ref={ref}>
        {children}
      </Button>
    )
  },
)

FastFormButton.displayName = 'FastFormButton'
