import { AssetUploadResponse } from '@bounty/types'
import axios, { AxiosInstance } from 'axios'
import { API_URL } from '../config/env'

export class StoreAssetUploader {
  httpClient: AxiosInstance

  constructor() {
    const authToken = localStorage.getItem('authToken')

    if (!authToken) {
      throw new Error('Cannot use StoreAssetUploader without authentication!')
    }

    this.httpClient = axios.create({
      baseURL: API_URL,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
  }

  async uploadSnippetImage(
    key: string,
    file: File,
  ): Promise<AssetUploadResponse> {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('key', key)

    const data = await this.httpClient.post<FormData, any>(
      'api/v1/uploads/brand-asset-upload',
      formData,
      {
        headers: { 'Content-Type': 'multipart/form-data' },
      },
    )

    return data.data
  }
}
