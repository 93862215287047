/**
 * Creates display values for things on the client.
 */
import { format, intervalToDuration, isAfter, parseISO } from 'date-fns'
import { inviteKeyLength } from '@bounty/constants'

export const prettyPercentage = (
  n: number,
  { showPlus = false }: { showPlus?: boolean } = {},
) => {
  const maybePlus = showPlus && n > 0 ? '+' : ''
  return `${maybePlus}${Math.round(n * 100)}%`
}

export const prettyNumber = (n: number) => {
  return n.toLocaleString()
}

export const prettyCurrency = (
  n: number,
  {
    locales = 'en-US',
    currencyCode = 'USD',
    precision = 0,
  }: {
    locales?: string
    currencyCode?: string
    precision?: number
  } = {},
) => {
  return new Intl.NumberFormat(locales, {
    style: 'currency',
    currency: currencyCode,
    // You need both of these otherwise this explodes in Node....
    // https://github.com/andyearnshaw/Intl.js/issues/123
    maximumFractionDigits: precision,
    minimumFractionDigits: precision,
  }).format(n)
}

const padNumberForDurationFormat = (n: number | undefined = 0) => {
  return n.toString().padStart(2, '0')
}

export const prettyDuration = ({
  start = new Date(),
  end,
  daysLimit = 2,
}: {
  /**
   * Defaults to now
   */
  start?: Date
  end: Date
  daysLimit?: number
}) => {
  // Don't show negative durations
  if (isAfter(start, end)) return `00:00:00`

  const duration = intervalToDuration({
    start,
    end,
  })

  // This doesn't round up or down the hours. So if there's 3 days, 23 hours, 59 minutes, and 59 seconds left it
  // will just say 3 days. Once the days limit is past it's precise
  if (duration.days != null && duration.days > daysLimit) {
    return `${duration.days} day${duration.days > 1 ? 's' : ''}`
  }

  duration.hours =
    duration.days != null && duration.days > 0 && duration.hours != null
      ? duration.days * 24 + duration.hours
      : duration.hours

  return `${padNumberForDurationFormat(
    duration.hours,
  )}:${padNumberForDurationFormat(
    duration.minutes,
  )}:${padNumberForDurationFormat(duration.seconds)}`
}

/**
 * @deprecated: please @see {@link prettyCount }
 */
export const prettyViews = (views: number): string => {
  if (views < 1000) {
    return `${views}`
  }

  if (views < 1000000) {
    return `${(views / 1000).toFixed(1)}K`
  }

  if (views < 1000000000) {
    return `${(views / 1000000).toFixed(1)}M`
  }

  return `${(views / 1000000000).toFixed(1)}B`
}

export const prettyCount = (value: number): string => {
  if (value < 1000) {
    return `${value}`
  }

  if (value < 1000000) {
    return `${(value / 1000).toFixed(1)}K`
  }

  if (value < 1000000000) {
    return `${(value / 1000000).toFixed(1)}M`
  }

  return `${(value / 1000000000).toFixed(1)}B`
}

export const prettyFloatingDate = (
  date: string,
  dateFormat?: string | undefined,
) => {
  // https://github.com/date-fns/date-fns/issues/489
  return format(new Date(parseISO(date)), dateFormat || 'MMMM dd, yyyy')
}

export const prettyInviteKey = (key: string) => {
  if (key.length !== inviteKeyLength) {
    throw new Error(`Invite keys need to be ${inviteKeyLength} characters long`)
  }
  if (key.includes('-')) {
    throw new Error(`Raw invite key needed without dashes`)
  }
  return key.match(/.{1,4}/g)!.join('-')
}
