import { Page } from '@bounty/brands-design-system'
import { NoEmbedsConfigured } from '../../components/NoEmbedsConfigured'
import { FulfillmentEventsDiagnostics } from '../../components/FulfillmentEventsDiagnostics/FulfillmentEventsDiagnostics'
import { Outlet, Route, Routes } from 'react-router-dom'
import { Dashboard } from './Dashboard'

export const Home = () => {
  return (
    <Page
      fluid
      data-testid="home-page"
      name="Dashboard"
      pb="12"
      addBottomSpacing={false}
      fixedHeight="desktopOnly"
      display="flex"
      flexDir="column"
    >
      <NoEmbedsConfigured />
      <FulfillmentEventsDiagnostics />

      <Routes>
        <Route
          path="/*"
          element={
            <>
              <Dashboard />
              <Outlet />
            </>
          }
        />
      </Routes>
    </Page>
  )
}
