import { Link, List, ListItem, Text } from '@bounty/brands-design-system'
import { ReactNode } from 'react'
import { FulfillmentEventsDiagnostics } from '../../../../components/FulfillmentEventsDiagnostics/FulfillmentEventsDiagnostics'

export type UgcConversionFunnelSection = {
  id:
    | 'OrdersWithUsers'
    | 'OrdersWithUsersAndProducts'
    | 'UserOrdersDelivered'
    | 'BountiesAccepted'
    | 'BountiesCompleted'
    | 'BountiesApproved'
  heading: string
  description: ReactNode
}

export const UGC_CONVERSION_FUNNEL_SECTIONS: UgcConversionFunnelSection[] = [
  {
    id: 'OrdersWithUsers',
    heading: 'Step 1: Users who placed an order',
    description: (
      <>
        <Text mb="2">
          This is the total number of orders on your store that are associated
          to a user who signed up for Bounty, either on your store, or who came
          to your store through the Bounty network.
        </Text>
        <List>
          <ListItem>
            <Link to="/user-experiences/overview" isExternal={false}>
              Manage Widgets
            </Link>
          </ListItem>
          <ListItem>
            <Link
              href="https://brands.support.bounty.co/hc/en-us/sections/12561290243611-Installing-User-Experiences"
              isExternal
            >
              How to set up Bounty's widgets
            </Link>
          </ListItem>
          <ListItem>
            <Link
              href="https://brands.support.bounty.co/hc/en-us/sections/11758004566939-User-Experiences"
              isExternal
            >
              Widget troubleshooting
            </Link>
          </ListItem>
        </List>
      </>
    ),
  },
  {
    id: 'UserOrdersDelivered',
    heading: 'Step 2: Orders marked delivered in Shopify',
    description: (
      <>
        <FulfillmentEventsDiagnostics />
        <Text mb="2">
          This is the total number of orders marked delivered as reported by
          Shopify. Our system triggers reminder notifications off of fulfillment
          events in Shopify, so if your fulfillment events aren't working
          creators won't get notifications reminding them to post their videos.
        </Text>
        <Text mb="2">
          If you have a low number of order delivered you may have a problem
          with fulfillment events, which can be solved through a number of
          simple app integrations.
        </Text>
        <List mb="4">
          <ListItem>
            <Link
              href="https://brands.support.bounty.co/hc/en-us/articles/12372385535771-Fixing-Missing-Fulfillment-Events"
              isExternal
            >
              Fixing Missing Fulfillment Events
            </Link>
          </ListItem>
        </List>
      </>
    ),
  },
  {
    id: 'BountiesAccepted',
    heading: 'Step 3: Bounties accepted',
    description: (
      <>
        <Text mb="2">
          The total number of creators who received your product and agreed to
          the terms of your Bounty offer.
        </Text>
        <Text mb="2">
          Not seeing many creators accept their bounties? Try increasing your
          minimum payout and/or CPMs to increase incentives for a creator to
          post.
        </Text>
        <List>
          <ListItem>
            <Link to="/your-offer" isExternal={false}>
              Manage Your Offers
            </Link>
          </ListItem>
        </List>
      </>
    ),
  },
  {
    id: 'BountiesCompleted',
    heading: 'Step 4: Bounties completed',
    description: (
      <Text>
        The number of bounties posted. You can view your{' '}
        <Link to="/content-library" isExternal={false}>
          completed Bounties
        </Link>{' '}
        here.
      </Text>
    ),
  },
  {
    id: 'BountiesApproved',
    heading: 'Step 5: Bounties approved',
    description: (
      <>
        <Text mb="2">
          We don’t approve every bounty, creators must tag content appropriately
          and follow content guidelines.
        </Text>
        <Text mb="2">
          If too many videos are being denied, consider updating your content
          briefs to give creators clearer instruction around the types of videos
          to create.
        </Text>
        <Text mb="2">
          You can also relax your content requirements anytime by updating your
          preferences to allow edgier content or mild profanity. Please note, if
          you do not allow profanity in content preferences, we will not approve
          content with profanity in music or voiceovers.
        </Text>
        <List>
          <ListItem>
            <Link to="/settings" isExternal={false}>
              Manage Content Preferences here
            </Link>
          </ListItem>
        </List>
      </>
    ),
  },
]
