import { Flex, Text } from '@bounty/brands-design-system'

type FromDataDisplayProps = {
  children: React.ReactNode
  description: string
  icon: React.ReactNode
}

export const FormDataDisplay = ({
  children,
  description,
  icon,
}: FromDataDisplayProps) => {
  return (
    <Flex
      direction="column"
      backgroundColor="gray.50"
      width={['100%', null, '55%']}
      borderTopRightRadius={['none', null, 'md']}
      borderBottomLeftRadius={['md', null, 'none']}
      borderBottomRightRadius="md"
      overflow="hidden"
    >
      <Flex p="8" flexGrow={1} direction="column" gridGap={8}>
        {children}
      </Flex>
      <Flex
        py="3"
        px="6"
        w="100%"
        justify="center"
        align="center"
        gridGap={1}
        backgroundColor="#B9A2FF"
        color="#190793"
      >
        {icon}
        <Text as="span" fontWeight="semibold" fontSize="md">
          {description}
        </Text>
      </Flex>
    </Flex>
  )
}
