import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react'

export type WelcomeState = {
  budgetedAmount?: number
}
const WelcomeStateContext = createContext<WelcomeState | undefined>(undefined)

export type WelcomeDispatch = {
  setWelcome: React.Dispatch<React.SetStateAction<WelcomeState>>
  setBudgetAmount: (budgetedAmount: number) => void
}
export const WelcomeDispatchContext = createContext<
  WelcomeDispatch | undefined
>(undefined)

export type WelcomeProviderProps = { children: ReactNode }

export const WelcomeProvider = ({ children }: WelcomeProviderProps) => {
  const [value, setWelcome] = useState<WelcomeState>({})

  const setBudgetAmount: WelcomeDispatch['setBudgetAmount'] = useCallback(
    (budgetedAmount) => {
      setWelcome((x) => ({ ...x, budgetedAmount }))
    },
    [setWelcome],
  )

  return (
    <WelcomeStateContext.Provider value={value}>
      <WelcomeDispatchContext.Provider value={{ setWelcome, setBudgetAmount }}>
        {children}
      </WelcomeDispatchContext.Provider>
    </WelcomeStateContext.Provider>
  )
}

export const useWelcomeState = (): WelcomeState => {
  const context = useContext(WelcomeStateContext)

  if (!context) {
    throw new Error('useWelcomeState must be used within a WelcomeProvider.')
  }

  return context
}

export const useWelcomeDispatch = (): WelcomeDispatch => {
  const context = useContext(WelcomeDispatchContext)

  if (!context) {
    throw new Error('useWelcomeDispatch must be used within a WelcomeProvider.')
  }

  return context
}

export const useWelcome = (): [WelcomeState, WelcomeDispatch] => {
  return [useWelcomeState(), useWelcomeDispatch()]
}
