import * as z from 'zod'
import { PayoutType } from '@bounty/constants'
import { UnreachableCaseError } from '@bounty/utils'

export const payPalSchema = z
  .object({
    email: z.string().email(),
  })
  .strict()

export const venmoSchema = z
  .object({
    email: z.string().email(),
    venmoName: z.string().min(3),
  })
  .strict()

export const getPayoutSchemaFromPayoutType = (payoutType: PayoutType) => {
  switch (payoutType) {
    case 'BITCOIN':
    case 'CASHAPP':
    case 'STRIPE':
      throw new Error(`Schema not implemented for ${payoutType}!`)
    case 'VENMO':
      return venmoSchema
    case 'PAYPAL':
      return payPalSchema

    default:
      throw new UnreachableCaseError(payoutType)
  }
}
