import {
  Box,
  Page,
  PageHeader,
  Skeleton,
  Tag,
  Grid,
} from '@bounty/brands-design-system'
import { CellRendererSelectorFunc, ColDef } from 'ag-grid-community'
import { useMemo } from 'react'
import { useQueryBackend } from '../../../../apollo/backend/hooks'
import {
  InviteHistoryDocument,
  InviteHistoryQuery,
  UserInviteStatus,
} from '../../../../generated/backendGraphql'
import { UnreachableCaseError } from '@bounty/utils'

type InviteHistoryList = InviteHistoryQuery['inviteHistory']
type InviteHistoryListItem = InviteHistoryQuery['inviteHistory'][0]
type CellRendererParams = Parameters<
  CellRendererSelectorFunc<InviteHistoryListItem>
>[0]

const InviteHistoryTable = ({ invites }: { invites: InviteHistoryList }) => {
  const columnDefs = useMemo(
    (): ColDef<InviteHistoryListItem>[] => [
      {
        headerName: 'Email',
        field: 'email',
      },
      {
        headerName: 'Name',
        field: 'name',
      },
      {
        headerName: 'Status',
        field: 'status',
        cellRenderer: (params: CellRendererParams) => {
          const value: UserInviteStatus = params.value
          switch (value) {
            case 'PENDING':
              return (
                <Tag color="white" background="gray.500" fontWeight="semibold">
                  PENDING
                </Tag>
              )
            case 'REJECTED':
              return (
                <Tag
                  color="white"
                  background="blackAlpha.700"
                  fontWeight="semibold"
                >
                  REJECTED
                </Tag>
              )
            case 'ACCEPTED':
              return (
                <Tag color="white" background="green.500" fontWeight="semibold">
                  ACCEPTED
                </Tag>
              )
            case 'SENT':
              return (
                <Tag
                  color="white"
                  background="purple.500"
                  fontWeight="semibold"
                >
                  SENT
                </Tag>
              )
            case 'FAILED':
              return (
                <Tag color="white" background="red.500" fontWeight="semibold">
                  FAILED
                </Tag>
              )
            default:
              throw new UnreachableCaseError(value)
          }
        },
      },
      {
        headerName: 'Gift',
        field: 'status',
        valueGetter: (params) => {
          return params.data?.storeGift?.name ?? ''
        },
      },
      {
        headerName: 'Date added',
        filter: 'agDateColumnFilter',
        field: 'createdAt',
        valueGetter: (params) => {
          // default filter uses midnight as comparator
          const getMidnight = (d: Date) => new Date(d.setHours(0, 0, 0, 0))
          return getMidnight(new Date(params.data!.createdAt))
        },
        cellRenderer: (params: CellRendererParams) => {
          return new Date(params.data!.createdAt).toLocaleDateString()
        },
      },
    ],
    [],
  )

  return (
    <Grid<InviteHistoryListItem>
      showQuickSearch={false}
      rowData={[...invites]}
      columnDefs={columnDefs}
      enableCellTextSelection={true}
    />
  )
}

export const InviteHistoryPage = () => {
  const { data, loading } = useQueryBackend(InviteHistoryDocument)

  return (
    <Page
      name="Invite History"
      fixedHeight="all"
      fluid
      addBottomSpacing={false}
      paddingBottom="3rem"
      width="100%"
    >
      <PageHeader
        title="Invite History"
        description="Invite history contains the status and history of all invites sent to
            creators."
        breadcrumbs={[
          {
            name: 'Creators',
            to: '/creators',
          },
          {
            name: 'Invite',
            to: '/creators/invite',
          },
          {
            name: 'Current',
            to: '',
            isCurrentPage: true,
          },
        ]}
      />
      {!loading && data ? (
        <InviteHistoryTable invites={data.inviteHistory} />
      ) : (
        <Box>
          <Skeleton width="100%" height="250" />
        </Box>
      )}
    </Page>
  )
}
