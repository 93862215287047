import {
  Link,
  Text,
  useFastForm,
  Alert,
  AlertTitle,
  AlertDescription,
  Box,
} from '@bounty/brands-design-system'
import * as z from 'zod'
import { GiftListQuery } from '../../../generated/backendGraphql'
import { GiftDisplay } from '../Invites/components/GiftDisplay/GiftDisplay'

const offerGiftFormSchema = z.object({
  giftId: z.string(),
  message: z.string().max(1000).optional(),
})

type GiftType = GiftListQuery['enabledStoreGifts']

export const OfferCreatorGiftForm = ({
  userId,
  onSubmitSuccess,
  enabledStoreGifts = [],
  isNetwork = false,
  isLoading = false,
}: {
  userId: string
  onSubmitSuccess: (giftId: string, message?: string) => void
  enabledStoreGifts: GiftType
  isNetwork: boolean
  isLoading: boolean
}) => {
  const {
    Form,
    Select,
    SubmitButton,
    Textarea,
    methods: { watch },
  } = useFastForm({
    schema: offerGiftFormSchema,
    defaultValues: {
      giftId: enabledStoreGifts?.[0]?.id ?? '',
      message: '',
    },
  })

  const [watchGiftId] = watch(['giftId'])
  const selectedGift = enabledStoreGifts.find((el) => el.id === watchGiftId)

  return (
    <Form
      onSubmit={async ({ giftId, message }) => {
        onSubmitSuccess(giftId, message)
      }}
    >
      {enabledStoreGifts.length === 0 ? (
        <Alert status="warning">
          <AlertTitle>You don't have any gifts configured yet!</AlertTitle>

          <AlertDescription>
            <Link
              isExternal={false}
              to={'/gifts/new'}
              textDecoration={'underline'}
            >
              create a gift
            </Link>
          </AlertDescription>
        </Alert>
      ) : (
        <Select
          label="Gift"
          name="giftId"
          placeholder="Bounty"
          options={enabledStoreGifts.map((x) => ({
            label: x.name,
            value: x.id,
          }))}
          helpText={
            <Text color="gray.600" size="sm" mb="2">
              Select a gift for the creator or{' '}
              <Link
                isExternal={false}
                to="/gifts/new"
                textDecoration="underline"
                color="inherit"
              >
                create one
              </Link>
              .
            </Text>
          }
        />
      )}
      <Box mb="4">{selectedGift && <GiftDisplay gift={selectedGift} />}</Box>
      <Textarea
        name="message"
        label="Message To Creator"
        isRequired={false}
        maxCharacterLimit={1000}
      />
      <SubmitButton
        mt="2rem"
        isDisabled={isLoading}
        event="Offer Creator a Gift Submission"
      >
        Send Gift
      </SubmitButton>
    </Form>
  )
}
