import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerProps,
  DrawerHeader,
  DrawerBody,
  DrawerCloseButton,
  Tabs,
  TabPanels,
  TabPanel,
  DrawerHeading,
  useFastForm,
  Box,
  Alert,
  AlertTitle,
  AlertDescription,
} from '@bounty/brands-design-system'
import { useNavigate } from 'react-router-dom'
import { GiftForm } from '../../../components/GiftForm'
import { GiftBountyForm } from '../../../components/Gift/GiftBountyForm'
import { UnreachableCaseError } from '@bounty/utils'
import { GiftDetails } from './GiftDetails'
import { EditGift } from './EditGift'
import { LearnMoreLink } from '../../../components/LearnMoreLink/LearnMoreLink'
import * as z from 'zod'
import { useAnalytics } from '@bounty/web-analytics'

type GiftDrawerMode = 'details' | 'edit' | 'create'
export type GiftsDrawerProps = Omit<
  DrawerProps,
  'children' | 'isOpen' | 'onClose'
> & {
  mode: GiftDrawerMode
  rootPath: string
}

const getTitle = (mode: GiftDrawerMode) => {
  switch (mode) {
    case 'details':
      return 'Gift Details'
    case 'edit':
      return 'Edit Gift'
    case 'create':
      return 'Create A New Gift'
    default:
      throw new UnreachableCaseError(mode)
  }
}

const getBody = (mode: GiftDrawerMode) => {
  switch (mode) {
    case 'details':
      return <GiftDetails />
    case 'edit':
      return <EditGift />
    case 'create':
      return <Create />
    default:
      throw new UnreachableCaseError(mode)
  }
}

const giftTypeSchema = z.object({
  type: z.enum(['Product', 'Bounty']),
})

const giftTypes = ['Product', 'Bounty']
type typeOfGiftTypes = {
  type: 'Product' | 'Bounty'
}

const GiftTypeAlert = ({ type }: typeOfGiftTypes) => {
  return (
    <Alert status="info" mb="8">
      <AlertTitle>About {type} gifts</AlertTitle>
      {type === 'Bounty' ? (
        <AlertDescription>
          A bounty gift is customizable offer to post a video about your brand.
          You can set the minimum payout for a Bounty gift to be any amount,
          different from the global minimum payout for your store.{' '}
        </AlertDescription>
      ) : (
        <AlertDescription>
          A product gift is the gift of free product(s) from your store. When a
          creator redeems a product gift, Bounty automatically generates the
          order in Shopify, ready for you to fulfill.{' '}
        </AlertDescription>
      )}
      <LearnMoreLink href="https://brands.support.bounty.co/hc/en-us/articles/11801352778011-What-s-the-difference-between-the-Product-and-Bounty-gift-types-" />
    </Alert>
  )
}

export const Create = () => {
  const navigate = useNavigate()
  const { track } = useAnalytics()
  const {
    Form,
    Select,
    methods: { watch },
  } = useFastForm({
    defaultValues: {
      type: 'Product',
    },
    schema: giftTypeSchema,
  })

  const selectedGiftType = watch('type')

  return (
    <Tabs>
      <TabPanels>
        <TabPanel>
          <Form
            onSubmit={() => {
              track('Gift Create Gift Type Selected', { selectedGiftType })
            }}
          >
            <Box>
              <Select
                name="type"
                label="Gift Type"
                placeholder="Select Gift Type"
                options={giftTypes.map((type) => ({
                  value: type,
                  label: type,
                }))}
              />
            </Box>
          </Form>

          {selectedGiftType === 'Bounty' && (
            <Box>
              <GiftTypeAlert type={selectedGiftType} />
              <GiftBountyForm
                onSubmitSuccess={() => {
                  navigate('../')
                }}
              />
            </Box>
          )}
          {selectedGiftType === 'Product' && (
            <Box>
              <GiftTypeAlert type={selectedGiftType} />
              <GiftForm
                onSubmitSuccess={() => {
                  navigate('../')
                }}
              />
            </Box>
          )}
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export const GiftDrawer = ({ rootPath, mode, ...rest }: GiftsDrawerProps) => {
  const navigate = useNavigate()
  return (
    <Drawer size="lg" isOpen onClose={() => navigate(rootPath)} {...rest}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>
          <DrawerHeading>{getTitle(mode)}</DrawerHeading>
        </DrawerHeader>
        <DrawerBody>{getBody(mode)}</DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
