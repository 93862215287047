import { forwardRef, useMemo, useRef, useState } from 'react'
import {
  CellRendererSelectorFunc,
  ColDef,
  RowNode,
  RowSelectedEvent,
  SelectionChangedEvent,
} from 'ag-grid-community'
import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import {
  ProductsGridDocument,
  UpdateShopifyStoreProductsDocument,
  ProductsGridQuery,
} from '../../generated/backendGraphql'
import {
  Button,
  Image,
  ButtonGroup,
  Switch,
  Link,
  Portal,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  ButtonProps,
  Spinner,
  Grid,
  Box,
  HStack,
  useFastForm,
} from '@bounty/brands-design-system'
import { useAuthState } from '../../hooks/useAuth'
import { CaretDownIcon } from '@bounty/brands-design-system'
import { debounce, prettyCurrency } from '@bounty/utils'
import { z } from 'zod'
import { filterFeaturedProductIds } from './filterDisabledProducts'

export type ProductsGridProps = {
  searchTerm: string
  variant?: 'storefront'
  productsOrder?: string[]
  setProductsOrder?: React.Dispatch<React.SetStateAction<string[]>>
  generateAndSetRowData?: (productIds: string[]) => void
}

export type ProductItem = ProductsGridQuery['shopifyStoreProducts'][0]
export type CellRendererParams = Parameters<
  CellRendererSelectorFunc<ProductItem>
>[0]

const getShouldBulkEnable = (items: RowNode<ProductItem>[]) => {
  return items.some((item) => item.data!.enabled === false)
}

const makeItemsToEnableBody = (items: RowNode<ProductItem>[]) => {
  return items
    .filter((item) => item.data!.enabled === false)
    .map((i) => i.data!.id)
}

const makeItemsToDisableBody = (items: RowNode<ProductItem>[]) => {
  return items
    .filter((item) => item.data!.enabled === true)
    .map((i) => i.data!.id)
}

const makeItemsToBulkAssignBrief = (items: RowNode<ProductItem>[]) => {
  return items.map((i) => i.data!.id)
}

// Need this to get a full width menu button
const BriefMenuActionButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => {
    return (
      <Button
        ref={ref}
        textAlign="left"
        width="100%"
        overflow="hidden"
        rightIcon={
          <CaretDownIcon width="auto" backgroundColor="white" pl="3px" pr="1" />
        }
        size="sm"
        variant="outline"
        pr="0"
        backgroundColor="white"
        _hover={{
          backgroundColor: 'white',
        }}
        _focus={{
          backgroundColor: 'white',
        }}
        _active={{
          backgroundColor: 'white',
        }}
        {...props}
      />
    )
  },
)

export const ProductsGrid = ({
  searchTerm,
  variant,
  setProductsOrder,
  productsOrder,
  generateAndSetRowData,
}: ProductsGridProps) => {
  const { shopifyStoreUrl } = useAuthState()
  const [updateShopifyStoreProducts, { loading }] = useMutationBackend(
    UpdateShopifyStoreProductsDocument,
    {
      optimisticResponse: (proxy) => {
        return {
          updateShopifyStoreProducts: {
            __typename: 'BatchUpdateResponse',
            count: proxy.ids.length,
          },
          updateStorefront: {
            __typename: 'Storefront',
            id: storefront?.id,
            featuredProductIds:
              proxy.input.featuredProductIds ?? storefront?.featuredProductIds,
          },
          __typename: 'Mutation',
        }
      },
      update(cache, _updates, { variables }) {
        if (!variables) return
        ;(variables.ids as string[]).forEach((id) => {
          cache.modify({
            id: `ShopifyStoreProduct:${id}`,
            fields: {
              enabled(val) {
                return variables.updates.enabled ?? val
              },
              productInstructionId(val) {
                return variables.updates.productInstructionId ?? val
              },
              productInstructionName(val) {
                return variables.updates.productInstructionName ?? val
              },
            },
          })
        })
      },
    },
  )

  /**
   * Be careful! Ag-grid mutates the objects stored here as you do stuff to the table and it does not cause rerenders!
   *
   * However, all mutations will cause a rerender on success so that's how the bulk toggle works so well.
   */
  const [selectedRows, setSelectedRows] = useState<RowNode<ProductItem>[]>([])
  const [currentRowNode, setCurrentRowNode] = useState<RowNode<ProductItem>>()
  const shouldBulkEnable = getShouldBulkEnable(selectedRows)

  const debouncedRowSelect = useRef(
    debounce<RowSelectedEvent<ProductItem>[], void>(async (e) => {
      setCurrentRowNode(e.node)
      setSelectedRows(e.api.getSelectedNodes())
    }, 300),
  )

  const {
    data: {
      shopifyStoreProducts = [],
      storeProductInstructions,
      storefront,
    } = {},
    loading: productsGridLoading,
  } = useQueryBackend(ProductsGridDocument, {
    variables: {
      searchTerm: searchTerm,
      enabled: variant === 'storefront' ? true : null,
    },
    fetchPolicy: 'cache-and-network',
  })

  const setColumnVisible = () => {
    return variant === 'storefront' ? true : false
  }

  const columnDefs = useMemo(
    (): ColDef<ProductItem>[] => [
      {
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        width: 30,
        flex: 0,
        resizable: false,
        suppressMovable: true,
        cellRenderer: (params: CellRendererParams) => {
          if (params.data && productsOrder?.includes(params.data.id)) {
            params.node.setSelected(true)
          }
        },
      },
      {
        headerName: '',
        width: 40,
        flex: 0,
        field: 'enabled',
        suppressMovable: true,
        hide: setColumnVisible(),
        cellRenderer: (params: CellRendererParams) => {
          return (
            <Switch
              isChecked={params.data!.enabled}
              onChange={() => {
                const filteredFeaturedProductIds = filterFeaturedProductIds(
                  params,
                  storefront?.featuredProductIds,
                )

                updateShopifyStoreProducts({
                  variables: {
                    ids: [params.data!.id],
                    updates: {
                      enabled: !params.data!.enabled,
                    },
                    input: {
                      featuredProductIds: filteredFeaturedProductIds,
                    },
                  },
                  update(cache, _updates, { variables }) {
                    if (!variables) return
                    ;(variables.ids as string[]).forEach((id) => {
                      cache.modify({
                        id: `ShopifyStoreProduct:${id}`,
                        fields: {
                          enabled(val) {
                            return variables.updates.enabled ?? val
                          },
                        },
                      })
                    })
                  },
                })
              }}
            />
          )
        },
        resizable: false,
      },
      {
        headerName: '',
        field: 'imgLink',
        width: 80,
        flex: 0,
        resizable: false,
        suppressMovable: true,
        cellRenderer: (params: CellRendererParams) => {
          return (
            <Image
              width="40px"
              height="40px"
              objectFit="cover"
              src={params.value ?? 'https://via.placeholder.com/150'}
              alt={params.data!.productName ?? 'Not provided.'}
            />
          )
        },
      },
      {
        headerName: 'Product Name',
        field: 'productName',
        flex: 2,
        cellRenderer: (params: CellRendererParams) => {
          return (
            <Link
              isExternal
              href={`https://${shopifyStoreUrl}/admin/products/${
                params.data!.productId
              }`}
            >
              {params.data!.productName}
            </Link>
          )
        },
      },
      { headerName: 'Shopify ID', field: 'productId' },
      { headerName: 'SKU', field: 'productSku' },

      // Hiding brief
      // It uses the old product instruction system which no longer works
      //
      // { headerName: 'Handle', field: 'handle' },
      // {
      //   headerName: 'Brief',
      //   field: 'productInstructionName',
      //   flex: 1,
      //   // Needed for search filters
      //   valueGetter: (params) => {
      //     return params.data?.productInstructionName || 'default'
      //   },
      // cellRenderer: (params: CellRendererParams) => {
      //   return (
      //     <Menu>
      //       <MenuButton as={BriefMenuActionButton}>
      //         {params.data?.productInstructionName || 'Default'}
      //       </MenuButton>
      //       <Portal>
      //         <MenuList>
      //           <MenuItem
      //             onClick={() => {
      //               updateShopifyStoreProducts({
      //                 variables: {
      //                   ids: [params.data!.id],
      //                   updates: {
      //                     productInstructionId: null,
      //                     productInstructionName: 'Default',
      //                   },
      //                   input: {},
      //                 },
      //               })
      //             }}
      //           >
      //             Default
      //           </MenuItem>
      //           <MenuDivider />
      //           {storeProductInstructions
      //             ?.filter((x) => x.isDefault === false)
      //             .map((x) => {
      //               return (
      //                 <MenuItem
      //                   onClick={() => {
      //                     updateShopifyStoreProducts({
      //                       variables: {
      //                         ids: [params.data!.id],
      //                         updates: {
      //                           productInstructionId: x.id,
      //                           productInstructionName: x.name,
      //                         },
      //                         input: {},
      //                       },
      //                     })
      //                   }}
      //                   key={x.id}
      //                 >
      //                   {x.name}
      //                 </MenuItem>
      //               )
      //             })}
      //         </MenuList>
      //       </Portal>
      //     </Menu>
      //   )
      // },
      //},
      {
        headerName: 'Price',
        field: 'price',
        filter: 'agNumberColumnFilter',
        valueFormatter: (x) =>
          x.data!.price != null
            ? prettyCurrency(x.data!.price, { precision: 2 })
            : 'n/a',
      },
    ],
    // eslint-disable-next-line
    [
      productsOrder,
      updateShopifyStoreProducts,
      shopifyStoreUrl,
      storeProductInstructions,
    ],
  )

  const storefrontConditions =
    variant === 'storefront' &&
    productsOrder &&
    setProductsOrder &&
    generateAndSetRowData

  const onRowSelectionChanged = (e: SelectionChangedEvent<ProductItem>) => {
    const selectedRows = e.api.getSelectedRows()
    if (storefrontConditions) {
      const selectedProductIds = selectedRows.map((row) => row.id)

      if (searchTerm) {
        if (!currentRowNode?.isSelected()) {
          const filteredProductIds = productsOrder.filter(
            (ids) => ids !== currentRowNode?.data?.id,
          )
          setProductsOrder([...filteredProductIds, ...selectedProductIds])
          generateAndSetRowData([...filteredProductIds, ...selectedProductIds])
          return
        }
        setProductsOrder([...productsOrder, ...selectedProductIds])
        generateAndSetRowData([...productsOrder, ...selectedProductIds])
      } else {
        setProductsOrder(selectedProductIds)
        generateAndSetRowData(selectedProductIds)
      }
    }
  }

  const isRowSelectable = (row: RowNode<ProductItem>) => {
    if (variant === 'storefront' && row.data) {
      return row.data.enabled ? true : false
    }
    return true
  }

  if (productsGridLoading) return <Spinner size="xl" />

  return (
    <Grid<ProductItem>
      containerProps={{ flex: 1, height: '100%' }}
      rowData={[...shopifyStoreProducts]}
      columnDefs={columnDefs}
      rowSelection="multiple"
      suppressRowClickSelection={true}
      showQuickSearch={false}
      rightComponent={
        selectedRows.length > 0 ? (
          <ButtonGroup variant="solid" size="sm">
            <Button
              event="Products Grid Bulk Enable/Disable Clicked"
              isDisabled={loading}
              onClick={() => {
                updateShopifyStoreProducts({
                  variables: {
                    ids: shouldBulkEnable
                      ? makeItemsToEnableBody(selectedRows)
                      : makeItemsToDisableBody(selectedRows),
                    updates: {
                      enabled: shouldBulkEnable,
                    },
                    input: {},
                  },
                })
              }}
            >
              {shouldBulkEnable ? 'Enable' : 'Disable'}
            </Button>
            {/* <Menu>
              <MenuButton
                size="sm"
                as={Button}
                isDisabled={loading}
                rightIcon={<CaretDownIcon />}
              >
                Assign Brief
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    updateShopifyStoreProducts({
                      variables: {
                        ids: makeItemsToBulkAssignBrief(selectedRows),
                        updates: {
                          productInstructionId: null,
                          productInstructionName: 'Default',
                        },
                        input: {},
                      },
                    })
                  }}
                >
                  Default
                </MenuItem>
                <MenuDivider />
                {storeProductInstructions
                  ?.filter((x) => x.isDefault === false)
                  .map((x) => {
                    return (
                      <MenuItem
                        onClick={() => {
                          updateShopifyStoreProducts({
                            variables: {
                              ids: makeItemsToBulkAssignBrief(selectedRows),
                              updates: {
                                productInstructionId: x.id,
                                productInstructionName: x.name,
                              },
                              input: {},
                            },
                          })
                        }}
                        key={x.id}
                      >
                        {x.name}
                      </MenuItem>
                    )
                  })}
              </MenuList>
            </Menu> */}
          </ButtonGroup>
        ) : null
      }
      onRowSelected={debouncedRowSelect.current}
      onSelectionChanged={(e) => {
        onRowSelectionChanged(e)
      }}
      isRowSelectable={isRowSelectable}
    />
  )
}

export const ProductSearchFormSchema = z
  .object({
    searchText: z.string(),
  })
  .strict()

type ProductsGridWrapperType = {
  variant?: 'storefront'
  productsOrder?: string[]
  setProductsOrder?: React.Dispatch<React.SetStateAction<string[]>>
  generateAndSetRowData?: (productIds: string[]) => void
}

export const ProductsGridSearchWrapper = ({
  variant,
  productsOrder,
  setProductsOrder,
  generateAndSetRowData,
}: ProductsGridWrapperType) => {
  const [searchText, setSearchText] = useState<string>('')
  const { Form, Input, SubmitButton } = useFastForm({
    schema: ProductSearchFormSchema,
    defaultValues: {
      searchText: '',
    },
  })

  return (
    <Box height="100%">
      <Form
        id="product-search"
        onSubmit={(values) => setSearchText(values.searchText)}
      >
        <HStack alignItems={'baseline'}>
          <Input name="searchText" placeholder="search product name or SKU" />
          <SubmitButton event="Product Search Clicked" alignSelf="center">
            Search
          </SubmitButton>
        </HStack>
      </Form>
      <ProductsGrid
        searchTerm={searchText}
        variant={variant}
        productsOrder={productsOrder}
        setProductsOrder={setProductsOrder}
        generateAndSetRowData={generateAndSetRowData}
      />
    </Box>
  )
}
