import { ChakraProvider } from '@chakra-ui/react'
import { PropsOf } from '@emotion/react'
import { PortableTextComponentsProvider } from '@portabletext/react'
import { createContext, useContext } from 'react'
import { buildPortableTextComponents } from './buildPortableTextComponents'
import { mergedTheme } from '../Chakra'
import { ErrorBoundary } from './ErrorBoundary'

type BountyContextState = {
  appName: string
  supportEmail: string
  showTopNav: boolean
  topNavHeight: string
  mobileNavHeight: string
}
const BountyContext = createContext<BountyContextState | undefined>(undefined)

export type BountyProviderProps = PropsOf<typeof ChakraProvider> & {
  appName: string
  supportEmail: string
  showTopNav?: boolean
  topNavHeight?: string
  mobileNavHeight?: string
}

const portableTextComponents = buildPortableTextComponents()

export const BountyProvider = ({
  children,
  appName,
  supportEmail,
  topNavHeight = '60px',
  mobileNavHeight = '50px',
  showTopNav = false,
  ...rest
}: BountyProviderProps) => {
  return (
    <BountyContext.Provider
      value={{
        appName,
        supportEmail,
        topNavHeight: showTopNav ? topNavHeight : '0px',
        mobileNavHeight,
        showTopNav,
      }}
    >
      <ChakraProvider theme={mergedTheme} {...rest}>
        <ErrorBoundary supportEmail={supportEmail} appName={appName}>
          <PortableTextComponentsProvider components={portableTextComponents}>
            {children}
          </PortableTextComponentsProvider>
        </ErrorBoundary>
      </ChakraProvider>
    </BountyContext.Provider>
  )
}

export const useBounty = () => {
  const context = useContext(BountyContext)

  if (!context) {
    throw new Error('Cannot useBounty without using within a BountyProvider.')
  }

  return context
}
