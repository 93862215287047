import { useQueryBackend } from '../../apollo/backend/hooks'
import {
  CombinedChargesLedgerDocument,
  CombinedChargesLedgerQuery,
} from '../../generated/backendGraphql'
import {
  Text,
  Tag,
  Box,
  Flex,
  Heading,
  Skeleton,
  Link,
  Image,
  Grid,
  VStack,
  HStack,
} from '@bounty/brands-design-system'
import { format } from 'date-fns'
import { prettyCurrency, isNil, prettyViews, isNotNil } from '@bounty/utils'
import { EyeIcon } from '@bounty/brands-design-system'
import { CellRendererSelectorFunc, ColDef } from 'ag-grid-community'
import { useMemo } from 'react'
import { getPreviewImageLinkForContent } from '../../utils/externalContentLinksGetters'

const extractUsernameLink = (link: string) => {
  const re = /\/(@[\w|.]+)\//i
  const m = link.match(re)
  if (m && m.length !== 0 && m.length >= 2) {
    return m[1]
  }
  return null
}

export type ContentChargeItem =
  CombinedChargesLedgerQuery['combinedChargesLedger'][0]
type CellRendererParams = Parameters<
  CellRendererSelectorFunc<ContentChargeItem>
>[0]

export const ContentCharges = () => {
  const { data, loading } = useQueryBackend(CombinedChargesLedgerDocument)

  // assume tiktok video exists
  const TikTokVideoDescription = ({
    charge,
  }: {
    charge: ContentChargeItem
  }) => {
    const previewImage = getPreviewImageLinkForContent(
      'TIKTOK_VIDEO',
      charge.userBounty!.tikTokVideo!.videoId!,
    )!
    return (
      <Box lineHeight={'short'} py={4}>
        <Link
          showIcon={false}
          isExternal
          href={charge.userBounty?.tikTokVideo?.videoUrl}
          fontSize="sm"
        >
          <HStack spacing={0} alignItems={'center'}>
            <Image
              src={previewImage}
              width={'40px'}
              p={2}
              borderRadius={3}
            ></Image>
            <VStack
              spacing={0}
              alignItems={'flex-start'}
              wordBreak={'break-word'}
            >
              <Text fontWeight={'bold'}>
                <EyeIcon />{' '}
                {prettyViews(charge.userBounty!.tikTokVideo!.viewCount)}
              </Text>
              {extractUsernameLink(
                charge.userBounty!.tikTokVideo!.videoUrl!,
              ) && (
                <Text color={'gray.600'}>
                  {extractUsernameLink(
                    charge.userBounty!.tikTokVideo!.videoUrl,
                  )}
                </Text>
              )}

              <Text color={'gray.600'}>{charge.description}</Text>
            </VStack>
          </HStack>
        </Link>
      </Box>
    )
  }

  // assume instagram content exists
  const InstagramVideoDescription = ({
    charge,
  }: {
    charge: ContentChargeItem
  }) => {
    const previewImage = getPreviewImageLinkForContent(
      'INSTAGRAM_REEL',
      charge.userBounty!.instagramContent!.contentId,
    )!

    return (
      <Box lineHeight={'short'} py={4}>
        <Link
          showIcon={false}
          isExternal
          href={charge.userBounty?.instagramContent?.url}
          fontSize="sm"
        >
          <HStack spacing={0} alignItems={'center'}>
            <Image
              src={previewImage}
              width={'40px'}
              p={2}
              borderRadius={3}
            ></Image>
            <VStack
              spacing={0}
              alignItems={'flex-start'}
              wordBreak={'break-word'}
            >
              <Text fontWeight={'bold'}>
                <EyeIcon />{' '}
                {prettyViews(
                  charge.userBounty?.instagramContent?.viewCount ?? 0,
                )}
              </Text>
              {extractUsernameLink(
                charge.userBounty!.instagramContent!.url!,
              ) && (
                <Text color={'gray.600'}>
                  {extractUsernameLink(
                    charge.userBounty!.instagramContent!.url,
                  )}
                </Text>
              )}

              <Text color={'gray.600'}>{charge.description}</Text>
            </VStack>
          </HStack>
        </Link>
      </Box>
    )
  }

  const columnDefs = useMemo(
    (): ColDef<ContentChargeItem>[] => [
      {
        headerName: 'Date',
        filter: 'agDateColumnFilter',
        field: 'createdAt',
        width: 70,
        flex: 0,
        valueGetter: (params) => {
          // default filter uses midnight as comparator
          const getMidnight = (d: Date) => new Date(d.setHours(0, 0, 0, 0))
          return getMidnight(new Date(params.data?.createdAt))
        },
        cellRenderer: (params: CellRendererParams) =>
          format(new Date(params.value), 'MM/dd/yy'),
      },
      {
        headerName: 'Status',
        width: 90,
        flex: 0,
        field: 'paymentStatus',
        cellRenderer: (params: CellRendererParams) => {
          return <Tag>{params.value}</Tag>
        },
      },
      {
        headerName: 'Category',
        width: 70,
        flex: 0,
        cellRenderer: (params: CellRendererParams) => {
          if (
            params?.data?.userBounty?.tikTokVideo?.id ||
            params?.data?.userBounty?.instagramContent?.id
          ) {
            return <Text>Views</Text>
          }
          return <Text>Misc</Text>
        },
      },
      {
        headerName: 'Description',
        field: 'description',
        flex: 1,
        wrapText: true,
        autoHeight: true,
        valueGetter: (params) => params.data?.description,
        cellRenderer: (params: CellRendererParams) => {
          const charge = params.data
          if (!charge) return ''

          if (isNotNil(charge.userBounty?.tikTokVideo?.videoUrl)) {
            return <TikTokVideoDescription charge={charge} />
          }
          if (isNotNil(charge.userBounty?.instagramContent?.url)) {
            return <InstagramVideoDescription charge={charge} />
          }

          return (
            <Text lineHeight={'short'} fontSize="sm">
              {charge.description}
            </Text>
          )
        },
      },
      {
        headerName: 'Service fee',
        field: 'bountyServiceFee',
        width: 70,
        flex: 0,
        filter: 'agNumberColumnFilter',
        valueFormatter: (x) =>
          prettyCurrency(x.value ?? 0, {
            precision: 2,
          }),
      },
      {
        headerName: 'Total',
        flex: 0,
        width: 70,
        field: 'price',
        filter: 'agNumberColumnFilter',
        valueFormatter: (x) => {
          const amountDefault = prettyCurrency(x.value ?? 0, { precision: 2 })

          if (data?.currentStore.bypassShopifyBilling === true) {
            const details = x.data?.userBounty?.userPayment?.paymentDetails
            if (!details) return amountDefault

            const storeUsageCharge = details.storeUsageCharge as number
            if (!storeUsageCharge) return amountDefault

            return `${prettyCurrency(storeUsageCharge ?? 0, {
              precision: 2,
            })} **`
          }

          return amountDefault
        },
      },
    ],
    [data?.currentStore.bypassShopifyBilling],
  )

  return (
    <Flex flexDir="column">
      <Heading mb="4" size="lg" marginTop="8">
        Content Charges
      </Heading>

      {loading || isNil(data) ? (
        <Box flex="1" px="4">
          <Skeleton height="16px" mb="4" />
          <Skeleton height="16px" />
        </Box>
      ) : (
        <Flex height={600}>
          {data.combinedChargesLedger.length === 0 ? (
            <Box py="4">There have been no payments made to creators yet.</Box>
          ) : (
            <Grid<ContentChargeItem>
              columnDefs={columnDefs}
              containerProps={{ height: '100%' }}
              rowData={[...data.combinedChargesLedger]}
            />
          )}
        </Flex>
      )}
    </Flex>
  )
}
