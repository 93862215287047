import { useEffect, useState } from 'react'

export const useCheckSeenUpdates = () => {
  const [hasSeenLatestUpdates, setHasSeenLatestUpdates] = useState(true)

  // This can be changed to lastUpdated property of announcemnents
  const latestSeenUpdateDate = localStorage.getItem('latestSeenUpdateDate')

  // Placeholder Last Seen Date
  const latestUpdateDate = new Date('2023-11-27T00:00:00Z').toISOString()

  useEffect(() => {
    if (!latestSeenUpdateDate || latestSeenUpdateDate !== latestUpdateDate) {
      setHasSeenLatestUpdates(false)
    }
  }, [latestSeenUpdateDate, latestUpdateDate])

  return { hasSeenLatestUpdates }
}
