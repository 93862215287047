import {
  Page,
  PageHeader,
  PageHeaderContents,
  PageHeaderDescription,
  PageHeaderTitle,
  PageHeaderInformation,
  PageHeaderActions,
  useFastForm,
  Flex,
  Box,
  useToast,
  PageSkeleton,
} from '@bounty/brands-design-system'
import {
  UpdateBrandingSchema,
  UpdateStorefrontBrandingInputType,
} from '@bounty/schemas'
import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import {
  UpdateStorefrontBrandingDocument,
  StorefrontQuery,
  StorefrontDocument,
} from '../../generated/backendGraphql'
import { StoreAssetUploader } from '../../utils/StoreAssetUploader'
import { z } from 'zod'
import { slugify } from '@bounty/utils'
import { useState } from 'react'
import { useFeatureForStore } from '../../hooks/useFeatureForStore'
import { UploadAndDeleteAsset } from '../../components/UploadAndDeleteAsset'

const MAX_FILE_SIZE = 5000000
const maxFileSizeErrorMessage =
  'Max file size is 5MB. Please choose a different image.'

const UpdateBrandingSchemaWithFiles = UpdateBrandingSchema.merge(
  z.object({
    logoImgLink: z.union([
      z.string().optional().nullable(),
      z
        .instanceof(File)
        .refine(
          (file) => file.size <= MAX_FILE_SIZE,
          `${maxFileSizeErrorMessage}`,
        ),
    ]),
    headerImgLinkPrimary: z.union([
      z.string().optional().nullable(),
      z
        .instanceof(File)
        .refine(
          (file) => file.size <= MAX_FILE_SIZE,
          `${maxFileSizeErrorMessage}`,
        ),
    ]),
    headerImgLinkSecondary: z.union([
      z.string().optional().nullable(),
      z
        .instanceof(File)
        .refine(
          (file) => file.size <= MAX_FILE_SIZE,
          `${maxFileSizeErrorMessage}`,
        ),
    ]),
  }),
)

type ImageUploadStates = 'uploading' | 'success' | 'error'

export const Branding = () => {
  const { data, loading } = useQueryBackend(StorefrontDocument)
  if (loading || !data) return <PageSkeleton />
  return <BrandingForm data={data} />
}

export const BrandingForm = ({ data }: { data: StorefrontQuery }) => {
  const toast = useToast()
  const [imageUploadStatus, setImageUploadStatus] =
    useState<ImageUploadStates>()
  const { isAvailable } = useFeatureForStore('OFFERS_IN_BRAND_DIRECTORY')

  const [
    updateStorefrontBranding,
    { loading: updateStorefrontBrandingLoading },
  ] = useMutationBackend(UpdateStorefrontBrandingDocument)

  const currentDateTime = new Date().getTime()
  const {
    Form,
    Input,
    Switch,
    Upload,
    Textarea,
    SubmitButton,
    ColorInput,
    methods: {
      formState: { isDirty, dirtyFields },
      setValue,
    },
  } = useFastForm({
    schema: UpdateBrandingSchemaWithFiles,
    defaultValues: {
      name: data?.currentStore?.name ?? '',
      logoImgLink:
        `${data?.currentStore?.logoImgLink}?t=${currentDateTime}` ?? '',
      description: data?.storefront?.description ?? '',
      headerImgLinkPrimary:
        `${data?.storefront?.headerImgLinkPrimary}?t=${currentDateTime}` ?? '',
      headerImgLinkSecondary:
        `${data?.storefront?.headerImgLinkSecondary}?t=${currentDateTime}` ??
        '',
      showBounties: data?.storefront?.showBounties ?? true,
      showDeals: data?.storefront?.showDeals ?? true,
      showFeaturedProducts: data?.storefront?.showFeaturedProducts ?? true,
      primaryColor: data?.storefront?.primaryColor,
      secondaryColor: data?.storefront?.secondaryColor,
    },
  })

  const buildAssetUrl = (storeId: string, uploadKey: string) => {
    return `https://shop-assets.bounty.co/${storeId}/${uploadKey}`
  }

  const getUploadKey = (type: string) => {
    if (type === 'logoImgLink') {
      return 'logo'
    }
    if (type === 'headerImgLinkPrimary') {
      return 'storefront-header-image-primary'
    }
    if (type === 'headerImgLinkSecondary') {
      return 'storefront-header-image-secondary'
    }
  }

  const uploadImagesAndReturnUrls = async (
    imageData: Record<string, any>,
    storeId: string,
  ) => {
    setImageUploadStatus('uploading')
    const filesToUpload: [file: File, type: string][] = []

    for (const key in imageData) {
      const val = imageData[key]
      if (val !== null) {
        filesToUpload.push([val, key])
      }
    }

    const uploadedImages: Record<string, string> = {}
    await Promise.all(
      filesToUpload.map(async ([file, type]) => {
        try {
          const key = getUploadKey(type)
          if (key) {
            const resp = await new StoreAssetUploader().uploadSnippetImage(
              key,
              file,
            )
            if (resp.code === 'FILE_UPLOADED') {
              uploadedImages[type] = buildAssetUrl(storeId, key)
            }
          }
        } catch (error) {
          setImageUploadStatus('error')
          console.error(error)
        }
      }),
    )

    return uploadedImages
  }

  const { currentStore } = data

  return (
    <Form
      onSubmit={async (params) => {
        const imgKeys = [
          'logoImgLink',
          'headerImgLinkPrimary',
          'headerImgLinkSecondary',
        ]

        const imageData: Record<string, any> = {}

        Object.keys(dirtyFields).forEach((dirtyField) => {
          if (imgKeys.includes(dirtyField)) {
            imageData[dirtyField] =
              params[dirtyField as keyof UpdateStorefrontBrandingInputType]
          }
        })

        const imageValues = await uploadImagesAndReturnUrls(
          imageData,
          currentStore.id,
        )
        const input = {
          slug: slugify(params.name),
          headerImgLinkPrimary:
            imageValues.headerImgLinkPrimary ?? params.headerImgLinkPrimary,
          headerImgLinkSecondary:
            imageValues.headerImgLinkSecondary ?? params.headerImgLinkSecondary,
          description: params.description,
          showDeals: params.showDeals,
          showBounties: params.showBounties,
          showFeaturedProducts: params.showFeaturedProducts,
          primaryColor: params.primaryColor,
          secondaryColor: params.secondaryColor,
        }

        const { data } = await updateStorefrontBranding({
          variables: {
            input: input,
            name: params.name,
            logoImgLink: imageValues.logoImgLink,
          },
        })

        if (data) {
          setImageUploadStatus('success')
          toast({
            title: 'Customize branding success',
            description:
              'You should see updates on your Storefront within 5 minutes.',
            status: 'success',
            id: 'brandingCustomizeSuccess',
          })
        }
      }}
    >
      <Page
        name="Customize Storefront Branding"
        data-testid="customize-storefront-branding-page"
        fixedHeight="all"
        addBottomSpacing={false}
        display="flex"
        flexDir="column"
        fluid
      >
        <Flex justifyContent="space-between" alignItems="flex-start" pr="12">
          <PageHeader
            breadcrumbs={[
              {
                name: 'Settings',
                to: '/settings',
              },
              {
                name: 'Branding',
                to: '',
                isCurrentPage: true,
              },
            ]}
          >
            <PageHeaderContents>
              <PageHeaderInformation>
                <PageHeaderTitle>Branding</PageHeaderTitle>
                {/* TODO: Needs a page description */}
                <PageHeaderDescription></PageHeaderDescription>
              </PageHeaderInformation>
              <PageHeaderActions>
                <SubmitButton
                  data-testid="customize-storefront-branding-save"
                  event="Customize Storefront Branding Save Clicked"
                  isDisabled={
                    updateStorefrontBrandingLoading || isDirty === false
                  }
                  isLoading={
                    updateStorefrontBrandingLoading ||
                    imageUploadStatus === 'uploading'
                  }
                >
                  Save
                </SubmitButton>
              </PageHeaderActions>
            </PageHeaderContents>
          </PageHeader>
        </Flex>
        <Box
          display="flex"
          flex="1"
          borderTop="1px solid"
          borderColor="gray.300"
          justifyContent="center"
        >
          <Box width="xl" overflowY="auto" py="6" px="10">
            <UploadAndDeleteAsset
              label="Brand Logo"
              name="logoImgLink"
              helpText="400 x 400 px minimum"
              assetKey={getUploadKey('logoImgLink') ?? ''}
              setValue={setValue}
            />

            <UploadAndDeleteAsset
              label="Primary Image"
              name="headerImgLinkPrimary"
              helpText="Used as the left header image in the storefront and the hero image on the community landing page. 800 px minimum width, 2:1 aspect ratio"
              setValue={setValue}
              assetKey={getUploadKey('headerImgLinkPrimary') ?? ''}
            />

            <UploadAndDeleteAsset
              label="Secondary Image"
              name="headerImgLinkSecondary"
              helpText="Used as the right header image in the storefront and the secondary image on the community landing page. 800 px minimum width, 2:1 aspect ratio"
              setValue={setValue}
              assetKey={getUploadKey('headerImgLinkSecondary') ?? ''}
            />
            <ColorInput label="Primary color" name="primaryColor" />
            <ColorInput label="Secondary color" name="secondaryColor" />
            <Input label="Brand Name" name="name" />
            <Textarea
              label="Description"
              name="description"
              placeholder="Tell shoppers and creators what makes your brand/product special..."
            />
            {isAvailable && <Switch label="Show Deals" name="showDeals" />}
            <Switch label="Show Bounties" name="showBounties" />
            <Switch
              label="Show Featured Products"
              name="showFeaturedProducts"
            />
          </Box>
        </Box>
      </Page>
    </Form>
  )
}
