import {
  useFastForm,
  Flex,
  Box,
  useToast,
  Skeleton,
} from '@bounty/brands-design-system'
import { useEffect } from 'react'
import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import {
  InstagramFormDocument,
  UpdateStoreInstagramDocument,
} from '../../generated/backendGraphql'
import { instagramSchema } from '@bounty/schemas'

export const InstagramForm = () => {
  const toast = useToast()

  const { data, loading } = useQueryBackend(InstagramFormDocument)
  const [updateInstagram, { loading: updateInstagramLoading }] =
    useMutationBackend(UpdateStoreInstagramDocument)

  const {
    Form,
    Input,
    SubmitButton,
    methods: { setValue, getValues },
  } = useFastForm({
    schema: instagramSchema,
    defaultValues: {
      instagramUsername: data?.currentStore.instagramUsername || '',
    },
  })

  useEffect(() => {
    if (data?.currentStore.instagramUsername) {
      setValue('instagramUsername', data.currentStore.instagramUsername)
    }
  }, [data?.currentStore.instagramUsername, setValue])

  return !data || loading ? (
    <Skeleton height="7" width="310px" />
  ) : (
    <Form
      data-testid="instagram-form"
      onChange={() => {
        const currentValue = getValues('instagramUsername')
        const changeValue = currentValue
          ? currentValue.startsWith('@')
            ? currentValue
            : `@${currentValue}`
          : ''
        setValue('instagramUsername', changeValue)
      }}
      onSubmit={async ({ instagramUsername }) => {
        const { data } = await updateInstagram({
          variables: {
            instagramUsername,
          },
        })

        if (data) {
          toast({
            status: 'success',
            description: 'Instagram username updated.',
          })
        }
      }}
    >
      <Flex
        alignItems="flex-start"
        justifyContent="center"
        maxWidth="310px"
        gap={'2'}
      >
        <Input
          name="instagramUsername"
          isRequired={false}
          placeholder="@username"
        />

        <SubmitButton
          isLoading={updateInstagramLoading}
          data-testid="submit-instagram-form-button"
          event="Update Instagram Submitted"
          alignSelf={'center'}
        >
          Submit
        </SubmitButton>
      </Flex>
    </Form>
  )
}
