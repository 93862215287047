import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  Flex,
} from '@bounty/brands-design-system'
import {
  useGiftsHistoryDispatch,
  useGiftsHistoryState,
} from '../../useGiftsHistory'

export const GiftsHistoryTablePagination = () => {
  const { value } = useGiftsHistoryState()
  const { setValues } = useGiftsHistoryDispatch()

  const getSkip = (direction: 'PREV' | 'NEXT') => {
    return direction === 'PREV'
      ? value.params.skip - value.params.take
      : value.params.skip + value.params.take
  }

  const handlePaginationClick = (direction: 'PREV' | 'NEXT') => {
    setValues((prev) => ({
      value: {
        ...prev.value,
        params: {
          ...prev.value.params,
          skip: getSkip(direction),
        },
      },
    }))
  }

  return (
    <Flex align="center" justify="center" gridGap={4}>
      <Button
        onClick={() => handlePaginationClick('PREV')}
        event={'Gift Offers History Table Click Previous Page'}
        disabled={value.params.skip === 0}
        variant="ghost"
      >
        <ArrowLeftIcon />
      </Button>
      <Button
        onClick={() => handlePaginationClick('NEXT')}
        event={'Gift Offers History Table Click Next Page'}
        variant="ghost"
      >
        <ArrowRightIcon />
      </Button>
    </Flex>
  )
}
