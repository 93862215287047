// TODO: Marcus to create a useContext hook that hides all of this away for these use cases
import { createContext, ReactNode, useContext, useState } from 'react'
import { InviteCreatorsYouDontKnowSchema } from './schemas'

export type InviteCreatorsYouDontKnowState = InviteCreatorsYouDontKnowSchema

const InviteCreatorsYouDontKnowStateContext =
  createContext<InviteCreatorsYouDontKnowState | null>(null)

export type InviteCreatorsYouDontKnowDispatch = {
  setInviteCreatorsYouDontKnowState: React.Dispatch<
    React.SetStateAction<InviteCreatorsYouDontKnowSchema>
  >
}

const InviteCreatorsYouDontKnowDispatchContext =
  createContext<InviteCreatorsYouDontKnowDispatch | null>(null)

export const InviteCreatorsYouDontKnowProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [state, setInviteCreatorsYouDontKnowState] =
    useState<InviteCreatorsYouDontKnowState>({
      giftId: '',
      message: '',
      rowData: [],
    })

  return (
    <InviteCreatorsYouDontKnowStateContext.Provider value={state}>
      <InviteCreatorsYouDontKnowDispatchContext.Provider
        value={{ setInviteCreatorsYouDontKnowState }}
      >
        {children}
      </InviteCreatorsYouDontKnowDispatchContext.Provider>
    </InviteCreatorsYouDontKnowStateContext.Provider>
  )
}

export const useInviteCreatorsYouDontKnowState =
  (): InviteCreatorsYouDontKnowState => {
    const context = useContext(InviteCreatorsYouDontKnowStateContext)

    if (!context) {
      throw new Error(
        'useInviteCreatorsYouDontKnowState must be used within a InviteCreatorsYouDontKnowProvider.',
      )
    }

    return context
  }

export const useInviteCreatorsYouDontKnowDispatch =
  (): InviteCreatorsYouDontKnowDispatch => {
    const context = useContext(InviteCreatorsYouDontKnowDispatchContext)

    if (!context) {
      throw new Error(
        'useInviteCreatorsYouDontKnowDispatch must be used within a InviteCreatorsYouDontKnowProvider.',
      )
    }

    return context
  }

export const useInviteCreatorsYouDontKnow = (): [
  InviteCreatorsYouDontKnowState,
  InviteCreatorsYouDontKnowDispatch,
] => {
  return [
    useInviteCreatorsYouDontKnowState(),
    useInviteCreatorsYouDontKnowDispatch(),
  ]
}
