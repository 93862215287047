import { nextSunday, isSunday } from 'date-fns'

export const CRON_REFRESH_HOUR = 20

const now = new Date()

// today's date in UTC w/ cron refresh hour
const dateToday = new Date(
  Date.UTC(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate(),
    CRON_REFRESH_HOUR,
  ),
)
const upcomingSunday = nextSunday(dateToday)

// if sunday is today, next
export const nextRefreshTime =
  now < dateToday && isSunday(now) ? dateToday : upcomingSunday
