import {
  Box,
  PageHeader,
  useTheme,
  useToast,
  Skeleton,
  Grid,
  PageHeaderActions,
  PageHeaderSecondaryAction,
  PageHeaderPrimaryAction,
} from '@bounty/brands-design-system'
import { useNavigate } from 'react-router-dom'
import { useInviteCreatorsYouKnowState } from '../../useInviteCreatorsYouKnow'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  CellStyle,
  ColDef,
  GetRowIdFunc,
  GetRowIdParams,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import {
  useMutationBackend,
  useQueryBackend,
} from '../../../../../../apollo/backend/hooks'
import { ReviewSchema } from '../../schemas'
import {
  GiftInviteBatchParams,
  InviteValidationResultRecordInput,
  ReviewInvitesDocument,
  SubmitGiftInvitesDocument,
} from '../../../../../../generated/backendGraphql'
import { useCheckForTableData } from '../../hooks/useCheckForTableData'
import { isNil } from '@bounty/utils'
import { InviteDetailsDrawer } from './InviteDetailsDrawer'

export type InviteRowItem = ReviewSchema['reviewData'][0] | any

export const ReviewInvites = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const { giftId, message, rowData } = useInviteCreatorsYouKnowState()

  useCheckForTableData()

  useEffect(() => {
    if (isNil(giftId)) {
      navigate('/creators/invite/creators-you-know/message')
      toast({
        title: 'No Gift Found',
        description: 'Please add a gift',
        status: 'error',
      })
    }
  }, [giftId, navigate, toast])

  const theme = useTheme()
  const gridRef = useRef<AgGridReact<InviteRowItem>>(null)

  const { data, loading: reviewInvitesLoading } = useQueryBackend(
    ReviewInvitesDocument,
    {
      variables: {
        id: giftId,
      },
    },
  )

  const columnDefs = useMemo((): ColDef<InviteRowItem>[] => {
    const cellStyles: CellStyle = {
      height: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center ',
      fontSize: theme.fontSizes.md.toString(),
      color: theme.colors['gray']['900'],
    }

    return [
      {
        headerName: 'Email',
        field: 'email',
        editable: false,
        cellStyle: cellStyles,
        flex: 1,
      },
      {
        headerName: 'Gift',
        field: 'gift',
        editable: false,
        cellStyle: cellStyles,
        flex: 1,
      },
      {
        headerName: 'Message',
        field: 'message',
        editable: false,
        cellStyle: cellStyles,
        flex: 1,
      },
    ]
  }, [theme])

  const sanitizedMessage = (msg: string, name: string) => {
    const nameCurlies = /{{name}}/g
    return msg.replace(nameCurlies, name)
  }

  const numberOfRowsToDisplay = 8
  const rowHeight = 72

  const getRowId = useMemo<GetRowIdFunc>(() => {
    return (params: GetRowIdParams) => params.data.email
  }, [])

  const [drawerData, setDrawerData] = useState<InviteRowItem>()
  const [showDrawer, setShowDrawer] = useState(false)

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current!.api.getSelectedRows()
    setDrawerData(selectedRows[0])
    setShowDrawer(true)
  }, [setDrawerData, setShowDrawer])

  const [submitGiftInvites, { loading: submitGiftInvitesLoading }] =
    useMutationBackend(SubmitGiftInvitesDocument)

  const formData: GiftInviteBatchParams = {
    giftId: giftId,
    records: rowData.map((el: InviteValidationResultRecordInput) => {
      return {
        email: el.email,
        ...(!isNil(message) && message.length > 0
          ? { message: sanitizedMessage(message, el.name ?? '') }
          : {}),
        ...(!isNil(el.name) && el.name.length > 0 ? { name: el.name } : {}),
      }
    }),
  }

  return (
    <>
      <PageHeader
        title={'Invite creators you know to Bounty'}
        description="Invite creators you already work with to join your community on Bounty."
        breadcrumbs={[
          {
            name: 'Creators',
            to: '/creators',
          },
          {
            name: 'Invite',
            to: '/creators/invite',
          },
          {
            name: 'Current',
            to: '',
            isCurrentPage: true,
          },
        ]}
        actions={
          <PageHeaderActions>
            <PageHeaderSecondaryAction
              event={'Invites Add Creators You Know Second Step Back Clicked'}
              onClick={() => navigate('../message')}
            >
              Back
            </PageHeaderSecondaryAction>
            <PageHeaderPrimaryAction
              event={'Invites Add Creators You Know Second Step Next Clicked'}
              onClick={async () => {
                const res = await submitGiftInvites({
                  variables: { params: formData },
                })
                if (res.data?.submitGiftInvites.errors.length === 0) {
                  toast({
                    title: `Invites sent`,
                    description: `Your invites have been sent`,
                    status: 'success',
                  })
                  navigate('/creators')
                } else {
                  res.data?.submitGiftInvites.errors.map((err) => {
                    return toast({
                      title: `Error sending invites`,
                      description: err.message ?? `Your invites failed to send`,
                      status: 'error',
                    })
                  })
                }
              }}
              isLoading={reviewInvitesLoading || submitGiftInvitesLoading}
            >
              Send Invites
            </PageHeaderPrimaryAction>
          </PageHeaderActions>
        }
      />
      <Box w="100%" h="500px">
        {reviewInvitesLoading || !data ? (
          <Skeleton
            w="100%"
            h={`calc(${rowHeight}px * ${numberOfRowsToDisplay} + 58px)`}
          />
        ) : (
          <Grid<InviteRowItem>
            rowStyle={{ cursor: 'pointer' }}
            gridRef={gridRef}
            columnDefs={columnDefs}
            containerProps={{
              height: `calc(${rowHeight}px * ${numberOfRowsToDisplay} + 58px)`,
              flex: 'none',
              borderRadius: 'md',
            }}
            rowHeight={rowHeight}
            rowData={rowData.map((row) => {
              const name = row.name
              return {
                ...row,
                ...(!isNil(message) && message.length > 0
                  ? { message: sanitizedMessage(message, name) }
                  : {}),
                gift: data.getStoreGift.name,
              }
            })}
            getRowId={getRowId}
            showQuickSearch={false}
            enableCellTextSelection={true}
            rowSelection={'single'}
            onSelectionChanged={onSelectionChanged}
          />
        )}
      </Box>
      {drawerData && (
        <InviteDetailsDrawer
          isOpen={showDrawer}
          onClose={() => {
            setShowDrawer(false)
            setDrawerData(null)
          }}
          data={drawerData}
        />
      )}
    </>
  )
}
