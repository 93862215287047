import * as z from 'zod'

export const InviteCSVEntrySchema = z
  .object({
    email: z.string().email(),
    name: z.string().max(100).optional(),
  })
  .strict()

export const InviteBatchEntrySchema = z
  .object({
    email: z.string().email(),
    name: z.string().max(100).optional(),
    message: z.string().max(2000).optional(),
  })
  .strict()

export const PrevCustomerInviteRequestParamsSchema = z
  .object({
    // The date ranges are required and must be filled
    startDate: z.string().min(1),
    endDate: z.string().min(1),
    productIds: z
      .array(z.string())
      .max(7, 'Cannot submit more than 7 products.'),
    customerTags: z.array(z.string().max(100)),
    emailSubscriptionStatusSubscribed: z.boolean(),
    giftId: z.string().min(1, { message: 'Must add a gift.' }),
  })
  .strict()

export type PrevCustomerInviteRequestParamsSchemaType = z.infer<
  typeof PrevCustomerInviteRequestParamsSchema
>

export const AdvancedInvitesSchema = z
  .object({
    giftId: z.string().min(1, { message: 'Must add a gift.' }),
    count: z.number().gte(1).lte(5000),
  })
  .strict()
