import { ReactNode, useEffect } from 'react'
import { LatestUpdatesModal } from '../../components/LatestUpdates/LatestUpdatesModal'
import { useDisclosure } from '@bounty/brands-design-system'
import { useCheckSeenUpdates } from '../../hooks/useCheckSeenUpdates'

interface LatestUpdatesCheckProps {
  children: ReactNode
}

export const LatestUpdatesCheck = (props: LatestUpdatesCheckProps) => {
  const { children } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { hasSeenLatestUpdates } = useCheckSeenUpdates()

  useEffect(() => {
    if (!hasSeenLatestUpdates) {
      onOpen()
    }
  }, [hasSeenLatestUpdates, onOpen])

  return (
    <>
      {children}
      {/* Used modal here again since modals doesn't have providers in this archi, and don't want to spend time to refactor */}
      {isOpen && <LatestUpdatesModal isOpen={isOpen} onClose={onClose} />}
    </>
  )
}
