import {
  Flex,
  Box,
  Skeleton,
  Select,
  VStack,
  Tile,
  Text,
  Heading,
  PageHeader,
  HStack,
  Button,
  AnnouncementsIcon,
  useDisclosure,
} from '@bounty/brands-design-system'
import { useState, useMemo } from 'react'

import { RecentActivity } from '../../components/RecentActivity'
import { Metrics } from './Metrics'
import { DateRangeDisplay } from '../../components/DateRangeDisplay'
import {
  UnreachableCaseError,
  getLastWeek,
  getLastMonth,
  getLastYear,
  getLast30Days,
  getLast3Months,
  getLast7Days,
  getYesterday,
  getToday,
} from '@bounty/utils'
import { useQueryBackend } from '../../apollo/backend/hooks'
import {
  HomeDocument,
  StorefrontDocument,
  TimeSeriesBin,
} from '../../generated/backendGraphql'
import { ResponsiveDashboardChart } from './DashboardChart'
import { Checklist } from '../../components/Checklist'
import { QuickShareLinkModule } from '../../components/QuickShareLink/QuickShareLinkModule'
import { useSubscriptionSuccess } from '../../hooks/useSubscriptionSuccess'
import { LatestUpdatesModal } from '../../components/LatestUpdates/LatestUpdatesModal'
import { CommunityLandingPageCopyLink } from '../../components/Community/CommunityLandingPageCopyLink'

export type DateRangeType =
  | 'TODAY'
  | 'YESTERDAY'
  | 'WEEK'
  | '7_DAYS'
  | 'MONTH'
  | '30_DAYS'
  | '3_MONTHS'
  | '12_MONTHS'

const getValuesForRange = (
  range: DateRangeType,
): { values: [Date, Date]; bin: TimeSeriesBin; comparison: [Date, Date] } => {
  switch (range) {
    case 'TODAY':
      return { values: getToday(), bin: 'HOUR', comparison: getYesterday() }
    case 'YESTERDAY':
      return {
        values: getYesterday(),
        bin: 'HOUR',
        comparison: getYesterday(getYesterday()[0]),
      }
    case 'WEEK':
      return {
        values: getLastWeek(),
        bin: 'DAY',
        comparison: getLastWeek(getLastWeek()[0]),
      }
    case '7_DAYS':
      return {
        values: getLast7Days(),
        bin: 'DAY',
        comparison: getLast7Days(getLast7Days()[0]),
      }
    case '30_DAYS':
      return {
        values: getLast30Days(),
        bin: 'DAY',
        comparison: getLast30Days(getLast30Days()[0]),
      }
    case 'MONTH':
      return {
        values: getLastMonth(),
        bin: 'DAY',
        comparison: getLastMonth(getLastMonth()[0]),
      }
    case '3_MONTHS':
      return {
        values: getLast3Months(),
        bin: 'WEEK',
        comparison: getLast3Months(getLast3Months()[0]),
      }
    case '12_MONTHS':
      return {
        values: getLastYear(),
        bin: 'MONTH',
        comparison: getLastYear(getLastYear()[0]),
      }
    default:
      throw new UnreachableCaseError(range)
  }
}

export const Dashboard = () => {
  // moving this here bc its after app is authed and its where it redirects to
  useSubscriptionSuccess()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [range, setRange] = useState<DateRangeType>('30_DAYS')
  const {
    values: [startDate, endDate],
    comparison: [comparisonStartDate, comparisonEndDate],
    bin,
  } = useMemo(() => getValuesForRange(range), [range])
  const { data, loading } = useQueryBackend(HomeDocument, {
    variables: { params: { startDate, endDate, bin } },
  })
  const { loading: storeFrontLoading, data: { storefront } = {} } =
    useQueryBackend(StorefrontDocument)

  return (
    <>
      <PageHeader
        title="Dashboard"
        description={<DateRangeDisplay start={startDate} end={endDate} />}
        actions={
          <HStack justifyContent="end" width={['auto', 'auto', 800]}>
            <Select
              width="100%"
              maxWidth="175px"
              value={range.toLowerCase()}
              onChange={(e) =>
                setRange(e.target.value.toUpperCase() as DateRangeType)
              }
              borderRadius="sm"
              size="md"
            >
              <option value="today">Today</option>
              <option value="yesterday">Yesterday</option>
              <option value="7_days">Last 7 Days</option>
              <option value="30_days">Last 30 Days</option>
              <option value="week">Last Week</option>
              <option value="month">Last Month</option>
              <option value="3_months">Last 3 Months</option>
              <option value="12_months">Last 12 Months</option>
            </Select>
            <Button
              width="fit-content"
              event="Show Latest Announcements Clicked"
              rightIcon={<AnnouncementsIcon />}
              onClick={() => onOpen()}
            >
              Latest Updates
            </Button>
          </HStack>
        }
      />

      <Checklist />

      <Flex
        flexDirection={['column', 'column', 'column', 'row']}
        flex="1"
        gridGap={4}
      >
        <Flex ml="0" width="100%" gridGap={4} direction="column">
          <Metrics
            start={startDate}
            end={endDate}
            comparisonStartDate={comparisonStartDate}
            comparisonEndDate={comparisonEndDate}
            width="100%"
          />

          <VStack minWidth={0} width="100%" spacing={4}>
            <Tile width="100%" height="100%" minHeight="425px">
              <VStack width="100%" alignItems="flex-start">
                <Heading fontSize="xl" fontWeight="bold">
                  Creators Added
                </Heading>
                <Text>Unique creators who signed up to your store</Text>
                <DateRangeDisplay start={startDate} end={endDate} />
                {!data || loading ? (
                  <VStack width="100%" align="stretch">
                    <Skeleton width="100%" height="300px" />
                  </VStack>
                ) : (
                  <Box minHeight="0" width="100%" height="300px">
                    <ResponsiveDashboardChart
                      data={data.storeStatsTimeSeries.creators}
                      range={range}
                    />
                  </Box>
                )}
              </VStack>
            </Tile>
            <Tile width="100%" height="100%" minHeight="425px">
              <VStack width="100%" alignItems="flex-start">
                <Heading fontSize="xl" fontWeight="bold">
                  Assets
                </Heading>
                <Text>New assets posted</Text>
                <DateRangeDisplay start={startDate} end={endDate} />
                {!data || loading ? (
                  <VStack width="100%" align="stretch">
                    <Skeleton width="100%" height="300px" />
                  </VStack>
                ) : (
                  <Box minHeight="0" width="100%" height="300px">
                    <ResponsiveDashboardChart
                      data={data.storeStatsTimeSeries.assets}
                      range={range}
                    />
                  </Box>
                )}
              </VStack>
            </Tile>
          </VStack>
        </Flex>
        <Flex
          w="100%"
          height="100%"
          overflow="hidden"
          alignSelf="stretch"
          direction="column"
          flexGrow={1}
          gridGap={4}
        >
          <Tile>
            <Box minH="93px">
              <QuickShareLinkModule />
            </Box>
          </Tile>
          {!storeFrontLoading &&
            storefront &&
            storefront.headerImgLinkPrimary && (
              <Tile
                py="8"
                px="6"
                as={Flex}
                flexDirection={['column-reverse', null, null, null, 'row']}
                justifyContent="space-between"
              >
                <Flex
                  flexDir="column"
                  width={['100%', '100%', '100%', '100%', '600px']}
                  mr="6"
                >
                  <Heading size="md" mb="4">
                    Your signup page link
                  </Heading>
                  <Text mb="4">
                    Share this link anywhere you'd like people to be able to
                    sign up to make videos and we'll take care of the rest!
                  </Text>
                  <CommunityLandingPageCopyLink slug={storefront.slug} />
                </Flex>
              </Tile>
            )}
          <Flex
            w="100%"
            height="100%"
            maxHeight={['480px', null, 'calc(1018px - 135px - 1rem)']}
            // alignSelf="stretch"
            flexGrow={1}
            as={Tile}
          >
            <RecentActivity />
          </Flex>
        </Flex>
      </Flex>
      <Box pb={['0', null, null, '14']}></Box>
      {isOpen && <LatestUpdatesModal isOpen={isOpen} onClose={onClose} />}
    </>
  )
}
