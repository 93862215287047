export const MIN_PAYOUT_THRESHOLD = 10.0

export const PAYOUT_TYPES = {
  PAYPAL: {},
  VENMO: {},
  CASHAPP: {},
  BITCOIN: {},
  STRIPE: {},
} as const

export type PayoutType = keyof typeof PAYOUT_TYPES

// Default for minimum bounty payout
export const DEFAULT_MINIMUM_BOUNTY_PAYOUT = 5.0
// Maximum that a brand can set the minimum bounty payout
// changed to 5k for scotts
export const MINIMUM_BOUNTY_PAYOUT_MAX = 5000.0
// Minimum that a brand can set the minimum bounty payout
export const MINIMUM_BOUNTY_PAYOUT_MIN = 5.0
