import { useCallback } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalProps,
  Text,
  Button,
  ButtonProps,
  Flex,
  Box,
} from '@bounty/brands-design-system'

type AdvancedInvitesModalProps = Omit<ModalProps, 'children'> & {
  loading: boolean
  giftName: string | undefined
  SubmitButton: (props: ButtonProps) => JSX.Element
}

export const AdvancedInvitesModal = ({
  loading,
  giftName,
  SubmitButton,
  onClose: onCloseProp,
  ...rest
}: AdvancedInvitesModalProps) => {
  const onClose = useCallback(() => {
    onCloseProp()
  }, [onCloseProp])
  return (
    <Modal size="xl" {...rest} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Send Invites</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Box>
            <Text mb="4">
              The invite links generated grants anyone with the link a chance to
              redeem the following gift one time:
            </Text>
            <Text mb="4" fontWeight={'semibold'}>
              {giftName}
            </Text>
            <Text mb="6">
              Please be careful of where you share these links, especially if
              redemption of the gift creates orders in your system.
            </Text>
            <Flex justifyContent="flex-end">
              <Button
                event="Advanced Invites Modal Cancel Clicked"
                mx="5"
                onClick={onClose}
                variant="outline"
              >
                Cancel
              </Button>
              <SubmitButton
                isDisabled={loading}
                form="advanced-invites-form"
                event="Advanced Invites Modal Confirm Clicked"
              >
                Confirm and download
              </SubmitButton>
            </Flex>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
