import { z } from 'zod'

export const PostPurchaseTokenSubmitBodySchema = z.object({
  orderId: z.string(),
  email: z.string().email(),
  token: z.string(),
})

export type PostPurchaseTokenSubmitBodySchemaType = z.infer<
  typeof PostPurchaseTokenSubmitBodySchema
>
