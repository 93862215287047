import { useState } from 'react'
import { z } from 'zod'

import { Flex, HStack, Text, useFastForm } from '@bounty/brands-design-system'

import { FeatureUnavailable } from '../../../components/FeatureUnavailable'
import { useFeatureForStore } from '../../../hooks/useFeatureForStore'
import { BountyNetworkTable } from './BountyNetworkTable'

export const CreatorNetworkSearchForm = z
  .object({
    searchText: z.string(),
  })
  .strict()

export const BountyNetworkTab = () => {
  const { isAvailable } = useFeatureForStore('BOUNTY_CREATOR_NETWORK')
  const [searchText, setSearchText] = useState<string>('')
  const { Form, Input, SubmitButton } = useFastForm({
    schema: CreatorNetworkSearchForm,
    defaultValues: {
      searchText: '',
    },
  })

  if (isAvailable === null) return null

  return (
    <Flex flexDir={'column'} height="100%">
      {isAvailable ? (
        <>
          <Text mb="6">
            You can offer any of these creators a free product once per month.
          </Text>
          <Form
            id="network-search"
            onSubmit={(values) => setSearchText(values.searchText)}
          >
            <HStack alignItems={'baseline'}>
              <Input
                name="searchText"
                placeholder="search on username, bio, or hashtags"
              />
              <SubmitButton
                event="Bounty Network Search Clicked"
                alignSelf="center"
              >
                Search
              </SubmitButton>
            </HStack>
          </Form>
          <BountyNetworkTable searchText={searchText} />
        </>
      ) : (
        <FeatureUnavailable ctaText="Upgrade to access our creator network!" />
      )}
    </Flex>
  )
}
