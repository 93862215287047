import {
  Flex,
  Skeleton,
  Switch,
  Text,
  FlexProps,
} from '@bounty/brands-design-system'
import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import {
  BountyTogglerDocument,
  ToggleIsSnippetEnabledDocument,
} from '../../generated/backendGraphql'
import { useAnalytics } from '@bounty/web-analytics'

export type BountyTogglerSwitchProps = FlexProps
export const BountyTogglerSwitch = (props: BountyTogglerSwitchProps) => {
  const { loading, data } = useQueryBackend(BountyTogglerDocument)
  const [mutation] = useMutationBackend(ToggleIsSnippetEnabledDocument)
  const { track } = useAnalytics()

  return (
    <Flex alignItems="center" {...props}>
      {loading || !data ? (
        <Skeleton height="30px" width="90px" />
      ) : (
        <>
          <Switch
            isChecked={data.currentStore.isSnippetEnabled}
            onChange={(e) => {
              track('Bounty Toggled', { isEnabled: e.target.checked })
              mutation({
                variables: {
                  isSnippetEnabled: e.target.checked,
                },
                optimisticResponse: {
                  updateStore: {
                    id: data.currentStore.id,
                    isSnippetEnabled: e.target.checked,
                    __typename: 'ShopifyStore',
                  },
                  __typename: 'Mutation',
                },
              })
            }}
            size="lg"
          />
          <Text as="span" fontSize="sm" ml="2">
            {data.currentStore.isSnippetEnabled
              ? 'Bounty is On'
              : 'Bounty is Off'}
          </Text>
        </>
      )}
    </Flex>
  )
}
