import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerProps,
  DrawerCloseButton,
  ArrowLeftIcon,
  Button,
} from '@bounty/brands-design-system'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { CreatorDetailsOfferGift } from './CreatorDetailsOfferGift'
import { CreatorDetailsOverview } from './CreatorDetailsOverview'

export type CreatorDetailsDrawerProps = Omit<
  DrawerProps,
  'children' | 'isOpen' | 'onClose'
> & {
  rootPath: string
  showBackButton?: boolean
}

export const CreatorDetailsDrawer = ({
  rootPath,
  showBackButton = false,
  ...rest
}: CreatorDetailsDrawerProps) => {
  const navigate = useNavigate()

  return (
    <Drawer size="xl" isOpen onClose={() => navigate(rootPath)} {...rest}>
      <DrawerOverlay />
      <DrawerContent>
        {showBackButton && (
          <Button
            variant="ghost"
            aria-label="back"
            event={
              'Gift Offer History Creator Details Drawer Back Button Click'
            }
            p={0.5}
            width="auto"
            position="absolute"
            top={3}
            left={3}
            onClick={() => navigate(-1)}
            borderRadius="lg"
          >
            <ArrowLeftIcon size={20} />
          </Button>
        )}
        <DrawerCloseButton />
        <Routes>
          <Route path="/" element={<CreatorDetailsOverview />} />
          <Route path="/give-gift" element={<CreatorDetailsOfferGift />} />
        </Routes>
      </DrawerContent>
    </Drawer>
  )
}
