import { defineStyleConfig } from '@chakra-ui/react'

export const badgeTheme = defineStyleConfig({
  defaultProps: {
    colorScheme: 'primary',
    variant: 'subtle',
  },
  variants: {
    subtle: (props: any) => {
      const { colorScheme: c } = props
      return {
        background: `${c}.50`,
      }
    },
  },
})
