import { PortableTextComponents } from '@portabletext/react'
import {
  Text,
  Heading,
  Link,
  ChakraGrid,
  Box,
  BoxProps,
  Image,
  OrderedList,
  UnorderedList,
  ListItem,
} from '../Chakra'
import {
  TikTokEmbed as TikTokEmbedRaw,
  YouTubeEmbed as YouTubeEmbedRaw,
} from 'react-social-media-embed'
import Refractor from 'react-refractor'
import js from 'refractor/lang/javascript'
import ts from 'refractor/lang/typescript'
import css from 'refractor/lang/css'
import markup from 'refractor/lang/markup'

Refractor.registerLanguage(js)
Refractor.registerLanguage(ts)
Refractor.registerLanguage(css)
Refractor.registerLanguage(markup)

const Figure = ({ value }: BoxProps & { value: any }) => {
  return (
    <Box as="figure" mb="4">
      <Image src={value.asset} alt={value.alt} mx="auto" mb="2" />
      <Text as="figcaption" textAlign="center" color="gray.600" fontSize="sm">
        {value.caption}
      </Text>
    </Box>
  )
}

const YoutubeEmbed = ({ value }: BoxProps & { value: any }) => {
  return (
    <Box mb="4" display="flex" justifyContent="center">
      <YouTubeEmbedRaw url={value.url} />
    </Box>
  )
}

const TikTokEmbed = ({ value }: BoxProps & { value: any }) => {
  return (
    <Box mb="4">
      <TikTokEmbedRaw url={value.url} />
    </Box>
  )
}

const getNodeForType = (item: any) => {
  switch (item._type) {
    case 'figure':
      return <Figure value={item} mb="0" />
    case 'tikTokEmbed':
      return <TikTokEmbed value={item} mb="0" />
    case 'youtubeEmbed':
      return <YoutubeEmbed value={item} mb="0" />

    default:
      return null
  }
}

export const buildPortableTextComponents = (): PortableTextComponents => {
  return {
    block: {
      normal: ({ children }) => <Text mb="3">{children}</Text>,
      blockquote: ({ children }) => (
        <Text
          as="blockquote"
          borderLeftWidth="3px"
          borderColor="purple.300"
          borderLeftStyle="solid"
          mx="6"
          my="8"
          fontSize="xl"
          pl="4"
        >
          {children}
        </Text>
      ),
      h1: ({ children }) => (
        <Heading as={'h1'} mb="4" mt="6" size="xl">
          {children}
        </Heading>
      ),
      h2: ({ children }) => (
        <Heading as={'h2'} mb="4" mt="6" size="lg">
          {children}
        </Heading>
      ),
      h3: ({ children }) => (
        <Heading as={'h3'} mb="3" mt="4" size="md">
          {children}
        </Heading>
      ),
      h4: ({ children }) => (
        <Heading as={'h4'} mb="3" mt="4" size="sm">
          {children}
        </Heading>
      ),
      h5: ({ children }) => (
        <Heading as={'h5'} mb="3" mt="4" size="xs">
          {children}
        </Heading>
      ),
      h6: ({ children }) => (
        <Heading as={'h6'} mb="2" size="xs">
          {children}
        </Heading>
      ),
    },
    types: {
      grid: ({ value }) => {
        return (
          <ChakraGrid
            templateColumns={[
              'repeat(1, minmax(0, 1fr))',
              'repeat(1, minmax(0, 1fr))',
              `repeat(${value.columns}, minmax(0, 1fr))`,
            ]}
            gap="4"
            mb="4"
          >
            {value.items.map((item: any, index: number) => {
              return <Box key={index}>{getNodeForType(item)}</Box>
            })}
          </ChakraGrid>
        )
      },
      tikTokEmbed: ({ value }) => {
        return <TikTokEmbed value={value} />
      },
      youtubeEmbed: ({ value }) => {
        return <YoutubeEmbed value={value} />
      },
      /**
       * NOTE: Make sure to import the stylesheet for the page if you use this. See blog.$blog.tsx for details.
       */
      code: ({ value }) => {
        return (
          <Refractor
            // In this example, `props` is the value of a `code` field
            language={value.language}
            value={value.code}
            markers={value.highlightedLines}
          />
        )
      },
      figure: ({ value }) => {
        return <Figure value={value} />
      },
    },
    list: {
      number: ({ children }) => <OrderedList>{children}</OrderedList>,
      bullet: ({ children }) => <UnorderedList>{children}</UnorderedList>,
    },
    listItem: ({ children }) => <ListItem>{children}</ListItem>,
    marks: {
      // Ex. 1: custom renderer for the em / italics decorator
      em: ({ children }) => <Text as={'em'}>{children}</Text>,
      strong: ({ children }) => <Text as={'strong'}>{children}</Text>,
      code: ({ children }) => <Text as="code">{children}</Text>,
      underline: ({ children }) => (
        <Text as="span" textDecoration="underline">
          {children}
        </Text>
      ),
      'strike-through': ({ children }) => <Text as="del">{children}</Text>,

      // Ex. 2: rendering a custom `link` annotation
      externalLink: ({ children, value }) => {
        return (
          <Link
            isExternal={true}
            target={value.blank ? '_blank' : '_self'}
            href={value.href}
          >
            {children}
          </Link>
        )
      },
    },
  }
}

export const getRoutePrefixForInternalLinks = <T extends { type: string }>(
  value: T,
) => {
  switch (value.type) {
    case 'brand':
      return '/shop/'
    case 'post':
      return '/blog/'

    default:
      return ''
  }
}
