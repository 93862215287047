import {
  Text,
  Skeleton,
  DrawerHeader,
  DrawerHeading,
  DrawerBody,
  VStack,
  HStack,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Button,
  Tile,
} from '@bounty/brands-design-system'
import { useQueryBackend } from '../../../apollo/backend/hooks'
import { CreatorDetailsNetworkOverviewDocument } from '../../../generated/backendGraphql'
import { prettyCurrency } from '@bounty/utils'
import { CreatorDetailUserTileNetwork } from './CreatorDetailUserTileNetwork'
import { useParamsOrThrow } from '@bounty/hooks'
import { FavoriteCreatorButton } from '../../../components/FavoriteCreatorButton/FavoriteCreatorButton'
import { useNavigate } from 'react-router-dom'

export const CreatorDetailsOverview = () => {
  const { userId } = useParamsOrThrow(['userId'])
  const navigate = useNavigate()

  const { data, loading } = useQueryBackend(
    CreatorDetailsNetworkOverviewDocument,
    {
      variables: { userId },
    },
  )

  const details = data?.creatorDetailsNetwork

  return loading || !details ? (
    <>
      <DrawerHeader>
        <CreatorDetailUserTileNetwork />
      </DrawerHeader>
      <DrawerBody>
        <VStack mt={8} spacing={4}>
          <Skeleton width="100%" height="3rem" />
          <Skeleton width="100%" height="3rem" />
          <Skeleton width="100%" height="3rem" />
          <Skeleton width="100%" height="3rem" />
        </VStack>
      </DrawerBody>
    </>
  ) : (
    <>
      <DrawerHeader>
        <DrawerHeading>
          <HStack justifyContent={'space-between'} pr="10">
            <Text>{details.profileName}</Text>
            <FavoriteCreatorButton userId={userId} data={details} />
          </HStack>
        </DrawerHeading>
      </DrawerHeader>
      <DrawerBody display="flex" flexDir={'column'} minHeight={0}>
        <Tabs display="flex" flexDir={'column'} minHeight={0}>
          <TabList>
            <Tab color="gray.600" _active={{ color: 'black' }} px={8}>
              Overview
            </Tab>
          </TabList>
          <TabPanels minHeight={0} overflow="auto">
            <TabPanel>
              <VStack width={'100%'} spacing={'8'} mb="8">
                <Button
                  event="Creator Network Creator Details Drawer Gift Product Clicked"
                  onClick={() => {
                    navigate('./give-gift')
                  }}
                  isDisabled={
                    data.creatorDetailsNetwork.creatorLimitForStore === 0
                  }
                  width="100%"
                >
                  Gift Product
                </Button>

                <CreatorDetailUserTileNetwork />
                <Tile width="100%" backgroundColor={'neutral.50'}>
                  <HStack py={6} justifyContent="space-around">
                    <VStack>
                      <Text fontSize="lg">Bounties</Text>
                      <Text fontSize="lg" fontWeight="semibold">
                        {details.numBounties}
                      </Text>
                    </VStack>
                    <VStack>
                      <Text fontSize="lg">Total earned</Text>
                      <Text fontSize="lg" fontWeight="semibold">
                        {prettyCurrency(details.totalEarned, {
                          precision: 2,
                        })}
                      </Text>
                    </VStack>
                    <VStack>
                      <Text fontSize="lg">Sign-up date</Text>
                      <Text fontSize="lg" fontWeight="semibold">
                        {new Date(details.createdAt).toLocaleDateString()}
                      </Text>
                    </VStack>
                  </HStack>
                </Tile>
                {/** TODO: I removed the content library here until there is clarity around what we should show
                 * I think for now they can just look at the tiktok profile
                 */}
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </DrawerBody>
    </>
  )
}
