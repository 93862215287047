import { Alert, AlertCloseButton, AlertDescription, AlertTitle } from './Alert'
import { chakra, useToast as useToastCore, UseToastOptions } from './Chakra'

export const useToast = (options?: UseToastOptions) => {
  const toast = useToastCore({
    position: 'bottom',
    isClosable: true,
    duration: 5000,
    /**
     * Lifted from https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/toast/src/toast.tsx
     * because I couldn't get the colors perfect otherwise.
     */
    render: (props) => {
      const {
        status = 'info',
        variant = 'solid',
        id,
        title,
        isClosable,
        onClose,
        description,
      } = props

      const ids = id
        ? {
            root: `toast-${id}`,
            title: `toast-${id}-title`,
            description: `toast-${id}-description`,
          }
        : undefined

      return (
        <Alert
          addRole={false}
          status={status}
          variant={variant}
          id={ids?.root}
          alignItems="start"
          borderRadius="md"
          boxShadow="lg"
          paddingEnd={8}
          textAlign="start"
          width="auto"
        >
          <chakra.div flex="1" maxWidth="100%">
            {title && <AlertTitle id={ids?.title}>{title}</AlertTitle>}
            {description && (
              <AlertDescription id={ids?.description} display="block">
                {description}
              </AlertDescription>
            )}
          </chakra.div>
          {isClosable && (
            <AlertCloseButton
              size="sm"
              onClick={onClose}
              position="absolute"
              insetEnd={1}
              top={1}
            />
          )}
        </Alert>
      )
    },
    ...options,
  })

  return toast
}
