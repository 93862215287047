import { ReactNode } from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { PageSkeleton } from './PageSkeleton'

export type RequireAuthProps = {
  children?: ReactNode
  isAuthed: boolean
  isLoading: boolean
  /**
   * Where to go if auth check fails
   *
   * @default '/login'
   */
  navToOnAuthFailure?: string
}

export const RequireAuth = ({
  children,
  isAuthed,
  isLoading,
  navToOnAuthFailure = '/login',
}: RequireAuthProps) => {
  const location = useLocation()
  const { inviteKey } = useParams()

  if (isLoading) return <PageSkeleton />

  if (isAuthed === false) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return (
      <Navigate state={{ from: location, inviteKey }} to={navToOnAuthFailure} />
    )
  }

  // TS yells when it's just children although that's valid
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>
}
