import * as z from 'zod'

export const StorefrontDeal = z.object({
  title: z.string().min(1),
  description: z.string().min(1),
  terms: z.string().optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
})

export const UpdateStorefrontInputSchema = z
  .object({
    slug: z.string().optional(),
    enabled: z.boolean().optional(),
    headerImgLinkPrimary: z.string().optional().nullable(),
    headerImgLinkSecondary: z.string().optional().nullable(),
    primaryColor: z.string().optional(),
    secondaryColor: z.string().optional(),
    description: z.string().optional(),
    showDeals: z.boolean().optional(),
    showBounties: z.boolean().optional(),
    showFeaturedProducts: z.boolean().optional(),
    deals: z
      .array(
        z.object({
          title: z
            .string()
            .min(1, { message: 'Must include a title.' })
            .max(25, { message: 'Must be 25 or fewer characters long.' }),
          dealsUrl: z.string().url({ message: 'Must include a valid URL.' }),
          description: z
            .string()
            .min(1, { message: 'Must include a description.' })
            .max(750, { message: 'Must be 750 or fewer characters long.' }),
          terms: z
            .string()
            .max(2000, { message: 'Must be 2000 or fewer characters long.' })
            .optional(),
          startDate: z.string().optional(),
          endDate: z.string().optional(),
        }),
      )
      .optional(),
    featuredVideoIds: z.array(z.string()).optional(),
    featuredProductIds: z.array(z.string()).optional(),
  })
  .strict()

export type UpdateStorefrontInputType = z.infer<
  typeof UpdateStorefrontInputSchema
>

export const UpdateBrandingSchema = z.object({
  logoImgLink: z.string().optional(),
  name: z.string().nonempty(),
  headerImgLinkPrimary: z.string().optional().nullable(),
  headerImgLinkSecondary: z.string().optional().nullable(),
  primaryColor: z.string().optional(),
  secondaryColor: z.string().optional(),
  description: z.string().optional(),
  showDeals: z.boolean().optional(),
  showBounties: z.boolean().optional(),
  showFeaturedProducts: z.boolean().optional(),
})

export const DealsSchema = UpdateStorefrontInputSchema.pick({ deals: true })
export type DealsType = z.infer<typeof DealsSchema>

export type UpdateStorefrontBrandingInputType = z.infer<
  typeof UpdateBrandingSchema
>

export const GetStoresParams = z.object({
  alwaysShow: z.boolean().optional(),
  skip: z
    .string()
    .optional()
    .default('0')
    .transform((s) => parseInt(s) || 0),
  views: z
    .string()
    .optional()
    .default('1000')
    .transform((s) => parseInt(s) || 0),
  take: z
    .string()
    .optional()
    .default('20')
    .transform((s) => parseInt(s) || 0),
  categories: z
    .string()
    .default('')
    .transform((x) => (x === '' ? [] : x.split(','))),
  tags: z
    .string()
    .default('')
    .transform((x) => (x === '' ? [] : x.split(','))),
  orderBy: z
    .enum([
      'RECOMMENDED',
      'STORE_NAME',
      'MINIMUM_PAYOUT',
      'HIGHEST_PAYING',
      'RECENTLY_ADDED',
    ])
    .optional()
    .default('RECOMMENDED'),
  orderByDirection: z
    .enum(['ASC', 'DESC'])
    .optional()
    .default('DESC')
    .transform((x) => x?.toLowerCase()),
})

export type GetStoresParamsType = z.infer<typeof GetStoresParams>

export const GetStoresCountParams = z.object({
  categories: z
    .string()
    .default('')
    .transform((x) => (x === '' ? [] : x.split(','))),
  tags: z
    .string()
    .default('')
    .transform((x) => (x === '' ? [] : x.split(','))),
})

export type GetStoresCountParamsType = z.infer<typeof GetStoresCountParams>

export const GetStoreParams = z.object({
  includeDisabledStores: z
    .string()
    .transform((x: any) => x === 'true')
    .optional(),
  views: z.string().transform((x: any) => Number(x)),
  alwaysShow: z
    .string()
    .transform((x: any) => x === 'true')
    .optional(),
})

export type GetStoreParamsType = z.infer<typeof GetStoreParams>

export const GetStorePayoutParams = z.object({
  views: z
    .string()
    .optional()
    .default('1000')
    .transform((s) => parseInt(s) || 0),
  includeMinimumPayout: z
    .string()
    .optional()
    .transform((x?: string | null) => x?.toLowerCase() === 'true'),
  bonuses: z
    .string()
    .default('')
    .transform((x) => (x === '' ? [] : x.split(','))),
})

export type GetStorePayoutParamsType = z.infer<typeof GetStorePayoutParams>
