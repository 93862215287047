import {
  UserBounty_Type,
  UserBountyContentType,
  SocialProfileType,
} from '../generated/backendGraphql'

export const BOUNTY_TYPE_MAP: Record<UserBounty_Type, string> = {
  RETAIL: 'Retail',
  SHOPIFY_AD_HOC: 'Direct Offer',
  SHOPIFY_STORE_ORDER: 'Customer Store Order',
  SHOPIFY_STORE_ORDER_GIFT: 'Gifted Order',
}

export const BOUNTY_CONTENT_TYPE_MAP: Record<UserBountyContentType, string> = {
  DIRECT_UPLOAD_VIDEO: 'Direct Upload',
  INSTAGRAM_REEL: 'Instagram Reel',
  INSTAGRAM_STORY: 'Instagram Story',
  TIKTOK_VIDEO: 'TikTok Video',
}

export const SOCIAL_PLATFORM_TYPE_MAP: Record<SocialProfileType, string> = {
  INSTAGRAM: 'Instagram',
  TIKTOK: 'TikTok',
}

export const getBountyTypeDisplayName = (type: UserBounty_Type) => {
  return BOUNTY_TYPE_MAP[type]
}

export const getBountyContentTypeDisplayName = (
  contentType: UserBountyContentType,
) => {
  return BOUNTY_CONTENT_TYPE_MAP[contentType]
}

export const getSocialPlatformType = (type: SocialProfileType) => {
  return SOCIAL_PLATFORM_TYPE_MAP[type]
}
