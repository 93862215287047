import { RgbaColor, RgbaColorPicker } from 'react-colorful'
import { forwardRef, useState, useEffect } from 'react'
import {
  Input,
  InputProps,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  Portal,
  useDisclosure,
} from '../Chakra'
import parseColor from 'parse-color'

export type ColorInputProps = Omit<InputProps, 'onChange' | 'value'> & {
  onChange: (color: string) => void
  value: string
  throttleMs?: number
}

const shouldWaitToParseColor = (value: string) => {
  if (!value) return true

  return value.startsWith('#') && value.length < 7
}

const getColorParts = (color: string) => {
  const parsedColor = shouldWaitToParseColor(color) ? null : parseColor(color)

  const rgbaColorString =
    parsedColor?.rgba != null
      ? `rgba(${parsedColor.rgba[0]}, ${parsedColor.rgba[1]}, ${parsedColor.rgba[2]}, ${parsedColor.rgba[3]})`
      : color
  const colorSwatchValue =
    parsedColor?.rgba != null
      ? `rgba(${parsedColor.rgba[0]}, ${parsedColor.rgba[1]}, ${parsedColor.rgba[2]}, ${parsedColor.rgba[3]})`
      : '#000000'
  const rgbaColorObject =
    parsedColor?.rgba != null
      ? {
          r: parsedColor.rgba[0],
          g: parsedColor.rgba[1],
          b: parsedColor.rgba[2],
          a: parsedColor.rgba[3],
        }
      : {
          r: 0,
          g: 0,
          b: 0,
          a: 1,
        }

  return { rgbaColorString, colorSwatchValue, rgbaColorObject }
}

const isColorObjectEqual = (a: RgbaColor, b: RgbaColor) => {
  return a.r === b.r && a.g === b.g && a.b === b.b && a.a === b.a
}

export const ColorInput = forwardRef<any, ColorInputProps>(
  function ColorInputComponent(
    { onChange, value, size, throttleMs = 100, ...rest },
    ref,
  ) {
    const { isOpen: isColorPickerOpen, onOpen, onClose } = useDisclosure()
    const { rgbaColorObject, colorSwatchValue, rgbaColorString } =
      getColorParts(value)

    const [colorPickerColor, setColorPickerColor] =
      useState<RgbaColor>(rgbaColorObject)

    useEffect(() => {
      // Don't update if the picker is focused
      if (isColorPickerOpen) return

      // If value changes, update the color picker
      setColorPickerColor((x) =>
        isColorObjectEqual(x, rgbaColorObject) ? x : rgbaColorObject,
      )
    }, [setColorPickerColor, isColorPickerOpen, rgbaColorObject])

    useEffect(() => {
      // Only fire when picker is focused
      if (isColorPickerOpen === false) return

      const { r, g, b, a } = colorPickerColor
      onChange(`rgba(${r}, ${g}, ${b}, ${a})`)
      // We can't guarantee the on change function is going to be a stable
      // reference since it's a prop
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [colorPickerColor, isColorPickerOpen])

    let colorSwatchBoxSize = 28
    const colorSwatchSpacing = 16
    switch (size) {
      // @ts-expect-error "lg" is valid
      case 'lg':
        colorSwatchBoxSize = 28
        break
      case 'md':
        colorSwatchBoxSize = 28
        break
      // @ts-expect-error "sm" is valid
      case 'sm':
        colorSwatchBoxSize = 24
        break
      // @ts-expect-error "xs" is valid
      case 'xs':
        colorSwatchBoxSize = 15
        break

      default:
      // no-op
    }

    return (
      <Box position="relative">
        <Popover isOpen={isColorPickerOpen} onOpen={onOpen} onClose={onClose}>
          <PopoverTrigger>
            <Button
              className="colorInputTriggerButton"
              position="absolute"
              top="50%"
              transform="translateY(-50%)"
              bottom="0"
              left="10px"
              zIndex="10"
              variant="unstyled"
              minW="auto"
              event="Color Input Swatch Clicked"
              // Adding a dynamic prop creates a new style prop on every change in emotion
              // and destroy the performance of the component
              style={{ backgroundColor: colorSwatchValue }}
              width={`${colorSwatchBoxSize}px`}
              height={`${colorSwatchBoxSize}px`}
              borderRadius="5px"
            ></Button>
          </PopoverTrigger>
          <Input
            ref={ref}
            pl={`${colorSwatchBoxSize + colorSwatchSpacing}px`}
            value={rgbaColorString}
            onChange={(e) => {
              onChange(e.target.value)
            }}
            {...rest}
          />

          <Portal>
            <PopoverContent width="auto">
              <RgbaColorPicker
                onChange={setColorPickerColor}
                color={colorPickerColor}
              />
            </PopoverContent>
          </Portal>
        </Popover>
      </Box>
    )
  },
)
