import * as z from 'zod'

export const ProductListRequestSchema = z
  .object({
    shop: z.string(),
    skip: z
      .string()
      .optional()
      .default('0')
      .transform((s) => parseInt(s) || 0),
    take: z
      .string()
      .optional()
      .default('10')
      .transform((s) => parseInt(s) || 10),
  })
  .strict()

export type ProductListRequestSchemaType = z.infer<
  typeof ProductListRequestSchema
>

export type ProductListRequestErrorCodes =
  | 'INVALID_REQUEST'
  | 'STORE_NOT_FOUND'
  | 'SERVER_ERROR'

export type ProductListRequestSuccessCodes = 'SUCCESS'
