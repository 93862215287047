import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Text,
  useTheme,
} from '@bounty/brands-design-system'
import { useRef } from 'react'
import { BiError } from 'react-icons/bi'
import { handleFileUpload } from '../../utils/handleFileUpload'

interface UploadCreatorsCsvErrorProps {
  updateInvitesCsv: (csv: string) => void
}

export const UploadCreatorsCsvError = ({
  updateInvitesCsv,
}: UploadCreatorsCsvErrorProps) => {
  const theme = useTheme()
  const inputRef = useRef<HTMLInputElement | null>(null)

  return (
    <Flex
      mb="8"
      p="7"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      gridGap={4}
      align="center"
    >
      <Box minHeight="12">
        <BiError size={theme.space['5'].toString()} />
      </Box>
      <Box flexGrow={1}>
        <Heading as="h4" size="sm" mb="2">
          Error
        </Heading>
        <Text size="md" color="gray.700" lineHeight="1.2">
          Please review and edit the rows highlighted in red below or{' '}
          <Button
            variant="link"
            color="inherit"
            fontWeight="inherit"
            letterSpacing="inherit"
            textDecoration="underline"
            event="Invites Upload a New CSV Clicked"
            onClick={() => inputRef?.current?.click()}
          >
            upload a new CSV
          </Button>
        </Text>
      </Box>
      <Button
        event="Invites Upload a CSV Clicked"
        onClick={() => inputRef?.current?.click()}
      >
        Upload a new CSV
      </Button>
      <Input
        type="file"
        accept=".csv"
        display="none"
        name="upload-csv"
        ref={inputRef}
        onChange={(e) => handleFileUpload({ e, updateInvitesCsv, inputRef })}
      />
    </Flex>
  )
}
