import { UserIcon } from '../icons'
import {
  Avatar as AvatarCore,
  AvatarProps as AvatarPropsCore,
  AvatarBadge as AvatarBadgeCore,
  AvatarBadgeProps as AvatarBadgePropsCore,
  AvatarGroup as AvatarGroupCore,
  AvatarGroupProps as AvatarGroupPropsCore,
} from './Chakra'
import { defineStyleConfig, forwardRef } from '@chakra-ui/react'

export const avatarTheme = defineStyleConfig({
  defaultProps: {
    colorScheme: 'primary',
  },
  baseStyle: (p) => {
    return {
      container: {
        backgroundColor: p.name ? 'primary.500' : 'primary.100',
        color: p.name ? 'white' : 'primary.500',
      },
    }
  },
})

export type AvatarProps = AvatarPropsCore

export const Avatar = forwardRef<AvatarProps, 'div'>(({ ...props }, ref) => {
  return <AvatarCore ref={ref} icon={<UserIcon />} {...props} />
})

export type AvatarBadgeProps = AvatarBadgePropsCore

export const AvatarBadge = forwardRef<AvatarBadgeProps, 'div'>(
  ({ ...props }, ref) => {
    return <AvatarBadgeCore ref={ref} {...props} />
  },
)

export type AvatarGroupProps = AvatarGroupPropsCore

export const AvatarGroup = forwardRef<AvatarGroupProps, 'div'>(
  ({ ...props }, ref) => {
    return <AvatarGroupCore ref={ref} {...props} />
  },
)
