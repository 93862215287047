import {
  useFastForm,
  Flex,
  Box,
  useToast,
  Skeleton,
  Stack,
} from '@bounty/brands-design-system'
import { useEffect } from 'react'
import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import {
  ContentTypesAllowedFormDocument,
  ReplaceContentTypesAllowedDocument,
} from '../../generated/backendGraphql'
import * as z from 'zod'
import { UnreachableCaseError } from '@bounty/utils'

export const contentTypesSchema = z.object({
  contentTypesAllowed: z.array(
    z.enum([
      'TIKTOK_VIDEO',
      'DIRECT_UPLOAD_VIDEO',
      'INSTAGRAM_REEL',
      'INSTAGRAM_STORY',
    ]),
  ),
})

type ContentTypesAllowedFormProps = {
  variant: 'TikTok' | 'Instagram'
}

export const ContentTypesAllowedForm = ({
  variant,
}: ContentTypesAllowedFormProps) => {
  const toast = useToast()
  const { data, loading } = useQueryBackend(ContentTypesAllowedFormDocument)
  const [replaceContentTypesAllowed, { loading: replaceContentTypesLoading }] =
    useMutationBackend(ReplaceContentTypesAllowedDocument)

  const {
    Form,
    CheckboxGroup,
    CheckboxGroupOption,
    SubmitButton,
    methods: { setValue },
  } = useFastForm({
    schema: contentTypesSchema,
    defaultValues: {
      contentTypesAllowed: data?.currentStore.contentTypesAllowed || [],
    },
  })

  useEffect(() => {
    if (data?.currentStore.contentTypesAllowed) {
      const allowedContentTypes = [...data.currentStore.contentTypesAllowed]
      setValue('contentTypesAllowed', allowedContentTypes)
    }
  }, [data?.currentStore.contentTypesAllowed, setValue])

  const getContentOptions = (
    variant: ContentTypesAllowedFormProps['variant'],
  ) => {
    switch (variant) {
      case 'TikTok': {
        return [{ value: 'TIKTOK_VIDEO', label: 'TikTok Video' }]
      }
      case 'Instagram': {
        return [{ value: 'INSTAGRAM_REEL', label: 'Instagram Reel' }]
      }
      default:
        throw new UnreachableCaseError(variant)
    }
  }

  return loading || !data ? (
    <Box>
      <Skeleton height="10" width="100%" mb="18" />
      <Skeleton height="10" width="100%" mb="18" />
    </Box>
  ) : (
    <Stack direction="column" spacing="6">
      <Form
        data-testid="content-types-allowed-form"
        onSubmit={async ({ contentTypesAllowed }) => {
          const { data } = await replaceContentTypesAllowed({
            variables: {
              contentTypesAllowed,
            },
          })
          if (data) {
            toast({
              status: 'success',
              description: 'Content types allowed updated.',
            })
          }
        }}
      >
        <CheckboxGroup
          name="contentTypesAllowed"
          data-testid="content-type-options"
        >
          {getContentOptions(variant).map((contentType) => [
            <CheckboxGroupOption
              data-testid="content-option"
              value={contentType.value}
              key={contentType.value}
            >
              {contentType.label}
            </CheckboxGroupOption>,
          ])}
        </CheckboxGroup>
        <SubmitButton
          alignSelf={'flex-start'}
          isLoading={replaceContentTypesLoading}
          data-testid="submit-content-type-allowed-form-button"
          event="Content Types Allowed Updated"
        >
          Submit
        </SubmitButton>
      </Form>
    </Stack>
  )
}
