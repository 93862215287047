import { ComponentProps, ReactNode } from 'react'
import { MobileNav } from './MobileNav'
import { TopNav } from './TopNav'
import { LeftNav } from './LeftNav'
import { AppRoute } from '../../types'
import { Flex, Box } from '../../Chakra'
import { Outlet } from 'react-router-dom'
import { useBounty } from '../BountyProvider'

export type AppWrapperProps = {
  navItems?: AppRoute[]
  navFooterComponent?: ReactNode
  logoLinkTo?: string
  showBetaLogo?: boolean
  showTopNav?: boolean
  mobileNavProps?: Partial<
    Omit<
      ComponentProps<typeof MobileNav>,
      'mainItems' | 'secondaryItems' | 'mobileNavHeight'
    >
  >
  topNavProps?: Partial<Omit<ComponentProps<typeof TopNav>, 'topNavHeight'>>
  leftNavProps?: Omit<ComponentProps<typeof LeftNav>, 'items' | 'navFooterItem'>
}

const LEFT_NAV_WIDTH = '250px'

export const AppWrapper = ({
  navItems,
  logoLinkTo = '/',
  showBetaLogo = false,
  mobileNavProps,
  topNavProps,
  navFooterComponent,
}: AppWrapperProps) => {
  const { topNavHeight, showTopNav, mobileNavHeight } = useBounty()
  return (
    <>
      <MobileNav
        display={['block', 'block', 'block', 'none']}
        mainItems={navItems}
        logoLinkTo={logoLinkTo}
        showBetaLogo={showBetaLogo}
        mobileNavHeight={mobileNavHeight}
        bottomNavMenuComponent={navFooterComponent}
        topNavMenuComponent={
          topNavProps?.leftComponent || topNavProps?.centerComponent ? (
            <Box>
              <Flex justifyContent="center" mb="6">
                {topNavProps?.leftComponent}
              </Flex>
              <Flex justifyContent="center">
                {topNavProps?.centerComponent}
              </Flex>
            </Box>
          ) : null
        }
        {...mobileNavProps}
      />
      {showTopNav && (
        <TopNav
          display={['none', 'none', 'none', 'flex']}
          position="sticky"
          alignSelf={'flex-start'}
          top="0"
          backgroundColor="white"
          zIndex="10"
          topNavHeight={topNavHeight}
          {...topNavProps}
        />
      )}

      <Box position="relative">
        <Box position="fixed" top="0" left="0" bottom="0">
          {navItems && (
            <LeftNav
              logoLinkTo={logoLinkTo}
              showBetaLogo={showBetaLogo}
              width={LEFT_NAV_WIDTH}
              display={['none', 'none', 'none', 'block']}
              top={showTopNav ? topNavHeight : 0}
              zIndex="10"
              footerComponent={navFooterComponent}
              items={navItems}
            />
          )}
        </Box>
        <Box ml={['0', '0', '0', LEFT_NAV_WIDTH]}>
          <Outlet />
        </Box>
      </Box>
    </>
  )
}
