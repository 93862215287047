import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import {
  List,
  ListItem,
  Page,
  Tile,
  VStack,
  Box,
  Input,
  CloseIcon,
  useToast,
} from '@bounty/brands-design-system'
import { Routes, Route } from 'react-router-dom'
import {
  Flex,
  Text,
  PageSkeleton,
  PageHeader,
  Button,
} from '@bounty/brands-design-system'
import { RetailBriefDrawer } from './RetailBriefDrawer'
import {
  RetailDocument,
  UpdateRetailLocatorUrlDocument,
  UpdateRetailLocationsDocument,
} from '../../generated/backendGraphql'
import { useEffect, useState } from 'react'
import { debounce } from 'lodash'

export const Retail = () => {
  const toast = useToast()
  const { data, loading } = useQueryBackend(RetailDocument)

  const [retailLocatorUrl, setRetailLocatorUrl] = useState<
    string | null | undefined
  >()

  const [retailerInputValue, setRetailerInputValue] = useState<
    string | null | undefined
  >()

  const [retailerLocationsList, setRetailerLocationsList] = useState<
    readonly string[] | []
  >([])

  const [updateRetailLocatorUrl, { loading: updateRetailLocatorUrlLoading }] =
    useMutationBackend(UpdateRetailLocatorUrlDocument, {
      refetchQueries: [RetailDocument],
    })

  const [updateRetailLocations, { loading: updateRetailLocationsLoading }] =
    useMutationBackend(UpdateRetailLocationsDocument, {
      refetchQueries: [RetailDocument],
    })

  useEffect(() => {
    if (data?.currentStore) {
      setRetailLocatorUrl(data?.currentStore.retailLocatorUrl)
      setRetailerLocationsList(data?.currentStore.retailLocations)
    }
  }, [data?.currentStore, retailerLocationsList.length])

  if (loading || !data) return <PageSkeleton />

  return (
    <Page
      addBottomSpacing={false}
      fixedHeight="all"
      fluid
      width="100%"
      data-testid="retail-page"
      name="Retailers"
      paddingBottom="3rem"
    >
      <Flex justifyContent="space-between" alignItems="flex-start">
        <PageHeader
          title="Retailers"
          description={
            <Text as="span">
              Show creators where they can find your product!
            </Text>
          }
        />
      </Flex>
      <VStack alignItems={'flex-start'} width="100%" spacing={8}>
        <Tile mt={8} px={6} py={8} width="100%" maxW={500}>
          <Box width="320px">
            <Text fontSize={'md'} fontWeight={'semibold'} mb="1">
              Store Locator URL{' '}
            </Text>
            <Box mb="2">
              <Input
                isRequired={false}
                defaultValue={retailLocatorUrl || ''}
                onChange={debounce(
                  (event) => setRetailLocatorUrl(event?.target?.value),
                  500,
                )}
              />
            </Box>
            <Button
              isDisabled={!retailLocatorUrl}
              isLoading={updateRetailLocatorUrlLoading}
              event="Save Store Locator URL"
              onClick={() => handleUpdateRetailLocatorUrl()}
            >
              Save URL
            </Button>
          </Box>
        </Tile>
        <Tile mt={8} px={6} py={8} width="100%" maxW={500}>
          <Box width="320px">
            <Text fontSize={'md'} fontWeight={'semibold'} mb="1">
              Retailer List{' '}
              <Text as="span" fontWeight={'normal'}>
                (Names, not locations)
              </Text>
            </Text>
            <Box mb="2">
              <Input
                value={retailerInputValue || ''}
                isRequired={false}
                placeholder="Retailer Name"
                onChange={(event) =>
                  setRetailerInputValue(event?.target?.value)
                }
              />
            </Box>
            <Button
              isLoading={updateRetailLocationsLoading}
              isDisabled={!retailerInputValue}
              onClick={() =>
                retailerInputValue &&
                handleAddRetailerLocation(retailerInputValue)
              }
              event="Save Store Locator URL"
            >
              Add Retailer
            </Button>
            <Flex>
              {retailerLocationsList?.length === 0 ? (
                <Text mt="2">
                  No retail locations found. Please contact support to add them.
                </Text>
              ) : (
                <VStack spacing={4} mt={2} alignItems="flex-start" width="full">
                  <List width="full">
                    {retailerLocationsList?.map((retailersItem, index) => (
                      <ListItem
                        borderWidth="1px"
                        borderColor="neutral.200"
                        key={index}
                        mt={1}
                        borderRadius="md"
                        height="10"
                        alignItems="center"
                        display="flex"
                        padding="3"
                        position="relative"
                        paddingRight="14"
                      >
                        <Text>{retailersItem}</Text>
                        <Flex
                          position="absolute"
                          right="0"
                          bg="neutral.200"
                          height="10"
                          width="14"
                          alignItems="center"
                          justifyContent="center"
                          cursor="pointer"
                          onClick={() =>
                            handleDeleteRetailerLocation(retailersItem)
                          }
                        >
                          <CloseIcon fontSize="16px" fontWeight="800" />
                        </Flex>
                      </ListItem>
                    ))}
                  </List>
                </VStack>
              )}
            </Flex>
          </Box>
        </Tile>
      </VStack>
      <Routes>
        <Route
          path="brief"
          element={<RetailBriefDrawer rootPath="/retail" />}
        />
      </Routes>
    </Page>
  )

  function handleUpdateRetailLocatorUrl() {
    if (retailLocatorUrl) {
      updateRetailLocatorUrl({
        variables: {
          input: {
            retailLocatorUrl,
          },
        },
      })
        .then(() => {
          toast({
            status: 'success',
            description: 'Store Locator URL Successfully Updated!',
          })
        })
        .catch(() => {
          toast({
            status: 'success',
            description: 'Error Occurred While Updating Store Locator URL',
          })
        })
    }
  }

  function handleAddRetailerLocation(value: string) {
    if (retailLocatorUrl) {
      updateRetailLocations({
        variables: {
          input: {
            retailLocations: [...retailerLocationsList, value],
          },
        },
      })
        .then(() => {
          toast({
            status: 'success',
            description: `Successfully Added ${value} As New Retailer Location!`,
          })
        })
        .catch(() => {
          toast({
            status: 'success',
            description: 'Error Occurred While Updating Retailer List',
          })
        })
        .finally(() => setRetailerInputValue(''))
    }
  }
  function handleDeleteRetailerLocation(value: string) {
    if (retailLocatorUrl) {
      updateRetailLocations({
        variables: {
          input: {
            retailLocations: retailerLocationsList.filter(
              (location) => value !== location,
            ),
          },
        },
      })
        .then(() => {
          toast({
            status: 'success',
            description: `Successfully Deleted ${value} From Retailer Locations!`,
          })
        })
        .catch(() => {
          toast({
            status: 'success',
            description: 'Error Occurred While Updating Retailer List',
          })
        })
    }
  }
}
