import {
  Select,
  Props,
  GroupBase,
  CreatableSelect as CreatableSelectCore,
  CreatableProps,
} from 'chakra-react-select'

export type FancySelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = Props<Option, IsMulti, Group> & {
  selectRef?: any
}

export const FancySelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  selectRef,
  ...rest
}: FancySelectProps<Option, IsMulti, Group>) => {
  return <Select ref={selectRef} {...rest} selectedOptionColor="primary" />
}

export type CreatableSelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = CreatableProps<Option, IsMulti, Group> & {
  selectRef?: any
}

export const CreatableSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  selectRef,
  ...rest
}: FancySelectProps<Option, IsMulti, Group>) => {
  return (
    <CreatableSelectCore
      ref={selectRef}
      {...rest}
      selectedOptionColor="primary"
    />
  )
}
