import * as z from 'zod'
import { CreateOrderRESTAddressPayloadSchema } from './address'

export const CreateOrderGiftOfferVariantSchema = z.object({
  productId: z.number(),
  variantId: z.number(),
  title: z.string(),
  name: z.string(),
})

export type CreateOrderGiftOfferVariant = z.infer<
  typeof CreateOrderGiftOfferVariantSchema
>

export const CreateOrderGiftOfferSchema = z.object({
  address: CreateOrderRESTAddressPayloadSchema,
  giftOfferId: z.string().uuid(),
  variants: z.array(CreateOrderGiftOfferVariantSchema).min(1),
})

export type CreateOrderGiftOffer = z.infer<typeof CreateOrderGiftOfferSchema>
