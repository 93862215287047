import { FC } from 'react'
import {
  Box,
  Flex,
  useToast,
  useFastForm,
  Skeleton,
  VStack,
  Text,
  Link,
} from '@bounty/brands-design-system'
import {
  GiftTableDocument,
  ProspectsTableDocument,
  CreateStoreGiftBountyDocument,
  StoreGiftBountyDocument,
} from '../../generated/backendGraphql'
import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import {
  DEFAULT_MINIMUM_BOUNTY_PAYOUT,
  OFFER_EXPIRATION_DAYS_DEFAULT,
} from '@bounty/constants'
import { createStoreGiftBountyInputSchema } from '@bounty/schemas'

export type GiftBountyFormProps = {
  onSubmitSuccess: () => void
}

export const GiftBountyForm: FC<GiftBountyFormProps> = ({
  onSubmitSuccess,
}) => {
  const toast = useToast()
  const { data, loading: StoreGiftDataLoading } = useQueryBackend(
    StoreGiftBountyDocument,
  )
  const { Form, Input, NumberInput, SubmitButton, Select } = useFastForm({
    schema: createStoreGiftBountyInputSchema,
    defaultValues: {
      name: '',
      offerExpirationDays: OFFER_EXPIRATION_DAYS_DEFAULT,
      minBountyPayment: DEFAULT_MINIMUM_BOUNTY_PAYOUT,
      briefId: '',
    },
  })
  const [createStoreGiftBounty, { loading }] = useMutationBackend(
    CreateStoreGiftBountyDocument,
    {
      refetchQueries: [
        { query: GiftTableDocument },
        { query: ProspectsTableDocument },
      ],
    },
  )

  if (!data || StoreGiftDataLoading)
    return (
      <VStack>
        <Skeleton height="3rem" width="100%" />
        <Skeleton height="3rem" width="100%" />
        <Skeleton height="3rem" width="100%" />
      </VStack>
    )
  return (
    <Box>
      <Form
        data-testid="bounty-gift-form"
        onSubmit={async (input) => {
          const { data } = await createStoreGiftBounty({
            variables: {
              input,
            },
          })
          if (data) {
            toast({
              status: 'success',
              description: 'New gift created!',
            })
            onSubmitSuccess()
          }
        }}
      >
        <Box>
          <Input
            label="Gift Name"
            name="name"
            placeholder="Gift Name"
            helpText={"Internal only - creators won't see this"}
          />
          <Select
            label="Brief"
            name="briefId"
            placeholder="Briefs"
            options={data.briefs.map((brief) => ({
              value: brief.id,
              label: brief.name,
            }))}
            isRequired
            helpText={
              <Text color="gray.600" size="sm" mb="2">
                Select a brief for the creator or{' '}
                <Link
                  isExternal={false}
                  to="/briefs/new"
                  textDecoration="underline"
                  color="inherit"
                >
                  create one
                </Link>
                .
              </Text>
            }
          />

          <NumberInput
            label="Offer Expiration (Days)"
            name="offerExpirationDays"
            placeholder="30"
          />

          <NumberInput
            label="Guaranteed Payout ($)"
            name="minBountyPayment"
            helpText="Minimum payout for this Bounty gift"
            placeholder="25"
          />

          <Flex justifyContent="flex-end">
            <SubmitButton
              event="Gift Bounty Form Submitted"
              data-testid="gift-form-button"
              isLoading={loading}
            >
              Save
            </SubmitButton>
          </Flex>
        </Box>
      </Form>
    </Box>
  )
}
