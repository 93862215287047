import { SVGProps } from 'react'
import { Icon, IconProps } from '@chakra-ui/react'

export type LeadingRawProps = SVGProps<SVGSVGElement>

export const LeadingRaw = ({ ...rest }: LeadingRawProps) => {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15 12.4999C15.8288 12.4999 16.6237 12.1707 17.2097 11.5846C17.7958 10.9986 18.125 10.2037 18.125 9.37493C18.125 8.54613 17.7958 7.75127 17.2097 7.16522C16.6237 6.57917 15.8288 6.24993 15 6.24993H11.875C11.875 6.24993 7.61719 6.24993 3.52344 2.82024C3.43238 2.74489 3.32183 2.69691 3.20461 2.68185C3.08738 2.66679 2.96829 2.68528 2.86115 2.73517C2.75401 2.78506 2.66321 2.86431 2.59929 2.96372C2.53536 3.06313 2.50094 3.17862 2.5 3.29681V15.4531C2.50094 15.5712 2.53536 15.6867 2.59929 15.7861C2.66321 15.8856 2.75401 15.9648 2.86115 16.0147C2.96829 16.0646 3.08738 16.0831 3.20461 16.068C3.32183 16.053 3.43238 16.005 3.52344 15.9296C7.61719 12.4999 11.875 12.4999 11.875 12.4999H15Z"
        stroke="#73BD4E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const LeadingIcon = (props: IconProps) => (
  <Icon as={LeadingRaw} {...props} />
)
