import { useState } from 'react'
import { Outlet, Route, Routes } from 'react-router'

import {
  Button,
  Flex,
  Link as RouterLink,
  Page,
  PageHeader,
  PageHeaderActions,
  PageHeaderPrimaryAction,
  PageSkeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
} from '@bounty/brands-design-system'

import { useQueryBackend } from '../../apollo/backend/hooks'
import { LearnMoreLink } from '../../components/LearnMoreLink/LearnMoreLink'
import { GetStoreIdAndNameDocument } from '../../generated/backendGraphql'
import { BountyNetworkTab } from './BountyNetwork/BountyNetworkTab'
import { OfferCreatorsGiftModal } from './components/OfferCreatorsGiftModal'
import { CreatorDetailsDrawer } from './CreatorDetailsDrawer/CreatorDetailsDrawer'
import { CreatorDetailsDrawerNetwork } from './CreatorDetailsDrawerNetwork/CreatorDetailsDrawerNetwork'
import { CreatorsTable } from './CreatorsTable'
import { CreatorsTableAllSignups } from './CreatorsTableAllSignups'
import { CreatorsTableFavorited } from './CreatorsTableFavorited'
import { InviteCreatorsDrawer } from './Invites/InviteCreatorsDrawer/InviteCreatorsDrawer'

export type CreatorsProps = unknown

export const Creators = () => {
  const { data, loading } = useQueryBackend(GetStoreIdAndNameDocument)

  // bulk offer gift to creators states
  const [tabIndex, setTabIndex] = useState(0)
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([])
  const { isOpen, onClose, onOpen } = useDisclosure()

  if (loading || !data) return <PageSkeleton />

  return (
    <Page
      name="Creators"
      fixedHeight="all"
      fluid
      addBottomSpacing={false}
      paddingBottom="3rem"
      width="100%"
    >
      <PageHeader
        title="Creators"
        description={
          <Text as="span">
            Creators are users, prospects,and anyone else who may create content
            for your brand.{' '}
            <LearnMoreLink href="https://brands.support.bounty.co/hc/en-us/sections/11757761095707-Creators-Content" />
          </Text>
        }
        actions={
          <PageHeaderActions>
            <PageHeaderPrimaryAction
              as={RouterLink}
              isExternal={true}
              href={`https://form.typeform.com/to/NXj9GtEB#shop_id=${data.currentStore.id}x&shop_name=${data.currentStore.name}`}
              event={'Create new Bounty Clicked'}
            >
              Create new Bounty
            </PageHeaderPrimaryAction>
          </PageHeaderActions>
        }
      />
      <Routes>
        <Route
          path="/"
          element={
            <Tabs isLazy height="100%" index={tabIndex} onChange={setTabIndex}>
              <TabList justifyContent="space-between">
                <Flex>
                  <Tooltip
                    label={
                      'Creators are users or customers who are already part of your community on Bounty.'
                    }
                  >
                    <Tab>Your Creators</Tab>
                  </Tooltip>
                  <Tab>Signups</Tab>
                  <Tab>Favorites</Tab>
                  <Tooltip
                    label={
                      "Find new creators to work with from within Bounty's network."
                    }
                  >
                    <Tab>Bounty Network</Tab>
                  </Tooltip>
                </Flex>
                {tabIndex === 2 && (
                  <Button
                    event="Offer Gift Clicked"
                    mt="-1"
                    onClick={onOpen}
                    isDisabled={selectedUserIds.length === 0}
                  >
                    Offer Gift
                  </Button>
                )}
              </TabList>
              <TabPanels height="100%">
                <TabPanel height="100%">
                  <CreatorsTable />
                  <Outlet />
                </TabPanel>
                <TabPanel height="100%">
                  <CreatorsTableAllSignups />
                  <Outlet />
                </TabPanel>
                <TabPanel height="100%">
                  <CreatorsTableFavorited
                    setSelectedUserIds={setSelectedUserIds}
                  />
                  <Outlet />
                </TabPanel>
                <TabPanel height="100%">
                  <BountyNetworkTab />
                  <Outlet />
                </TabPanel>
              </TabPanels>
              <OfferCreatorsGiftModal
                isOpen={isOpen}
                onClose={onClose}
                userIds={selectedUserIds}
              />
            </Tabs>
          }
        >
          <Route
            path="details/:userId/*"
            element={<CreatorDetailsDrawer rootPath="/creators" />}
          />
          <Route
            path="details-network/:userId/*"
            element={<CreatorDetailsDrawerNetwork rootPath="/creators" />}
          />
          <Route
            path="invite"
            element={<InviteCreatorsDrawer rootPath="/creators" />}
          />
        </Route>
      </Routes>
    </Page>
  )
}
