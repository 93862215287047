import { getStoreEmbeds, isScriptTagActivated } from '@bounty/common'
import { EmbedConfigEntry } from '@bounty/types'
import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import {
  AddScriptTagForLocationDocument,
  ManualInstallDocument,
  RemoveScriptTagForLocationDocument,
} from '../../generated/backendGraphql'
import {
  Button,
  Flex,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
} from '@bounty/brands-design-system'
import { useRef } from 'react'
import { IndividualEmbedTogglerSwitch } from './IndividualEmbedToggler'

export const InstallSnippetToggleButton = ({
  code,
  prettyName,
  showToggle = true,
}: {
  code: EmbedConfigEntry['code']
  prettyName: string
  showToggle?: boolean
}) => {
  const cancelRef = useRef(null)
  const { data, loading: queryLoading } = useQueryBackend(ManualInstallDocument)
  const { isOpen, onClose, onOpen } = useDisclosure()
  const [addScriptTag, { loading: addScriptTagLoading }] = useMutationBackend(
    AddScriptTagForLocationDocument,
  )
  const [removeScriptTag, { loading: removeScriptTagLoading }] =
    useMutationBackend(RemoveScriptTagForLocationDocument)

  const maybeEmbeds = data && getStoreEmbeds(data.currentStore)
  const isActivated = maybeEmbeds && isScriptTagActivated(maybeEmbeds, code)

  const handleSnippetActivate = async () => {
    if (queryLoading || isActivated) {
      return
    }

    addScriptTag({
      variables: {
        code,
      },
    })
  }

  return (
    <Flex flexDir={'column'}>
      <Flex flexDir={'column'}>
        <Button
          isDisabled={isActivated || addScriptTagLoading}
          isLoading={addScriptTagLoading}
          flexShrink={0}
          data-testid="snippet-install-activate-button"
          onClick={handleSnippetActivate}
          event="Manual Install Product Placement Activate Script Tag Clicked"
          mr="4"
          mb="2"
          size={'lg'}
        >
          {isActivated ? 'Installed' : 'Install Now'}
        </Button>
        {isActivated ? (
          <Button
            isLoading={addScriptTagLoading || removeScriptTagLoading}
            flexShrink={0}
            data-testid="snippet-install-deactivate-button"
            onClick={onOpen}
            variant="link"
            size="md"
            event="Manual Install Product Placement Deactivate Clicked"
            alignSelf={'flex-start'}
          >
            Uninstall
          </Button>
        ) : null}

        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Uninstall Widget
              </AlertDialogHeader>

              <AlertDialogBody>
                Uninstalling the widget will remove the script tag for your site
                if it is not using app blocks. This will cause Bounty to no
                longer show up on your website.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  event="Manual Install Product Placement Deactivate Modal Cancel Clicked"
                  ref={cancelRef}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Button
                  event="Manual Install Product Placement Deactivate Modal Delete Clicked"
                  colorScheme="error"
                  isLoading={removeScriptTagLoading}
                  onClick={async () => {
                    if (isActivated) {
                      await removeScriptTag({
                        variables: {
                          code,
                        },
                      })

                      onClose()
                    }
                  }}
                  ml={3}
                >
                  Uninstall
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Flex>

      {!isActivated && showToggle ? (
        <IndividualEmbedTogglerSwitch prettyName={prettyName} code={code} />
      ) : null}
    </Flex>
  )
}
