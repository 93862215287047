import { defineStyle, defineStyleConfig } from '@chakra-ui/react'
import { getSemanticColorValue } from '../foundations'

const outline = defineStyle({
  field: {
    borderColor: 'neutral.300',
    _hover: {
      borderColor: 'neutral.400',
    },
    _invalid: {
      borderColor: 'error.500',
      boxShadow: `0 0 0 1px ${getSemanticColorValue('error.500')}`,
    },
    _focusVisible: {
      zIndex: 1,
      borderColor: 'primary.500',
      boxShadow: `0 0 0 1px ${getSemanticColorValue('primary.500')}`,
    },
  },
})

export const numberInputTheme = defineStyleConfig({
  variants: {
    outline,
  },
})
