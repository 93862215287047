import { useMemo, useState } from 'react'
import { CellRendererSelectorFunc, ColDef } from 'ag-grid-community'
import { useQueryBackend } from '../../apollo/backend/hooks'
import {
  GiftTableDocument,
  GiftTableQuery,
  GiftFormDocument,
  GiftFormQuery,
} from '../../generated/backendGraphql'
import {
  Box,
  Link,
  Alert,
  AlertTitle,
  AlertDescription,
  HStack,
  Text,
  Grid,
  Skeleton,
  Switch,
  Button,
} from '@bounty/brands-design-system'
import { isEmpty } from 'lodash'
import { giftType } from '@bounty/constants'
import { useNavigate } from 'react-router-dom'

export type GiftItem = GiftTableQuery['storeGifts'][0]

type CellRendererParams = Parameters<CellRendererSelectorFunc<GiftItem>>[0]

export type GiftsTableProps = unknown

export const GiftsTable = () => {
  const { data: { storeGifts = [], briefs = [] } = {}, loading } =
    useQueryBackend(GiftTableDocument)
  const { data } = useQueryBackend(GiftFormDocument)
  const navigate = useNavigate()
  const [showDisabledGiftToggleState, setShowDisabledGiftToggleState] =
    useState<boolean>(false)

  const maybeFindProduct = (
    products: GiftFormQuery['enabledStoreProducts'],
    id: string,
  ) => products.find((p) => p.productId === id)

  const columnDefs = useMemo(
    (): ColDef<GiftItem>[] => [
      {
        headerName: 'Name',
        field: 'name',
      },
      {
        headerName: 'Type',
        field: 'type',
        valueFormatter: (x) => (x.data?.type ? giftType[x.data.type] : '--'),
      },
      {
        headerName: 'Products',
        field: 'lineItems',
        valueGetter: (params) => {
          if (!params.data) return '--'
          switch (params.data.__typename) {
            case 'StoreGiftProduct': {
              const productsArr = params.data.lineItems.map(
                (item: { productId: string; quantity: number }) => {
                  const foundProduct = maybeFindProduct(
                    data?.enabledStoreProducts ?? [],
                    item.productId,
                  )
                  return foundProduct?.productName
                },
              )
              if (!productsArr || productsArr.length === 0) return '--'
              return productsArr.join(' ')
            }
            case 'StoreGiftBounty': {
              if (
                !params.data.bountyProductIds ||
                params.data.bountyProductIds.length === 0
              ) {
                return '--'
              }
              const productsArr = params.data.bountyProductIds?.map(
                (productId: string) => {
                  const foundProduct = maybeFindProduct(
                    data?.enabledStoreProducts ?? [],
                    productId,
                  )
                  return foundProduct?.productName
                },
              )
              if (!productsArr || productsArr.length === 0) return '--'
              return productsArr.join(' ')
            }
          }
        },
        cellRenderer: (params: CellRendererParams) => {
          if (!params.data) return '--'
          switch (params.data.__typename) {
            case 'StoreGiftProduct': {
              const productsArr = params.data.lineItems.map(
                (item: { productId: string; quantity: number }) => {
                  const foundProduct = maybeFindProduct(
                    data?.enabledStoreProducts ?? [],
                    item.productId,
                  )
                  return <Text>{foundProduct?.productName}</Text>
                },
              )
              if (!productsArr || productsArr.length === 0) return '--'
              return (
                <>
                  {productsArr.map((product, i) => (
                    <HStack key={i} spacing={0}>
                      {product}
                      {i < productsArr.length - 1 ? (
                        <Text as="span">,&nbsp;</Text>
                      ) : null}
                    </HStack>
                  ))}
                </>
              )
            }
            case 'StoreGiftBounty': {
              if (
                !params.data.bountyProductIds ||
                params.data.bountyProductIds.length === 0
              ) {
                return '--'
              }
              const productsArr = params.data.bountyProductIds?.map(
                (productId: string) => {
                  const foundProduct = maybeFindProduct(
                    data?.enabledStoreProducts ?? [],
                    productId,
                  )
                  return <Text>{foundProduct?.productName}</Text>
                },
              )
              if (!productsArr || productsArr.length === 0) return '--'
              return (
                <>
                  {productsArr.map((product, i) => (
                    <HStack key={i} spacing={0}>
                      {product}
                      {i < productsArr.length - 1 ? (
                        <Text as="span">,&nbsp;</Text>
                      ) : null}
                    </HStack>
                  ))}
                </>
              )
            }
          }
        },
      },
      {
        headerName: 'Min. Payout',
        field: 'paymentSnapshot',
        valueFormatter: (x) => {
          if (x.data?.__typename === 'StoreGiftProduct') {
            if (
              x.data.productPaymentSnapshot?.minBountyPayment &&
              x.data.productPaymentSnapshot.productGiftConfig?.hasMinPayment
            ) {
              return `$${x.data.productPaymentSnapshot?.minBountyPayment}`
            }
          }
          if (x.data?.__typename === 'StoreGiftBounty') {
            if (x.value) return `$${x.value?.minBountyPayment}`
          }
          return '--'
        },
      },
      {
        headerName: 'Brief',
        field: 'brief',
        filter: 'agDateColumnFilter',
        cellRenderer: (params: CellRendererParams) => {
          return (
            briefs.find((brief) => brief.id === params.data?.briefId)?.name ??
            '--'
          )
        },
      },
      {
        headerName: 'Created At',
        field: 'createdAt',
        filter: 'agDateColumnFilter',
        cellRenderer: (params: CellRendererParams) => {
          return new Date(params.data?.createdAt).toLocaleDateString()
        },
      },
    ],
    [briefs, data?.enabledStoreProducts],
  )

  const filteredGifts = storeGifts.filter((g) => {
    if (!showDisabledGiftToggleState) {
      return g.enabled
    }
    return true
  })

  return loading || !data ? (
    <Skeleton height="10" width="100%" mb="18" />
  ) : isEmpty(storeGifts) ? (
    <Alert
      bg="deepPurple.50"
      border="1px solid"
      borderColor="deepPurple.300"
      p="4"
      mb="4"
    >
      <Box>
        <AlertTitle fontSize="xl" fontWeight="semibold" mb="2">
          No gifts configured
        </AlertTitle>
        <AlertDescription fontSize="md">
          <Link isExternal={false} to="/gifts/new">
            Add Gift
          </Link>
        </AlertDescription>
      </Box>
    </Alert>
  ) : (
    <>
      <HStack justifyContent={'flex-end'}>
        <Switch
          isChecked={showDisabledGiftToggleState}
          onChange={(e) =>
            setShowDisabledGiftToggleState(!showDisabledGiftToggleState)
          }
        ></Switch>
        <Text>Show Disabled Gifts</Text>
      </HStack>
      <Grid<GiftItem>
        rowStyle={{ cursor: 'pointer' }}
        onRowClicked={(e) => navigate(`./details/${e.data?.id}`)}
        rowData={[...filteredGifts]}
        columnDefs={columnDefs}
      />
    </>
  )
}
