export type RemoteConfigSnippetSetting =
  | {
      label: string
      id: string
      type: 'checkbox'
      default: boolean
      isCSSProperty: boolean
    }
  | {
      label: string
      id: string
      type: 'text'
      default: string
      isCSSProperty: boolean
    }
  | {
      label: string
      id: string
      type: 'emoji'
      default: string
      isCSSProperty: boolean
    }
  | {
      label: string
      id: string
      imageLookupKey: string
      type: 'image'
      default: string | null
      isCSSProperty: boolean
    }
  | {
      label: string
      id: string
      type: 'color'
      default: string
      isCSSProperty: boolean
    }

export type RemoveConfigSnippetSettingValue =
  RemoteConfigSnippetSetting['default']

export type SnippetPropConfigurationObject = {
  embedConfiguration?: any | null | undefined
  /**
   * Injected snippet props are props that come from:
   *
   * 1. Somewhere in the document markup for a snippet like a data-prop or script prop tag
   * 2. App blocks
   */
  injectedSnippetProps?: any | null | undefined
}

export const BOUNTY_PRODUCT_SNIPPET_REMOTE_CONFIG_SETTINGS: RemoteConfigSnippetSetting[] =
  [
    {
      label: 'Show Branding on Widget',
      id: 'showBrandingOnWidget',
      type: 'checkbox',
      default: true,
      isCSSProperty: false,
    },
    {
      label: 'CTA Full Width',
      id: 'ctaFullWidth',
      type: 'checkbox',
      default: false,
      isCSSProperty: false,
    },
    {
      label: 'CTA Spacing Top',
      id: 'ctaSpacingTop',
      type: 'text',
      default: '0px',
      isCSSProperty: true,
    },
    {
      label: 'CTA Spacing Left',
      id: 'ctaSpacingLeft',
      type: 'text',
      default: '0px',
      isCSSProperty: true,
    },
    {
      label: 'CTA Spacing Bottom',
      id: 'ctaSpacingBottom',
      type: 'text',
      default: '0px',
      isCSSProperty: true,
    },
    {
      label: 'CTA Spacing Right',
      id: 'ctaSpacingRight',
      type: 'text',
      default: '0px',
      isCSSProperty: true,
    },
    {
      label: 'CTA Font Size',
      id: 'ctaFontSize',
      type: 'text',
      default: '16px',
      isCSSProperty: true,
    },
    {
      label: 'CTA Body Font Color',
      id: 'ctaBodyFontColor',
      type: 'color',
      default: '#1C1D1F',
      isCSSProperty: true,
    },
    {
      label: 'CTA Border Radius',
      id: 'ctaBorderRadius',
      type: 'text',
      default: '5px',
      isCSSProperty: true,
    },
    {
      label: 'CTA Background Color',
      id: 'ctaBackgroundColor',
      type: 'color',
      default: 'rgba(255, 255, 255, 0)',
      isCSSProperty: true,
    },
    {
      label: 'CTA Background Hover',
      id: 'ctaBackgroundHover',
      type: 'color',
      default: 'rgba(0,0,0,0.1)',
      isCSSProperty: true,
    },
    {
      label: 'CTA Border Color',
      id: 'ctaBorderColor',
      type: 'color',
      default: 'rgba(0, 0, 0, 0.15)',
      isCSSProperty: true,
    },
  ]

export const BOUNTY_THANK_YOU_SNIPPET_REMOTE_CONFIG_SETTINGS: RemoteConfigSnippetSetting[] =
  [
    {
      label: 'Show Branding on Widget',
      id: 'showBrandingOnWidget',
      type: 'checkbox',
      default: true,
      isCSSProperty: false,
    },
    {
      label: 'CTA Image',
      id: 'imgLink',
      type: 'image',
      imageLookupKey: 'thank-you-sign-up-cta-primary',
      default:
        'https://shop-assets.bounty.co/defaults/thank-you-sign-up-cta-primary',
      isCSSProperty: false,
    },
    {
      label: 'CTA Floaty Image',
      id: 'thankYouFloatyImage1ImgLink',
      type: 'image',
      imageLookupKey: 'thank-you-sign-up-cta-primary-floaty-image-1',
      default: null,
      isCSSProperty: false,
    },
    {
      label: 'Hero Floaty Text 1',
      id: 'heroFloatyText1',
      type: 'emoji',
      default: '💯',
      isCSSProperty: false,
    },
    {
      label: 'CTA Spacing Top',
      id: 'ctaSpacingTop',
      type: 'text',
      default: '0px',
      isCSSProperty: true,
    },
    {
      label: 'CTA Spacing Left',
      id: 'ctaSpacingLeft',
      type: 'text',
      default: '0px',
      isCSSProperty: true,
    },
    {
      label: 'CTA Spacing Bottom',
      id: 'ctaSpacingBottom',
      type: 'text',
      default: '0px',
      isCSSProperty: true,
    },
    {
      label: 'CTA Spacing Right',
      id: 'ctaSpacingRight',
      type: 'text',
      default: '0px',
      isCSSProperty: true,
    },
    {
      label: 'CTA Background Color',
      id: 'ctaBackgroundColor',
      type: 'color',
      default: 'rgba(255, 255, 255, 0)',
      isCSSProperty: true,
    },
    {
      label: 'CTA Header Font Color',
      id: 'ctaHeaderFontColor',
      type: 'color',
      default: '#1C1D1F',
      isCSSProperty: true,
    },
    {
      label: 'CTA Body Font Color',
      id: 'ctaBodyFontColor',
      type: 'color',
      default: '#1C1D1F',
      isCSSProperty: true,
    },
    {
      label: 'CTA Border Radius',
      id: 'ctaBorderRadius',
      type: 'text',
      default: '5px',
      isCSSProperty: true,
    },
    {
      label: 'CTA Border Color',
      id: 'ctaBorderColor',
      type: 'color',
      default: '#d9d9d9',
      isCSSProperty: true,
    },
    {
      label: 'Button Font Color',
      id: 'buttonFontColor',
      type: 'color',
      default: '#ffffff',
      isCSSProperty: true,
    },
    {
      label: 'Primary Color',
      id: 'primaryColor',
      type: 'color',
      default: '#000000',
      isCSSProperty: true,
    },
    {
      label: 'CTA Button Text',
      id: 'buttonText',
      type: 'text',
      default: 'Activate!',
      isCSSProperty: false,
    },
  ]

export const BOUNTY_LANDING_PAGE_SNIPPET_REMOTE_CONFIG_SETTINGS: RemoteConfigSnippetSetting[] =
  [
    {
      label: 'Show Branding on Widget',
      id: 'showBrandingOnWidget',
      type: 'checkbox',
      default: true,
      isCSSProperty: false,
    },
    {
      label: 'Hero Image',
      id: 'heroImgLink',
      imageLookupKey: 'landing-page-hero-image',
      type: 'image',
      default: 'https://shop-assets.bounty.co/defaults/landing-page-hero-image',
      isCSSProperty: false,
    },
    {
      label: 'Hero Floaty Image 1',
      id: 'heroFloatyImage1ImgLink',
      imageLookupKey: 'landing-page-hero-floaty-image-1',
      type: 'image',
      default:
        'https://shop-assets.bounty.co/defaults/landing-page-hero-floaty-image-1',
      isCSSProperty: false,
    },
    {
      label: 'Hero Floaty Image 2',
      id: 'heroFloatyImage2ImgLink',
      imageLookupKey: 'landing-page-hero-floaty-image-2',
      type: 'image',
      default:
        'https://shop-assets.bounty.co/defaults/landing-page-hero-floaty-image-2',
      isCSSProperty: false,
    },
    {
      label: 'Primary Color',
      id: 'primaryColor',
      type: 'color',
      default: '#000000',
      isCSSProperty: true,
    },
    {
      label: 'Accent Color',
      id: 'accentColor',
      type: 'color',
      default: '#bbfbc9',
      isCSSProperty: true,
    },
    {
      label: 'Block Background Color',
      id: 'blockBackgroundColor',
      type: 'color',
      default: '#ffffff',
      isCSSProperty: true,
    },
    {
      label: 'Hero Floaty Text 1',
      id: 'heroFloatyText1',
      type: 'text',
      default: '$182',
      isCSSProperty: false,
    },
    {
      label: 'Hero Floaty Text 2',
      id: 'heroFloatyText2',
      type: 'text',
      default: '21.8K',
      isCSSProperty: false,
    },
  ]
