import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerProps,
  DrawerCloseButton,
} from '@bounty/brands-design-system'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { CreatorDetailsNetworkOfferGift } from './CreatorDetailsNetworkOfferGift'
import { CreatorDetailsOverview } from './CreatorDetailsOverviewNetwork'

export type CreatorDetailsDrawerNetworkProps = Omit<
  DrawerProps,
  'children' | 'isOpen' | 'onClose'
> & {
  rootPath: string
}

export const CreatorDetailsDrawerNetwork = ({
  rootPath,
  ...rest
}: CreatorDetailsDrawerNetworkProps) => {
  const navigate = useNavigate()

  return (
    <Drawer size="lg" isOpen onClose={() => navigate(rootPath)} {...rest}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <Routes>
          <Route path="/" element={<CreatorDetailsOverview />} />
          <Route
            path="give-gift"
            element={<CreatorDetailsNetworkOfferGift />}
          />
        </Routes>
      </DrawerContent>
    </Drawer>
  )
}
