import { INVITES_CSV_DEFAULT } from '@bounty/constants'
import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Text,
} from '@bounty/brands-design-system'
import { useRef } from 'react'
import { handleFileUpload } from '../../utils/handleFileUpload'
interface UploadCreatorsCsvProps {
  updateInvitesCsv: (csv: string) => void
}

export const UploadCreatorsCsv = ({
  updateInvitesCsv,
}: UploadCreatorsCsvProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleDownloadCsv = async () => {
    const encodedUri = encodeURI(INVITES_CSV_DEFAULT)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('target', '_blank')
    link.setAttribute('download', 'bounty_invite_creators.csv')
    document.body.appendChild(link)

    link.click()
  }

  return (
    <Flex
      mb="8"
      p="7"
      border="1px solid"
      borderColor="gray.200"
      borderRadius="md"
      gridGap={4}
      align="center"
    >
      <Box flexGrow={1}>
        <Heading as="h4" size="sm" mb="2">
          You can use the table below or upload a CSV to speed things up.
        </Heading>
        <Text size="md" color="gray.700" lineHeight="1.2">
          Download{' '}
          <Button
            variant="link"
            onClick={() => handleDownloadCsv()}
            fontSize="inherit"
            fontWeight="inherit"
            letterSpacing="inherit"
            color="gray.700"
            textDecoration="underline"
            event="Invites Download Creators Template CSV Clicked"
          >
            our CSV template
          </Button>{' '}
          to get started.
        </Text>
      </Box>
      <Button
        onClick={() => inputRef?.current?.click()}
        event="Invites Upload Creators CSV Clicked"
      >
        Upload CSV
      </Button>
      <Input
        type="file"
        accept=".csv"
        display="none"
        name="upload-csv"
        ref={inputRef}
        onChange={(e) => handleFileUpload({ e, updateInvitesCsv, inputRef })}
      />
    </Flex>
  )
}
