import {
  Box,
  Flex,
  useFastForm,
  useToast,
  Skeleton,
} from '@bounty/brands-design-system'
import { UpdateBountyLimitPerMonth } from '@bounty/schemas'
import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import { useEffect } from 'react'
import {
  BountyLimitPerMonthFormDocument,
  UpdateBountyLimitPerMonthDocument,
} from '../../generated/backendGraphql'

export const BountyLimitPerMonthForm = () => {
  const toast = useToast()
  const { data, loading } = useQueryBackend(BountyLimitPerMonthFormDocument)
  const [
    updateBountyLimitPerMonth,
    { loading: updateBountyLimitPerMonthLoading },
  ] = useMutationBackend(UpdateBountyLimitPerMonthDocument)

  const {
    Form,
    NumberInput,
    SubmitButton,
    methods: { setValue },
  } = useFastForm({
    schema: UpdateBountyLimitPerMonth,
    defaultValues: {
      limit: data?.currentStore.bountyLimitPerMonth || 5,
    },
  })

  useEffect(() => {
    if (data?.currentStore.bountyLimitPerMonth) {
      setValue('limit', data.currentStore.bountyLimitPerMonth)
    }
  }, [data?.currentStore.bountyLimitPerMonth, setValue])

  return !data || loading ? (
    <Skeleton height="7" width="310px" />
  ) : (
    <Form
      onSubmit={async ({ limit }) => {
        const { data } = await updateBountyLimitPerMonth({
          variables: {
            limit,
          },
        })
        if (data) {
          toast({
            status: 'success',
            description: 'Creator Bounty limit per month updated.',
          })
        }
      }}
    >
      <Flex alignItems="center" justifyContent="flex-start" maxWidth="310px">
        <Box mr="2">
          <NumberInput
            name="limit"
            label="Creator Bounty Limit"
            isRequired={false}
          />
        </Box>
        <Box>
          <SubmitButton
            event={'Update Bounty Limit Per Month Submit Clicked'}
            isLoading={updateBountyLimitPerMonthLoading}
          >
            Submit
          </SubmitButton>
        </Box>
      </Flex>
    </Form>
  )
}
