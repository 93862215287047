import {
  Menu as MenuCore,
  MenuButton as MenuButtonCore,
  MenuList as MenuListCore,
  MenuItem as MenuItemCore,
  MenuItemOption as MenuItemOptionCore,
  MenuGroup as MenuGroupCore,
  MenuOptionGroup as MenuOptionGroupCore,
  MenuDivider as MenuDividerCore,
} from './Chakra'
import { ComponentProps } from 'react'
import { CaretDownIcon } from '../icons'
import { Button } from './Button'
import { defineStyleConfig } from '@chakra-ui/react'

export const menuTheme = defineStyleConfig({
  baseStyle: (p) => {
    return {
      item: {
        color: 'neutral.900',
        fontWeight: 'semibold',
        py: 2,
        px: 4,
        _hover: {
          bg: 'neutral.100',
        },
      },
    }
  },
})

export type MenuProps = ComponentProps<typeof MenuCore>

export const Menu = ({ ...props }: MenuProps) => {
  return <MenuCore {...props} />
}

export type MenuButtonProps = ComponentProps<typeof MenuButtonCore>

export const MenuButton = ({ ...props }: MenuButtonProps) => {
  return (
    <MenuButtonCore
      as={Button}
      rightIcon={<CaretDownIcon weight="bold" />}
      {...props}
    />
  )
}

export type MenuListProps = ComponentProps<typeof MenuListCore>

export const MenuList = ({ ...props }: MenuListProps) => {
  return <MenuListCore {...props} />
}

export type MenuItemProps = ComponentProps<typeof MenuItemCore>

export const MenuItem = ({ ...props }: MenuItemProps) => {
  return <MenuItemCore {...props} />
}

export type MenuItemOptionProps = ComponentProps<typeof MenuItemOptionCore>

export const MenuItemOption = ({ ...props }: MenuItemOptionProps) => {
  return <MenuItemOptionCore {...props} />
}

export type MenuGroupProps = ComponentProps<typeof MenuGroupCore>

export const MenuGroup = ({ ...props }: MenuGroupProps) => {
  return <MenuGroupCore {...props} />
}

export type MenuOptionGroupProps = ComponentProps<typeof MenuOptionGroupCore>

export const MenuOptionGroup = ({ ...props }: MenuOptionGroupProps) => {
  return <MenuOptionGroupCore {...props} />
}

export type MenuDividerProps = ComponentProps<typeof MenuDividerCore>

export const MenuDivider = ({ ...props }: MenuDividerProps) => {
  return <MenuDividerCore {...props} />
}
