import { Box, Flex, Text } from '@bounty/brands-design-system'
import { InviteFlowVariant } from '../AddCreatorsTable/types'

type PhoneDisplayProps = {
  email: string
  message: string
  subject?: string
  variant: InviteFlowVariant
}

export const PhoneEmailDisplay = ({
  email,
  message,
  subject,
  variant,
}: PhoneDisplayProps) => {
  return (
    <Flex
      py="9"
      px="8"
      w={['xs', 'sm']}
      h="auto"
      minH="300px"
      borderRadius="56px 56px 0 0"
      border="12px solid"
      borderBottom="none"
      borderColor="blackAlpha.900"
      backgroundColor="white"
      align="baseline"
      direction="column"
      gridGap="3"
    >
      <Flex align="baseline">
        <Text minWidth="80px" color="gray.600" fontWeight="semibold">
          To
        </Text>
        <Text
          as="span"
          display="inline-block"
          px="2"
          py="1"
          backgroundColor="gray.100"
          borderRadius="7px"
          color="black"
          wordBreak="break-word"
        >
          {email}
        </Text>
      </Flex>
      <Flex align="baseline">
        <Text minWidth="80px" color="gray.600" fontWeight="semibold">
          From
        </Text>
        <Text
          as="span"
          display="inline-block"
          px="2"
          py="1"
          backgroundColor="gray.100"
          borderRadius="7px"
          color="black"
          wordBreak="break-word"
        >
          creators@bounty.co
        </Text>
      </Flex>
      <Flex align="baseline">
        <Text minWidth="80px" color="gray.600" fontWeight="semibold">
          Subject
        </Text>
        <Text
          as="span"
          display="inline-block"
          px="2"
          py="1"
          backgroundColor="transparent"
          borderRadius="7px"
          color="black"
        >
          {subject ?? 'Join our creator program!'}
        </Text>
      </Flex>
      <Box>
        {variant === 'CreatorsYouDontKnow' ? (
          <>
            <Text
              mb="4"
              fontSize="sm"
              color="gray.900"
              wordBreak="break-word"
              whiteSpace="pre-wrap"
              overflowWrap="break-word"
            >
              Hey &#123;FirstName&#125;, my name is Kaley and I’m working with
              &#123;BrandName&#125; to build new TikTok partnerships!
            </Text>
            <Text
              mb="4"
              fontSize="sm"
              color="gray.900"
              wordBreak="break-word"
              whiteSpace="pre-wrap"
              overflowWrap="break-word"
            >
              &#123;BrandName&#125; and I thought you'd be a perfect fit for
              their product and absolutely love what you're about.
            </Text>
            <Text
              mb="0"
              fontSize="sm"
              color="gray.900"
              wordBreak="break-word"
              whiteSpace="pre-wrap"
              overflowWrap="break-word"
            >
              We work with &#123;BrandName&#125; through our platform called
              Bounty. The best thing about Bounty is that not only do you get
              the free product, but if your post earns more than the value of
              your gift through Bounty, you'll get the difference in cash. We’d
              be thrilled to send you some free product and if you love it we
              just ask that you share it!
            </Text>
          </>
        ) : (
          <Text
            mb="4"
            fontSize="lg"
            color="gray.900"
            wordBreak="break-word"
            whiteSpace="pre-wrap"
            overflowWrap="break-word"
          >
            {message}
          </Text>
        )}
        {/* {variant === 'CreatorsYouKnow' && (
          <Button colorScheme="darkBlack" pointerEvents={'none'}>
            Redeem gift here
          </Button>
        )} */}
      </Box>
    </Flex>
  )
}
