import { useEffect } from 'react'
import { Routes, Route, Outlet, Navigate } from 'react-router-dom'
import { Login } from './pages/Login/Login'
import { LoginSuccess } from './pages/LoginSuccess/LoginSuccess'
import {
  RequireAuth,
  AppWrapper,
  Box,
  NavItemFooterLink,
  NavItemFooterButton,
  ResourcesIcon,
  CurrencyCircleDollarIcon,
} from '@bounty/brands-design-system'
import { useAuthState, useAuthDispatch } from './hooks/useAuth'
import { useScrollToTop } from '@bounty/hooks'
import { useAnalytics } from '@bounty/web-analytics'
import { Welcome } from './pages/Welcome/Welcome'
import { RequireWelcomeFlow } from './pages/Welcome/RequireWelcomeFlow/RequireWelcomeFlow'
import { INTERIOR_NAV_ROUTES } from './pages/interiorNavRoutes'
import { SupportIcon, LogoutIcon } from '@bounty/brands-design-system'
import * as Sentry from '@sentry/react'
import { InviteCreatorsYouKnow } from './pages/Creators/Invites/InviteCreatorsYouKnow/InviteCreatorsYouKnow'
import { ActivatePreviousCustomers } from './pages/Creators/Invites/ActivatePreviousCustomers/ActivatePreviousCustomers'
import { InviteCreatorsYouDontKnow } from './pages/Creators/Invites/InviteCreatorsYouDontKnow/InviteCreatorsYouDontKnow'
import { AdvancedInvites } from './pages/Creators/Invites/AdvancedInvites/AdvancedInvites'
import { InviteHistoryPage } from './pages/Creators/Invites/InviteHistory/InviteHistoryPage'
import { GiftOffersHistoryPage } from './pages/Creators/Invites/GiftOffersHistory/GiftOffersHistoryPage'
import { Storefront } from './pages/Storefront/Storefront'
import { setClarityUser } from '@bounty/web-analytics'
import { GiftsHistoryPerformancePage } from './pages/Performance/GiftsHistory/GiftsHistoryPerformancePage'
import { useFeatureForStore } from './hooks/useFeatureForStore'
import { Home } from './pages/Home/Home'
import { GiftDrawer } from './pages/Gifts/GiftDrawer/GiftDrawer'
import { LatestUpdatesCheck } from './components/LatestUpdates/LatestUpdatesCheck'
import { Support } from './pages/Support/Support'

const AuthWrapper = () => {
  const { user } = useAuthState()
  const { identify, setUserProperty } = useAnalytics()

  useEffect(() => {
    if (!user) return
    Sentry.setUser({ id: user.shopUrl })
    identify(user.sessionId)
    setUserProperty('shopifyUrl', user.shopUrl)
  }, [identify, setUserProperty, user])

  return <Outlet />
}

const AuthedProviders = () => {
  const { shopifyStoreUrl, isLoading, isAuthed } = useAuthState()

  useEffect(() => {
    if (shopifyStoreUrl) {
      setClarityUser(shopifyStoreUrl)
    }
  }, [shopifyStoreUrl])

  return (
    <RequireAuth isAuthed={isAuthed} isLoading={isLoading}>
      <RequireWelcomeFlow>
        <LatestUpdatesCheck>
          <AuthWrapper />
        </LatestUpdatesCheck>
      </RequireWelcomeFlow>
    </RequireAuth>
  )
}

const FooterNav = () => {
  const { logout } = useAuthDispatch()
  return (
    <Box>
      <NavItemFooterLink
        icon={ResourcesIcon}
        isExternal={true}
        href="https://thebountyappinc.zohodesk.com/portal/en/kb/bounty-brands"
      >
        Resources
      </NavItemFooterLink>
      <NavItemFooterLink
        icon={SupportIcon}
        isExternal={true}
        // href="mailto:brandssupport@thebountyappinc.zohodesk.com"
        href="/support"
      >
        Support
      </NavItemFooterLink>
      {/* <NavItemFooterLink
        icon={CurrencyCircleDollarIcon}
        isExternal={true}
        href="https://bounty.tapfiliate.com"
      >
        Refer a brand
      </NavItemFooterLink> */}
      <NavItemFooterButton
        event="Nav Item Sign Out Clicked"
        onClick={logout}
        icon={LogoutIcon}
      >
        Sign out
      </NavItemFooterButton>
    </Box>
  )
}

const AuthedAppWrapper = () => {
  const { isAvailable } = useFeatureForStore('RETAIL_BOUNTIES')
  return (
    <AppWrapper
      navItems={INTERIOR_NAV_ROUTES.filter(
        (r) => r.name !== 'Retail' || isAvailable,
      )}
      navFooterComponent={<FooterNav />}
      showBetaLogo={false}
    />
  )
}

export const AuthedApp = () => {
  return (
    <Routes>
      <Route element={<AuthedProviders />}>
        <Route element={<AuthedAppWrapper />}>
          {INTERIOR_NAV_ROUTES.map((route) => {
            if (route.isExternal === false) {
              const { isGrouping } = route

              if (isGrouping === false) {
                const { routerPath, component: Component } = route

                return (
                  <Route
                    key={routerPath}
                    path={routerPath}
                    element={<Component />}
                  />
                )
              }

              const { children } = route

              return children.map((childRoute) => {
                if (childRoute.isExternal === false) {
                  const { routerPath, component: Component } = childRoute

                  return (
                    <Route
                      key={routerPath}
                      path={routerPath}
                      element={<Component />}
                    />
                  )
                }

                return null
              })
            }

            return null
          })
            .filter(Boolean)
            .flat(Infinity)}

          {/* TODO: move to nested route when Drawer component supports it */}
          <Route
            element={<InviteCreatorsYouKnow />}
            path="creators/invite/creators-you-know/*"
          />
          <Route
            element={<InviteCreatorsYouDontKnow />}
            path="creators/invite/creators-you-dont-know/*"
          />
          <Route
            element={
              <>
                <AdvancedInvites />
                <Outlet />
              </>
            }
            path="creators/invite/advanced/*"
          />
          <Route
            path="creators/invite/activate-previous-customers/*"
            element={
              <>
                <ActivatePreviousCustomers />
                <Outlet />
              </>
            }
          ></Route>
          <Route
            path="creators/invite/history"
            element={<InviteHistoryPage />}
          />
          <Route
            path="creators/invite/gift-offer/history"
            element={<GiftOffersHistoryPage />}
          />

          <Route
            path="performance/gifts-history/*"
            element={
              <>
                <GiftsHistoryPerformancePage />
                <Outlet />
              </>
            }
          />
          <Route path="/storefront/*" element={<Storefront />} />

          <Route
            path="/"
            element={
              <>
                <Home />
                <Outlet />
              </>
            }
          >
            <Route
              path="new-gift-from-dashboard/*"
              element={<GiftDrawer mode="create" rootPath="/" />}
            />
          </Route>
        </Route>
        <Route element={<Support />} path="/support" />
        <Route element={<Welcome />} path="/welcome/*" />

        {/* Redirect to home on path not found. */}
        <Route element={<Navigate to="/" />} path="*" />
      </Route>
    </Routes>
  )
}

export const App = () => {
  useScrollToTop()

  return (
    <Routes>
      <Route element={<AuthedApp />} path="/*" />
      <Route element={<Login />} path="/login" />
      <Route element={<LoginSuccess />} path="/login-success" />
    </Routes>
  )
}
