import { defineStyleConfig } from '@chakra-ui/react'

export const sliderTheme = defineStyleConfig({
  sizes: {
    lg: {
      track: {
        height: '2',
      },
      thumb: {
        width: '6',
        height: '6',
      },
    },
  },
  baseStyle: (p) => {
    const { colorScheme: c } = p
    return {
      thumb: {
        border: '1px',
        borderColor: `${c}.500`,
      },
    }
  },
  defaultProps: {
    colorScheme: 'primary',
    size: 'lg',
  },
})
