import {
  Page,
  PageHeader,
  PageHeaderContents,
  PageHeaderDescription,
  PageHeaderTitle,
  PageHeaderInformation,
  PageHeaderActions,
  PageHeaderPrimaryAction,
  Flex,
  Box,
  Button,
  useDisclosure,
  AspectRatio,
  PageSkeleton,
  IconButton,
  IconButtonProps,
  useToast,
  ChakraGrid,
  ChakraGridProps,
  Text,
  TrashIcon,
} from '@bounty/brands-design-system'
import { useState, useEffect, useRef } from 'react'
import {
  UpdateFeaturedBountiesDocument,
  StorefrontFeaturedBountiesDocument,
} from '../../generated/backendGraphql'
import { useQueryBackend, useMutationBackend } from '../../apollo/backend/hooks'
import { FeaturedBountiesModal } from './FeaturedBountiesModal'
import { Reorder } from 'framer-motion'
import { CLOUDINARY_URL, S3_BUCKET_URL } from '../../config/env'
import { DEFAULT_PARAMS } from '../ContentLibrary/searchParams'

type RemoveBountyButtonProps = Omit<IconButtonProps, 'aria-label'>

const RemoveBountyButton = ({ ...rest }: RemoveBountyButtonProps) => {
  return (
    <IconButton
      aria-label="Remove featured bounty video"
      size="sm"
      icon={<TrashIcon boxSize="20px" />}
      colorScheme={'blackAlpha'}
      variant="solid"
      {...rest}
    />
  )
}

type FeaturedBountiesProps = ChakraGridProps

export const FeaturedBounties = ({ ...rest }: FeaturedBountiesProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const toast = useToast()
  const videoRef = useRef<HTMLVideoElement>(null)

  const { data, loading: storefrontDataLoading } = useQueryBackend(
    StorefrontFeaturedBountiesDocument,
    {
      variables: {
        params: DEFAULT_PARAMS,
      },
    },
  )
  const [
    updateStorefrontBounties,
    { loading: updateStorefrontBountiesLoading },
  ] = useMutationBackend(UpdateFeaturedBountiesDocument)

  const [selectedVideoIds, setSelectedVideoIds] = useState<string[]>([])

  useEffect(() => {
    if (data?.storefront?.featuredVideoIds) {
      setSelectedVideoIds([...data.storefront.featuredVideoIds])
    }
  }, [data?.storefront?.featuredVideoIds])

  if (!data || storefrontDataLoading) return <PageSkeleton />

  return (
    <Page
      name="Customize Storefront Featured Bounties"
      data-testid="customize-storefront-featured-bounties-page"
      fixedHeight="all"
      addBottomSpacing={false}
      display="flex"
      flexDir="column"
      fluid
    >
      <Flex justifyContent="space-between" pr="12">
        <PageHeader
          breadcrumbs={[
            {
              name: 'Storefront',
              to: '/storefront',
            },
            {
              name: 'Current',
              to: '',
              isCurrentPage: true,
            },
          ]}
        >
          <PageHeaderContents>
            <PageHeaderInformation>
              <PageHeaderTitle>Featured Bounties</PageHeaderTitle>
              <PageHeaderDescription>
                Add up to five Bounties to display on your storefront to inspire
                action and creativity. Click the plus symbol below to get
                started.
              </PageHeaderDescription>
            </PageHeaderInformation>
            <PageHeaderActions>
              <PageHeaderPrimaryAction
                event="Customize Storefront Featured Bounties Save Clicked"
                isDisabled={updateStorefrontBountiesLoading}
                isLoading={updateStorefrontBountiesLoading}
                onClick={async () => {
                  const res = await updateStorefrontBounties({
                    variables: {
                      input: {
                        featuredVideoIds: selectedVideoIds,
                      },
                    },
                  })

                  if (!res.errors) {
                    toast({
                      title: 'Featured bounties customize success',
                      description:
                        'You should see updates on your Storefront within 5 minutes.',
                      status: 'success',
                      id: 'featuredBountiesCustomizeSuccess',
                    })
                  }
                }}
              >
                Save
              </PageHeaderPrimaryAction>
            </PageHeaderActions>
          </PageHeaderContents>
        </PageHeader>
      </Flex>
      <Box>
        <Text color="neutral.700" mb="6">
          Videos appear here in the order you choose from the content library.
          Reorder them by dragging and dropping them into place.
        </Text>
        <Flex flexWrap={'wrap'} justifyContent={['center', 'flex-start']}>
          <Reorder.Group
            axis="x"
            values={selectedVideoIds}
            onReorder={setSelectedVideoIds}
            as={'div'}
          >
            <ChakraGrid
              templateColumns={[
                'repeat(1, 1fr)',
                'repeat(2, 1fr)',
                'repeat(3, 1fr)',
                'repeat(4, 1fr)',
                'repeat(5, 1fr)',
                'repeat(6, 1fr)',
              ]}
              gap={6}
              rowGap={12}
              {...rest}
            >
              {selectedVideoIds.map((item) => {
                const video = data.contentLibrary.items.find(
                  (content) => content.id === item,
                )
                return (
                  <Reorder.Item key={item} value={item} as="div" drag>
                    <Box position="relative" isolation="isolate">
                      <RemoveBountyButton
                        position="absolute"
                        right="10px"
                        top="10px"
                        zIndex={10}
                        onClick={() => {
                          setSelectedVideoIds(
                            selectedVideoIds.filter(
                              (selectedVideoId) =>
                                selectedVideoId !== video?.id,
                            ),
                          )
                        }}
                      />
                      <Box
                        data-testid="featured-bounty-item"
                        height="60"
                        width="36"
                        tabIndex={0}
                        backgroundColor={'neutral.200'}
                      >
                        {video && (
                          <AspectRatio ratio={0.56}>
                            <video
                              poster={`${CLOUDINARY_URL}/video_id_${video.externalContentId}.jpg`}
                              autoPlay={false}
                              loop={true}
                              ref={videoRef}
                              muted
                              preload="none"
                              onMouseEnter={(e) => {
                                e.currentTarget.play()
                              }}
                              onMouseLeave={(e) => {
                                e.currentTarget.pause()
                              }}
                            >
                              <source
                                src={`${S3_BUCKET_URL}/${video.externalContentId}.mp4`}
                                type="video/mp4"
                              ></source>
                            </video>
                          </AspectRatio>
                        )}
                      </Box>
                    </Box>
                  </Reorder.Item>
                )
              })}

              <Button
                variant="unstyled"
                event={'Storefront Add Featured Bounty Clicked'}
              >
                <Flex
                  height="64"
                  width="36"
                  backgroundColor={'neutral.200'}
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => {
                    onOpen()
                  }}
                >
                  +
                </Flex>
              </Button>
            </ChakraGrid>
          </Reorder.Group>
        </Flex>
      </Box>
      <FeaturedBountiesModal
        isOpen={isOpen}
        onClose={onClose}
        selectedVideoIds={selectedVideoIds}
        setSelectedVideoIds={setSelectedVideoIds}
      />
    </Page>
  )
}
