export const ShopifyIcon = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_411_3371)">
        <path
          d="M14.0002 3.46602C13.9877 3.37512 13.9078 3.32469 13.8414 3.3192C13.7756 3.31371 12.4862 3.29424 12.4862 3.29424C12.4862 3.29424 11.4077 2.25353 11.3012 2.14766C11.1947 2.04179 10.9866 2.07375 10.9057 2.09772C10.9047 2.09822 10.7032 2.16014 10.364 2.26451C10.3072 2.08174 10.2238 1.85702 10.1047 1.6313C9.72078 0.903198 9.15846 0.518175 8.47903 0.517179C8.47804 0.517179 8.47752 0.517179 8.47653 0.517179C8.42928 0.517179 8.38255 0.521673 8.33533 0.525666C8.31523 0.501696 8.29512 0.478227 8.27402 0.455253C7.97802 0.140645 7.59864 -0.0126654 7.14385 0.000817836C6.26644 0.0257869 5.39257 0.655506 4.68401 1.77412C4.18551 2.56114 3.8061 3.54993 3.69857 4.31547C2.691 4.62558 1.98647 4.8423 1.97089 4.84731C1.46234 5.0061 1.44626 5.02158 1.37992 5.49801C1.33068 5.85804 0 16.0834 0 16.0834L11.1515 18L15.9847 16.806C15.9847 16.806 14.0128 3.55692 14.0002 3.46602ZM9.80571 2.4363C9.54892 2.5152 9.25694 2.60509 8.94035 2.70247C8.93383 2.26102 8.88106 1.64678 8.67402 1.11593C9.33986 1.24128 9.6675 1.98985 9.80571 2.4363ZM8.35692 2.88224C7.7725 3.06201 7.13479 3.25827 6.49509 3.45504C6.67499 2.77038 7.01621 2.08873 7.43532 1.64178C7.59109 1.47549 7.80918 1.29022 8.06747 1.18435C8.31019 1.68773 8.36296 2.40034 8.35692 2.88224ZM7.16142 0.581097C7.36746 0.576603 7.54083 0.621549 7.68909 0.718428C7.45189 0.840777 7.22273 1.01656 7.00767 1.24577C6.45035 1.84004 6.02321 2.76239 5.85286 3.65229C5.32169 3.81558 4.80208 3.9759 4.32368 4.12272C4.62572 2.72195 5.80712 0.620049 7.16142 0.581097Z"
          fill="#95BF47"
        />
        <path
          d="M13.842 3.31967C13.7762 3.31418 12.4867 3.29471 12.4867 3.29471C12.4867 3.29471 11.4083 2.25399 11.3017 2.14813C11.262 2.10867 11.2083 2.0882 11.152 2.07971L11.1525 17.9995L15.9852 16.806C15.9852 16.806 14.0134 3.55739 14.0008 3.46649C13.9882 3.37559 13.9078 3.32516 13.842 3.31967Z"
          fill="#5E8E3E"
        />
        <path
          d="M8.47305 5.78914L7.91175 7.87603C7.91175 7.87603 7.28561 7.59289 6.54339 7.63933C5.45491 7.70776 5.44334 8.3899 5.45442 8.5612C5.51371 9.49453 7.9846 9.69829 8.12331 11.8846C8.23235 13.6044 7.20518 14.781 5.72527 14.8739C3.94885 14.9852 2.97095 13.944 2.97095 13.944L3.34734 12.353C3.34734 12.353 4.33177 13.0911 5.11972 13.0416C5.63432 13.0092 5.81823 12.5932 5.79963 12.2991C5.72226 11.0816 3.71015 11.1535 3.58303 9.15295C3.47598 7.46956 4.58858 5.76367 7.04337 5.60986C7.98912 5.54944 8.47305 5.78914 8.47305 5.78914Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_411_3371">
          <rect width="16" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
