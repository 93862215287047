import { FormHTMLAttributes, ReactNode } from 'react'
import { Box, BoxProps, forwardRef } from '../Chakra'

export type FormProps = React.DetailedHTMLProps<
  FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
> & { children: ReactNode } & Omit<BoxProps, 'onSubmit'>

export const Form = forwardRef<FormProps, 'form'>(
  ({ children, ...rest }, ref) => {
    return (
      <Box
        as={'form'}
        display="flex"
        flexDirection={'column'}
        ref={ref}
        {...rest}
      >
        {children}
      </Box>
    )
  },
)

Form.displayName = 'Form'
