import { zodResolver } from '@hookform/resolvers/zod'
import { GroupBase } from 'chakra-react-select'
import { useEffect, useMemo, useRef } from 'react'
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormProps,
} from 'react-hook-form'
import { z } from 'zod'
import { Form as FormCore, FormProps } from '../Form'
import { FastFormButton, FastFormButtonProps } from './FastForm'
import {
  FastFormColorInput,
  FastFormColorInputProps,
} from './FastFormColorInput'
import {
  FastFormCreatableSelect,
  FastFormCreatableSelectProps,
} from './FastFormCreatableSelect'
import { FastFormDateInput } from './FastFormDateInput'
import {
  FastFormFancySelect,
  FastFormFancySelectProps,
} from './FastFormFancySelect'
import { FastFormInput, FastFormInputProps } from './FastFormInput'
import {
  FastFormNativeSelect,
  FastFormNativeSelectProps,
} from './FastFormNativeSelect'
import {
  FastFormNumberInput,
  FastFormNumberInputProps,
} from './FastFormNumberInput'
import {
  FastFormRadioGroup,
  FastFormRadioGroupProps,
  FastFormRadioOption,
} from './FastFormRadioGroup'
import { FastFormSwitch, FastFormSwitchProps } from './FastFormSwitch'
import { FastFormTextarea, FastFormTextareaProps } from './FastFormTextarea'
import { isFieldRequired } from './utils'
import {
  FastFormCheckboxGroup,
  FastFormCheckboxGroupProps,
  FastFormCheckboxOption,
} from './FastFormCheckboxGroup'
import { FastFormSlider, FastFormSliderProps } from './FastFormSlider'
import { FastFormUpload, FastFormUploadProps } from './FastFormUpload'

export const useFastForm = <
  T extends z.ZodTypeAny,
  TFieldValues extends FieldValues = z.infer<T>,
>({
  schema,
  ...rest
}: UseFormProps<TFieldValues> & { schema: T }) => {
  const methods = useForm<TFieldValues>({
    resolver: zodResolver(schema),
    ...rest,
  })
  const methodsRef = useRef(methods)
  const schemaRef = useRef(schema)

  useEffect(() => {
    if (schema) {
      schemaRef.current = schema
    }
  }, [schema])

  const Form = useMemo(
    () =>
      ({
        children,
        onSubmit,
        ...rest
      }: Omit<FormProps, 'onSubmit'> & {
        onSubmit: SubmitHandler<TFieldValues>
      }) => {
        return (
          <FormProvider<TFieldValues> {...methodsRef.current}>
            <FormCore
              noValidate
              {...rest}
              onSubmit={methodsRef.current.handleSubmit(onSubmit, (e) => {
                console.log('Erroring submitting form', e)
              })}
            >
              {children}
            </FormCore>
          </FormProvider>
        )
      },
    [],
  )

  const ColorInput = useMemo(
    () => (props: FastFormColorInputProps<TFieldValues>) => {
      return (
        <FastFormColorInput
          isRequired={isFieldRequired(props.name, schemaRef.current)}
          {...props}
        />
      )
    },
    [],
  )

  const Input = useMemo(
    () => (props: FastFormInputProps<TFieldValues>) => {
      return (
        <FastFormInput
          isRequired={isFieldRequired(props.name, schemaRef.current)}
          {...props}
        />
      )
    },
    [],
  )

  const DateInput = useMemo(
    () => (props: FastFormInputProps<TFieldValues>) => {
      return (
        <FastFormDateInput
          isRequired={isFieldRequired(props.name, schemaRef.current)}
          {...props}
        />
      )
    },
    [],
  )

  const NumberInput = useMemo(
    () => (props: FastFormNumberInputProps<TFieldValues>) => {
      return (
        <FastFormNumberInput
          isRequired={isFieldRequired(props.name, schemaRef.current)}
          {...props}
        />
      )
    },
    [],
  )

  const Textarea = useMemo(
    () => (props: FastFormTextareaProps<TFieldValues>) => {
      return (
        <FastFormTextarea
          isRequired={isFieldRequired(props.name, schemaRef.current)}
          {...props}
        />
      )
    },
    [],
  )

  const SelectNative = useMemo(
    () => (props: FastFormNativeSelectProps<TFieldValues>) => {
      return (
        <FastFormNativeSelect
          isRequired={isFieldRequired(props.name, schemaRef.current)}
          {...props}
        />
      )
    },
    [],
  )

  const Switch = useMemo(
    () => (props: FastFormSwitchProps<TFieldValues>) => {
      return (
        <FastFormSwitch
          isRequired={isFieldRequired(props.name, schemaRef.current)}
          {...props}
        />
      )
    },
    [],
  )

  const CheckboxGroup = useMemo(
    () => (props: FastFormCheckboxGroupProps<TFieldValues>) => {
      return (
        <FastFormCheckboxGroup
          isRequired={isFieldRequired(props.name, schemaRef.current)}
          {...props}
        />
      )
    },
    [],
  )

  const RadioGroup = useMemo(
    () => (props: FastFormRadioGroupProps<TFieldValues>) => {
      return (
        <FastFormRadioGroup
          isRequired={isFieldRequired(props.name, schemaRef.current)}
          {...props}
        />
      )
    },
    [],
  )

  const CreatableSelect = useMemo(
    () =>
      <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
        props: FastFormCreatableSelectProps<
          Option,
          IsMulti,
          Group,
          TFieldValues
        >,
      ) => {
        return (
          <FastFormCreatableSelect
            isRequired={isFieldRequired(props.name, schemaRef.current)}
            {...props}
          />
        )
      },
    [],
  )

  const Select = useMemo(
    () =>
      <
        Option,
        IsMulti extends boolean = false,
        Group extends GroupBase<Option> = GroupBase<Option>,
      >(
        props: FastFormFancySelectProps<Option, IsMulti, Group, TFieldValues>,
      ) => {
        return (
          <FastFormFancySelect
            isRequired={isFieldRequired(props.name, schemaRef.current)}
            {...props}
          />
        )
      },
    [],
  )

  const Upload = useMemo(
    () => (props: FastFormUploadProps) => {
      return (
        <FastFormUpload
          isRequired={isFieldRequired(props.name, schemaRef.current)}
          {...props}
        />
      )
    },
    [],
  )

  const Slider = useMemo(
    () => (props: FastFormSliderProps<TFieldValues>) => {
      return (
        <FastFormSlider
          isRequired={isFieldRequired(props.name, schemaRef.current)}
          {...props}
        />
      )
    },
    [],
  )

  const SubmitButton = useMemo(
    () => (props: FastFormButtonProps) => {
      return <FastFormButton {...props} />
    },
    [],
  )

  return {
    Form,
    SubmitButton,
    ColorInput,
    Input,
    DateInput,
    CheckboxGroup,
    CheckboxGroupOption: FastFormCheckboxOption,
    RadioGroup,
    RadioGroupOption: FastFormRadioOption,
    CreatableSelect,
    Select,
    Switch,
    NumberInput,
    SelectNative,
    Slider,
    Textarea,
    Upload,
    methods,
  }
}
