import {
  Page,
  PageHeader,
  PageHeaderContents,
  PageHeaderDescription,
  PageHeaderTitle,
  PageHeaderInformation,
  PageHeaderActions,
  PageHeaderPrimaryAction,
  PageHeaderSecondaryAction,
  Flex,
  Box,
  useToast,
  Grid,
  PageSkeleton,
  Image,
  Tooltip,
  IconButton,
  MinusIcon,
  useDisclosure,
  Button,
} from '@bounty/brands-design-system'
import { useMemo, useRef, useState, useEffect } from 'react'
import { CellRendererSelectorFunc, ColDef } from 'ag-grid-community'
import {
  FeaturedProductsDocument,
  FeaturedProductsQuery,
  UpdateFeaturedProductsDocument,
  Storefront,
} from '../../generated/backendGraphql'
import { useQueryBackend, useMutationBackend } from '../../apollo/backend/hooks'
import { AgGridReact } from 'ag-grid-react'
import { FeaturedProductsModal } from './FeaturedProductsModal'
import { generateUpdatedProductIds } from './generateUpdatedProductIds'

type ProductType = FeaturedProductsQuery['enabledStoreProducts'][0]
type CellRendererParams = Parameters<CellRendererSelectorFunc<ProductType>>[0]
export type FeaturedProductIdType = Storefront['featuredProductIds'][0]

export const FeaturedProducts = () => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const gridRef = useRef<AgGridReact>(null)

  const { data: { enabledStoreProducts = [], storefront } = {}, loading } =
    useQueryBackend(FeaturedProductsDocument, {
      fetchPolicy: 'network-only',
    })

  const [
    updateStorefrontProducts,
    { loading: updateStorefrontProductsLoading },
  ] = useMutationBackend(
    UpdateFeaturedProductsDocument,

    {
      optimisticResponse: (proxy) => {
        return {
          __typename: 'Mutation',
          updateStorefront: {
            __typename: 'Storefront',
            id: storefront?.id,
            featuredProductIds: proxy.input.featuredProductIds,
          },
        }
      },
    },
  )

  const [productsOrder, setProductsOrder] = useState<string[]>([])
  const [rowData, setRowData] = useState<ProductType[]>()

  const generateAndSetRowData = (
    allSelectedProductIds: FeaturedProductIdType[],
  ) => {
    const updatedFeaturedProductIds = generateUpdatedProductIds(
      storefront?.featuredProductIds,
      allSelectedProductIds,
    )

    setProductsOrder(updatedFeaturedProductIds)

    const updatedRowData: ProductType[] = []

    updatedFeaturedProductIds.forEach((id) => {
      const foundProduct = enabledStoreProducts.find(
        (product) => product.id === id,
      )
      if (foundProduct) {
        updatedRowData.push(foundProduct)
      }
    })

    setRowData(updatedRowData)
  }

  useEffect(
    () => {
      if (storefront?.featuredProductIds) {
        setProductsOrder([...storefront.featuredProductIds])
        generateAndSetRowData([...storefront.featuredProductIds])
      }
    },
    // eslint-disable-next-line
    [storefront],
  )

  const columnDefs = useMemo(
    (): ColDef<ProductType>[] | undefined => [
      {
        headerName: '',
        rowDrag: true,
        width: 30,
        flex: 0,
        resizable: false,
      },
      {
        headerName: '',
        width: 30,
        flex: 0,
        field: 'id',
        suppressMovable: true,
        cellRenderer: (params: CellRendererParams) => {
          return (
            <Tooltip hasArrow label="Remove from featured products">
              <IconButton
                aria-label="Remove featured product button"
                variant="unstyled"
                minWidth="auto"
                minHeight="auto"
                _focus={{
                  boxShadow: 'none',
                }}
                height="auto"
                onClick={() => {
                  const updateProducts = productsOrder?.filter(
                    (id) => id !== params.value,
                  )
                  setProductsOrder(updateProducts)
                  generateAndSetRowData(updateProducts)
                }}
                icon={<MinusIcon weight="fill" boxSize="20px" />}
              />
            </Tooltip>
          )
        },
        resizable: false,
      },
      {
        headerName: '',
        field: 'imgLink',
        width: 40,
        flex: 0,
        resizable: false,
        suppressMovable: true,
        cellRenderer: (params: CellRendererParams) => {
          return (
            <Image
              width="40px"
              height="40px"
              objectFit="cover"
              src={params.value ?? 'https://via.placeholder.com/40'}
              alt={params.data!.productName ?? 'Not provided.'}
            />
          )
        },
      },
      { headerName: 'Product Name', field: 'productName' },
      { headerName: 'Shopify ID', field: 'productId' },
      { headerName: 'SKU', field: 'productSku' },
      { headerName: 'Price', field: 'price' },
    ],
    // eslint-disable-next-line
    [productsOrder],
  )

  const getRowData = () => {
    const updatedProductsIds: string[] = []
    gridRef.current?.api.forEachNode((node) => {
      if (node.data) {
        updatedProductsIds.push(node.data['id'])
      }
    })

    setProductsOrder(updatedProductsIds)
  }

  if (loading || !storefront) return <PageSkeleton />

  return (
    <Page
      name="Customize Storefront Featured Products"
      data-testid="customize-storefront-featured-products-page"
      fixedHeight="all"
      addBottomSpacing={false}
      display="flex"
      flexDir="column"
      fluid
    >
      <Flex justifyContent="space-between" alignItems="flex-start" pr="12">
        <PageHeader
          breadcrumbs={[
            {
              name: 'Storefront',
              to: '/storefront',
            },
            {
              name: 'Current',
              to: '',
              isCurrentPage: true,
            },
          ]}
        >
          <PageHeaderContents>
            <PageHeaderInformation>
              <PageHeaderTitle>Featured Products</PageHeaderTitle>
              <PageHeaderDescription>
                Order your featured products to showcase them to customers and
                creators on your Bounty storefront.
              </PageHeaderDescription>
            </PageHeaderInformation>
            <PageHeaderActions>
              <PageHeaderSecondaryAction
                event="Storefront Add Product Clicked"
                onClick={() => {
                  onOpen()
                }}
              >
                Add Products
              </PageHeaderSecondaryAction>
              <PageHeaderPrimaryAction
                event="Customize Storefront Featured Products Save Clicked"
                isDisabled={updateStorefrontProductsLoading}
                isLoading={updateStorefrontProductsLoading}
                onClick={async () => {
                  const res = await updateStorefrontProducts({
                    variables: {
                      input: {
                        featuredProductIds: productsOrder,
                      },
                    },
                  })
                  if (!res.errors) {
                    toast({
                      title: 'Featured products customize success',
                      description:
                        'You should see updates on your Storefront within 5 minutes.',
                      status: 'success',
                      id: 'featuredProductsCustomizeSuccess',
                    })
                  }
                }}
              >
                Save
              </PageHeaderPrimaryAction>
            </PageHeaderActions>
          </PageHeaderContents>
        </PageHeader>
      </Flex>

      {productsOrder.length > 0 ? (
        <Grid<ProductType>
          data-test-id="storefront-featured-products-grid"
          showQuickSearch={false}
          containerProps={{ flex: 1 }}
          rowDragManaged
          rowDragEntireRow={true}
          rowSelection="single"
          columnDefs={columnDefs}
          rowData={rowData}
          gridRef={gridRef}
          onRowDragEnd={getRowData}
        />
      ) : (
        <Box>
          No products selected to feature. Please{' '}
          <Button
            event={'Storefront No Products Selected Add Product Clicked'}
            color="gray.700"
            variant="link"
            onClick={() => {
              onOpen()
            }}
          >
            select products
          </Button>{' '}
          to display on your storefront.
        </Box>
      )}
      <FeaturedProductsModal
        isOpen={isOpen}
        onClose={onClose}
        variant="storefront"
        productsOrder={productsOrder}
        setProductsOrder={setProductsOrder}
        generateAndSetRowData={generateAndSetRowData}
      />
    </Page>
  )
}
