import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerHeading,
  DrawerBody,
  DrawerCloseButton,
  Skeleton,
  Box,
} from '@bounty/brands-design-system'
import { useNavigate } from 'react-router-dom'
import { useQueryBackend } from '../../apollo/backend/hooks'
import { RetailBriefDocument } from '../../generated/backendGraphql'
import { RetailBriefForm } from './RetailBriefForm'

export type RetailBriefDrawerProps = {
  rootPath: string
}

export const RetailBriefDrawer = ({ rootPath }: RetailBriefDrawerProps) => {
  const navigate = useNavigate()

  const { data, loading } = useQueryBackend(RetailBriefDocument)

  return (
    <Drawer size="lg" isOpen onClose={() => navigate(rootPath)}>
      <DrawerCloseButton />
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <DrawerHeading>Edit retail brief</DrawerHeading>
        </DrawerHeader>
        <DrawerBody>
          {loading || !data ? (
            <Box>
              <Skeleton height="60px" mb="14" />
              <Skeleton height="80px" mb="4" />
              <Box width="90%">
                <Skeleton height="60px" mb="4" />
                <Skeleton height="60px" mb="4" />
                <Skeleton height="60px" mb="4" />
              </Box>
            </Box>
          ) : (
            <RetailBriefForm
              rootPath={rootPath}
              headline={data?.currentStore.retailBrief?.headline ?? ''}
              features={data?.currentStore.retailBrief?.features ?? []}
            />
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
