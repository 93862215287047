import { Box, Page, PageHeader, Text } from '@bounty/brands-design-system'
import { UgcConversionFunnelModule } from './Funnel/UgcConversionFunnelModule'

export const UgcConversionFunnelPage = () => {
  return (
    <Page
      data-testid="ugc-funnel-page"
      name="Funnel Performance"
      addBottomSpacing={false}
      fluid
      width="100%"
      px={16}
      pb={8}
    >
      <PageHeader
        title="Funnel Performance"
        description={
          <Text>
            Tap any step of the funnel for suggestions to increase performance.
          </Text>
        }
        mb={12}
      />
      <Box as="main">
        <UgcConversionFunnelModule />
      </Box>
    </Page>
  )
}
