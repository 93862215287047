import {
  useFastForm,
  Box,
  Flex,
  Link,
  Text,
  Skeleton,
  SkeletonText,
  PageHeader,
  PageHeaderActions,
  PageHeaderSecondaryAction,
  PageHeaderPrimaryAction,
} from '@bounty/brands-design-system'
import { useEffect } from 'react'
import { useQueryBackend } from '../../../../../../apollo/backend/hooks'
import { GiftListDocument } from '../../../../../../generated/backendGraphql'
import { useNavigate } from 'react-router-dom'
import {
  useInviteCreatorsYouKnowDispatch,
  useInviteCreatorsYouKnowState,
} from '../../useInviteCreatorsYouKnow'
import { messageSchema } from '../../schemas'
import { useCheckForTableData } from '../../hooks/useCheckForTableData'
import { isNotNil } from '@bounty/utils'
import { FormDataDisplayWrapper } from '../../../components/FormDataDisplay/FormDataDisplayWrapper'
import { FormWrapper } from '../../../components/FormDataDisplay/FormWrapper'
import { FormDataDisplay } from '../../../components/FormDataDisplay/FormDataDisplay'
import { GiftIcon } from '../../../components/icons/GiftIcon'
import { MailIcon } from '../../../components/icons/MailIcon'
import { PhoneEmailDisplay } from '../../../components/PhoneEmailDisplay/PhoneEmailDisplay'
import { GiftDisplay } from '../../../components/GiftDisplay/GiftDisplay'
import { INVITE_MESSAGE_PREVIEW_TEXT_DISCLAIMER } from '../../../constants/invites.constants'

export const AddGiftAndMessage = () => {
  useCheckForTableData()

  const navigate = useNavigate()

  const { data: { enabledStoreGifts = [], currentStore } = {}, loading } =
    useQueryBackend(GiftListDocument)

  const { rowData } = useInviteCreatorsYouKnowState()
  const { setInviteCreatorsYouKnowState } = useInviteCreatorsYouKnowDispatch()

  const {
    Form,
    Select,
    Textarea,
    methods: { setValue, watch },
  } = useFastForm({
    defaultValues: {
      giftId: '',
      message: `Hello {{name}}! We’d love to send you a gift if you post about us on TikTok`,
    },
    schema: messageSchema,
  })

  const [watchGiftId, watchMessage] = watch(['giftId', 'message'])

  const selectedGift = enabledStoreGifts.find((el) => el.id === watchGiftId)

  useEffect(() => {
    if (!loading && enabledStoreGifts != null && enabledStoreGifts.length > 0)
      setValue('giftId', enabledStoreGifts[0].id)
  }, [loading, setValue, enabledStoreGifts])

  const { email, name } = rowData.find(({ name = '' }) => name.length > 0) ??
    rowData[0] ?? { email: '', name: '' }

  const sanitizedMessage = () => {
    const nameCurlies = /{{name}}/g

    return watchMessage.replace(nameCurlies, name)
  }

  return (
    <Form
      onSubmit={(submitData) => {
        if (isNotNil(submitData.giftId) && submitData.giftId.length > 0) {
          setInviteCreatorsYouKnowState((prevState) => ({
            ...prevState,
            ...submitData,
          }))

          navigate('../review')
        }
      }}
    >
      <PageHeader
        title={'Invite creators you know to Bounty'}
        description="Invite creators you already work with to join your community on Bounty."
        breadcrumbs={[
          {
            name: 'Creators',
            to: '/creators',
          },
          {
            name: 'Invite',
            to: '/creators/invite',
          },
          {
            name: 'Current',
            to: '',
            isCurrentPage: true,
          },
        ]}
        actions={
          <PageHeaderActions>
            <PageHeaderSecondaryAction
              event={'Invites Add Creators You Know Second Step Back Clicked'}
              onClick={() => navigate('..')}
            >
              Back
            </PageHeaderSecondaryAction>
            <PageHeaderPrimaryAction
              event={'Invites Add Creators You Know Second Step Next Clicked'}
              type="submit"
            >
              Next
            </PageHeaderPrimaryAction>
          </PageHeaderActions>
        }
      />
      <Box>
        <FormDataDisplayWrapper>
          <FormWrapper>
            <Box width="400px" maxWidth="100%">
              {loading ? (
                <>
                  <SkeletonText
                    mb="2"
                    w="30%"
                    borderRadius="md"
                    noOfLines={1}
                    skeletonHeight="4"
                  />
                  <Skeleton w="100%" h="10" mb="5" borderRadius="md" />
                </>
              ) : (
                <Select
                  name="giftId"
                  label="Gift"
                  isRequired={true}
                  options={enabledStoreGifts.map((x) => ({
                    label: x.name,
                    value: x.id,
                  }))}
                  helpText={
                    <Text color="gray.600" fontSize="sm">
                      Select a gift for the creator or{' '}
                      <Link
                        isExternal={false}
                        to="create-gift"
                        textDecoration="underline"
                        color="inherit"
                      >
                        create one
                      </Link>
                    </Text>
                  }
                />
              )}
            </Box>
          </FormWrapper>
          {selectedGift && (
            <FormDataDisplay icon={<GiftIcon />} description="Gift details">
              <GiftDisplay gift={selectedGift} />
            </FormDataDisplay>
          )}
        </FormDataDisplayWrapper>

        <FormDataDisplayWrapper>
          <FormWrapper>
            <Box flexGrow={1}>
              <Textarea
                name="message"
                rows={8}
                formElementProps={{
                  py: '2',
                  px: '3',
                  width: '400px',
                  maxWidth: '100%',
                  background: 'white',
                  border: '1px solid',
                  borderRadius: 'md',
                  boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.05)',
                  color: 'gray.900',
                }}
                helpText="Bounty emails this message to the creators you're inviting."
              />
            </Box>
            <Text fontSize={'xs'} color="gray.600">
              {INVITE_MESSAGE_PREVIEW_TEXT_DISCLAIMER}
            </Text>
          </FormWrapper>
          <FormDataDisplay icon={<MailIcon />} description="Message preview">
            <Flex
              mb="-8"
              pt="14"
              px="8"
              flexGrow={1}
              direction="column"
              align="center"
              justify="flex-end"
              minHeight="328px"
              height="auto"
              position="relative"
            >
              {loading ? (
                <div></div>
              ) : (
                <PhoneEmailDisplay
                  email={email}
                  message={sanitizedMessage()}
                  subject={
                    currentStore
                      ? `${currentStore.name} partnership invite`
                      : 'Partnership invite'
                  }
                  variant="CreatorsYouKnow"
                />
              )}
            </Flex>
          </FormDataDisplay>
        </FormDataDisplayWrapper>
      </Box>
    </Form>
  )
}
