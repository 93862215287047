import {
  BoxProps,
  Box,
  Image,
  Button,
  forwardRef,
  useMergeRefs,
  Text,
} from '../Chakra'
import React, { useEffect, useState } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import { ImageSquareIcon } from '../icons'
import { set } from 'lodash'

export const isFileWithPreview = (
  x: unknown,
): x is File & { preview: string } => {
  // @ts-expect-error - we are appending preview to the file object due to https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL#memory_management
  return x?.preview != null && x instanceof File
}
const focusProps: BoxProps = {
  borderColor: 'primary.500',
  outline: 'none',
}
const dragActiveProps: BoxProps = {
  borderColor: 'primary.500',
}
const dragRejectProps: BoxProps = {
  borderColor: 'error.500',
}
const dragAcceptProps: BoxProps = {
  borderColor: 'success.500',
}
const isFileWithImage = (
  value: (File & { preview: string }) | null | undefined,
) => {
  if (value?.preview) return true
  const imageFile = value as unknown as string
  return imageFile.includes('null') ? false : true
}
export type UploadProps = DropzoneOptions & {
  name: string
  onError?: (s: string) => void
  onChange: (file: (File & { preview: string }) | null) => void
  value: (File & { preview: string }) | null | undefined
  /**
   * Show a preview of the uploaded files. Useful for images.
   */
  showPreview?: boolean
  /** Prevent uploading content */
  isDisabled?: boolean
  removed?: boolean
  setRemoved?: React.Dispatch<React.SetStateAction<boolean>>
}
export const Upload = forwardRef<UploadProps, 'input'>(
  (
    {
      name,
      onError,
      onChange,
      value,
      showPreview = true,
      isDisabled,
      removed,
      setRemoved,
      ...rest
    },
    ref,
  ) => {
    const [error, setError] = useState<string>()

    // See: https://react-dropzone.js.org/#!/Previews
    const {
      getRootProps,
      getInputProps,
      isFocused,
      isDragAccept,
      isDragReject,
      isDragActive,
    } = useDropzone({
      accept: {
        'image/png': ['.png'],
        'image/jpg': ['.jpg'],
        'image/jpeg': ['.jpeg'],
      },
      maxSize: 5000000,
      multiple: false,
      onDrop: (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
          if (rejectedFiles[0].errors[0].code === 'file-too-large') {
            setError('Max file size is 5MB. Please choose a different image.')
          }
          onError?.('File dropped not an accepted format.')

          return
        }
        if (acceptedFiles.length > 0) {
          const file = Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0]),
          })

          onChange(file)
          setError('')
          if (setRemoved) {
            setRemoved(false)
          }
        }
      },
      ...rest,
    })

    useEffect(() => {
      return () => {
        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount or when value changes
        if (isFileWithPreview(value)) {
          URL.revokeObjectURL(value.preview)
        }
      }
    }, [value])

    useEffect(() => {
      if (removed) {
        if (isFileWithPreview(value)) {
          URL.revokeObjectURL(value.preview)
        }
        onChange(null)
      }
    }, [onChange, removed, value])
    // @ts-expect-error - Their internal types are wrong for this getter
    const { ref: useDropzoneInputRef, ...restOfUseDropzoneProps } =
      getInputProps()

    return (
      <Box>
        <Box
          role="group"
          border="2px solid"
          borderRadius="md"
          borderColor={'gray.200'}
          padding={4}
          outline={'none'}
          cursor={isDisabled ? 'not-allowed' : 'pointer'}
          pointerEvents={isDisabled ? 'none' : 'initial'}
          {...getRootProps({ className: 'dropzone' })}
          {...(isFocused && focusProps)}
          {...(isDragActive && dragActiveProps)}
          {...(isDragReject && dragRejectProps)}
          {...(isDragAccept && dragAcceptProps)}
          mb="2"
        >
          <input
            name={name}
            ref={useMergeRefs(ref, useDropzoneInputRef)}
            {...restOfUseDropzoneProps}
          />
          <Box display="flex" alignItems="center">
            <Box width="30%" pr="4" height="80px">
              {showPreview && value && isFileWithImage(value) && !removed ? (
                <Image
                  width="100%"
                  objectFit="contain"
                  height="100%"
                  src={isFileWithPreview(value) ? value.preview : value}
                />
              ) : (
                <Box
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems={'center'}
                  borderRadius="md"
                  borderStyle={'dashed'}
                  justifyContent="center"
                  borderWidth="1px"
                  borderColor="neutral.300"
                  bg="neutral.50"
                  _groupHover={{
                    bg: 'neutral.200',
                  }}
                >
                  <ImageSquareIcon color="neutral.500" />
                </Box>
              )}
            </Box>
            <Box flexGrow={1} p="2">
              <Button
                variant="outline"
                size="sm"
                mb="2"
                width="100%"
                event={null}
                color="gray.600"
                tabIndex={-1}
                isDisabled={isDisabled}
              >
                {value ? 'Replace' : 'Upload'}
              </Button>
            </Box>
          </Box>
          {error && (
            <Text fontSize="xs" color="error.500">
              {error}
            </Text>
          )}
        </Box>
      </Box>
    )
  },
)
