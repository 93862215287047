import * as z from 'zod'
import { PhoneCountryCode } from '@bounty/constants'
import { UnreachableCaseError } from '@bounty/utils'

export const australiaAddressSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  address1: z.string(),
  address2: z.string().optional(),
  suburb: z.string(),
  state: z.string(),
  postalCode: z.string(),
})
export type AustraliaAddress = z.infer<typeof australiaAddressSchema>

export const canadaAddressSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  address1: z.string(),
  address2: z.string().optional(),
  city: z.string(),
  province: z.string(),
  postalCode: z.string(),
})
export type CanadaAddress = z.infer<typeof canadaAddressSchema>

export const greatBritainAddressSchema = z.object({
  firstName: z.string(),
  lastName: z.string(),
  address1: z.string(),
  address2: z.string().optional(),
  city: z.string(),
  postalCode: z.string(),
})
export type GreatBritainAddress = z.infer<typeof greatBritainAddressSchema>

export const unitedStatesAddressSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  address1: z.string().min(1),
  address2: z.string().optional(),
  city: z.string().min(1),
  state: z.string().min(1),
  zip: z.string().length(5),
})
export type UnitedStatesAddress = z.infer<typeof unitedStatesAddressSchema>

export const getAddressSchemaFromCountryCode = (
  countryCode: PhoneCountryCode,
) => {
  switch (countryCode) {
    case 'AU':
      return australiaAddressSchema
    case 'CA':
      return canadaAddressSchema
    case 'GB':
      return greatBritainAddressSchema
    case 'US':
      return unitedStatesAddressSchema
    default:
      throw new UnreachableCaseError(countryCode)
  }
}
