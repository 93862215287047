import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
  ReactNode,
} from 'react'
import { logger } from '../utils/logger'
import { useLocation } from 'react-router-dom'
import { API_URL } from '../config/env'
import decode from 'jwt-decode'
import { logout as logoutUser } from '../apollo/utils'
import { ServerlessBackendApiJwtPayload } from '@bounty/types'
import { isFunction } from '@bounty/utils'

const isJwtExpired = <T extends { exp: number; data: any }>(jwt: T) => {
  const current_time = new Date().getTime() / 1000
  return current_time > jwt.exp
}

export type AuthState = UnauthedState | AuthedState
const AuthStateContext = createContext<AuthState | undefined>(undefined)

export type AuthDispatch = {
  setAuthState: React.Dispatch<React.SetStateAction<AuthState>>
  handleLoginSuccess: () => { redirectUrl: string }
  logout: () => Promise<void>
  login: () => Promise<void>
  setShopifyStoreUrl: (s: string) => void
}
export const AuthDispatchContext = createContext<AuthDispatch | undefined>(
  undefined,
)

type UnauthedState = {
  isAuthed: false
  isLoading: boolean
  shopifyStoreUrl: string
  authError: string | null
  user: null
  token: undefined
}

type AuthedState = {
  isAuthed: true
  isLoading: boolean
  shopifyStoreUrl: string
  authError: string | null
  user: ServerlessBackendApiJwtPayload['data']
  token: string
}

export const initialAuthState: UnauthedState = {
  isAuthed: false,
  isLoading: true,
  shopifyStoreUrl: '',
  authError: null,
  user: null,
  token: undefined,
}

export type AuthProviderProps = {
  /** Mostly used for testing but it seeds the initial auth state for the context */
  initialAuthState?: UnauthedState | AuthedState
  children: ReactNode | ((props: AuthState) => ReactNode)
}

export const AuthProvider = ({
  children,
  initialAuthState: initialAuthStateProp = initialAuthState,
}: AuthProviderProps) => {
  const [authState, setAuthState] = useState<AuthState>(initialAuthStateProp)
  const location = useLocation()
  const { shopifyStoreUrl, token } = authState

  // const [storeUrlLocal, setStoreUrlLocal] = useLocalStorage('storeUrl', '', {
  //   raw: true,
  // })

  const handleToken = useCallback(
    (token: string) => {
      try {
        const decodedToken = decode<ServerlessBackendApiJwtPayload>(token)
        if (isJwtExpired(decodedToken)) {
          throw new Error('Token is expired, sending to login!')
        }

        setAuthState((s) => ({
          ...s,
          token,
          isAuthed: true,
          isLoading: false,
          user: decodedToken.data,
          shopifyStoreUrl: decodedToken.data.shopUrl,
        }))
        localStorage.setItem('authToken', token)
        localStorage.setItem('storeUrl', decodedToken.data.shopUrl)

        return true
      } catch (error: any) {
        console.error('Error handling token', error)
        setAuthState((x) => ({
          ...x,
          isLoading: false,
          isAuthed: false,
          user: null,
          token: undefined,
        }))
        localStorage.removeItem('authToken')
        return false
      }
    },
    [setAuthState],
  )

  // Effect ran on mount of the app to check auth state and login if necessary
  useEffect(() => {
    const authToken = localStorage.getItem('authToken')
    if (!authToken) {
      setAuthState((x) => ({
        ...x,
        isLoading: false,
        isAuthed: false,
        token: undefined,
        user: null,
      }))
      return
    }

    handleToken(authToken)
  }, [handleToken, token])

  // Make sure the localStorage and useAuth hook store urls are in sync
  // useEffect(() => {
  //   if (!storeUrlLocal) return

  //   setAuthState((x) => ({
  //     ...x,
  //     shopifyStoreUrl: storeUrlLocal,
  //   }))
  // }, [storeUrlLocal, setAuthState])

  const handleLoginSuccess: AuthDispatch['handleLoginSuccess'] =
    useCallback(() => {
      const params = new URLSearchParams(location.search)
      const token = params.get('authToken')

      if (!token) {
        logger.warn('No token returned! Sending to login.')
        return { redirectUrl: '/login' }
      }

      return handleToken(token)
        ? { redirectUrl: '/' }
        : { redirectUrl: '/login' }
    }, [handleToken, location])

  const logout: AuthDispatch['logout'] = useCallback(async () => {
    return logoutUser()
  }, [])

  const login: AuthDispatch['login'] = useCallback(async () => {
    // Domains must end with myshopify.com to login to our store
    // https://help.shopify.com/en/manual/domains
    // if (shopifyStoreUrl.endsWith('myshopify.com') === false) {
    //   logger.warn('Cannot login if store URL is not valid!')

    //   setAuthState((x) => ({
    //     ...x,
    //     authError: 'URL must end with myshopify.com.',
    //   }))
    //   return
    // }

    const params = new URLSearchParams({ shop: shopifyStoreUrl })
    setAuthState((x) => ({
      ...x,
      isLoading: true,
      authError: null,
    }))

    // Clear any old tokens just in case
    localStorage.removeItem('authToken')

    window.location.assign(`${API_URL}/auth?${params.toString()}`)
  }, [shopifyStoreUrl])

  const setShopifyStoreUrl: AuthDispatch['setShopifyStoreUrl'] = useCallback(
    (s) => {
      // If the user clears everything out of the input then clear localstorage too
      if (s === '') {
        localStorage.setItem('storeUrl', '')
      }
      setAuthState((x) => ({ ...x, shopifyStoreUrl: s }))
    },
    [setAuthState],
  )

  return (
    <AuthStateContext.Provider value={authState}>
      <AuthDispatchContext.Provider
        value={{
          handleLoginSuccess,
          setAuthState,
          logout,
          login,
          setShopifyStoreUrl,
        }}
      >
        {isFunction(children) ? children(authState) : children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  )
}

export const useAuthState = (): AuthState => {
  const context = useContext(AuthStateContext)

  if (!context) {
    throw new Error('useAuthState must be used within a AuthProvider.')
  }

  return context
}

export const useAuthDispatch = (): AuthDispatch => {
  const context = useContext(AuthDispatchContext)

  if (!context) {
    throw new Error('useAuthDispatch must be used within a AuthProvider.')
  }

  return context
}

export const useAuth = (): [AuthState, AuthDispatch] => {
  return [useAuthState(), useAuthDispatch()]
}
