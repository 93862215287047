import { z } from 'zod'

export const RequestTrialForLicensesSchema = z.object({
  contentId: z.string(),
  contentType: z.string(),
})

export type RequestTrialForLicensesSchemaType = z.infer<
  typeof RequestTrialForLicensesSchema
>

export const PurchaseLicensesSchema = z.object({
  contentId: z.string(),
  contentType: z.string(),
  licenseTypes: z.array(
    z.enum(['SOCIAL_POST', 'PAID_MARKETING', 'OWNED_MARKETING']),
  ),
  amount: z.number().gt(0),
})

export type PurchaseLicensesSchemaType = z.infer<typeof PurchaseLicensesSchema>
