import {
  Tile,
  Heading,
  Text,
  Checkbox,
  Skeleton,
} from '@bounty/brands-design-system'
import { useQueryBackend, useMutationBackend } from '../apollo/backend/hooks'
import {
  SnippetsExperimentsTogglerDocument,
  ToggleIsSnippetExperimentsEnabledDocument,
} from '../generated/backendGraphql'

export type SnippetExperimentsProps = unknown

export const SnippetExperimentsToggler = () => {
  const { data, loading } = useQueryBackend(SnippetsExperimentsTogglerDocument)
  const [
    toggleSnippetExperiments,
    { loading: toggleSnippetExperimentsLoading },
  ] = useMutationBackend(ToggleIsSnippetExperimentsEnabledDocument)

  return (
    <Tile mb="6">
      <Heading as={'h2'} size="md" mb="2">
        Want better results? Participate in A/B tests.
      </Heading>
      <Text>
        We’re testing new CTA’s, designs and ways to increase signups for our
        brand partners, sign up if you’d like to participate.
      </Text>
      {loading === true || !data ? (
        <Skeleton height="20px" maxWidth="200px" />
      ) : (
        <Checkbox
          isChecked={data.currentStore.isSnippetExperimentsEnabled}
          isDisabled={toggleSnippetExperimentsLoading}
          onChange={(e) => {
            toggleSnippetExperiments({
              variables: {
                isSnippetExperimentsEnabled: e.target.checked,
              },
              optimisticResponse: {
                __typename: 'Mutation',
                updateStore: {
                  isSnippetExperimentsEnabled: e.target.checked,
                  id: data.currentStore.id,
                  __typename: 'ShopifyStore',
                },
              },
            })
          }}
        >
          <Text padding="2">
            By checking this box you agree to participate in A/B tests for the
            Bounty user experiences on your website.
          </Text>
        </Checkbox>
      )}
    </Tile>
  )
}
