import * as z from 'zod'

/** TODO add email address here and destructure it out */
export const CreateOrderRESTAddressPayloadSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  address1: z.string(),
  address2: z.string().optional(),
  city: z.string(),
  province: z.string(),
  zip: z.string(),
  country_code: z.enum(['US', 'CA']),
  phone: z.string().optional(),
})

export type CreateOrderRESTAddressPayload = z.infer<
  typeof CreateOrderRESTAddressPayloadSchema
>
