import { Skeleton, VStack, HStack, Text } from '@bounty/brands-design-system'
import { useQueryBackend } from '../../../apollo/backend/hooks'
import { BriefDetailsDocument } from '../../../generated/backendGraphql'
import { useParamsOrThrow } from '@bounty/hooks'
import { prettyFloatingDate } from '@bounty/utils'

const BriefDetailsItem = ({
  label,
  value,
}: {
  label: string
  value: string
}) => {
  return (
    <HStack width="100%" justifyContent="flex-start" alignItems={'flex-start'}>
      <Text as="span" fontWeight="semibold" fontSize={'lg'}>
        {label}
      </Text>
      <Text as="span" fontSize={'lg'}>
        {value}
      </Text>
    </HStack>
  )
}

export const BriefDetails = () => {
  const { briefId } = useParamsOrThrow(['briefId'])
  const { data, loading } = useQueryBackend(BriefDetailsDocument, {
    variables: { id: briefId },
  })

  if (loading || !data)
    return (
      <VStack>
        <Skeleton height="3rem" width="100%" />
        <Skeleton height="3rem" width="100%" />
        <Skeleton height="3rem" width="100%" />
      </VStack>
    )

  return (
    <VStack width="100%" alignItems="flex-start" data-testid="brief-details">
      <BriefDetailsItem label="Name:" value={data.brief.name} />
      <BriefDetailsItem label="Brief:" value={data.brief.content} />
      <BriefDetailsItem
        label="Created:"
        value={prettyFloatingDate(data.brief.createdAt)}
      />
    </VStack>
  )
}
