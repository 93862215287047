import { SVGProps } from 'react'

import { Icon, IconProps } from '@chakra-ui/react'

export type CopyClipboardRawProps = SVGProps<SVGSVGElement>

export const CopyClipboardRaw = ({ ...rest }: CopyClipboardRawProps) => {
  return (
    <svg
      width={21}
      height={22}
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15.0159 2.6001H8.58952C7.56085 2.6001 6.72085 3.4401 6.72085 4.46876V5.20376H5.98585C4.95719 5.20376 4.11719 6.04376 4.11719 7.07243V17.551C4.11719 18.5797 4.95719 19.4197 5.98585 19.4197H12.4123C13.4409 19.4197 14.2809 18.5797 14.2809 17.551V16.816H15.0159C16.0446 16.816 16.8846 15.976 16.8846 14.9473V4.46876C16.8846 3.4401 16.0446 2.6001 15.0159 2.6001ZM12.4124 18.7915H5.98594C5.29277 18.7915 4.72594 18.2247 4.72594 17.5315V7.07331C4.72594 6.38014 5.29277 5.81331 5.98594 5.81331H12.4115C13.1047 5.81331 13.6715 6.38014 13.6715 7.07331V16.5019V16.5232V16.5445V17.5527C13.6723 18.2237 13.1047 18.7915 12.4124 18.7915ZM16.2759 14.9271C16.2759 15.6203 15.7091 16.1871 15.0159 16.1871H14.2809V7.07352C14.2809 6.04485 13.4409 5.20485 12.4123 5.20485L7.30906 5.20403V4.46903C7.30906 3.77587 7.87589 3.20903 8.56906 3.20903H14.9955C15.6887 3.20903 16.2555 3.77587 16.2555 4.46903L16.2547 14.9272L16.2759 14.9271Z"
        fill="black"
        fillOpacity="0.5"
      />
    </svg>
  )
}

export const CopyClipboardIcon = (props: IconProps) => (
  <Icon as={CopyClipboardRaw} {...props} />
)
