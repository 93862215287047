export const GiftIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.75 7.5H4.25C3.83579 7.5 3.5 7.83579 3.5 8.25V11.25C3.5 11.6642 3.83579 12 4.25 12H20.75C21.1642 12 21.5 11.6642 21.5 11.25V8.25C21.5 7.83579 21.1642 7.5 20.75 7.5Z"
        stroke="#11047A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 12V18.75C20 18.9489 19.921 19.1397 19.7803 19.2803C19.6397 19.421 19.4489 19.5 19.25 19.5H5.75C5.55109 19.5 5.36032 19.421 5.21967 19.2803C5.07902 19.1397 5 18.9489 5 18.75V12"
        stroke="#11047A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 7.5V19.5"
        stroke="#11047A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.7469 6.44067C15.6781 7.50005 12.5 7.50005 12.5 7.50005C12.5 7.50005 12.5 4.32192 13.5594 3.25317C13.9821 2.83048 14.5554 2.59302 15.1531 2.59302C15.7509 2.59302 16.3242 2.83048 16.7469 3.25317C17.1696 3.67586 17.407 4.24915 17.407 4.84692C17.407 5.44469 17.1696 6.01798 16.7469 6.44067V6.44067Z"
        stroke="#11047A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25317 6.44067C9.32192 7.50005 12.5 7.50005 12.5 7.50005C12.5 7.50005 12.5 4.32192 11.4407 3.25317C11.018 2.83048 10.4447 2.59302 9.84692 2.59302C9.24915 2.59302 8.67586 2.83048 8.25317 3.25317C7.83048 3.67586 7.59302 4.24915 7.59302 4.84692C7.59302 5.44469 7.83048 6.01798 8.25317 6.44067V6.44067Z"
        stroke="#11047A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.2"
        d="M20 12V18.75C20 18.9489 19.921 19.1397 19.7803 19.2803C19.6397 19.421 19.4489 19.5 19.25 19.5H5.75C5.55109 19.5 5.36032 19.421 5.21967 19.2803C5.07902 19.1397 5 18.9489 5 18.75V12H20Z"
        fill="#11047A"
      />
    </svg>
  )
}
