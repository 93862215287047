import slugifyCore from 'slugify'

type SlugifyOptions = {
  replacement?: string | undefined
  remove?: RegExp | undefined
  lower?: boolean | undefined
  strict?: boolean | undefined
  locale?: string | undefined
  trim?: boolean | undefined
}

export const slugify = (string: string, options?: SlugifyOptions) =>
  slugifyCore(string, {
    lower: true,
    trim: true,
    remove: /[*+~.()'"!:@+,]/g,
    ...options,
  })
