import dlv from 'dlv'
import { ComponentProps, ReactNode } from 'react'
import { Controller, FieldValues, useFormContext } from 'react-hook-form'
import {
  Checkbox,
  CheckboxGroup,
  CheckboxGroupProps,
  forwardRef,
  Stack,
} from '../../Chakra'
import { FastFormControl } from './FastFormControl'
import { FastFormElementProps } from './types'
import { getLabelFromName } from './utils'

export type FastFormCheckboxType = ComponentProps<typeof Checkbox>
export const FastFormCheckboxOption = Checkbox

export type FastFormCheckboxGroupBaseProps = CheckboxGroupProps

export const FastFormCheckboxGroupBase = forwardRef<
  FastFormCheckboxGroupBaseProps,
  'div'
>(({ children, ...rest }, ref) => {
  return (
    <CheckboxGroup {...rest}>
      {/* Not sure the best ref to pass here. It's for focus management. Most likely should be the first checkbox input */}
      <Stack ref={ref} spacing="4">
        {children}
      </Stack>
    </CheckboxGroup>
  )
})

FastFormCheckboxGroupBase.displayName = 'FastFormCheckboxGroupBase'

export type FastFormCheckboxGroupProps<
  TFieldValues extends FieldValues = FieldValues,
> = FastFormElementProps<
  Omit<FastFormCheckboxGroupBaseProps, 'children'>,
  TFieldValues
> & {
  children: ReactNode
}

export const FastFormCheckboxGroup = <
  TFieldValues extends FieldValues = FieldValues,
>({
  label,
  name,
  helpText,
  labelProps,
  tooltipText,
  errorProps,
  isRequired,
  isDisabled,
  children,
  formControlProps,
  formElementProps,
  showLabel,
}: FastFormCheckboxGroupProps<TFieldValues>) => {
  const methods = useFormContext<TFieldValues>()
  const {
    formState: { errors },
    control,
  } = methods
  const error = dlv(errors, name)

  return (
    <FastFormControl
      error={error}
      helpText={helpText}
      label={label ?? getLabelFromName(name)}
      labelProps={labelProps}
      tooltipText={tooltipText}
      errorProps={errorProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
      showLabel={showLabel}
      {...formControlProps}
    >
      <Controller
        control={control}
        name={name}
        render={({ field }) => {
          return (
            <FastFormCheckboxGroupBase {...formElementProps} {...field}>
              {children}
            </FastFormCheckboxGroupBase>
          )
        }}
      />
    </FastFormControl>
  )
}
