import {
  Tile,
  Box,
  Text,
  Flex,
  Button,
  TileProps,
  Skeleton,
  Heading,
  Form,
  FormControl,
  Input,
  FormErrorMessage,
} from '@bounty/brands-design-system'
import { useState, ReactNode, useEffect } from 'react'
import { prettyCurrency } from '@bounty/utils'
import { DEFAULT_BUDGETED_VALUE } from '../../const'
import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import {
  BudgetTileDocument,
  UpdateSubscriptionDocument,
} from '../../generated/backendGraphql'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'

const BUDGET_AMOUNTS = ['1000', '2500', '5000', '10000', '100000']

export const monthlyBudgetSchema = z.object({
  value: z
    .string({
      required_error: 'Budget is required',
    })
    .refine((v) => Number(v) >= 1000, {
      message: 'Cannot be less than $1000',
    })
    .refine((v) => Number(v) <= 100000, {
      message: 'Cannot be more than $100,000',
    }),
})

export type MonthlyBudgetAmount = z.infer<typeof monthlyBudgetSchema>

export type MonthlyBudgetProps = Omit<TileProps, 'onSubmit'> & {
  editMode?: boolean
  initialValue?: number
  onSubmitSuccess?: (value: number) => void
  submitButtonContent?: ReactNode
  footerComponent?: ReactNode
}

export const MonthlyBudget = ({
  editMode = false,
  initialValue = DEFAULT_BUDGETED_VALUE,
  submitButtonContent = 'Set New Budget',
  onSubmitSuccess,
  footerComponent = null,
  ...rest
}: MonthlyBudgetProps) => {
  const { data, loading } = useQueryBackend(BudgetTileDocument)
  const [budget, setBudget] = useState(initialValue)
  const [updateSubscriptionMutation, { loading: updateSubLoading }] =
    useMutationBackend(UpdateSubscriptionDocument)
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getValues,
    reset,
  } = useForm<MonthlyBudgetAmount>({
    resolver: zodResolver(monthlyBudgetSchema),
  })

  const onSubmitBudget: SubmitHandler<MonthlyBudgetAmount> = async (data) => {
    onSubmitSuccess?.(parseInt(data.value))
  }

  const onSubmitUpdateBudget = async ({ value }: { value: string }) => {
    const { data } = await updateSubscriptionMutation({
      variables: {
        cappedAmount: value,
      },
    })
    if (data?.updateSubscription?.confirmation_url) {
      window.location.assign(data.updateSubscription.confirmation_url)
    }
  }
  useEffect(() => {
    if (data?.currentSubscription?.currentPlanDetails?.capped_amount) {
      setBudget(
        parseInt(data.currentSubscription.currentPlanDetails.capped_amount, 10),
      )
      reset({
        ...getValues(),
        value: parseInt(
          data.currentSubscription.currentPlanDetails.capped_amount,
        ).toString(),
      })
    }
  }, [data, getValues, reset])

  return (
    <Tile p={10} mb={6} {...rest} data-testid="monthly-budget-container">
      {loading || !data ? (
        <Skeleton height="40px" width="100%" mb="18" />
      ) : (
        <Flex flexDirection="column">
          <Flex flexDirection={'column'}>
            <Flex
              alignItems="center"
              width={'100%'}
              justifyContent="space-between"
              pb={12}
            >
              {editMode === true && (
                <Heading mb="4" size="md" marginTop="2">
                  Monthly budget
                </Heading>
              )}
            </Flex>

            <Form
              mb="8"
              display="flex"
              flexDir={'column'}
              alignItems="center"
              onSubmit={
                editMode
                  ? handleSubmit(onSubmitUpdateBudget)
                  : handleSubmit(onSubmitBudget)
              }
            >
              <Box>
                <Flex mb="4">
                  <FormControl
                    width="300px"
                    isInvalid={!!errors.value}
                    marginRight="1"
                  >
                    <Controller
                      defaultValue=""
                      name="value"
                      control={control}
                      render={({ field }) => (
                        <Input
                          placeholder="$5000"
                          variant="outline"
                          {...field}
                          onChange={(e) => {
                            const { value } = e.target
                            const valueWithoutDollar = value.startsWith('$')
                              ? value.substring(1)
                              : value
                            const isValueNotNumber = isNaN(
                              Number(valueWithoutDollar),
                            )
                            if (isValueNotNumber) {
                              e.target.value = ''
                              field.onChange(e)
                              return
                            }
                            e.target.value = valueWithoutDollar
                            field.onChange(e)
                            setBudget(parseInt(e.target.value))
                          }}
                          value={field.value ? `$${field.value}` : ''}
                        />
                      )}
                    />
                    {errors.value && (
                      <FormErrorMessage>
                        {errors.value.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>

                  <Button
                    px="8"
                    mx="4"
                    type="submit"
                    variant="solid"
                    isLoading={updateSubLoading}
                    event="Monthly Budget Form Submit"
                  >
                    {submitButtonContent}
                  </Button>
                </Flex>
                <Box>
                  {BUDGET_AMOUNTS.map((amount) => {
                    return (
                      <Button
                        key={amount}
                        variant="outline"
                        size="xs"
                        p="4"
                        mr="1"
                        mt="1"
                        type="button"
                        borderRadius="full"
                        event={{
                          eventName: 'Set Budget Amount Quick Button Clicked',
                          value: amount,
                        }}
                        onClick={() => {
                          setValue('value', amount)
                          setBudget(parseInt(amount))
                        }}
                      >
                        {prettyCurrency(parseInt(amount))}
                      </Button>
                    )
                  })}
                </Box>
              </Box>
            </Form>
            <Flex width={'100%'} justifyContent="space-between" pb={6}>
              <Box mr={4} flex="1">
                <Tile backgroundColor="gray.50" flex="1" px={10} py={6} mb={4}>
                  <Text textAlign="center">Soft Limit</Text>
                  <Text fontSize="lg" fontWeight={'bold'} textAlign="center">
                    {budget
                      ? prettyCurrency(budget * 0.75)
                      : data?.currentSubscription?.currentPlanDetails
                          ?.capped_amount
                      ? prettyCurrency(
                          parseInt(
                            data?.currentSubscription?.currentPlanDetails
                              ?.capped_amount,
                          ) * 0.75,
                        )
                      : '--'}
                  </Text>
                </Tile>
                <Text fontSize="0.7rem" textAlign={'center'} color="gray.500">
                  This is where we start to throttle the service
                </Text>
              </Box>

              <Box flex="1">
                <Tile backgroundColor="gray.50" px={10} py={6} flex="1" mb={4}>
                  <Text textAlign="center">Hard Limit</Text>
                  <Text fontSize="lg" fontWeight={'bold'} textAlign="center">
                    {budget
                      ? prettyCurrency(budget)
                      : data?.currentSubscription?.currentPlanDetails
                          ?.capped_amount
                      ? prettyCurrency(
                          parseInt(
                            data?.currentSubscription?.currentPlanDetails
                              ?.capped_amount,
                          ),
                        )
                      : '--'}
                  </Text>
                </Tile>
                <Text fontSize="0.7rem" textAlign={'center'} color="gray.500">
                  This is the maximum you can be billed
                </Text>
              </Box>
            </Flex>

            <Text mb="6">
              Due to the flexible nature of organic reach we begin to pause
              creators' ability to post Bounties when you start to approach your
              monthly budget. This begins when you've reached your soft limit.
              You will never be billed more than your hard limit in a given
              cycle.
            </Text>
            <Text mb="6">
              You'll receive notifications when you reach your soft limit, and
              can always top off your monthly budget if you'd like creators to
              keep posting.
            </Text>
            {footerComponent}
          </Flex>
        </Flex>
      )}
    </Tile>
  )
}
