import {
  GiftOfferRefType,
  GiftOfferStatus,
  StoreGiftType,
  UserBounty_Status,
} from '../../../generated/backendGraphql'

export const getGiftTypeDisplay = (giftType: StoreGiftType | undefined) => {
  switch (giftType) {
    case 'BOUNTY_AD_HOC':
      return 'Ad Hoc Bounty'
    case 'DISCOUNT_CODE':
      return 'Discount Code'
    case 'PRODUCT':
      return 'Product'
    default:
      return ''
  }
}

export const getGiftStatusDisplay = (
  giftStatus: GiftOfferStatus | undefined,
) => {
  switch (giftStatus) {
    case 'CLAIMED':
      return 'Claimed'
    case 'COMPLETED':
      return 'Redeemed'
    case 'OPEN':
      return 'Open'
    case 'REJECTED':
      return 'Rejected'
    default:
      return ''
  }
}

export const getBountyStatusDisplay = (
  bountyStatus: UserBounty_Status | undefined,
) => {
  switch (bountyStatus) {
    case 'COMPLETED':
      return 'Completed'
    case 'REJECTED':
      return 'Rejected'
    case 'ACCEPTED':
      return 'Accepted'
    case 'APPROVED':
      return 'Approved'
    case 'HOLD':
      return 'Hold'
    case 'LIVE':
      return 'Live'
    case 'PENDING':
      return 'Pending'
    default:
      return ''
  }
}

export const getRefTypeDisplay = (refType: GiftOfferRefType | undefined) => {
  switch (refType) {
    case 'EXTERNAL_INVITE':
      return 'Invites'
    case 'PROSPECT':
      return 'Prospects'
    case 'USER':
      return 'Creators Table'
  }
}
