import { EyeIcon } from '@bounty/brands-design-system'
import { prettyViews } from '@bounty/utils'
import { Flex, Text, Image } from '@bounty/brands-design-system'

import { useQueryBackend } from '../apollo/backend/hooks'
import {
  ContentLibraryItem,
  StoreProductDocument,
} from '../generated/backendGraphql'
import { getCloudinaryVideoImageUrl } from '../utils/urls'

type BountyCardProps = {
  content: ContentLibraryItem
}

const ProductImageComponent = ({ productId }: { productId: string }) => {
  const { data } = useQueryBackend(StoreProductDocument, {
    variables: { id: productId },
  })
  const product = data?.shopifyStoreProduct
  if (!product) return null

  return (
    <Flex
      borderRadius="md"
      p={2}
      alignItems="center"
      borderColor="gray.200"
      borderWidth="1px"
    >
      <Image
        src={product.imgLink ?? 'https://via.placeholder.com/30'}
        alt={product.productName ?? 'No name provided.'}
        height="30px"
        width="30px"
        objectFit="cover"
        borderRadius="sm"
        mr={2}
      />
      <Text fontWeight="semibold">
        {product.productName ?? 'No name provided.'}
      </Text>
    </Flex>
  )
}

export const BountyCard = ({ content }: BountyCardProps) => {
  return (
    <Flex
      width="100%"
      p={4}
      borderRadius="lg"
      borderColor="gray.500"
      borderWidth="1px"
      alignItems="flex-start"
    >
      <Image
        alt={content.profileName}
        src={getCloudinaryVideoImageUrl(content.externalContentId)}
        maxH="125px"
        objectFit="contain"
        borderRadius="md"
        mr={4}
      />
      <Flex flexDir="column" justifyContent="center">
        <Text mb={2} fontWeight="semibold">
          {content.profileName}
        </Text>
        <Text mb={2}>{content.title}</Text>
        {content.productId && (
          <ProductImageComponent productId={content.productId} />
        )}
      </Flex>
      <Flex alignItems="center">
        <EyeIcon />
        <Text ml="2" fontSize="lg" fontWeight="semibold">
          {prettyViews(content.viewCount ?? 0)}
        </Text>
      </Flex>
    </Flex>
  )
}
