import { useAnalytics } from '@bounty/web-analytics'
import {
  Flex,
  PageSkeleton,
  useToast,
  VStack,
  Button,
} from '@bounty/brands-design-system'
import { isAfter, subDays } from 'date-fns'
import { useState, useEffect } from 'react'
import { useMutationBackend, useQueryBackend } from '../../apollo/backend/hooks'
import {
  ContentLibraryResponse,
  CreateSparkCodeRequestDocument,
  GetRequestsForVideoDocument,
} from '../../generated/backendGraphql'
import { BountyCard } from '../BountyCard'
import { SparkCodeRequestForm } from './SparkCodeRequestForm'
import { SparkCodeRequestTimeline } from './SparkCodeRequestTimeline'

type SparkCodeRequestProps = {
  item: ContentLibraryResponse['items'][0]
  onSubmit: () => void
}

export const SparkCodeRequest = ({ item, onSubmit }: SparkCodeRequestProps) => {
  const toast = useToast()
  const { track } = useAnalytics()
  const [createSparkCodeRequest, { loading }] = useMutationBackend(
    CreateSparkCodeRequestDocument,
    {
      update(cache, { data }) {
        cache.modify({
          fields: {
            getRequestsForVideo: (previous, { toReference }) => {
              if (!data) return previous
              return [toReference(data.createSparkCodeRequest), ...previous]
            },
          },
        })
      },
    },
  )
  const { data, loading: requestsLoading } = useQueryBackend(
    GetRequestsForVideoDocument,
    // Assume this is a tiktok video id if this component is rendered
    { variables: { videoId: item.internalContentId } },
  )
  const [showSparkCodeForm, setShowSparkCodeForm] = useState(false)

  const hasNoSparkAdRequests = data?.getSparkCodeRequestsForVideo.length === 0
  useEffect(() => {
    if (hasNoSparkAdRequests) setShowSparkCodeForm(true)
  }, [hasNoSparkAdRequests, setShowSparkCodeForm])

  if (requestsLoading || !data) {
    return <PageSkeleton />
  }

  const sparkAdCodeRequests = [...data.getSparkCodeRequestsForVideo].sort(
    (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
  )

  // Show the button
  // - if we haven't already shown the form
  // - if we are in a terminal status and past waiting period
  const showRequestSparkCodeButton = () => {
    if (hasNoSparkAdRequests || showSparkCodeForm) return false

    const lastRequest = sparkAdCodeRequests[0]
    const pastRejected =
      lastRequest.status === 'CREATOR_REJECTED' &&
      !isAfter(new Date(lastRequest.updatedAt), subDays(new Date(), 1))
    const pastAccepted =
      lastRequest.status === 'PAID_OUT' &&
      !isAfter(new Date(lastRequest.updatedAt), subDays(new Date(), 30))

    return pastRejected || pastAccepted
  }

  return (
    <Flex width={['90%', '80%']} flexDir="column">
      <VStack spacing="6">
        <BountyCard content={item} />
        {showRequestSparkCodeButton() && (
          <Button
            borderRadius="full"
            size="lg"
            event="Request Another Spark Code Clicked"
            onClick={() => setShowSparkCodeForm(true)}
          >
            Request another
          </Button>
        )}
        {showSparkCodeForm ? (
          <SparkCodeRequestForm
            tikTokVideoId={item.internalContentId}
            status={item.status}
            isLoading={loading}
            onSubmit={async (variables) => {
              track('Content Detail Spark Code Requested', {
                offerPrice: variables.offerPrice,
              })
              const { data } = await createSparkCodeRequest({
                variables,
              })

              if (data?.createSparkCodeRequest.status === 'CREATED') {
                toast({
                  title: 'Spark Code Request Sent!',
                  description:
                    'We will contact you at the email associated with your Shopify account with next steps.',
                  status: 'success',
                })
              } else {
                toast({
                  title: 'Spark Code Request Failed',
                  description: 'Please wait a few moments and try again.',
                  status: 'error',
                })
              }
              onSubmit()
            }}
          />
        ) : (
          <VStack spacing={9}>
            {sparkAdCodeRequests.map((r) => (
              <SparkCodeRequestTimeline key={r.id} sparkCodeRequest={r} />
            ))}
          </VStack>
        )}
      </VStack>
    </Flex>
  )
}
