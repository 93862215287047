import { Box, FlexProps, Flex } from '@bounty/brands-design-system'
import { useScript } from '@bounty/hooks'
import { SNIPPET_THANK_YOU_URL } from '../../../config/env'

export type ThankYouSnippetPreviewProps = FlexProps & {
  configProps?: { [x: string]: any }
}

export const ThankYouSnippetPreview = ({
  configProps,
  ...props
}: ThankYouSnippetPreviewProps) => {
  useScript({ src: SNIPPET_THANK_YOU_URL, id: 'snippet-thank-you' })

  return (
    <Flex
      position="relative"
      height="100%"
      px="10"
      py="4"
      flexDirection="column"
      minHeight="150px"
      {...props}
    >
      <Flex flex={1} justifyContent="center" alignItems="center">
        <Box>
          {/* 
          // @ts-expect-error - Please add details why ignored */}
          <bounty-thank-you-sign-up></bounty-thank-you-sign-up>
          <script
            type="text/props"
            data-island-props="thank-you-sign-up"
            data-testid="thankYouSnippetProps"
          >
            {JSON.stringify(
              configProps
                ? { ...configProps, isDemoMode: true }
                : { isDemoMode: true },
            )}
          </script>
        </Box>
      </Flex>
    </Flex>
  )
}
