import { useState, SetStateAction } from 'react'
import { Page, Text } from '@bounty/brands-design-system'
import { useQueryBackend, useMutationBackend } from '../../apollo/backend/hooks'
import {
  ContentLibraryDocument,
  ContentLibraryInput,
  AddFavoriteToVideoDocument,
  RemoveFavoriteFromVideoDocument,
} from '../../generated/backendGraphql'
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  PageHeader,
} from '@bounty/brands-design-system'
import {
  ContentLibraryItemProps,
  ContentLibraryItems,
} from './ContentLibraryItems'
import { ContentLibraryFilters } from './ContentLibraryFilters'
import { useSearchParams } from 'react-router-dom'
import { convertToSearchParams, getDefaultParams } from './searchParams'
import { LearnMoreLink } from '../../components/LearnMoreLink/LearnMoreLink'

type ContentLibraryProps = {
  variant?: 'storefront'
  selectedVideoIds?: string[]
  setSelectedVideoIds?: React.Dispatch<React.SetStateAction<string[]>>
}

export const ContentLibrary = ({
  variant,
  selectedVideoIds,
  setSelectedVideoIds,
}: ContentLibraryProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [params, setParams] = useState<ContentLibraryInput>(
    getDefaultParams(searchParams),
  )
  const { data, loading, fetchMore, error } = useQueryBackend(
    ContentLibraryDocument,
    {
      variables: { params },
    },
  )
  const [addFavoriteToVideo] = useMutationBackend(AddFavoriteToVideoDocument)
  const [removeFavoriteFromVideo] = useMutationBackend(
    RemoveFavoriteFromVideoDocument,
  )

  /**
   * The favorite/unfavorite resolver does not return the entity that was favorited so we need to manually update
   * the cache when that happens. It doesn't stop there though! Since all of these filters are passed as params
   * every new filter applied creates a new watched query. When a user favorites/unfavorites we need to go through
   * every query that is watched with "isFavorited":true and augment the returned object refs to either add/remove
   * the favorited item.
   */
  const onFavoriteClicked: ContentLibraryItemProps['onFavoriteClicked'] =
    async (item) => {
      if (item.isFavorited) {
        removeFavoriteFromVideo({
          variables: {
            bountyId: item.id,
          },
          optimisticResponse: {
            __typename: 'Mutation',
            removeFavoriteFromVideo: true,
          },
          update(cache) {
            // First, update the document in the cache
            cache.modify({
              id: cache.identify(item),
              fields: {
                isFavorited() {
                  return false
                },
              },
            })

            // Next, remove the reference to that object in all the cached queries that filters on isFavorited: true
            cache.modify({
              id: 'ROOT_QUERY',
              fields(cachedQuery, { storeFieldName }) {
                if (
                  cachedQuery.__typename === 'ContentLibraryResponse' &&
                  storeFieldName
                    .replace(new RegExp('\\"', 'g'), '"')
                    .includes('"isFavorited":true')
                ) {
                  return {
                    ...cachedQuery,
                    items: cachedQuery.items.filter(
                      (i: any) => i.__ref !== cache.identify(item),
                    ),
                  }
                }
                return cachedQuery
              },
            })
          },
        })
      } else {
        addFavoriteToVideo({
          variables: {
            bountyId: item.id,
          },
          optimisticResponse: {
            __typename: 'Mutation',
            addFavoriteToVideo: true,
          },
          update(cache) {
            cache.modify({
              id: cache.identify(item),
              fields: {
                isFavorited() {
                  return true
                },
              },
            })

            cache.modify({
              id: 'ROOT_QUERY',
              fields(cachedQuery, { storeFieldName }) {
                if (
                  cachedQuery.__typename === 'ContentLibraryResponse' &&
                  storeFieldName
                    .replace(new RegExp('\\"', 'g'), '"')
                    .includes('"isFavorited":true')
                ) {
                  return {
                    ...cachedQuery,
                    items: [
                      ...cachedQuery.items,
                      { __ref: cache.identify(item) },
                    ],
                  }
                }
                return cachedQuery
              },
            })
          },
        })
      }
    }

  const syncParams = (update: SetStateAction<ContentLibraryInput>) => {
    setParams(update)
    setSearchParams(
      convertToSearchParams(
        typeof update === 'function' ? update(params) : update,
      ),
    )
  }

  const getDefaultIndex = () => {
    if (params.isFavorited) return 2
    if (params.filterBountyStatus === 'LIVE') return 1
    return 0
  }

  return (
    <Page
      fluid
      data-testid="content-library-page"
      name="Content Library"
      pb="12"
      addBottomSpacing={false}
    >
      {variant !== 'storefront' && (
        <PageHeader
          title="Content"
          description={
            <Text as="span">
              You have full, unlimited usage rights to every piece of content
              you receive through Bounty.
            </Text>
          }
        />
      )}
      <ContentLibraryFilters params={params} setParams={syncParams} />
      <Tabs
        defaultIndex={getDefaultIndex()}
        isLazy
        size="lg"
        onChange={(index) => {
          syncParams((x) => ({
            ...x,
            isFavorited: index === 1 ? true : null,
            filterBountyStatus: index === 0 ? 'APPROVED' : null,
          }))
        }}
      >
        <TabList>
          <Tab minWidth="110px">Completed</Tab>
          <Tab minWidth="110px">Favorites</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <ContentLibraryItems
              loading={loading}
              error={!!error}
              onFavoriteClicked={onFavoriteClicked}
              hasNextPage={
                !data ||
                data?.contentLibrary.items.length < data?.contentLibrary.count
              }
              onLoadMore={() => {
                fetchMore({
                  variables: {
                    params: {
                      ...params,
                      skip: data?.contentLibrary.items.length ?? 0,
                    },
                  },
                })
              }}
              items={data?.contentLibrary.items}
              variant={variant}
              selectedVideoIds={selectedVideoIds}
              setSelectedVideoIds={setSelectedVideoIds}
            />
          </TabPanel>

          <TabPanel>
            <ContentLibraryItems
              loading={loading}
              error={!!error}
              hasNextPage={
                !data ||
                data?.contentLibrary.items.length < data?.contentLibrary.count
              }
              onFavoriteClicked={onFavoriteClicked}
              onLoadMore={() => {
                fetchMore({
                  variables: {
                    params: {
                      ...params,
                      skip: data?.contentLibrary.items.length ?? 0,
                    },
                  },
                })
              }}
              items={data?.contentLibrary.items}
              variant={variant}
              selectedVideoIds={selectedVideoIds}
              setSelectedVideoIds={setSelectedVideoIds}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Page>
  )
}
