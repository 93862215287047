import { UnreachableCaseError } from '@bounty/utils'
import {
  CheckCircleIcon,
  ErrorIcon,
  InfoIcon,
  CurrencyCircleDollarIcon,
} from '../icons'
import {
  Alert as AlertCore,
  AlertProps as AlertPropsCore,
  AlertIcon as AlertIconCore,
  AlertIconProps as AlertIconPropsCore,
  AlertTitle as AlertTitleCore,
  AlertTitleProps as AlertTitlePropsCore,
  AlertDescription as AlertDescriptionCore,
  AlertDescriptionProps as AlertDescriptionPropsCore,
  CloseButton,
  CloseButtonProps,
  Box,
  useAlertStyles,
  ComponentStyleConfig,
} from './Chakra'

/**
 * NOTE: Internally these styles impact toasts for Chakra
 */
export const alertTheme: ComponentStyleConfig = {
  variants: {
    subtle: (props) => {
      const { colorScheme: c } = props
      return {
        container: {
          border: '1px',
          borderRadius: 'md',
          borderColor: `${c}.200`,
          bg: `${c}.50`,
        },
        title: {
          fontWeight: 'semibold',
          color: `${c}.900`,
        },
        icon: {
          color: `${c}.500`,
        },
        description: {
          color: `${c}.900`,
        },
      }
    },
    'left-accent': (props) => {
      const { colorScheme: c } = props
      return {
        container: {
          bg: `${c}.50`,
        },
        title: {
          fontWeight: 'semibold',
          color: `${c}.900`,
        },
        icon: {
          color: `${c}.500`,
        },
        description: {
          color: `${c}.900`,
        },
      }
    },
    'right-accent': (props) => {
      const { colorScheme: c } = props
      return {
        container: {
          bg: `${c}.50`,
        },
        title: {
          fontWeight: 'semibold',
          color: `${c}.900`,
        },
        icon: {
          color: `${c}.500`,
        },
        description: {
          color: `${c}.900`,
        },
      }
    },
    solid: (props) => {
      const { colorScheme: c } = props
      return {
        container: {
          bg: `${c}.50`,
        },
        title: {
          fontWeight: 'semibold',
          color: `${c}.900`,
        },
        icon: {
          color: `${c}.500`,
        },
        description: {
          color: `${c}.900`,
        },
      }
    },
  },
}

export type AlertProps = Omit<AlertPropsCore, 'status'> & {
  showIcon?: boolean
  status?: AlertPropsCore['status'] | 'neutral' | 'payment'
}

export const getIconForStatus = (status: AlertProps['status']) => {
  if (!status) throw new Error('status required')

  switch (status) {
    case 'info':
    case 'warning':
    case 'loading':
    case 'neutral':
      return InfoIcon
    case 'error':
      return ErrorIcon
    case 'success':
      return CheckCircleIcon
    case 'payment':
      return CurrencyCircleDollarIcon

    default:
      throw new UnreachableCaseError(status)
  }
}

export const getColorSchemeForStatus = (status: AlertProps['status']) => {
  if (!status) throw new Error('status required')

  switch (status) {
    case 'info':
      return 'primary'
    case 'warning':
      return 'warning'
    case 'loading':
    case 'neutral':
      return 'neutral'
    case 'error':
      return 'error'
    case 'payment':
    case 'success':
      return 'success'

    default:
      throw new UnreachableCaseError(status)
  }
}

export const Alert = ({
  children,
  status = 'info',
  showIcon = true,
  ...props
}: AlertProps) => {
  return (
    <AlertCore colorScheme={getColorSchemeForStatus(status)} {...props}>
      {showIcon && (
        <AlertIcon alignSelf={'flex-start'} as={getIconForStatus(status)} />
      )}
      <Box width="100%">{children}</Box>
    </AlertCore>
  )
}

export type AlertIconProps = AlertIconPropsCore

export const AlertIcon = (props: AlertIconProps) => {
  return <AlertIconCore {...props} />
}

export type AlertTitleProps = AlertTitlePropsCore

export const AlertTitle = (props: AlertTitleProps) => {
  return <AlertTitleCore {...props} />
}

export type AlertDescriptionProps = AlertDescriptionPropsCore

export const AlertDescription = (props: AlertDescriptionProps) => {
  return <AlertDescriptionCore {...props} />
}

export type AlertCloseButtonProps = CloseButtonProps

export const AlertCloseButton = (props: AlertCloseButtonProps) => {
  const styles = useAlertStyles()

  return (
    <CloseButton
      alignSelf="flex-start"
      position="absolute"
      color={styles.icon.color}
      right={'3'}
      top={'2'}
      size="sm"
      {...props}
    />
  )
}
