import {
  Alert,
  Box,
  Flex,
  Heading,
  List,
  ListItem,
  PageSkeleton,
  Text,
  Tooltip,
  useTheme,
} from '@bounty/brands-design-system'
import { useQueryBackend } from '../../../../apollo/backend/hooks'
import { UgcConversionFunnelMetricsDocument } from '../../../../generated/backendGraphql'
import { isNotNil, prettyPercentage } from '@bounty/utils'
import { useState } from 'react'
import {
  UgcConversionFunnelSection,
  UGC_CONVERSION_FUNNEL_SECTIONS,
} from './constants'
import { FunnelDatum, UgcConversionFunnel } from './UgcConversionFunnel'
import { InfoButton } from '../../../../components/InfoButton/InfoButton'

const FunnelQuickStats = () => {
  const theme = useTheme()
  const { data } = useQueryBackend(UgcConversionFunnelMetricsDocument)

  const metrics = data?.conversionFunnelMetrics.metrics

  const ordersTotalCount =
    metrics?.find((el) => el.step === 'OrdersTotal')?.count ?? 0
  const ordersWithUsersAndProductsCount =
    metrics?.find((el) => el.step === 'OrdersWithUsers')?.count ?? 0
  const userOrdersDeliveredCount =
    metrics?.find((el) => el.step === 'UserOrdersDelivered')?.count ?? 0
  const bountiesCompletedCount =
    metrics?.find((el) => el.step === 'BountiesCompleted')?.count ?? 0
  const bountiesApprovedCount =
    metrics?.find((el) => el.step === 'BountiesApproved')?.count ?? 0

  return (
    <Box mb="8">
      <Heading as="h3" size="sm" mb="2">
        Quick stats:
      </Heading>
      <List>
        <ListItem>
          <Text as="span" fontWeight="bold">
            {prettyPercentage(
              ordersWithUsersAndProductsCount / ordersTotalCount || 0,
            )}
          </Text>{' '}
          of your store’s orders are from “Bounty users”{' '}
          <Tooltip label="Bounty Users are people who sign up for Bounty and connect their TikTok to allow us to track their performance with first party data.">
            <InfoButton color={theme.colors.purple[500]} />
          </Tooltip>
        </ListItem>
        <ListItem>
          <Text as="span" fontWeight="bold">
            {prettyPercentage(
              userOrdersDeliveredCount / ordersWithUsersAndProductsCount || 0,
            )}
          </Text>{' '}
          of users’ orders were successfully marked delivered in Shopify
        </ListItem>
        <ListItem>
          <Text as="span" fontWeight="bold">
            {prettyPercentage(
              bountiesCompletedCount / userOrdersDeliveredCount || 0,
            )}
          </Text>{' '}
          of users who received their orders posted a video
        </ListItem>
        <ListItem>
          <Text as="span" fontWeight="bold">
            {prettyPercentage(
              bountiesApprovedCount / bountiesCompletedCount || 0,
            )}
          </Text>{' '}
          videos posted followed the rules and were approved for payment
        </ListItem>
      </List>
    </Box>
  )
}

type FunnelInfoDisplayProps = {
  currentSection: UgcConversionFunnelSection
}
const FunnelInfoDisplay = ({ currentSection }: FunnelInfoDisplayProps) => {
  return (
    <Box>
      <Heading as="h3" size="sm" mb="2">
        {currentSection.heading}
      </Heading>
      <Box>{currentSection.description}</Box>
    </Box>
  )
}

export const UgcConversionFunnelModule = () => {
  const { data: funnelMetrics, loading } = useQueryBackend(
    UgcConversionFunnelMetricsDocument,
  )

  const [currentSection, setCurrentSection] =
    useState<UgcConversionFunnelSection>(UGC_CONVERSION_FUNNEL_SECTIONS[0])

  const updateSelectedMetric = (funnelSection: FunnelDatum) => {
    const obj = UGC_CONVERSION_FUNNEL_SECTIONS.find(
      (el) => el.id === funnelSection.id,
    )
    if (isNotNil(obj)) setCurrentSection(obj)
  }

  if (!funnelMetrics || loading) return <PageSkeleton />

  const ordersWithUsersAndProductsCount =
    funnelMetrics.conversionFunnelMetrics.metrics?.find(
      (el) => el.step === 'OrdersWithUsers',
    )?.count ?? 0

  return ordersWithUsersAndProductsCount > 0 ? (
    <>
      <FunnelQuickStats />
      <Flex w="100%" h="360px" mb="8" gridGap={5}>
        <UgcConversionFunnel updateSelectedMetric={updateSelectedMetric} />
      </Flex>
      <FunnelInfoDisplay currentSection={currentSection} />
    </>
  ) : (
    <Alert status="info" mb="8">
      You don't have any data yet. The Automated UGC Funnel shows performance
      for orders from 10 to 40 days ago. This 10-day delay allows for orders to
      be delivered and bounties to be reviewed.
    </Alert>
  )
}
